<div class="card h-100" *ngIf="isReady()">
  <div class="card-header justify-content-between">
    <h6 class="card-title my-auto">Invitations & Requests</h6>
    <div class="btn-group" *ngIf="hasPages()">
      <a class="btn btn-sm btn-primary rounded-pill rounded-end px-3" [ngClass]="{disabled: !hasPrev()}" (click)="prev()">
        <app-icon name="angle-left" class="icon-white"></app-icon>
      </a>
      <a class="btn btn-sm btn-primary rounded-pill rounded-start px-3" [ngClass]="{disabled: !hasNext()}" (click)="next()">
        <app-icon name="angle-right" class="icon-white"></app-icon>
      </a>
    </div>
  </div>
  <div class="card-body">
    <p class="small">All students, managers and teachers will only be added once they have accepted the invitation. Below are the pending invitations which haven’t been accepted yet:
    </p>
    <p class="mt-5 text-center text-muted small" *ngIf="isEmpty()">There are no pending invitations</p>
    <div class="table-responsive" *ngIf="!isEmpty()">
      <table class="table table-sm table-borderless">
        <thead>
          <tr class="table-row table-row-head">
            <th>Email</th>
            <th>Role</th>
            <th>Expires</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let entry of getEntries()" class="table-row">
            <td>
              {{getMail(entry)}}
            </td>
            <th class="text-muted">
              {{getRole(entry)}}
            </th>
            <td class="text-muted">
              {{getExpiry(entry) | date: "dd MMM"}}
            </td>
            <td class="text-end">
              <a class="btn border-0" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                <app-icon name="more-vertical" class="icon-secondary"></app-icon>
              </a>
              <div class="dropdown-menu p-2 rounded-3" aria-labelledby="dropdownMenuReference">
                <a class="dropdown-item px-3 py-2 rounded-3 cursor-pointer" (click)="resend(entry)">Resend</a>
                <a class="dropdown-item px-3 py-2 rounded-3 cursor-pointer" (click)="revoke(entry)">Revoke</a>
                <a class="dropdown-item px-3 py-2 rounded-3 cursor-pointer text-danger" (click)="activate(entry)" *ngIf="mayBeActivated(entry)">Activate manually</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-modal #activationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Activate the user account manually</h5>
      <button type="button" class="btn-close" (click)="activationModal.hide()"  aria-label="Close">
      </button>
    </div>
    <div class="modal-body" >
      <p>
        Are you sure if you want to activate the request <strong>{{getMail(confirmationToActivate)}}</strong>
        [{{getRole(confirmationToActivate)}}] manually (<strong class="text-danger">not recommended</strong>) ?
      </p>
      <div class="alert alert-danger" *ngIf="hasError()">
        <h6>Check errors:</h6>
        <p>{{getErrorMessage()}}</p>
      </div>
      <form *ngIf="activationRequestForm" #f="ngForm" (ngSubmit)="f.form.valid && submitActivation()" appPasswordVerifyValidator>
        <div class="row gy-3">
          <div class="col-12">
            <div class="form-floating">
              <input
              type="password"
              class="form-control form-control-sm"
              autocomplete="off"
              name="managerPassword"
              placeholder="managerPassword"
              id="managerPassword"
              #managerPassword="ngModel"
              [(ngModel)]="activationRequestForm.passwords.managerPassword"
              required="required"
              [ngClass]="{'is-invalid': f.submitted && managerPassword.invalid}"
              minlength="3"
              >
              <label class="small">Your password</label>
              <div *ngIf="f.submitted && managerPassword.invalid" class="invalid-feedback">
                <div *ngIf="managerPassword.errors.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="managerPassword.errors.minlength" class="text-danger xsmall"> {{managerPassword.errors.minlength.requiredLength}} characters or more required</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input
              type="password"
              class="form-control form-control-sm"
              autocomplete="off"
              name="password"
              placeholder="password"
              id="password"
              #password="ngModel"
              [(ngModel)]="activationRequestForm.passwords.password"
              required="required"
              minlength="7"
              [ngClass]="{'is-invalid': f.submitted && password.invalid}"
              >
              <label class="small">User password</label>
              <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                <div *ngIf="password.errors.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="password.errors.minlength" class="text-danger xsmall"> {{password.errors.minlength.requiredLength}} characters or more required</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input
              type="password"
              class="form-control form-control-sm"
              autocomplete="off"
              name="password-verify"
              placeholder="password-verify"
              id="password-verify"
              #passwordVerify="ngModel"
              [(ngModel)]="userPasswordRetype"
              required="true"
              minlength="7"
              [ngClass]="{'is-invalid': f.submitted && (passwordVerify.invalid || f.errors?.differentPassword)}"
              >
              <label class="small">Retype user password</label>
              <div *ngIf="f.submitted && (passwordVerify.invalid || f.errors?.differentPassword)" class="invalid-feedback">
                <div *ngIf="passwordVerify.errors?.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="passwordVerify.errors?.minlength" class="text-danger xsmall"> {{passwordVerify.errors.minlength.requiredLength}} characters or more required</div>
                <div *ngIf="f.errors?.differentPassword" class="text-danger xsmall">Passwords are different</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-secondary rounded-pill px-3" (click)="activationModal.hide()">Cancel</button>
            <button class="btn btn-primary rounded-pill px-3 ms-2">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-modal>
