<div class="table-responsive" *ngIf="data">
  <table class="table">
    <thead>
      <tr class="table-row table-row-head">
        <th>Name</th>
        <th>Attendees</th>
        <th style="width: 36px;"></th>
      </tr>
    </thead>
    <tbody class="table-body cursor-pointer">
      <tr *ngFor="let room of data.content" class="table-row" (click)="openRoom(room)">
        <td>
          {{getName(room)}} <span class="text-uppercase badge xsmall bg-warning">{{getStatus(room)}}</span><br>
          <p class="mb-0 lh-sm">
            <span class="text-muted xsmall">
              Classroom opened at {{getCreated(room) | date: "HH:mm (dd MMM)"}}
            </span>
            <span class="text-muted xsmall" *ngIf="getStarted(room)">
              <br>Lesson started: {{getStarted(room) | date: "HH:mm (dd MMM)"}}
            </span>
            <span class="text-muted xsmall" *ngIf="getFinished(room)">
              <br>Lesson finished: {{getFinished(room) | date: "HH:mm"}}
            </span>
            <span class="text-muted xsmall" *ngIf="getDuration(room)">
              <br>Duration: {{getDuration(room)}}
            </span>
          </p>
        </td>
        <td>
          {{getTeacher(room)}}<br>
          <span class="text-muted xsmall">{{getNbOfStudents(room)}} students</span>
        </td>
        <td>
          <button class="btn rounded-circle btn-outline-secondary-2" type="button" (click)="openRoom(room)">
            <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
