import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile} from 'src/app/model/rest/rest-model';
import {LessonType} from "../../components/student-lessons/student-lessons.component";
import {tap} from "rxjs/operators";
import {Page, Pageable} from "../../model/rest/base";

@Component({
  selector: 'app-manager-student-lessons-page',
  templateUrl: './manager-student-lessons-page.component.html',
  styleUrls: ['./manager-student-lessons-page.component.css']
})
export class ManagerStudentLessonsPageComponent implements OnInit {
  schoolId: number;
  studentId: number;
  lessonTypes = LessonType;
  lang = 'en';
  futureLessonsPage: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  futureLessonsPageable: Pageable;
  pastLessonsPage: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  pastLessonsPageable: Pageable;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.paramMap.pipe(
      tap( params => {
        this.schoolId = Number(params.get('schoolId'));
        this.studentId = Number(params.get('studentId'));
      }),
      ).subscribe()
  }

  ngOnInit() {
  }


  futureLessonsPageLoaded($event: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>) {
    this.futureLessonsPage = $event;
  }
}
