<div class="row">
  <div class="col-12 col-xl-5 col-xxl-4 order-xl-1 ms-auto">
    <div class="card card-border" *ngIf="!isSchoolPostPayAllowed() && creditsLoaded()">
      <div class="card-body">
        <h5 class="card-title">eBook credits</h5>
        <p class="my-4"><strong *ngIf="!hasAnyCreditRow()">You have no credits</strong></p>
        <div *ngIf="hasAnyCreditRow()">
          <p class="card-text text-muted small">Each eBook costs one credit. Your credit balance:</p>
          <ul class="list-unstyled">
            <li *ngFor="let entry of getCreditsEntriesSorted()">
              {{getCreditEntryNameWithAmountText(entry)[0]}}:
              <span class="text-muted"> {{getCreditEntryNameWithAmountText(entry)[1]}}</span>
            </li>
          </ul>
        </div>
        <button type="button" class="btn btn-link text-decoration-none px-0" data-bs-toggle="modal" data-bs-target="#creditsCart">Buy Credits</button>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-6 order-xl-0">
    <div class="alert alert-danger" *ngIf="error">
      Can't finish the order. Check the order and your credits amount and try again.
    </div>
    <div class="alert alert-success" *ngIf="success">
      Order finished successfully.
    </div>
    <div *ngFor="let group of productsCatalogue" class="mt-4">
      <h6 class="border-bottom border-2 border-secondary pb-3">{{group.groupName}} products</h6>
      <div class="table-responsive">
        <table class="table table-sm table-borderless w-auto">
          <thead>
            <tr>
              <th></th>
              <th class="text-center">select all</th>
              <th *ngFor="let stageIndex of stageIndexArray" class="text-center">{{getStageNumber(group, stageIndex)}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let subGroup of group.subGroups"
                [ngClass]="{'opacity-50' : !hasSubscriptionForProducts(subGroup)}"
                [attr.data-bs-toggle]="!hasProductCredits(subGroup) && !isFreeProduct(subGroup)? 'modal' : ''"
                [attr.data-bs-target]="!hasProductCredits(subGroup) && !isFreeProduct(subGroup)? '#creditsCart' : ''"
                (click)="openSubscriptionModal(subGroup)">
              <th>{{subGroup.name}}</th>
              <td class="text-center">
                <div class="form-check d-flex p-0">
                  <input class="form-check-input mx-auto"
                          type="checkbox"
                          [checked]="isSelectAllChecked(group, subGroup)"
                          [disabled]="isSelectAllDisabled(group, subGroup)"
                          (change)="onGroupSelectAllChange(group, subGroup, $event)"
                          [ngClass]="{'pe-none' : !hasSubscriptionForProducts(subGroup)}">
                </div>
              </td>
              <td *ngFor="let stageIndex of stageIndexArray" class="text-center">
                <div class="form-check d-flex p-0" *ngIf="subGroupHasStageOnPosition(subGroup, stageIndex)">
                  <input
                    class="form-check-input mx-auto"
                    type="checkbox"
                    [checked]="isProductCheckedByIndex(group, subGroup, stageIndex)"
                    [disabled]="isProductDisabledByIndex(group, subGroup, stageIndex)"
                    (change)="onProductChange(group, subGroup, stageIndex, $event)"
                    [ngClass]="{'pe-none' : !hasSubscriptionForProducts(subGroup)}"
                  >
                </div>
              </td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
    <div class="mt-1 text-end" *ngIf="!hideControls && hasAnySelection()">
      <button class="btn btn-secondary rounded-pill me-2" (click)="clearSelection()" [disabled]="isLoading()">Clear Selection</button>
      <button class="btn btn-primary rounded-pill" (click)="submitOrder()" [disabled]="isLoading()">
        <span *ngIf="isLoading()" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Submit Order</button>
    </div>
  </div>
</div>
<app-buy-credits-cart [school]="schoolDetails"></app-buy-credits-cart>

