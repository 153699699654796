<div class="card">
  <div class="card-header">
    <h4>Edit school</h4>
  </div>
  <div class="card-body" *ngIf="school">
    <form
      #fOldSchoolId="ngForm"
      (ngSubmit)="fOldSchoolId.form.valid && saveOldSchoolId()"
    >
      <div class="row position-relative">
        <div class="col-12 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="oldSchoolId"
              #oldSchoolId="ngModel"
              placeholder="oldSchoolId"
              id="oldSchoolId"
              [(ngModel)]="school.oldId"
              [ngClass]="{
                'is-invalid': fOldSchoolId.submitted && oldSchoolId.invalid
              }"
              class="form-control"
            />
            <label for="name">Old School Id</label>
          </div>
          <button
            *ngIf="fOldSchoolId.dirty"
            type="submit"
            class="btn btn-primary btn-sm rounded-pill px-2 btn-inside-input"
            [disabled]="isDisabled(oldSchoolIdSaveStatus)"
          >
            <div
              class="spinner-grow spinner-grow-xs text-secondary"
              role="status"
              *ngIf="isDisabled(oldSchoolIdSaveStatus)"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ oldSchoolIdSaveStatus }}
          </button>
        </div>
      </div>
    </form>

    <hr class="border-top border-2 my-4" />

    <h4 class="mb-4">School details</h4>
    <form
      #fSchoolDetails="ngForm"
      (ngSubmit)="fSchoolDetails.form.valid && saveSchoolDetails()"
      class="my-2"
    >
      <div class="row">
        <div class="col-12 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="schoolName"
              #schoolName="ngModel"
              placeholder="schoolName"
              id="schoolName"
              [(ngModel)]="school.details.name"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && schoolName.invalid
              }"
              class="form-control"
              required
            />
            <label for="name" class="field-required">Name of School</label>
            <div
              *ngIf="fSchoolDetails.submitted && schoolName.invalid"
              class="invalid-feedback"
            >
              <span
                *ngIf="schoolName.errors.required"
                class="xsmall text-danger"
                >Field is required</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <h5 class="my-3">Address</h5>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="addressLine1"
              #addressLine1="ngModel"
              placeholder="addressLine1"
              id="addressLine1"
              [(ngModel)]="school.details.address.line1"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && addressLine1.invalid
              }"
              class="form-control"
            />
            <label for="name">Street</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="addressCity"
              #addressCity="ngModel"
              placeholder="addressCity"
              id="addressCity"
              [(ngModel)]="school.details.address.city"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && addressCity.invalid
              }"
              class="form-control"
            />
            <label for="name">City</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="county"
              #county="ngModel"
              placeholder="county"
              id="county"
              [(ngModel)]="school.details.address.county"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && county.invalid
              }"
              class="form-control"
            />
            <label for="name">County</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <select
              id="country"
              name="country"
              class="form-select"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && country.invalid,
                'select-not-empty': school.details.address.country
              }"
              #country="ngModel"
              [(ngModel)]="school.details.address.country"
            >
              <option *ngFor="let country of countries" [value]="country.name">
                {{ country.name }}
              </option>
            </select>
            <label>Contry</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="d-flex mb-4">
          <h5 class="w-fit-content my-auto">Contact</h5>
          <button
            type="button"
            class="btn btn-primary rounded-circle px-2 ms-auto"
            (click)="addContact()"
          >
            <app-icon name="plus" class="icon-white icon-sm"></app-icon>
          </button>
        </div>
      </div>
      <div
        class="row"
        *ngFor="let contact of school.details.contacts; let i = index"
      >
        <div class="col-12 col-sm-4 col-xl mb-4">
          <div class="form-floating">
            <select
              id="contactType-{{ i }}"
              name="contactType-{{ i }}"
              class="form-select"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && contactType.invalid,
                'select-not-empty': school.details.contacts[i].entryType
              }"
              #contactType="ngModel"
              [(ngModel)]="school.details.contacts[i].entryType"
              required
            >
              <option value="PHONE">Phone</option>
              <option value="EMAIL">Email</option>
              <option value="SKYPE">Skype</option>
              <option value="WEBSITE">Web Site</option>
              <option value="CUSTOM">Custom</option>
            </select>
            <label class="field-required">Contact type</label>
            <div
              *ngIf="fSchoolDetails.submitted && contactType.invalid"
              class="invalid-feedback"
            >
              <span
                *ngIf="contactType.errors.required"
                class="xsmall text-danger"
                >Field is required</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-8 col-xl mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="contactValue-{{ i }}"
              #contactValue="ngModel"
              placeholder="contactValue"
              id="contactValue-{{ i }}"
              [(ngModel)]="school.details.contacts[i].value"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && contactValue.invalid
              }"
              class="form-control"
              required
            />
            <label for="name" class="field-required">Contact</label>
            <div
              *ngIf="fSchoolDetails.submitted && contactValue.invalid"
              class="invalid-feedback"
            >
              <span
                *ngIf="contactValue.errors.required"
                class="xsmall text-danger"
                >Field is required</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-sm col-xl mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="contactName-{{ i }}"
              #contactName="ngModel"
              placeholder="contactName"
              id="contactName-{{ i }}"
              [(ngModel)]="school.details.contacts[i].name"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && contactName.invalid
              }"
              class="form-control"
            />
            <label for="name">Contact name</label>
          </div>
        </div>
        <div class="col mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="contactPerson-{{ i }}"
              #contactPerson="ngModel"
              placeholder="contactPerson"
              id="contactPerson-{{ i }}"
              [(ngModel)]="school.details.contacts[i].contactPerson"
              [ngClass]="{
                'is-invalid': fSchoolDetails.submitted && contactPerson.invalid
              }"
              class="form-control"
            />
            <label for="name">Person's name</label>
          </div>
        </div>
        <div class="col-auto mb-4 d-flex ms-auto">
          <button
            type="button"
            class="btn btn-primary rounded-circle p-2 lh-sm my-auto"
            (click)="removeContact(i)"
            data-toggle="tooltip"
            title="Delete user from the list"
          >
            <app-icon name="delete" class="icon-white"></app-icon>
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary rounded-pill px-3"
            [disabled]="isDisabled(schoolDetailsSaveStatus)"
          >
            <div
              class="spinner-grow spinner-grow-sm text-secondary"
              role="status"
              *ngIf="isDisabled(schoolDetailsSaveStatus)"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ schoolDetailsSaveStatus }}
          </button>
        </div>
      </div>
    </form>

    <hr class="border-top border-2 my-4" />

    <h4 class="mb-4">Corporate details</h4>
    <form
      #fCorpDetails="ngForm"
      (ngSubmit)="fCorpDetails.form.valid && saveCorpDetails()"
      class="my-2"
    >
      <div class="row">
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="corpEntityName"
              #corpEntityName="ngModel"
              placeholder="corpEntityName"
              id="corpEntityName"
              [(ngModel)]="school.corpDetails.corpEntityName"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && corpEntityName.invalid
              }"
              class="form-control"
            />
            <label for="name">Corporate entity name</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="corpNumber"
              #corpNumber="ngModel"
              placeholder="corpNumber"
              id="corpNumber"
              [(ngModel)]="school.corpDetails.corpNumber"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && corpNumber.invalid
              }"
              class="form-control"
            />
            <label for="name">Corporate number</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="corpDetails"
              #corpDetails="ngModel"
              placeholder="corpDetails"
              id="corpDetails"
              [(ngModel)]="school.corpDetails.corpDetails"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && corpDetails.invalid
              }"
              class="form-control"
            />
            <label for="name">Corporate details</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="corpAddress"
              #corpAddress="ngModel"
              placeholder="corpAddress"
              id="corpAddress"
              [(ngModel)]="school.corpDetails.corpAddress"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && corpAddress.invalid
              }"
              class="form-control"
            />
            <label for="name">Corporate address</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="nameOfPartners"
              #nameOfPartners="ngModel"
              placeholder="nameOfPartners"
              id="nameOfPartners"
              [(ngModel)]="school.corpDetails.nameOfPartners"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && nameOfPartners.invalid
              }"
              class="form-control"
            />
            <label for="name">Name of partners</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="bankReference"
              #bankReference="ngModel"
              placeholder="bankReference"
              id="bankReference"
              [(ngModel)]="school.corpDetails.bankReference"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && bankReference.invalid
              }"
              class="form-control"
            />
            <label for="name">Bank reference</label>
          </div>
        </div>
        <div class="col-12 col-sm-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              name="capital"
              #capital="ngModel"
              placeholder="capital"
              id="capital"
              [(ngModel)]="school.corpDetails.capital"
              [ngClass]="{
                'is-invalid': fCorpDetails.submitted && capital.invalid
              }"
              class="form-control"
            />
            <label for="name">Capital</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary rounded-pill px-3"
            [disabled]="isDisabled(schoolCorpDetailsSaveStatus)"
          >
            <div
              class="spinner-grow spinner-grow-sm text-secondary"
              role="status"
              *ngIf="isDisabled(schoolCorpDetailsSaveStatus)"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ schoolCorpDetailsSaveStatus }}
          </button>
        </div>
      </div>
    </form>

    <hr class="border-top border-2 my-4" />

    <h4 class="mb-4">School labels</h4>
    <form
      #fSchoolLabels="ngForm"
      (ngSubmit)="fSchoolLabels.form.valid && saveSchoolLabels()"
      class="my-2"
    >
      <div class="row">
        <div class="col-12 mb-4">
          <div class="form-floating">
            <textarea
              type="text"
              name="schoolLabels"
              #schoolLabels="ngModel"
              placeholder="schoolLabels"
              id="schoolLabels"
              [(ngModel)]="school.labels"
              [ngClass]="{
                'is-invalid': fSchoolLabels.submitted && schoolLabels.invalid
              }"
              class="form-control"
            ></textarea>
            <label for="learningUnitLabels">Learning Unit Labels</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary rounded-pill px-3"
            [disabled]="isDisabled(schoolLabelsSaveStatus)"
          >
            <div
              class="spinner-grow spinner-grow-sm text-secondary"
              role="status"
              *ngIf="isDisabled(schoolLabelsSaveStatus)"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ schoolLabelsSaveStatus }}
          </button>
        </div>
      </div>
    </form>

    <hr class="border-top border-2 my-4" />

    <h4 class="mb-4">School status</h4>
    <form
      #fSchoolStatus="ngForm"
      (ngSubmit)="fSchoolStatus.form.valid && saveSchoolStatusDetails()"
      class="my-2"
    >
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="date"
              name="expiryDate"
              #expiryDate="ngModel"
              placeholder="expiryDate"
              id="expiryDate"
              [(ngModel)]="school.expDate"
              [ngClass]="{
                'is-invalid': fSchoolStatus.submitted && expiryDate.invalid
              }"
              class="form-control"
            />
            <label for="name">Expiry date</label>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <select
              id="schoolStatus"
              name="schoolStatus"
              #schoolStatus="ngModel"
              [ngClass]="{
                'is-invalid': fSchoolStatus.submitted && schoolStatus.invalid,
                'select-not-empty': school?.status
              }"
              [(ngModel)]="school.status"
              class="form-select"
              required
            >
              <option
                [value]="status"
                *ngFor="let status of getSchoolStatusList()"
              >
                {{ getSchoolStatusName(status) }}
              </option>
            </select>
            <label class="field-required">Status of the school</label>
            <div
              *ngIf="fSchoolStatus.submitted && schoolStatus.invalid"
              class="invalid-feedback"
            >
              <span
                *ngIf="schoolStatus.errors.required"
                class="xsmall text-danger"
                >Field is required</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary rounded-pill px-3"
            [disabled]="isDisabled(schoolStatusDetailsSaveStatus)"
          >
            <div
              class="spinner-grow spinner-grow-sm text-secondary"
              role="status"
              *ngIf="isDisabled(schoolStatusDetailsSaveStatus)"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            {{ schoolStatusDetailsSaveStatus }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
