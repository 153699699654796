import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { zip,  of } from 'rxjs';
import {map, switchMap, take, tap, timeoutWith} from 'rxjs/operators';
import { VideoServerRestService } from 'src/app/services/rest/video-server-rest.service';
import {
  TemplateDetails,
  TemplateParticipantDetailsBase, RoomSettings, RoomDef, IdentifiedRoomTemplate, IdentifiedTemplateParticipant
} from 'src/app/model/server';
import { RoomTemplateEditComponent } from 'src/app/components/room-template-edit/room-template-edit.component';
import { SearchingContextService } from 'src/app/services/searching-context.service';
import { ProgressDataProvider } from 'src/app/services/helpers/progress-history-list.provider';
import { Constants } from 'src/app/services/constants';
import {
  ListEvent,
  SchedulesListComponent
} from "../../components/schedules-list/schedules-list.component";
import {ScheduleListQuery, SchedulesListProvider} from "../../components/schedules-list/schedules-list.provider";
import {ScheduleRestService} from "../../services/rest/schedule-rest.service";
import {ScheduleRowSimplified} from "../../model/schedule";
import {CreateScheduleComponent} from "../../components/create-schedule/create-schedule.component";
import {DateUtils} from "../../services/helpers/date-utils";
import {ModalComponent} from "../../components/modal/modal.component";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {School} from "../../model/rest/casa-apiv2.model";
import {Page, Pageable} from "../../model/rest/base";
import {LocalStateService} from "../../services/local-state.service";

@Component({
  selector: 'app-room-template-details-page',
  templateUrl: './room-template-details-page.component.html',
  styleUrls: ['./room-template-details-page.component.css']
})
export class RoomTemplateDetailsPageComponent implements OnInit {

  schoolId: number;
  templateId: number = null;
  template: IdentifiedRoomTemplate;
  historyLength = 0;
  historyDataProvider: ProgressDataProvider = null;
  @ViewChild('startConfirmModal', {static: true}) startConfirmModal: ModalComponent;
  @ViewChild('deleteConfirmModal', {static: true}) deleteConfirmModal: ModalComponent;
  @ViewChild('schedulesListComponent', {static: false}) schedulesListComponent: SchedulesListComponent;
  @ViewChild('createScheduleComponent', {static: true}) createScheduleComponent: CreateScheduleComponent;
  @ViewChild('startScheduleConfirmModal', {static: true}) startScheduleConfirmModal: ModalComponent;
  private schedulesDataProvider: SchedulesListProvider;
  scheduleToDelete: ScheduleRowSimplified;
  scheduleToStart: ScheduleRowSimplified;
  isScheduleLoading = false;
  schedulesCount: number = 0;
  savedCorrectly = false;
  saveError = false;
  deleteFollowing = false;
  private school: School;
  roomsPageable: Pageable;
  roomsData: Page<RoomDef>;

  constructor(route: ActivatedRoute,
              private rest: VideoServerRestService,
              private router: Router,
              private searchingContext: SearchingContextService,
              private schedulesRest: ScheduleRestService,
              private casa: CasaRestApiV2,
              private localStateService: LocalStateService
    ) {
    zip(
      route.parent.paramMap.pipe(
        map( params => Number(params.get('schoolId')))
      ),
      route.paramMap.pipe(
        map( params => params.get('id'))
      )
    ).subscribe(
      ([schoolId, templateId]) => this.setupData(schoolId, templateId)
    );
  }

  setupData(schoolId: number, templateId: string): void {
    this.schoolId = schoolId;
    this.loadSchool();
    if (templateId === 'new') {
      this.createNewTemplate();
    } else {
      this.templateId = Number(templateId);
      this.reloadTemplate();
      this.restoreRoomsSearching().pipe(
        switchMap( pageable => this.loadRooms(pageable))
      ).subscribe();
      this.restoreHistorySearching();
      this.restoreScheduleSearching();
    }
  }

  private restoreScheduleSearching() {
    const contextPath = `/schools/${this.schoolId}/templates/${this.templateId}/schedules`;
    this.searchingContext.getOrCreateContext<ScheduleListQuery>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new ScheduleListQuery(0 , this.templateId)))
    ).subscribe( initialQuery => {
      this.schedulesDataProvider = new SchedulesListProvider(contextPath, this.searchingContext, this.schedulesRest, this.schoolId, initialQuery);
    })
  }

  roomsContextPath(){
    return `/schools/${this.schoolId}/template/${this.templateId}/rooms`;
  }

  private loadRooms(pageable: Pageable) {
    this.roomsPageable = pageable;
    return this.localStateService.set(this.roomsContextPath(), pageable).pipe(
      switchMap(pageable => this.rest.listSchooTemplatelRooms(this.schoolId, this.templateId, pageable)),
      tap<Page<RoomDef>>( rooms => this.roomsData = rooms)
    )
  }

  restoreRoomsSearching() {
    const contextPath = this.roomsContextPath();
    return this.localStateService.get<Pageable>( contextPath ,
      () => Pageable.of(0, 10, ['createDate,desc'])
      ).pipe(
        tap(pageable => this.roomsPageable = pageable)
    )
  }

  hasRoomData() {
    return this.roomsData != null;
  }

  getRoomsCount() {
    if (!this.hasRoomData()) return null;
    return this.roomsData.totalElements;
  }

  changeRoomsPage(toPage: Pageable) {
    this.loadRooms(toPage).subscribe();
  }

  restoreHistorySearching() {
    const contextPath = `/schools/${this.schoolId}/template/${this.templateId}/progress-history`;
    this.searchingContext.getOrCreateContext<Pageable>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new Pageable(0, Constants.SmallPageSize, ['createDate,desc'])))
    ).subscribe( initialPageable =>
      this.historyDataProvider = new ProgressDataProvider(
        this.schoolId,
        this.templateId,
        contextPath,
        this.searchingContext,
        this.rest,
        initialPageable
      )
    );
  }

  reloadTemplate() {
    this.rest.getTemplateById(this.schoolId, this.templateId).pipe(
      tap( template => this.fixParticipants(template))
    ).subscribe(
      template => this.template = template
    );
  }

  createNewTemplate() {
    this.template = new IdentifiedRoomTemplate();
    this.template.details = new TemplateDetails();
    const emptyTeacher = new IdentifiedTemplateParticipant();
    emptyTeacher.details = new TemplateParticipantDetailsBase();
    emptyTeacher.details.role = 'Teacher';
    const emptyStudent = new IdentifiedTemplateParticipant();
    emptyStudent.details = new TemplateParticipantDetailsBase();
    emptyStudent.details.role = 'Student';
    this.template.participants = [emptyTeacher, emptyStudent];
  }

  onEditEvent(event: string) {
    if (event === RoomTemplateEditComponent.EVENT_CANCEL) {
      if (this.templateId == null) {
        this.createNewTemplate();
      } else {
        this.reloadTemplate();
      }
    } else if (event === RoomTemplateEditComponent.EVENT_SAVE) {
      this.saveTemplate();
    }
  }

  onDelete() {
    this.deleteConfirmModal.hide();
    if (this.scheduleToDelete != null) {
      this.schedulesRest.deleteSchedule(this.schoolId, this.scheduleToDelete.schedule.id, this.deleteFollowing).subscribe( _ => {
        this.refreshSchedules();
      });
      this.deleteFollowing = false;
      this.scheduleToDelete = null;
    } else {
      this.rest.deleteTemplate(this.schoolId, this.templateId)
        .subscribe(_ => this.router.navigate(['school', this.schoolId, 'groups']));
    }
  }

  onStart() {
    this.startConfirmModal.hide();
      const roomSettings = new RoomSettings();
      this.rest.createRoomWithTemplate(this.schoolId, this.templateId, roomSettings)
        .subscribe(room => this.router.navigate(['school', this.schoolId, 'rooms', room.uuid, 'details']));
  }

  saveTemplate() {
    this.savedCorrectly = false;
    this.saveError = false;

    this.rest.saveOrCreateTemplate(this.schoolId, this.template).subscribe(
      saved => {
        this.savedCorrectly = true;
        setTimeout(() => this.savedCorrectly = false, 3000);
        this.template = saved;
        if (!this.templateId) {
          this.templateId = saved.id;
          this.router.navigate(['school', this.schoolId, 'groups', this.templateId]);
        }
        //this.router.navigate(['manager', this.schoolId, 'dashboard']);
      }
    , err => {
        this.saveError = true;
        setTimeout(() => this.saveError = false, 3000);
      });
  }

  ngOnInit() {
  }

  getSchedulesDataProvider() {
    return this.schedulesDataProvider;
  }

  onSchedulesEvent(event: ListEvent) {
    if (event.event === ListEvent.EVENT_START) {
      this.scheduleToStart = event.item;
      this.startScheduleConfirmModal.show(false)
    } else if (event.event === ListEvent.EVENT_DELETE) {
      this.scheduleToDelete = event.item;
      this.deleteConfirmModal.show(false)
    } else if (event.event === ListEvent.EVENT_OPEN
      && (!this.scheduleToDelete && !this.scheduleToStart))
        this.router.navigate(["school", this.schoolId, "schedules", event.item.schedule.id])
  }

  createNewSchedule() {
    this.createScheduleComponent.show();
  }

  refreshSchedules() {
    this.schedulesListComponent.refreshData();
  }

  moveScheduleWeekOffset(number: number) {
    this.schedulesDataProvider.queryArgs.weekOffset += number;
    this.schedulesListComponent.refreshData();
  }

  onSchedulesLoading($event: boolean) {
    this.isScheduleLoading = $event;
  }

  onSchedulesDataSetUpdate(dataSet: ScheduleRowSimplified[]) {
    this.schedulesCount = dataSet.length;
  }

  hasScheduleQueryArgs() {
    return this.schedulesDataProvider && this.schedulesDataProvider.queryArgs && this.schedulesDataProvider.queryArgs.weekOffset != null;
  }

  getWeekStartDate() {
    return DateUtils.weekStart(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  getWeekEndDate() {
    return DateUtils.weekEnd(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  moveScheduleToNow() {
    this.schedulesDataProvider.queryArgs.weekOffset = 0;
    this.schedulesListComponent.refreshData();
  }

  onStartSchedule() {
    this.startScheduleConfirmModal.hide();
    if (this.scheduleToStart!=null) {
      this.schedulesRest.updateScheduleState(this.schoolId, this.scheduleToStart.schedule.id, "PENDING").subscribe(row =>
        {
          this.scheduleToStart = null;
          this.refreshSchedules();
        }
      )
    }
  }

  cancelDelete() {
    this.deleteConfirmModal.hide();
    this.scheduleToDelete = null;
  }

    getGroupName() {
        if (this.template && this.template.details.name && this.template.details.name.length > 0) return this.template.details.name;
        return "Group details";
    }

  private loadSchool() {
    this.casa.getSchoolDetails(this.schoolId).pipe(
      tap( school => this.school = school)
    ).subscribe();
  }

  isSchoolReady() {
    return this.school != null;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  isDeleted() {
    return this.template && this.template.deleted;
  }

  openRoomDetails(room: RoomDef) {
    this.router.navigate(["school",this.schoolId,"rooms",room.uuid,"details"]);
  }

  recover() {
    this.rest.recoverTemplate(this.schoolId, this.templateId).subscribe( _ => this.reloadTemplate())
  }

  private fixParticipants(template: IdentifiedRoomTemplate) {
    template.participants
      .filter( it => it.details.role == null)
      .forEach( it => it.details.role = "Student");
    if (!template.participants.find( it => it.details.role == 'Teacher')) {
      let missingTeacher = new IdentifiedTemplateParticipant();
      missingTeacher.details = new TemplateParticipantDetailsBase();
      missingTeacher.details.role = "Teacher";
      template.participants.push(missingTeacher);
    }
  }

}
