<div class="card mb-5">
  <div class="card-header border-bottom border-secondary pb-4">
    <h5 class="my-auto">Teacher personal information</h5>
    <a (click)="editPersonalProfile()" *ngIf="!personalProfileUnderEdit" class="btn btn-outline-secondary rounded-circle px-2 ms-auto">
      <app-icon name="edit" class="icon-secondary"></app-icon>
    </a>
  </div>
  <div class="card-body">
    <app-teacher-personal-profile-form #teacherPersonalProfileFormComponent [schoolId]="schoolId" [teacherId]="teacherId" (stateEvent)="onPersonalProfileEvent($event)"></app-teacher-personal-profile-form>
  </div>
</div>

<div class="card mb-5" [hidden]="!teacherProfileReady">
  <div class="card-header border-bottom border-secondary pb-4">
    <h5 class="my-auto">Your personal information</h5>
    <a (click)="editTeacherProfile()" *ngIf="!teacherProfileUnderEdit" class="btn btn-outline-secondary rounded-circle px-2 ms-auto">
      <app-icon name="edit" class="icon-secondary"></app-icon>
    </a>
  </div>
    <div class="card-body">
      <app-teacher-profile-form #teacherProfileFormComponent [schoolId]="schoolId" [teacherId]="teacherId" (stateEvent)="onTeacherProfileEvent($event)"></app-teacher-profile-form>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <app-teacher-allowances [schoolId]="schoolId" [teacherId]="teacherId"></app-teacher-allowances>
    </div>
  </div>
