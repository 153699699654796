<div class="card h-100" *ngIf="isReady()">
  <div class="card-header d-block">
    <div class="d-flex justify-content-between">
      <h6 class="card-title mb-0">Managers</h6>
      <span class="text-muted small">
        You have <strong class="text-muted small">{{ getMenagersLength()}}</strong> Managers
      </span>
    </div>
    <div class="btn-group float-end d-block text-end mt-2" *ngIf="hasPages()">
      <a class="btn btn-sm btn-primary rounded-pill rounded-end px-3" [ngClass]="{disabled: !hasPrev()}" (click)="prev()">
        <app-icon name="angle-left" class="icon-white"></app-icon>
      </a>
      <a class="btn btn-sm btn-primary rounded-pill rounded-start px-3" [ngClass]="{disabled: !hasNext()}" (click)="next()">
        <app-icon name="angle-right" class="icon-white"></app-icon>
      </a>
    </div>
  </div>
  <div class="card-body">
    <p class="my-4" *ngIf="isEmpty()">
      Managers list is empty.
    </p>
    <div class="table-responsive" *ngIf="!isEmpty()">
      <table class="table table-sm table-borderless">
        <thead>
          <tr class="table-row table-row-head">
            <th>Name</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let manager of getCurrentPageDate()" class="table-row">
            <td>
              {{getName(manager)}}
            </td>
            <td class="text-muted">
              {{getMail(manager)}}
            </td>
            <td>
              <button (click)="delete(manager)" class="btn text-primary btn-link text-decoration-none float-end">delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer text-end">
      <a href="#" class="btn btn-primary btn-sm rounded-pill px-3 px-md-4 py-2" routerLink="/school/{{mSchool.id}}/manager/new">Add manager</a>
    </div>
  </div>
</div>
