import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {combineLatest} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import {Student} from "../../model/rest/casa-apiv2.model";

@Component({
  selector: 'app-manager-student-credits-page',
  templateUrl: './manager-student-credits-page.component.html',
  styleUrls: ['./manager-student-credits-page.component.css']
})
export class ManagerStudentCreditsPageComponent implements OnInit {
  schoolId: number;
  studentId: number;

  constructor(
    private activeRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    combineLatest(
      [this.activeRoute.parent.parent.paramMap,
        this.activeRoute.parent.paramMap
      ]
    ).pipe(
      tap<[ParamMap, ParamMap]>( ([parentParams, params]) => {
        this.schoolId = Number(parentParams.get('schoolId'));
        this.studentId = Number(params.get('studentId'))
      }),
    ).subscribe(out => console.log(out));
  }


}
