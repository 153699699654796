import {RoomDef, RoomTemplate, RoomTemplateBase, RoomTemplateDetailsBase, TemplateParticipant} from "./server";

export class ScheduleDefinitionDetails {
  startDate: number;
  scheduleCycle: string[];
  cycleFinish: number;
  timezone: string;
  durationMin: number;
}

export class ScheduleParticipantBase {
  id: number;
  role: string;
  name: string;
  email: string;
  offline: boolean;
}

export class ScheduleDetailsBase<T extends ScheduleParticipantBase> {
  startDate: number;
  participants: T[];
  hybridState: string;
  durationMin: number;
  place: string;
  notes: string;
}

export class ScheduleDetails extends ScheduleDetailsBase<ScheduleParticipant>{
}

export class ScheduleParticipant extends ScheduleParticipantBase {
  eid: string;
  videoOff = false;
  audioOff = false;
  passive = false;
  chartOff = false;
  secret = false;
  muteOff = false;
  chatOff = false;
}

export class ScheduleBase<T extends ScheduleDetailsBase<any>> {
  state: string;
  details: T;
  id: number;
}

export class ScheduleBaseWithTemplateReference<T extends ScheduleDetailsBase<any>> extends ScheduleBase<T> {
  templateId: number;
}

export class Schedule extends ScheduleBase<ScheduleDetails>{
}

export class ScheduleRowBase<S extends ScheduleBase<any>, T extends RoomTemplateBase<any>> {
  schedule: S;
  template: T;
}

export class ScheduleRowSimplified extends ScheduleRowBase<ScheduleBase<ScheduleDetailsBase<ScheduleParticipantBase>>, RoomTemplateBase<RoomTemplateDetailsBase>>{}

export class ScheduleRow extends ScheduleRowBase<Schedule, RoomTemplate<TemplateParticipant>>{
  room: RoomDef;
}

export enum ScheduleParticipantAction {
  REMOVE = "REMOVE", ADD = "ADD", MODIFY = "MODIFY"
}

export class ScheduleActionBase {
  partiId: number;
  action: ScheduleParticipantAction;
  role: string;
  name: string;
  email: string;
  offline: boolean;
}

export class ScheduleActionBaseWithScheduleRef extends ScheduleActionBase {
  scheduleId: number;
}

export class ScheduleListOptimized {
  groups: RoomTemplate<TemplateParticipant>[];
  schedules: ScheduleBaseWithTemplateReference<ScheduleDetailsBase<ScheduleParticipantBase>>[];
  actions: ScheduleActionBaseWithScheduleRef[];
}
