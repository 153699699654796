import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import {RangeSet} from "../../../services/utils/calendar-events-splitter";

export class CalendarEntry {
  dateFrom: Date;
  dateTo: Date;
  id: any;
  eventId: number;
  title: () => string;
  subtitle: string;
  relatedObject: any;
  classes: string[];
  _narrowIntersected = false;

  constructor(id: any, eventId: number, dateFrom: Date, dateTo: Date,
    title: () => string , subtitle: string,  relatedObject: any, ...classes: string[]) {
    this.id = id;
    this.eventId = eventId;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.title = title;
    this.subtitle = subtitle;
    this.classes = classes;
    this.relatedObject = relatedObject;
  }

  public narrowIntersected() {
    this._narrowIntersected = true;
    return this;
  }
}

export class ClickEvent {
  entry: CalendarEntry;
  clickDate: Date;

  constructor(entry: CalendarEntry, date: Date) {
    this.entry = entry;
    this.clickDate = date;
  }
}

class EventInternal {
  start: number;
  height: number;
  title: () => string;
  subtitle: string;
  relatedEntry: CalendarEntry;
  classes: string;
  left: number;
  width: number;

  constructor( start: number, height: number, entry: CalendarEntry) {
    this.start = start;
    this.height = height;
    this.title = entry.title;
    this.subtitle = entry.subtitle;
    this.relatedEntry = entry;
    this.classes = entry.classes.join(' ');
  }
}

@Component({
  selector: 'app-week-calendar',
  templateUrl: './week-calendar.component.html',
  styleUrls: ['./week-calendar.component.css']
})
export class WeekCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('hoursScroll', {static: true}) hoursScrollRef: ElementRef;
  @ViewChild('daysScroll', {static: true}) daysScrollRef: ElementRef;
  @ViewChild('gridScroll', {static: true}) gridScrollRef: ElementRef;
  @ViewChild('calendar', {static: true}) caledar: ElementRef;
  _currentDate: Date;
  _weekDates: Date[];
  _weekDayEndings: Date[];
  _activeDay: number;
  _currentTimeAsProgress: number;
  _dayEvents: Array<Array<EventInternal>> = [];
  _events: CalendarEntry[];

  eventWidth = 90;
  MINUTE_LONG = 1000 * 60;
  HOUR_LONG = this.MINUTE_LONG * 60;
  DAY_LONG = this.HOUR_LONG * 24;

  loadingSubject = new Subject<boolean>();
  _loading = true;

  constructor() {
    this.loadingSubject.pipe(
      auditTime(200)
      ).subscribe( val => {
        this._loading = val;
        }
      );
  }
  @Output()
  dateUpdate = new EventEmitter<Date>();
  @Output()
  timeSelect = new EventEmitter<ClickEvent>();

  @Input()
  set currentDate(date: Date) {
    this._currentDate = date;
    this.setupDate();
    this.calculateEntries();
    this.dateUpdate.emit(date);
    this.scrollToFocusDate();
  }

  @Input()
  set events(events: CalendarEntry[]) {
    this._events = events;
    this.calculateEntries();
  }

  @Input()
  set loading(state: boolean) {
    this.loadingSubject.next(state);
  }

  get loading(): boolean {
    return this._loading;
  }

  beginingOfDay(date: Date): Date {
    const res = new Date(date);
    res.setHours(0);
    res.setMinutes(0);
    res.setSeconds(0);
    res.setMilliseconds(0);
    return res;
  }

  endingOfDay(date: Date): Date {
    const res = new Date(date);
    res.setHours(23);
    res.setMinutes(59);
    res.setSeconds(59);
    res.setMilliseconds(999);
    return res;
  }

  setupDate() {

    if (!this._currentDate) { return; }
    const nowDate = new Date();

    const currentDayNumber = this._currentDate.getDay();
    let firstDay = new Date();
    firstDay.setTime(this._currentDate.getTime() - (currentDayNumber * this.DAY_LONG ));
    firstDay = this.beginingOfDay(firstDay);

    let lastDay = new Date();
    lastDay.setTime(firstDay.getTime() + 7 * this.DAY_LONG );
    lastDay = this.endingOfDay(lastDay);

    if (nowDate.getTime() > firstDay.getTime() && nowDate.getTime() < lastDay.getTime()) {
      this._activeDay = currentDayNumber;
    } else {
      this._activeDay = -1;
    }

    this._weekDates = [];
    this._weekDayEndings = [];
    for (let i = 0 ; i < 7 ; i++ ) {
      let dayDate = new Date();
      // move 1 day forward and then half a day to be sure that the days border was crossed
      dayDate.setTime ( firstDay.getTime() + (i * this.DAY_LONG + this.HOUR_LONG * 12 ) );

      dayDate = this.beginingOfDay(dayDate);
      this._weekDates[i] = dayDate;
      dayDate = this.endingOfDay(dayDate);
      this._weekDayEndings[i] = dayDate;
    }

    this._currentTimeAsProgress = this.timeAsProgress(nowDate);

  }

  timeAsProgress(date: Date): number {
    return (date.getHours() * 60 + date.getMinutes()) / (24 * 60) * 100;
  }

  calculateEntries() {
    this._dayEvents = [[], [], [], [], [], [], []];
    if (!this._events || !this._weekDates || !this._weekDayEndings) {
      return;
    }
    this.splitEventsInHalfHourInterval()
    for (const ev of this._events) {
      let hasStarted = ev.dateFrom.getTime() < this._weekDates[0].getTime();
      let hasFinished = ev.dateTo.getTime() < this._weekDates[0].getTime();
      if (hasStarted && hasFinished) {
        continue;
      }
      for (let dayNb = 0; dayNb < 7; dayNb++) {
        const dayStart = this._weekDates[dayNb];
        const dayEnding = this._weekDayEndings[dayNb];
        let startedToday = false;
        let finishedToday = false;
        if (!hasStarted) {
          if (
            ev.dateFrom.getTime() >= dayStart.getTime() &&
            ev.dateFrom.getTime() <= dayEnding.getTime()
          ) {
            hasStarted = true;
            startedToday = true;
          }
        }
        if (!hasFinished) {
          if (
            ev.dateTo.getTime() >= dayStart.getTime() &&
            ev.dateTo.getTime() <= dayEnding.getTime()
          ) {
            hasFinished = true;
            finishedToday = true;
          }
        }

        // is not started yet, therefore jump to the next day
        if (!hasStarted) {
          continue;
        }
        const startedProgress = startedToday
          ? this.timeAsProgress(ev.dateFrom)
          : 0;
        const finishProgress = finishedToday
          ? this.timeAsProgress(ev.dateTo)
          : 100;
        const progressRange = finishProgress - startedProgress;
        this._dayEvents[dayNb].push(
          new EventInternal(startedProgress, progressRange, ev)
        );

        // is finished, skip next days
        if (hasFinished) {
          break;
        }
      }
    }

    this.fixIntersectingWidths();
  }

  splitEventsInHalfHourInterval() {
    let events = []
    const halfHour = this.HOUR_LONG / 2
    for(const ev of this._events) {
      let newEvent = {...ev}
      newEvent.dateFrom = ev.dateFrom
      newEvent.dateTo = new Date(new Date(newEvent.dateFrom).getTime() + halfHour)
      events.push({...newEvent})
      while(new Date(newEvent.dateFrom).getTime() < new Date(new Date(ev.dateTo).getTime() - halfHour).getTime()) {
        newEvent.dateFrom = events[events.length - 1].dateTo
        newEvent.dateTo = new Date(new Date(newEvent.dateFrom).getTime() + halfHour)
        events.push({...newEvent})
      }
    }
    this._events = events
  }

  fixIntersectingWidths() {
    for (let i = 0 ; i < this._dayEvents.length ; i++ ) {
      const dayEvents = this._dayEvents[i];

      const eventsRangeSet = new RangeSet<EventInternal>();
      dayEvents.filter( e => !e.relatedEntry._narrowIntersected ).forEach ( e => {
        e.left = 0;
        e.width = this.eventWidth;
      });

      dayEvents
      .filter ( e => e.relatedEntry._narrowIntersected )
      .forEach ( e => eventsRangeSet.addRange( e.relatedEntry.dateFrom.getTime(), e.relatedEntry.dateTo.getTime(), e) );
      eventsRangeSet.reassingGroups();

      const groupWidth = this.eventWidth / eventsRangeSet.groupsNumber;

      dayEvents
      .filter ( e => e.relatedEntry._narrowIntersected )
      .forEach ( de => {
        de.width = groupWidth;
        de.left = eventsRangeSet.getAsigmentByData(de) * groupWidth;
      });
    }

  }


  moveDays(days: number) {
    const newTime = this._currentDate.getTime() + days * this.DAY_LONG;
    const newDate = new Date();
    newDate.setTime(newTime);
    this.currentDate = newDate;
  }

  today() {
    this.currentDate = new Date();
  }

  ngOnInit() {
    if (! this._currentDate) {
      this.currentDate = new Date();
    }
  }

  private progressToDate(dayDate: Date, progress: number): Date {
    const resultDate = new Date(dayDate);
    const timeInMinutes = progress * (24 * 60);
    const hour = Math.floor(timeInMinutes / 60);
    const minute = Math.floor(timeInMinutes % 60);

    resultDate.setHours(hour);
    resultDate.setMinutes(minute);
    return resultDate;
  }

  public dayClick(column: number, ev: MouseEvent) {
    const target = ev.currentTarget as HTMLElement;
    const eventTarget = ev.target as HTMLElement;

    let offset = ev.offsetY;

    // special case - if event is targeted on hour cell - we have to calculate offset relative to it
    if (eventTarget.classList.contains('wcal-day-hour')) {
      offset += eventTarget.offsetTop;
    }

    const timeAsProgress = offset / target.clientHeight;
    this.timeSelect.emit(new ClickEvent(null, this.progressToDate(this._weekDates[column], timeAsProgress)));
  }

  public eventClick(column: number, ev: EventInternal, mouseEvent: MouseEvent) {
    const target = mouseEvent.currentTarget as HTMLElement;

    const progressInElement = mouseEvent.offsetY / target.clientHeight;
    const clickDayProgress = (ev.start + ev.height * progressInElement) / 100;

    this.timeSelect.emit(new ClickEvent(ev.relatedEntry, this.progressToDate(this._weekDates[column], clickDayProgress)));
    mouseEvent.stopPropagation();
  }

  syncScroll(parent: any, child: any, horizontal: boolean) {
    this.fixChildSize(parent, child, horizontal);
    window.addEventListener('resize', () => {
      this.fixChildSize(parent, child, horizontal);
    });
    parent.addEventListener('scroll', (function() {
      if (horizontal) {
        child.scrollLeft = parent.scrollLeft;
      } else {
        child.scrollTop = parent.scrollTop;
      }
    }));
  }

  fixChildSize(parent: any, child: any, horizontal: boolean) {
    if (horizontal) {
      child.style.width = parent.clientWidth + 'px';
    } else {
      child.style.height = parent.clientHeight + 'px';
    }
  }

  setupColumnWidth(calendar: any, width: number) {
    let columnWidth = width / 7;
    if (columnWidth < 128 ) {
      columnWidth = 128;
    }
    const gridWidth = columnWidth * 7;

    Array.from(calendar.getElementsByClassName('wc-col-width'))
    .forEach((element: HTMLElement) => {
      element.style.width = columnWidth + 'px';

    })
    Array.from(calendar.getElementsByClassName('wc-width'))
    .forEach((element: HTMLElement) => {
      element.style.width = gridWidth + 'px';

    })
  }

  calculateScroll(progress: number, boardSize: number, windowSize: number) {
    const requestedPoint = progress * boardSize;
    let requestedScroll = Math.round(requestedPoint - windowSize / 2);
    requestedScroll = requestedScroll > 0 ? requestedScroll : 0;
    return requestedScroll;
  }
  scrollToFocusDate() {
    if (!this._currentDate) {return; }

    const gridScroll = this.gridScrollRef.nativeElement as HTMLElement;
    const windowWidth = gridScroll.clientWidth;
    const windowHeight = gridScroll.clientHeight;
    const childBoard = gridScroll.children.item(0);
    const boardHeight = childBoard.clientHeight;
    const boardWidth = childBoard.clientWidth;

    const dayNb = this._currentDate.getDay();
    const horizontalProgress = dayNb / 7;
    const verticalProgress = this.timeAsProgress(this._currentDate) / 100;

    const horizontalScroll = this.calculateScroll(horizontalProgress, boardWidth, windowWidth);
    const verticalScroll = this.calculateScroll(verticalProgress, boardHeight, windowHeight);

    try {
      gridScroll.scrollTop = verticalScroll;
      gridScroll.scrollLeft = horizontalScroll;
    } catch (e) {

    }
    this.hoursScrollRef.nativeElement.scrollTop = verticalScroll;
    this.daysScrollRef.nativeElement.scrollLeft = horizontalScroll;
  }

  ngAfterViewInit(): void {
    const hoursScroll = this.hoursScrollRef.nativeElement;
    const daysScroll = this.daysScrollRef.nativeElement;
    const gridScroll = this.gridScrollRef.nativeElement;
    this.syncScroll(gridScroll, hoursScroll, false);
    this.syncScroll(gridScroll, daysScroll, true);
    this.setupColumnWidth(this.caledar.nativeElement, gridScroll.clientWidth );
    window.addEventListener('resize', () => {
      this.setupColumnWidth(this.caledar.nativeElement, gridScroll.clientWidth );
    });
    this.scrollToFocusDate();
  }

  getHours() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(`${i.toString().padStart(2, '0')}:00`);
      hours.push(`${i.toString().padStart(2, '0')}:30`);
    }
    return hours;
  }

  getWeekDates() {
    return this._weekDates;
  }

  getActiveDay() {
    return this._activeDay;
  }

  getCurrentTimeAsProgress() {
    return this._currentTimeAsProgress;
  }

}
