import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page, Pageable } from '../../model/rest/base';
import { NewsListEntry } from '../../model/rest/casa-apiv2.model';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}

  url(schoolId: number, managerId: number, method: string) {
    return `${environment.casaEndpoint}/api/school/${schoolId}/manager/${managerId}${method}`;
  }
  listNews(
    schoolId: number,
    managerId: number,
    pageable: Pageable,
    onlyUnread: boolean = null
  ): Observable<Page<NewsListEntry>> {
    if(!schoolId || !managerId) return of(null)
    let params = Pageable.appedPageableParams(new HttpParams(), pageable)

    if(onlyUnread != null)
      params = params.append(
      'onlyUnread',
      onlyUnread
    )
    return this.http.get<Page<NewsListEntry>>(
      this.url(schoolId, managerId, '/news'),
      {
        params: params
      }
    );
  }
  getNewsById(
    schoolId: number,
    managerId: number,
    newsId: number
  ): Observable<NewsListEntry> {
    return this.http.get<NewsListEntry>(
      this.url(schoolId, managerId, `/news/${newsId}`)
    );
  }

  updateUserNewsStatus(schoolId: number, managerId: number, newsId: number) {
    return this.http.put(
      this.url(schoolId, managerId, `/news/${newsId}`),
      null
    );
  }
}
