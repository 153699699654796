import {AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {tap} from "rxjs/operators";
import {Subscription} from "rxjs";
import {School} from "../../../model/rest/casa-apiv2.model";
import {WarehouseEntry} from "../../../model/rest/old-casa-api.model";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {PusherService} from "../../../services/pusher.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-credits-summary-block',
  templateUrl: './credits-summary-block.component.html',
  styleUrls: ['./credits-summary-block.component.css']
})
export class CreditsSummaryBlockComponent implements OnInit, OnDestroy, AfterViewChecked {
  private mSchool: School;
  private credits: WarehouseEntry[];
  private pusherSubscription: Subscription;
  contentFoggy: boolean
  initialHeight: string

  @Input()
  set school(value: School) {
    this.mSchool = value;
    if (!value || !value.id) return;
    this.reload();
    this.subscribePusher();
  }

  @ViewChild('ebookCreditsCard') card: ElementRef
  @ViewChild('cardHeightPlaceholder') cardHeightPlaceholder: ElementRef

  constructor(
    private casaApi: CasaRestApiV2,
    private pusher: PusherService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.pusherSubscription) {
      this.pusherSubscription.unsubscribe();
      this.pusherSubscription = null;
    }
  }
  ngAfterViewChecked(): void {
    if(!this.initialHeight && this.card)
      this.initialHeight = `${this.card?.nativeElement.offsetHeight}px`
    if(this.initialHeight && !this.cardHeightPlaceholder.nativeElement.style.height) {
      this.renderer.setStyle(
        this.cardHeightPlaceholder.nativeElement,
        'height',
        this.initialHeight
      )
    }
  }

  private reload() {
    this.loadCredits(this.mSchool.id)
      .pipe(
        tap( credits => this.store(credits))
      )
      .subscribe();
  }

  private loadCredits(schoolId: number) {
    return this.casaApi.queryCredits(schoolId);
  }

  private store(credits: WarehouseEntry[]) {
    this.credits = credits;
    this.contentFoggy = this.credits.length > 4
  }

  isReady() {
    return this.credits;
  }

  hasCredits() {
    return this.credits.length > 0;
  }

  isContentFoggy() {
    return this.contentFoggy
  }

  isContentExpanded() {
    return !this.contentFoggy && this.getCreditsEntries().length > 4
  }

  toggleContentFoggy() {
    this.contentFoggy = !this.contentFoggy
  }

  getCreditsEntries(): WarehouseEntry[] {
    return this.credits;
  }
  getCreditsEntriesSorted(): WarehouseEntry[] {
    return [
      this.getCreditEntry('en-r3'),
      this.getCreditEntry('en'),
      this.getCreditEntry('sp'),
      this.getCreditEntry('bs'),
      this.getCreditEntry('cfk'),
    ].filter(it => it)
  }

  getCreditEntry(productCode: string): WarehouseEntry {
    return this.credits.find(credit => credit.product.productCode.endsWith(productCode))
  }

  getEntryDescription(entry: WarehouseEntry) {
    if(!entry) return
    return `${this.mapProductToCreditsName(entry.product.productCode)}: ${entry.amount}`;
  }

  private mapProductToCreditsName(productCode: string) {
    switch (productCode) {
      case "credit-en-r3": return "Callan English 3rd Edition";
      case "credit-en": return "Callan English 2nd Edition";
      case "credit-sp": return "Callan Español";
      case "credit-bs": return "Callan for Business";
      case "credit-cfk": return "Callan for Kids";
    }
    return "unknown credit type";
  }

  private subscribePusher() {
    if (!this.mSchool) return;
    if (this.pusherSubscription) return;
    this.pusherSubscription = this.pusher.joinCredits<any>(
      `casa.${environment.creditsPusher.instanceName}.warehouse.${this.mSchool.id}`,
      "update")
      .subscribe( _ => this.reload());
  }
}
