<div
  class="row flex-column justify-content-between h-100"
  [ngClass]="{ 'flex-lg-row': isWide }"
  (click)="goToDetails()"
>
  <div *ngIf="entry?.newsItem?.content.image" class="col-12 mb-3" [ngClass]="{ 'col-lg-6': isWide }">
    <div class="ratio ratio-16x9">
      <img [src]="entry?.newsItem?.content.image" class="w-100 rounded-1 object-fit-contain" />
    </div>
  </div>
  <div *ngIf="!entry?.newsItem?.content.image" class="col-12 mb-3" [ngClass]="{ 'col-lg-6': isWide }">
  </div>
  <div
    class="col-12 d-flex flex-column justify-content-between"
    [ngClass]="{ 'col-lg-6': isWide }"
  >
    <div>
      <p [class]="isSmall ? 'h5' : 'h3'" [innerHTML]="entry?.newsItem?.content.title"></p>
    </div>
    <div *ngIf="isWide" class="h-100 d-flex align-items-start">
      <p class="mt-3 overflow-hidden news-preview" [innerHTML]="entry?.newsItem?.content?.copy"></p>
    </div>
    <div class="row">
      <div
        class="d-flex flex-column flex-md-row align-items-md-center flex-wrap gap-2"
        [ngClass]="{ 'gap-md-3': !isSmall }"
      >
        <span>{{ getAuthor() }}</span>
        <p class="small text-muted mb-0">
          {{ entry?.newsItem?.publicationDate | date : "d MMMM y | h:mm" }}
        </p>
        <div class="d-flex gap-1">
          <span
            *ngFor="let label of getLabels()"
            class="badge badge-outline-secondary fw-lighter text-uppercase"
            >{{ label }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
