<app-modal id="teacherSubscription" #teacherSubscription>
  <ng-container *ngIf="!isTeacherSubscribed; else unsubscription">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Add Teacher to subscription
        </h5>
        <button type="button" class="btn-close" (click)="teacherSubscription.hide()"  aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <p class="small">The 3rd Edition Teacher eBooks work on a subscription model. Add each Teacher to your subscription to give them access to eBooks..</p>
        <p class="small mb-0">The price of subscription is £3 per Teacher per month.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm rounded-pill px-4 py-2" (click)="teacherSubscription.hide()">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill px-4 py-2" (click)="addTeacherToSubscription()">Subscribe</button>
      </div>
    </div>
  </ng-container>
  <ng-template #unsubscription>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Remove Teacher from subscription?
        </h5>
        <button type="button" class="btn-close" (click)="teacherSubscription.hide()"  aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <p class="small mb-0">If you remove this teacher from your subscription, they will lose access to 3rd edition materials.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm rounded-pill px-4 py-2" (click)="teacherSubscription.hide()">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill px-4 py-2" (click)="removeTeacherFromSubscription()">Unsubscribe</button>
      </div>
    </div>
  </ng-template>
</app-modal>
