<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Reports'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page">Reports</li>
        <li class="breadcrumb-item active" aria-current="page">Group Usage</li>
      </ol>
    </nav>

    <div class="card mb-4">
      <div class="card-body">
        <form class="mb-3">
          <div class="row">
            <div class="col-12 col-sm mb-2">
              <div class="form-floating">
                <select name="report-month" [(ngModel)]="month" class="form-select"
                [ngClass]="{ 'select-not-empty': month != null }">
                  <option value="0">This month</option>
                  <option value="-1">Last month</option>
                  <option value="-2">2 months ago</option>
                  <option value="-3">3 months ago</option>
                  <option value="-6">6 months ago</option>
                  <option value="-12">12 months ago</option>
                </select>
                <label>Start</label>
              </div>
            </div>
            <div class="col mb-2">
              <div class="form-floating">
                <select name="report-duration" [(ngModel)]="duration" class="form-select"
                [ngClass]="{ 'select-not-empty': duration }">
                  <option value="1">for 1 month</option>
                  <option value="2">for 2 months</option>
                  <option value="3">for 3 months</option>
                  <option value="6">for 6 months</option>
                  <option value="12">for 12 months</option>
                </select>
                <label>Duration</label>
              </div>
            </div>
          </div>
        </form>

        <app-activity-report [dataProvider]="reportData"></app-activity-report>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-header row gap-3 gap-sm-0">
        <div class="col-12 col-sm-7"><h4 class="mb-0">Groups' lessons <span class="text-primary">( {{getRoomsCount()}} )</span></h4></div>
        <div class="col-12 col-sm-5 col-lg-4 ms-auto">
          <select class="form-select" name="group-id" [(ngModel)]="templateId"
          [ngClass]="{ 'select-not-empty': templateId }">
            <option value="-1">All groups</option>
            <option *ngFor="let group of availableGroups" [value]="group.id">
              {{group.details.name}}
            </option>
          </select>
        </div>

        <div class="col-auto ms-auto mt-sm-3" *ngIf="hasRoomPrev() || hasRoomNext()">
          <div class="btn-group-sm btn-group">
            <button class="btn btn-outline-secondary rounded-pill rounded-end" type="button" (click)="roomPrev()" [disabled]="!hasRoomPrev()">
              <app-icon name="angle-left" class="icon-secondary"></app-icon>
              </button>
            <button class="btn btn-outline-secondary px-3" type="button" (click)="roomFirst()"> {{roomPage()}} </button>
            <button class="btn btn-outline-secondary rounded-pill rounded-start" type="button" (click)="roomNext()" [disabled]="!hasRoomNext()">
              <app-icon name="angle-right" class="icon-secondary"></app-icon>
              </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <app-rooms-list [data]="roomsPage" (details)="openRoomDetails($event)" ></app-rooms-list>
      </div>
    </div>
  </div>
</div>
