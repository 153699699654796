<app-modal #dialog>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Schedule lessons</h5>
      <button type="button" class="btn-close" (click)="dialog.hide()"  aria-label="Close">
      </button>
    </div>
    <form #f="ngForm" (ngSubmit)="f.form.valid && areDatesValid() && onSubmit()">
      <div class="modal-body">
        <ng-container *ngIf="!templateId">
          <div class=" mb-4">
            <div class="form-floating">
              <input class="form-control"
                     name="group-name"
                     placeholder="Group's name"
                     (ngModelChange)="matchGroups()"
                     [(ngModel)]="searchTerm"
                     #groupName="ngModel"
                     required
                     [ngClass]="{ 'is-invalid': f.submitted && (groupName.invalid || !selectedGroup), 'linked': !!selectedGroup }"
              >
              <label>Group's name</label>
              <button *ngIf="!!selectedGroup" type="button" aria-label="Close" class="btn-close btn-remove-group" (click)="selectedGroup = null"></button>
              <div *ngIf="f.submitted && (groupName.invalid || !selectedGroup)" class="invalid-feedback">
                <div *ngIf="groupName?.errors?.required || !selectedGroup">Field is required</div>
              </div>
            </div>
            <ng-container *ngIf="matchedGroups?.length && !selectedGroup">
              <span class="small mb-0">Scheduling lessons for group: </span>
              <a class="small mb-0 me-2" *ngFor="let group of matchedGroups" (click)="onGroupSelection(group)">{{group.details.name}}</a>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="serverReportedErrors" class="alert alert-danger">
          Check for errors:<br>
          {{serverReportedErrors | json}}
        </div>
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input class="form-control"
              type="datetime-local"
              placeholder="yyyy-mm-dd HH:mm"
              required pattern="\d{4}-\d{2}-\d{2}[T| ]?\d{2}:\d{2}"
              [ngClass]="{ 'is-invalid': f.submitted && (dateFrom.invalid || isValidDate())}"
              #dateFrom="ngModel"
              [(ngModel)]="formInput.dateFrom"
              name="dateFrom">
              <label>First lesson on</label>
              <div *ngIf="f.submitted && (dateFrom.invalid || isValidDate())" class="invalid-feedback">
                <div *ngIf="dateFrom.errors?.required">Field is required</div>
                <div *ngIf="dateFrom.errors?.pattern">Remember about the correct pattern yyyy-MM-dd HH:mm</div>
                <div *ngIf="isValidDate()">Invalid date</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input class="form-control"
              type="number"
              placeholder="minutes"
              [(ngModel)]="formInput.durationMin"
              name="durationMs">
              <label>Duration</label>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <p class="text-muted small">Then repeat on the following days:</p>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-mon" [(ngModel)]="formInput.recurring['EVERY_MONDAY']" name="recurringMonday">
              <label class="form-check-label small" for="rt-mon">
                Monday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-tue" [(ngModel)]="formInput.recurring['EVERY_TUESDAY']" name="recurringTuesday">
              <label class="form-check-label small" for="rt-tue">
                Tuesday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-wed" [(ngModel)]="formInput.recurring['EVERY_WEDNESDAY']" name="recurringWednesday">
              <label class="form-check-label small" for="rt-wed">
                Wednesday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-thu" [(ngModel)]="formInput.recurring['EVERY_THURSDAY']" name="recurringThursday">
              <label class="form-check-label small" for="rt-thu">
                Thursday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-fri" [(ngModel)]="formInput.recurring['EVERY_FRIDAY']" name="recurringFriday">
              <label class="form-check-label small" for="rt-fri">
                Friday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-sat" [(ngModel)]="formInput.recurring['EVERY_SATURDAY']" name="recurringSaturday">
              <label class="form-check-label small" for="rt-sat">
                Saturday
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="rt-sun" [(ngModel)]="formInput.recurring['EVERY_SUNDAY']" name="recurringSunday">
              <label class="form-check-label small" for="rt-sun">
                Sunday
              </label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="hasAnyRecurring()">
          <div class="col-12">
            <div class="form-floating">
              <input class="form-control"
              type="date"
              placeholder="yyyy-mm-dd"
              [(ngModel)]="formInput.dateTo"
              name="dateTo"
              pattern="\d{4}-\d{2}-\d{2}"
              [ngClass]="{ 'is-invalid': f.submitted && (dateTo.invalid || !isRepeatLaterThanStart())}"
              #dateTo="ngModel"
              [required]="hasAnyRecurring()"
              >
              <label>Repeat until</label>
              <div *ngIf="f.submitted && (dateTo.invalid || !isRepeatLaterThanStart())" class="invalid-feedback">
                <div *ngIf="dateTo.errors?.required">Field is required</div>
                <div *ngIf="dateTo.errors?.pattern">Remember about the correct pattern yyyy-MM-dd</div>
                <div *ngIf="!dateTo.errors?.required && !isRepeatLaterThanStart()">Invalid date</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-end">
        <button class="btn btn-secondary px-4 py-2 rounded-pill me-3" type="button" (click)="dialog.hide()">Cancel</button>
        <button class="btn btn-primary px-4 py-2 rounded-pill" type="submit">Submit</button>
      </div>
    </form>
  </div>
</app-modal>
