import {Person, PersonDetails} from "../../model/rest/casa-apiv2.model";
import {Pageable} from "../../model/rest/base";


export class ManagerUtils {

  static getName<T extends PersonDetails>(person: Person<T>) {
    const components = [];
    if (person.details.name) components.push(person.details.name);
    if (person.details.surname) components.push(person.details.surname);
    return components.join(" ");
  }

  static getInitials<T extends PersonDetails>(person: Person<T>) {
    return this.getName(person).split(' ').map(part => {return part[0]}).join('')
  }
}

export class SortingRule {
  constructor(public sorting: string[]) {
  }
}

export class LocalState<T extends SortingRule>{
  constructor(public term: string, public sortingRule: T, public page: Pageable) {}

  public next(): LocalState<T> {
    this.applySorting();
    return new LocalState<T>(this.term, this.sortingRule, this.page.next());
  }

  public prev(): LocalState<T> {
    this.applySorting();
   return new LocalState(this.term, this.sortingRule, this.page.prev());
  }

  public first(): LocalState<T>{
    this.applySorting();
    return new LocalState(this.term, this.sortingRule, this.page.first());
  }

  public applySorting() {
    this.page.sort = this.sortingRule.sorting;
  }
}
