import {School, Student, StudentBase, Teacher, TeacherBase} from "./rest/casa-apiv2.model";

enum Status {
  ACTIVATING, ACTIVE, LOCKED, REMOVED
}

export class GroupReference {
  id: number;
}

export class GroupDetails {
  name: string;
}

export class GroupSimple extends GroupReference {
  details: GroupDetails;
  status: Status;
  createDate: Date;
}

abstract class GroupBase<T extends TeacherBase, S extends StudentBase> extends GroupSimple {
  teachers :GroupTeacher<T>[];
  students: GroupStudent<S>[];
  school: School;
}
export class DetailedGroup extends GroupBase<Teacher, Student> {
}
export class GroupShort extends GroupBase<TeacherBase, StudentBase> {
}
export abstract class GroupParticipant<T> {
  id: number;
  details: ParticipantDetails<T>;
}

export class GroupStudent<T extends StudentBase> extends GroupParticipant<T> {
}
export class GroupTeacher<T extends TeacherBase> extends GroupParticipant<T> {
}

export class ParticipantBaseDetails {
  from: number;
  to: number;
  labels: String[];
}

export class ParticipantDetails<T> extends ParticipantBaseDetails {
  participant: T;
}
