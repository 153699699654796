<div class="card mb-5" *ngFor="let setAvailability of setAvailabilities">
  <div class="card-header border-bottom border-secondary pb-4">
    <h5 class="mb-0">
      {{getSetName(setAvailability)}}
    </h5>
  </div>
  <div class="card-body">
    <div class="row pt-3 pb-4 border-bottom border-secondary">
      <h6 class="text-muted">Student exercise progress:</h6>
      <p class="small mb-0">
        <!--Overall stage <strong>score is {{getItemScore(setAvailability)}}%</strong>.-->
        Last exercise was completed on
        <span class="text-muted">
          {{getItemLastSubmit(setAvailability) | date: "dd MMM yy HH:mm" }}.
        </span>
      </p>
      <p *ngIf="countSetSessions(setAvailability) > 0" class="small mb-0">
        In total the student completed
          <span class="text-muted">
            {{countSetSessions(setAvailability)}} sessions.
          </span>
      </p>
    </div>
    <div class="row py-4" *ngFor="let stage of listAvailableStages(setAvailability.path)">
      <div class="col-12 col-md-4 col-lg-2 pb-2">
        <h6>{{getItemShortName(stage)}}</h6>
        <!--score: {{getItemScore(getItemByPath(stage.path))}}%<br/>-->
        <p class="small">last completed: <span class="text-muted">{{getItemLastSubmit(getItemByPath(stage.path)) | date: "dd MMM yy HH:mm" }}</span></p>
      </div>
      <div class="col">
        <div class="row mb-2" *ngFor="let section of stage.sections">
          <div class="col-12 col-sm-3 col-md-4 col-lg-3 pb-2">
            <a>{{getItemShortName(section)}}:</a>
            <p *ngIf="isExam(section.path)" class="text-muted xsmall lh-sm mb-0">Select exam to view results.</p>
          </div>
          <div class="col text-center text-md-left text-light">
            <div class="d-inline-block rounded text-center mb-1 me-1 px-1 py-0 small exercise-box"
                  *ngFor="let exercise of section.exercises"
                  [ngClass]="getStatusClass(buildPath(stage, section, exercise))"
                  [class]="isExam(section.path) ? 'cursor-pointer' : 'pe-none'"
                  [routerLink]="isExam(section.path) ? '../exam-results' : null">
              {{getItemShortName(exercise)}}
            </div>

          </div>
        </div>
      </div>
    </div>
    <div >
    <p class="text-muted">
      Recent sessions in the course:
    </p>
    </div>
    <div class="table-responsive" *ngIf="countSetSessions(setAvailability) > 0">
      <table class="table table-sm table-borderless">
        <thead>
          <tr class="table-row table-row-head">
            <th>{{ getChapterHeader(setAvailability) }}</th>
            <th>Section</th>
            <th>{{ getExerciseNameHeader(setAvailability) }}</th>
            <th>Finished</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let session of getSetSessions(setAvailability)" class="table-row">
            <td>
              {{getChapter(session)}}
            </td>
            <td>
              {{getSection(session)}}
            </td>
            <td>
              {{getExerciseName(session)}}
            </td>
            <td class="text-muted">
              {{getSessionDate(session) | date: "dd MMM yy HH:mm" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
