<!--<div *ngIf="isReady() && hasAnyPaymentRecord()" class="card h-100">-->
<!--  <div class="card-body">-->
<!--    <h5 class="card-title">Payments</h5>-->
<!--    <h6 class="card-subtitle mb-2 text-muted" *ngIf="getNotConfirmedCount()">-->
<!--      You have {{getNotConfirmedCount()}} unconfirmed-->
<!--      <span *ngIf="getNotPaidCount()">and {{getNotPaidCount()}} unpaid</span> payments for-->
<!--      <strong>{{getNotConfirmedQuarters()}}</strong>.</h6>-->
<!--    <div *ngIf="hasCurrent()" class="mt-2">-->
<!--      <p class="card-text">In current quarter we registered actions:</p>-->
<!--      <ul class="list-inline">-->
<!--        <li class="list-inline-item" *ngIf="getCurrentNew()"><strong>{{getCurrentNew()}} new</strong></li>-->
<!--        <li class="list-inline-item" *ngIf="getCurrentRenew()"><strong>{{getCurrentRenew()}} renewed</strong></li>-->
<!--        <li class="list-inline-item" *ngIf="getCurrentBlocked()"><strong>{{getCurrentBlocked()}} blocked</strong></li>-->
<!--        <li class="list-inline-item" *ngIf="getCurrentUnblocked()"><strong>{{getCurrentUnblocked()}} unblocked</strong></li>-->
<!--        <li class="list-inline-item" *ngIf="getCurrentDeleted()"><strong>{{getCurrentDeleted()}} deleted</strong> students</li>-->
<!--      </ul>-->
<!--    </div>-->
<!--&lt;!&ndash;    <a href="#" class="card-link">Make Payment</a>&ndash;&gt;-->
<!--&lt;!&ndash;    <a href="#" class="card-link">Payment Details</a>&ndash;&gt;-->
<!--  </div>-->
<!--</div>-->

<!--<div *ngIf="isReady() && !hasAnyPaymentRecord()" class="card h-100">-->
<!--  <div class="card-body">-->
<!--    <h5 class="card-title">Payments</h5>-->
<!--    <h6 class="card-subtitle mb-2 text-light text-center mt-4">We didn't found any payment record.</h6>-->
<!--  </div>-->
<!--</div>-->


<div *ngIf="isReady() " class="card h-100">
  <div class="card-header">
    <app-icon name="person" class="icon-primary icon-bg bg-gray"></app-icon>
    <div class="d-flex">
        <h6 class="card-title my-auto">Payments</h6>
    </div>
  </div>
  <div class="card-body d-flex">
    <h6 class="card-subtitle text-muted text-center m-auto">Coming soon.</h6>
  </div>
</div>
