import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GroupRestServiceImpl} from "../../../services/rest/group-rest.service";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {map, switchMap, tap} from "rxjs/operators";
import {DetailedGroup} from "../../../model/group";
import {ModalComponent} from "../../../components/modal/modal.component";

@Component({
  selector: 'app-group-details-page',
  templateUrl: './group-details-page.component.html',
  styleUrls: ['./group-details-page.component.scss']
})
export class GroupDetailsPageComponent implements OnInit {
  @ViewChild('deleteConfirmModal') deleteConfirmModal: ModalComponent
  schoolId: number;
  groupId: number = null
  group: DetailedGroup

  constructor(activatedRoute: ActivatedRoute,
              private groupRest: GroupRestServiceImpl,
              private casa: CasaRestApiV2,
              private router: Router,
              private route: ActivatedRoute) {
    activatedRoute.paramMap.pipe(
      map( params => {
        this.schoolId = Number(params.get('schoolId'))
        this.groupId = Number(params.get('groupId'))
      }),
      switchMap(_ =>  this.loadGroupDetails())
    ).subscribe();
  }

  ngOnInit(): void {
  }

  private loadGroupDetails() {
    return this.groupRest.getGroupDetails(this.schoolId, this.groupId).pipe(
      tap(group => {
        this.group = group
      })
    )
  }

  getInitials() {
    return this.group?.details.name.split(' ').map(part => {return part[0]}).join('')
  }

  deleteGroup() {
    this.groupRest.deleteGroup(this.schoolId, this.groupId).subscribe(_ => {
      this.deleteConfirmModal.hide()
      this.router.navigate(
        ['../../'],
        {relativeTo: this.route}
      ).then()
    })
  }
}
