import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.scss']
})
export class PaymentCancelComponent implements OnInit {
  public schoolId: string;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.paramMap.subscribe(params => this.schoolId = params.get('schoolId'))
  }

  ngOnInit(): void {
  }

}
