<div *ngIf="participants.length; else noResults" class="table-responsive">
  <table class="table table-sm">
    <thead>
    <tr class="table-row table-row-head">
      <th>Name</th>
      <th>Email</th>
      <th>From</th>
      <th>To</th>
      <th>Labels</th>
      <th style="width: 48px" *ngIf="isEdited"></th>
    </tr>
    </thead>
    <tbody class="table-body cursor-pointer">
    <tr *ngFor="let parti of participants" class="table-row">
      <td>
        {{getName(parti)}}
        <span class="badge xsmall" [class]="getStatusBadgeClass(parti)">{{getStatus(parti)}}</span>
      </td>
      <td>
        {{getEmail(parti)}}
      </td>
      <td class="text-center">
        {{getDateFrom(parti)}}<br>
      </td>
      <td class="text-center">
        {{getDateTo(parti)}}<br>
      </td>
      <td class="text-center">
        {{getLabels(parti)}}<br>
      </td>
      <td *ngIf="isEdited">
        <div class="dropleft my-auto ms-auto">
          <a class="dropdown-toggle-split btn btn-outline-secondary-2 rounded-circle p-1" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
            <app-icon name="more-vertical" class="icon-primary"></app-icon>
          </a>
          <div class="dropdown-menu py-2" aria-labelledby="dropdownMenuReference">
            <a class="dropdown-item" (click)="editParticipant(parti)">
              <app-icon name="edit" class="icon-white"></app-icon>
              Edit
            </a>
            <a class="dropdown-item" (click)="deleteParticipant(parti)">
              <app-icon name="delete" class="icon-white"></app-icon>
              Delete
            </a>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<ng-template #noResults>
  <div class="d-flex">
    <span class="text-muted mx-auto">No results found</span>
  </div>
</ng-template>
