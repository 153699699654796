<nav class="navbar p-0">
  <!-- profile nav on top right  -->
  <div class="nav-container w-100">
    <!-- face button -->
    <div class="dropdown">
        <button type="button" class="btn btn-primary rounded-circle btn-nav d-none d-md-block" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
            <app-icon name="smile"></app-icon>
        </button>
        <ul class="dropdown-menu dropdown-profile mt-3" aria-labelledby="dropdownMenu2">
          <li *ngIf="isAdminLoggedInAsManager() | async">
            <a class="dropdown-item" (click)="logOutFromManager()">
              <app-icon name="log-out" class="icon-secondary me-3"></app-icon>
              Back to Admin
            </a>
          </li>
            <li>
                <a class="dropdown-item" routerLink="./" (click)="logout()">
                    <app-icon name="log-out" class="icon-secondary me-3"></app-icon>
                    Log Out
                </a>
            </li>
        </ul>
    </div>
    <div class="d-none d-xl-flex flex-column text-nowrap pe-4 right-border">
        <span class="small">{{ getName() }}</span>
        <span class="small text-muted"> {{ getRole() }}</span>
    </div>

    <!-- notifications button  -->
    <div class="w-100 w-md-auto d-flex justify-content-between">
      <app-notifications [schoolId]="schoolId"></app-notifications>

      <button
          type="button"
          class="btn btn-primary rounded-circle btn-nav d-md-none lh-1"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBackdrop"
          aria-controls="offcanvasWithBackdrop">
          <app-icon name="menu"></app-icon>
      </button>
    </div>
  </div>
  <div class="navbar-panel d-none d-md-flex flex-column justify-content-between">
    <manager-main-nav class="w-100 h-100" [isOffcanvas]="false" [schoolId]="schoolId"></manager-main-nav>
  </div>
</nav>
<!-- offcanvas menu on mobile -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
  <div class="offcanvas-body">
    <manager-main-nav class="h-100" [isOffcanvas]="true" [schoolId]="schoolId"></manager-main-nav>
  </div>
</div>


<div class="workspace">
  <router-outlet></router-outlet>
</div>
<app-buy-credits-cart [school]="school"></app-buy-credits-cart>


