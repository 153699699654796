import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchType, SchoolsSortingRule, SearchTypeName } from 'src/app/model/admin';
import { Page, Pageable } from 'src/app/model/rest/base';
import {
  School,
  SchoolStatus,
  SchoolStatusEnum,
} from 'src/app/model/rest/casa-apiv2.model';
import { FunctionalAccessService } from 'src/app/services/functional-access.service';
import { AdminRestService } from 'src/app/services/rest/admin-rest.service';
import { LocalState } from 'src/app/services/utils/manager-utils';

class SearchForm {
  searchType: SearchType = SearchType.SCHOOL_NAME;
  searchValue: string;
  schoolStatus: SchoolStatus;
  dateAfter: Date;
  dateBefore: Date;
}
@Component({
  selector: 'app-school-list-page',
  templateUrl: './school-list-page.component.html',
  styleUrls: ['./school-list-page.component.scss'],
})
export class SchoolListPageComponent implements OnInit {
  schoolsPage: Page<School>;
  searchForm: SearchForm = new SearchForm();
  sortingRules = SchoolsSortingRule.values;
  pageable = new Pageable(0, 10, []);
  currentState: LocalState<SchoolsSortingRule>;

  constructor(
    private adminRest: AdminRestService,
    private faService: FunctionalAccessService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadSchools();
    this.currentState = new LocalState(
      '',
      SchoolsSortingRule.Default,
      this.pageable
    );
  }

  loadSchools() {
    this.adminRest.listSchools(this.pageable).subscribe((schoolsPage) => {
      this.schoolsPage = schoolsPage;
    });
  }

  getAllSearchTypes() {
    return SearchType.ALL;
  }

  getSearchTypeName(key: string) {
    return SearchTypeName[key];
  }

  resetDates() {
    this.searchForm.dateAfter = null;
    this.searchForm.dateBefore = null;
  }

  isSearchByExpDate() {
    return this.searchForm.searchType === SearchType.EXPIRY_DATE;
  }
  isSearchByStatus() {
    return this.searchForm.searchType === SearchType.STATUS;
  }

  getSchoolStatusList() {
    return SchoolStatus.ALL;
  }

  getSchoolStatusName(key: string) {
    return SchoolStatusEnum[key];
  }

  resetSearch() {
    this.searchForm = new SearchForm();
    this.updateSearch();
  }

  sortBy() {
    this.pageable.page = 0;
    this.pageable.sort[0] = this.currentState.sortingRule.sorting.join()
    this.loadSchools();
  }
  updateSearch() {
    const searchTerm = this.searchForm.searchValue?.toLowerCase();
    let observable = new Observable<Page<School>>()
    if (!searchTerm && !this.isSearchByExpDate() && !this.isSearchByStatus()) {
      this.adminRest.listSchools(this.pageable).subscribe(schoolsPage =>
        this.schoolsPage = schoolsPage)
      return;
    }

    switch (this.searchForm.searchType) {
      case SearchType.SCHOOL_NAME:
        observable = this.adminRest.listSchools(this.pageable, searchTerm)
        break;
      case SearchType.SCHOOL_ID:
        observable = this.adminRest.listSchools(this.pageable, null, searchTerm)
        break;
      case SearchType.SCHOOL_OLD_ID:
        observable = this.adminRest.listSchools(this.pageable, null, null, searchTerm)
        break;
      case SearchType.COUNTRY:
        observable = this.adminRest.listSchools(this.pageable, null, null, null, searchTerm)
        break;
      case SearchType.STATUS:
        observable = this.adminRest.listSchools(this.pageable, null, null, null, null, this.searchForm.schoolStatus)
        break;
      case SearchType.EXPIRY_DATE:
        observable = this.adminRest.listSchools(this.pageable, null, null, null, null, null, this.searchForm.dateAfter, this.searchForm.dateBefore)
        break;
      default:
        break;
    }
    observable.subscribe(schoolsPage =>
      this.schoolsPage = schoolsPage)
  }

  switchPage($event) {
    this.pageable = $event
    this.updateSearch()
  }

  navigateBasingOnRole(school: School) {
    this.faService.isSuperUser().subscribe(res => {
      if(res)
        this.router.navigate([`/admin/school/${school.id }`])
      else
        this.router.navigate([`/school/${school.id}/dashboard`])
    })
  }
}
