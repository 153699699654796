<p *ngIf="!hasResults()"
  class="text-muted text-center my-3 f-sanchez">
  Please wait...
</p>
<div *ngIf="hasResults() && data.numberOfElements==0" class="text-center">
  No results found.
</div>
<ul *ngIf="hasResults() && data.numberOfElements > 0"  class="list-unstyled lessons-list rounded-3 mb-0">
  <li class="row mb-2 px-2 py-3 rounded-3" *ngFor="let lesson of data.content">
    <div class="col">
      <div class="row">
        <div class="col-12 col-lg-3 mb-2 mb-lg-0 d-flex">
            <div *ngIf="getPersonProfilePhoto(lesson.students[0].person)"  class="rounded-circle img-thumbnail my-auto" style="width: 4rem; height: fit-content;">
                <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getPersonProfilePhoto(lesson.students[0].person) +')'">
                </div>
            </div>
            <div *ngIf="!getPersonProfilePhoto(lesson.students[0].person)" class="initials-circle-box-lg me-3 my-auto">
              <span class="m-auto small text-white">{{ getInitials(lesson) }}</span>
            </div>
            <span class="badge d-lg-none my-auto ms-auto" [ngClass]='lessonBadgeClass(lesson)'>{{ lesson.lessonStatus }}</span>
        </div>
        <div class="col-12 col-lg-4 mb-2 mb-lg-0 d-flex flex-column justify-content-center">
            <p class="text-muted small mb-0">Student:</p>
            <h6 class="mb-0">{{getStudentNames(lesson)}}</h6>
        </div>
        <div class="col-12 col-lg-5 mb-2 mb-lg-0 d-flex flex-column justify-content-center">
            <p class="text-muted small mb-0">Date:</p>
            <h6 class="mb-0">{{getLessonDate(lesson) | date: 'EEEE, dd MMM yyyy'}} {{getLessonDate(lesson) | date: 'HH:mm'}}</h6>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-2 d-none d-lg-flex">
      <span class="badge m-auto" [ngClass]='lessonBadgeClass(lesson)'>{{ lesson.lessonStatus }}</span>
    </div>
    <div class="col-12 col-lg-3 mb-1 d-flex flex-column">
      <a routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/lessons/{{lesson.id}}"
      *ngIf="mayViewLesson(lesson)" class="btn btn-outline-secondary-2 rounded-pill d-block w-100 my-auto"
      >View details</a>
    </div>
  </li>
</ul>



