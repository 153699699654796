import { HttpParams } from '@angular/common/http';
import {Browser, SystemInfo} from "../services/browser-version.service";

export class Page<T> {
    content: T[];
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

export class Pageable {

    constructor(public page: number, public size: number, public sort?: string) {}
    inital = false;

    public appendParams(params: HttpParams): HttpParams {
        let result = params;
        if (this.page != null) {
            result = result.append('page', this.page.toString());
        }
        if (this.size != null) {
            result = result.append('size', this.size.toString());
        }
        if (this.sort != null) {
            result = result.append('sort', this.sort);
        }
        return result;
      }
    public getHttpParams(): HttpParams {
        return this.appendParams(new HttpParams());
    }

    clone(): Pageable {
        return new Pageable(this.page, this.size, this.sort);
    }

    cloneForInitial(): Pageable {
        const res = this.clone();
        res.inital = true;
        return res;
    }

    consumeInitial(): Pageable {
        if (this.inital) {
            this.inital = false;
            const res = this.clone();
            res.page = 0;
            res.size = (this.page + 1) * this.size;
            return res;
        } else {
            return this;
        }
    }

    next(): Pageable {
        const res = this.clone();
        res.page++;
        return res;
    }
}

export class ProgressDetails {
    course: string;
    stage: string;
    lastHw: string;
    paragraph: string;
    reading: string;
    dictation: string;
    comment: string;
    readingLesson: string;
    startPage: string;
    lessonType: string;
}

export class AttendanceDetails {
    attended: boolean;
    participant: ParticipantDef;
}

export class RoomProgressReport {
    details: ProgressDetails;
    created: number;
    teacher: ParticipantDef;
    attenders: AttendanceDetails[];
}

export class Provisioning {
    created: number;
    updated: number;
    duration: number;
    endTime: number;
    status: string;
}

export class ApiBase {
    id: number;
}

export class RoomTemplateDetailsBase {
  name: string;
  hybridState: string;
  place: string;
  notes: string;
}

export class TemplateDetails extends RoomTemplateDetailsBase {
  region: string;
}

export class TemplateParticipantDetailsBase {
    role: "Student" | "Teacher";
    name: string;
    email: string;
    offline = false;
    videoOff = false;
    audioOff = false;
    passive = false;
    chartOff = false;
    secret = false;
    muteOff = false;
    chatOff = false;
}

export class TemplateParticipant extends ApiBase {
    details: TemplateParticipantDetailsBase;
}

export class IdentifiedTemplateParticipant extends TemplateParticipant {
  eid: String;
}

export class RoomTemplateBase<T extends RoomTemplateDetailsBase> extends ApiBase {
  details: T;
}

export class RoomTemplate<T extends TemplateParticipant> extends RoomTemplateBase<TemplateDetails> {
  created: number;
  deleted: number;
  participants: T[];
}

export class IdentifiedRoomTemplate extends RoomTemplate<IdentifiedTemplateParticipant> {
  eid: String;
}

export class RoomDef {
  uuid: string;
  name: string;
  mode: string;
  time: Date;
  provisioning: Provisioning;
  lessonStartDate: Date;
  timerStartDate: Date;
  totalTime: number;
  createDate: Date;
  id: string;
  sid: string;
  participants: Array<ParticipantDef>;
  chart: Chart;
  region: string;
  logo: string;
  serverTime: number;
  /**
   * time - serverTime
   */
  clockSkew: number;
  progress: RoomProgressReport;
  plannedDate: number;
  place: string;
  hybridState: string;
  reportStats: boolean;
}

export class ParticipantVideoInfo {
  duration: number;
  startTime: number;
  endTime: number;
  status: string;
  pingAge: number;
}

export class ParticipantDef {
    id: string;
    uuid: string;
    name: string;
    mail: string;
    accessToken: string;
    role: string;
    muted: boolean;
    focused: boolean;
    hidden: boolean;
    active: boolean;
    activationsNb: number;
    videoInfo: ParticipantVideoInfo;
    offline: boolean;
    passive: boolean;
    chartOff: boolean;
    muteOff: boolean;
    secret: boolean;
    chatOff: boolean;
}

export class Chart {
    chartType: string;
    chartId: string;
    chartData: string;
    entryAssigned: ChartEntry;
}

export class ChatEntry {
    date: Date;
    participant: ParticipantDef;
    message: string;
}

export class Chat {
    entries: Array<ChatEntry>;
}

export class ClientReport {
    constructor(systemInfo: SystemInfo) {
        this.os = systemInfo.osName;
        this.browser = Browser[systemInfo.browser];
        this.browserVersion = systemInfo.version;
        this.versionMajor = systemInfo.major;
        this.screenSize = systemInfo.screenSize;
        this.navVersion = systemInfo.navigatorVersion;
        this.navAgent = systemInfo.navigatorAgent;
        this.navApp = systemInfo.navigatorApp;
        this.mobile = systemInfo.mobile;
        this.osVersion = systemInfo.osVersion;
        this.appVersion = systemInfo.appVersion;
    }
    os: string;
    browser: string;
    browserVersion: string;
    versionMajor: number;
    screenSize: string;
    navVersion: string;
    navAgent: string;
    navApp: string;
    mobile: boolean;
    osVersion: string;
    appVersion: string;
}

export class Issues {
    static values = ['STUDENT_AUDIO', 'STUDENT_VIDEO', 'TEACHER_AUDIO', 'TEACHER_VIDEO'];
    static descriptions = ['I couldn\'t hear the student',
    'I couldn\'t see the student',
    'The student couldn\'t hear me',
    'The student couldn\t see me'];
    static getDescription(v: string) {
        return this.descriptions[this.values.indexOf(v)];
    }
}

export class ParticipantSurvey {
    success: boolean;
    audioQualityRating: number;
    videoQualityRating: number;
    comment: string;
    issues: Array<string>;
    constructor(public overalRating: number) {}
}

export class School extends ApiBase {
    name: string;
}

export class FileEntity extends ApiBase {
    name: string;
    contentType: string;
    size: number;
    accessUrl: string;
}

export class SchoolConfig {
    logo: FileEntity;
}

export class GroupTiming {
    group: RoomTemplate<TemplateParticipant>;
    roomsOpened: number;
    totalStudents: number;
    totalStudentsTime: number;
    roomTotalTime: number;
    teacherTotalTime: number;
    totalBillingTime: number;
}

export class RoomSettings {
  plannedStartDate: number;
}

export class ChartEntry {
  imageName: string;
  title: string;
}

export class ChartSet {
  chartSetId: string;
  chartSetName: string;
  charts: Array<ChartEntry>
}

export class ErrorBase extends Error {
  constructor(message: string) {
    super(message);
    super.message = message;
    this.userMessage = message;
  }
  developerMessage: string;
  userMessage: string;
  errorCode: string;
  interactive: boolean;
}

export class TeachersAttendanceReport {
  teacherEmail: String;
  numberOfLessons: number;
  totalSeconds: number;
}

export class HybridState {
  static Online = "Online";
  static Hybrid = "Hybrid";
  static Offline = "Offline";
}

export class QualityReportEntry {
  uuid: string;
  time: number;
  stats: any;
}
