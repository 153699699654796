import {Component, OnInit} from '@angular/core';
import {GroupParticipantCreate} from "./group-participant-create";
import {Person, PersonDetails, Teacher, TeacherBase} from "../../../model/rest/casa-apiv2.model";
import {
  TeacherAutocompleteAdapter
} from "../../person-autocomplete/person-autocomplete-adapters";
import {ActivatedRoute} from "@angular/router";
import {GroupRestServiceImpl} from "../../../services/rest/group-rest.service";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {GroupTeacher, ParticipantDetails} from "../../../model/group";
import {PersonNameExtractor} from "../../../services/utils/profile-photo-url-extractor";
import {Adapter} from "../../person-autocomplete/person-autocomplete.component";
import {Observable} from "rxjs";

@Component({
  selector: 'app-group-teacher-create',
  templateUrl: './group-participant-create.component.html',
  styleUrls: ['./group-participant-create.component.scss']
})
export class GroupTeacherCreateComponent extends GroupParticipantCreate<Teacher, TeacherBase, PersonDetails> implements OnInit {

  participant = new GroupTeacher<Teacher>()
  participantAdapter: Adapter<Teacher>;

  constructor(activatedRoute: ActivatedRoute,
              groupRest: GroupRestServiceImpl,
              casa: CasaRestApiV2) {
    super(activatedRoute, groupRest, casa)
  }

  ngOnInit(): void {
  }

  setupAutocompleteAdapters() {
    this.participantAdapter = new TeacherAutocompleteAdapter(this.schoolId, this.casa);
  }
  participantSelected(teacher: GroupTeacher<Teacher>, selectedTeacher: Teacher) {
    this.isParticipantSelected = true
    this.participant = JSON.parse(JSON.stringify(teacher)) as GroupTeacher<Teacher>
    this.participant.details.participant = JSON.parse(JSON.stringify(selectedTeacher)) as Teacher
    this.participant.details.participant.person.details.name = PersonNameExtractor.getCasaPersonName(selectedTeacher.person);
  }

  addParticipant() {
    this.isParticipantBeingAdded = true
    this.resetParticipantObject()
  }
  resetParticipantObject() {
    this.unlinkParticipant()
    this.groupParticipantForm.resetForm()
    this.isParticipantSelected = false
    this.participant = new GroupTeacher<Teacher>();
    this.participant.details = new ParticipantDetails<Teacher>()
    this.participant.details.participant = new Teacher()
    this.participant.details.participant.person = new Person<PersonDetails>()
    this.participant.details.participant.person.details = new PersonDetails()
    this.participant.details.participant.person.details.name = ""
  }

  saveParticipant() {
    this.participant.details.labels = this.labels?.split(',').map(it => it.trim())
    let observable: Observable<any>
    if(!this.isParticipantBeingEdited)
      observable = this.groupRest.addGroupTeacher(this.schoolId, this.groupId, this.participant.details)
    else
      observable = this.groupRest.updateParticipantDetails(this.schoolId, this.groupId, this.participant.id, this.participant.details)

    observable.subscribe(_ => {
      this.isParticipantBeingAdded = false
      this.isParticipantBeingEdited = false
      this.reloadGroupEmitter.emit()
    })
    this.resetParticipantObject()
  }
}
