import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {switchMap, take, tap} from "rxjs/operators";
import {StudentVideoRestService} from "../../../services/rest/student-video-rest.service";
import {RoomDef} from "../../../model/server";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-student-room-details-page',
  templateUrl: './student-room-details-page.component.html',
  styleUrls: ['./student-room-details-page.component.scss']
})
export class StudentRoomDetailsPageComponent implements OnInit {
  schoolId: number;
  studentId: number;
  roomUuid: string;
  roomData: RoomDef;

  constructor(
    activatedRoute: ActivatedRoute,
    private rest: StudentVideoRestService
    ) {

    forkJoin([
      activatedRoute.paramMap.pipe(take(1)),
      activatedRoute.parent.paramMap.pipe(take(1))
    ]).pipe(
      tap<[ParamMap, ParamMap]>( ([params, parentParams]) => this.readParams(params, parentParams)),
      switchMap( _ => this.loadRoomData())
    ).subscribe();
  }

  private loadRoomData() {
    return this.rest.getRoomDetails(this.schoolId, this.studentId, this.roomUuid).pipe(
      tap( roomData => this.storeRoomData(roomData))
    )
  }

  private storeRoomData(roomData: RoomDef) {
    this.roomData = roomData;
  }

  private readParams(params: ParamMap, parentParams: ParamMap) {
    console.log(params);
    this.schoolId = Number(parentParams.get('schoolId'));
    this.studentId = Number(parentParams.get('studentId'));
    this.roomUuid = params.get('roomUuid');
  }

  ngOnInit(): void {
  }

}
