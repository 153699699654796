import { Component, OnInit } from '@angular/core';
import {AuthServiceImpl, AuthServiceProvider} from 'src/app/services/col2/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
/*
logout landing page - is not reponsible for making logout logic
but only for displaying after logout page.
*/
export class LogoutComponent implements OnInit {


  constructor(authService: AuthServiceProvider) {
    authService.get().subscribe(api => api.clear());
  }

  ngOnInit() {
  }

}
