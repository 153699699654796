<p class="text-success small mb-5">
  Any changes to this list will
  <strong class="text-success">affect all future planned lessons</strong>.
</p>
<form *ngIf="template" #nrForm="ngForm" (submit)="onSave()" autocomplete="off">
  <div class="row mb-5">
    <div class="col-12 col-md-6 mb-4">
      <div class="form-floating">
        <input class="form-control"
        [(ngModel)]="template.details.name"
        placeholder="Group name"
        name="room-name">
        <label>Group name</label>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <div class="form-floating">
        <input class="form-control"
        [(ngModel)]="template.details.place"
        placeholder="classroom number / name"
        name="room-number">
        <label>Classroom name <small class="text-muted">(optional for offline groups)</small></label>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="form-floating">
        <textarea class="form-control"
          rows="1"
          [(ngModel)]="template.details.notes"
          name="group-notes"
          placeholder="group-notes">
        </textarea>
        <label>Notes about the Group <small class="text-muted">(shown to the teacher inside the Online Classroom)</small></label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" #groupOnline id="online-group" name="online-group" [(ngModel)]="onlineGroup">
        <label class="form-check-label text-muted" *ngIf="groupOnline.checked" for="online-group">The group is online</label>
        <label class="form-check-label text-muted" *ngIf="!groupOnline.checked" for="online-group">The group is offline (in a physical classroom<app-icon name="school" class="icon-secondary align-text-bottom icon-sm ms-2"></app-icon>)</label>
      </div>
    </div>
  </div>

    <!-- this is an option for element soft connected to the casa entity -->
  <div class="row mb-5">
    <div class="d-flex mb-4">
      <h5 class="w-fit-content my-auto">Default teacher</h5>
      <div class="btn-group p-2 rounded-pill border border-2 border-secondary ms-auto" *ngIf="isCasaConnected(teacher)">
        <button type="button"
          class="btn btn-primary rounded-circle p-2 lh-sm"
          (click)="unlink(teacher)"
          data-bs-toggle="tooltip"
          title="Unlink this account from the user list. IMPORTANT: They will not be able to see the data related to this group or lesson in their User Interface."
        >
          <app-icon name="unlink" class="icon-white"></app-icon>
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <app-person-autocomplete
        [adapter]="teacherAdapter"
        (itemSelected)="casaTeacherItemSelected($event)"
      >
        <div class="form-floating">
          <input class="form-control"
                  name="teacher-name"
                  placeholder="Teacher's name"
                  [(ngModel)]="teacher.details.name"
                  [class]="isCasaConnected(teacher) ? 'linked' : 'unlinked'"
          >
          <label>Teacher's name</label>
        </div>
      </app-person-autocomplete>
    </div>
    <div class="col-12 col-md-6 mb-4">
      <div class="form-floating">
        <input
          class="form-control"
          name="teacher-mail" type="email"
          placeholder="Teacher's email"
          [(ngModel)]="teacher.details.email"
          [class]="isCasaConnected(teacher) ? 'linked' : 'unlinked'"
        >
        <label>Teacher's email</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="d-flex mb-4">
      <h5 class="w-fit-content my-auto">Students</h5>
      <div class="btn-group p-2 rounded-pill border border-2 border-secondary ms-auto" *ngIf="canAdd()">
        <button type="button"
                class="btn btn-primary rounded-circle p-2 lh-sm"
                (click)="addStudent()"
                data-bs-toggle="tooltip"
                title="Add a new participant."
        >
          <app-icon name="plus" class="icon-white"></app-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="row mb-4" *ngFor="let student of students; let i = index">
    <div class="col-12 col-lg mb-4 mb-md-2">
      <app-person-autocomplete
      [adapter]="studentAdapter"
      (itemSelected)="casaStudentItemSelected(student, $event)"
      >
        <div class="form-floating">
            <input
              class="form-control"
              name="student-name-{{getStudentIndex(student)}}"
              placeholder="Student's name"
              [(ngModel)]="student.details.name"
              [class]="isCasaConnected(student) ? 'linked' : 'unlinked'"

            >
            <label>Student's name</label>
        </div>
      </app-person-autocomplete>
    </div>
    <div class="col-12 col-lg mb-2">
      <div class="form-floating">
        <input
          class="form-control"
          name="student-mail-{{getStudentIndex(student)}}"
          type="email" placeholder="Student's email"
          [(ngModel)]="student.details.email"
          [class]="isCasaConnected(student) ? 'linked' : 'unlinked'"

        >
        <label>Student's email</label>
      </div>
    </div>
    <div class="col-5 col-lg-12 order-lg-3">
      <span *ngIf="student.details.offline"><app-icon name="school" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.videoOff"><app-icon name="camera-off" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.audioOff"><app-icon name="audio-off" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.passive"><app-icon name="front-hand" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.chartOff"><app-icon name="chart" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.secret"><app-icon name="secret" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.muteOff"><app-icon name="mic-off" class="icon-secondary me-1"></app-icon></span>
      <span *ngIf="student.details.chatOff"><app-icon name="no-chat" class="icon-secondary me-1"></app-icon></span>
    </div>
    <div class="col-7 col-lg-auto text-end ms-auto">
      <div class="btn-group p-2 rounded-pill border border-2 border-secondary ms-auto">
        <button
            *ngIf="isCasaConnected(student)"
            type="button" class="btn btn-primary rounded-circle p-2 lh-sm me-2" (click)="unlink(student)"
            data-toggle="tooltip"
            title="Unlink this account from the user list. IMPORTANT: They will not be able to see the data related to this group or lesson in their User Interface."
          >
          <app-icon name="unlink" class="icon-white"></app-icon>
        </button>
        <button type="button" class="btn btn-primary rounded-circle p-2 lh-sm me-2" (click)="removeStudent(student)"
                  data-toggle="tooltip"
                  title="Delete user from the list"
          >
          <app-icon name="delete" class="icon-white"></app-icon>
        </button>
          <button type="button" class="btn btn-primary rounded-circle p-2 lh-sm" (click)="editSettings(student)"
                  data-toggle="tooltip"
                  title="Manage user's settings"
          >
          <app-icon name="settings" class="icon-white"></app-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="col-12 mb-4">
        <div class="form-floating">
          <select class="form-select" [(ngModel)]="template.details.region" name="room-region" [ngClass]="{ 'select-not-empty' : template.details.region }">
            <option *ngFor="let region of regions" [value]="region.code">{{region.name}}</option>
          </select>
          <label>Select a region</label>
          <span id="passwordHelpBlock" class="xsmall text-danger">
            Choose the region closest to your location for improved connection quality.
          </span>
        </div>
      </div>
  </div>
  <div class="text-end">
    <button type="button" class="btn btn-secondary px-4 py-2 rounded-pill me-3" (click)="onCancel()">Clear</button>
    <button type="submit" class="btn btn-primary px-4 py-2 rounded-pill">Save</button>
  </div>
</form>

<app-modal #settingsModal size="lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">Participant settings</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body" *ngIf="editParticipantSettings">
        <!-- <div class="col-12 mb-4">
          <div class="col-1 text-muted pt-2">
            <i class="fa fa-school"></i>
          </div>
          <div class="col col-form-label">
            <label> In class
            </label>
            <div class="text-muted small mt-n2">
              If you are going to have a hybrid lesson check this switch for students in the class.
            </div>
          </div>
          <div class="form-floating col-auto pt-2">
            <div class="form-control form-switch">
              <input type="checkbox" class="form-control-input" id="settings-offline" [(ngModel)]="editParticipantSettings.details.offline">
              <label class="form-control-label text-hide" for="settings-offline">In class</label>
            </div>
          </div>
        </div> -->
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="front-hand" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent random prompting</p>
          <p class="text-muted xsmall mb-0">
            The student will never be selected by the randomizer (prompting with the space bar).
            To prompt the student, you will need to select them manually.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-passive" [(ngModel)]="editParticipantSettings.details.passive">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="chart" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent chart interaction</p>
          <p class="text-muted xsmall mb-0">
            The student won’t be able to interact with the demonstration charts.
            <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-chart-off" [(ngModel)]="editParticipantSettings.details.chartOff">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="no-chat" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent chat interaction</p>
          <p class="text-muted xsmall mb-0">
            The student won’t be able to send chat messages, but will see other chat messages.
            <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-chat-off" [(ngModel)]="editParticipantSettings.details.chatOff">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="secret" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Observation mode</p>
          <p class="text-muted xsmall mb-0">
            The observer won’t be seen by the other participants, and won’t be able to broadcast audio or video, use the chat feature, or interact with the demonstration charts.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-secret" [(ngModel)]="editParticipantSettings.details.secret">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="mic-off" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent muting</p>
          <p class="text-muted xsmall mb-0">
            The participant can’t be muted by the teacher and won’t be muted automatically when another student is selected. The participant can still mute themselves.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-mute-off" [(ngModel)]="editParticipantSettings.details.muteOff">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="camera-off" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Video Off</p>
          <p class="text-muted xsmall mb-0">
            The student’s video will be off when they join the lesson. You can turn their video back on during the lesson.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-video-off" [(ngModel)]="editParticipantSettings.details.videoOff">
        </div>
      </div>
      <div class="d-flex gap-3">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="audio-off" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Audio Off</p>
          <p class="text-muted xsmall mb-0">
            The student’s audio will be off when they join the lesson. You can turn their audio back on during the lesson.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-audio-off" [(ngModel)]="editParticipantSettings.details.audioOff">
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-secondary rounded-pill px-md-5 py-md-3" data-bs-dismiss="modal">Close</button>
    </div>
  </div>

</app-modal>
