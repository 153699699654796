import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiLessonFlag} from 'src/app/model/rest/rest-model';
import {LangProductMapper} from "../../services/utils/lang-mappers";
import {Dates} from "../../services/utils/calendar-utils";

export class ReportGenericFilterData {
  dateFrom: Date;
  dateTo: Date;
  productCode: string;
  lessonType: string[];
  lessonFlag: string[];
  lessonStatus: string[];
}

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.css']
})
export class ReportsFilterComponent implements OnInit {
  dateFrom: string;
  dateTo: string;
  productCode: string;
  langCodes = LangProductMapper.activeLangCodes;
  preselectedPeriod: number;
  flagTypes = ApiLessonFlag.flagsTyppes;
  lessonType: string[] = [];
  lessonFlag: string[] = [];
  lessonStatus: string[] = [];
  lessonTypes = ['Standard', 'Revision', 'Quick Stage Revision', 'Introduction', 'Prova', 'Exam'];
  lessonStatuses = ['Initializing', 'Booked', 'Due', 'In Progress', 'Complete', 'Cancel', 'Invalidated By Teacher', 'Invalidated By Student'];
  lessonFlags = ['Poor Connection', 'Classroom Issue', 'Student Needs Help', 'Student Not Show', 'Teacher Not Show', 'Other'];
  constructor() { }

  @Input()
  withLessonType = false;
  @Input()
  withLessonStatus = false;
  @Input()
  withLessonFlag = false;

  @Output()
  generateReport = new EventEmitter<{dateFrom: Date, dateTo: Date, productCode: string}>();

  getLangName(langCode: string) {
    return LangProductMapper.mapLangCodeToLangName(langCode);
  }

  preselectedChange() {
    this.preselectedPeriod = Number(this.preselectedPeriod);
    this.dateFrom = Dates.simpleDateFormat(Dates.monthStartDate(this.preselectedPeriod));
    this.dateTo = Dates.simpleDateFormat(new Date());
    if (this.preselectedPeriod !== 0) {
      this.dateTo = Dates.simpleDateFormat(Dates.monthFinishDate(this.preselectedPeriod));
    }
  }

  public generate() {
    if((this.withLessonType && this.lessonType.length === 0)
      || (this.withLessonStatus && this.lessonStatus.length === 0))
      return
    const dateFrom = this.dateFrom ? Dates.simpleDateTimeParse(Dates.simpleDateFormat(Dates.startTime(this.dateFrom))) : null;
    const dateTo = this.dateTo ? Dates.simpleDateTimeParse(Dates.simpleDateFormat(Dates.finishTime(this.dateTo))) : null;
    const event: ReportGenericFilterData = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      productCode: this.productCode,
      lessonType: this.lessonType,
      lessonFlag: this.lessonFlag,
      lessonStatus: this.lessonStatus};
    this.generateReport.emit(event);
  }

  public predefProva() {
    this.lessonFlag = null;
    this.lessonType = ['Prova'];
    this.lessonStatus = ['Booked', 'Due', 'InProgress', 'Complete', 'InvalidatedByTeacher', 'InvalidatedByStudent'];
  }
  public predefProvaNoShow() {
    this.lessonFlag = ['StudentNotShow'];
    this.lessonType = ['Prova'];
    this.lessonStatus = ['Complete'];
  }
  public predefNoShow() {
    this.lessonFlag = null;
    this.lessonType = ['Standard', 'Revision', 'QuickStageRevision', 'Introduction', 'Prova', 'Exam'];
    this.lessonFlag = ['StudentNotShow'];
    this.lessonStatus = ['Complete'];
  }
  ngOnInit() {
  }

  resetDates() {
    this.dateFrom = null
    this.dateTo = null
    this.preselectedPeriod = null
  }

  onTypeChange(type: string, event: Event) {
    const checked = (event.target as any).checked;
    if(!this.lessonType)
      this.lessonType = []
    if (checked) {
      this.lessonType.push(type);
      return;
    } else {
      this.lessonType.filter(_type => {return _type != type});
      if(this.lessonType.length === 0 )
        this.lessonType = null
    }
  }
  onStatusChange(status: string, event: Event) {
    const checked = (event.target as any).checked;
    if(!this.lessonStatus)
      this.lessonStatus = []
    if (checked) {
      this.lessonStatus.push(status);
      return;
    } else {
      this.lessonStatus.filter(_status => {return _status != status});
      if(this.lessonStatus.length === 0 )
        this.lessonStatus = null
    }
  }
  onFlagChange(flag: string, event: Event) {
    const checked = (event.target as any).checked;
    if(!this.lessonFlag)
      this.lessonFlag = []
    if (checked) {
      this.lessonFlag.push(flag);
      return;
    } else {
      this.lessonFlag.filter(_flag => {return _flag != flag});
      if(this.lessonFlag.length === 0 )
        this.lessonFlag = null
    }
  }
  isTypeChecked(type: string) {
    return this.lessonType?.includes(type)
  }
  isStatusChecked(status: string) {
    return this.lessonStatus?.includes(status)
  }
  isFlagChecked(flag: string) {
    return this.lessonType?.includes(flag)
  }

}
