<form *ngIf="profile" #f="ngForm" (ngSubmit)="f.form.valid && onSave()" class="my-2">

  <div class="card mb-5">
    <ng-content select="[form-header]"></ng-content>
    <div class="card-body">
      <ng-content select="[form-body-top]"></ng-content>
      <div class="row px-0 form-file mb-4">
        <div class="col-auto mb-4">
          <label for="photo" class="form-label d-flex justify-content-between">
              <div class="progress mb-2" *ngIf="uploadProgress">
                  <div class="progress-bar" role="progressbar" [ngStyle]="{'width':uploadProgress + '%'}"></div>
              </div>
              <div class="photo-square" [ngClass]="profile.photo?.publicAccessUrl? 'border-0' : ''">
                  <div  class="photo-circle img-thumbnail d-flex"
                  [style.background-image]="getProfilePhoto(profile)">
                      <app-icon *ngIf="!profile.photo?.publicAccessUrl" name="photo-add" class="icon-secondary m-auto"></app-icon>
                  </div>
              </div>
          </label>
          <input
            #fileInput
            class="form-control d-none"
            (change)="onProfileImageUpload($event.target.files)"
            accept="image/svg, image/png, image/jpg, image/gif"
            type="file" id="photo"
            >
        </div>
        <div class="col-12 col-lg">
          <div class="row border-bottom border-2 border-secondary">
            <div class="col-12">
              <ng-content class="mb-4" select="[form-top]"></ng-content>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                type="text"
                name="name"
                #name="ngModel"
                placeholder="Name"
                id="name"
                [(ngModel)]="profile.name"
                [ngClass]="{ 'is-invalid': f.submitted && name.invalid}"
                class="form-control"
                required>
                <label for="name" class="field-required">Name</label>
                <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                  <div *ngIf="name.errors.required">Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                type="text"
                placeholder="Surname"
                [ngClass]="{ 'is-invalid': f.submitted && surname.invalid}"
                class="form-control"
                id="surname"
                name="surname"
                #surname="ngModel"
                [(ngModel)]="profile.surname"
                required>
                <label for="surname" class="field-required">Surname</label>
                <div *ngIf="f.submitted && surname.invalid" class="invalid-feedback">
                  <div *ngIf="surname.errors.required">Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <select
                id="lang"
                name="language"
                #language="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && language.invalid,
                'select-not-empty' : profile.locale}"
                [(ngModel)]="profile.locale"
                class="form-select"
                required
                >
                <option value="de">Deutsch</option>
                <option selected value="en">English</option>
                <option value="es">Español</option>
                <option value="hr">Hrvatski</option>
                <option value="it">Italiano</option>
                <option value="ja">日本語</option>
                <option value="pl">Polski</option>
                <option value="pt">Português</option>
                <option value="ru">Pусский</option>
                <option value="uk">Yкраїнська</option>
              </select>
              <label class="field-required">Language</label>
                <div *ngIf="f.submitted && language.invalid" class="invalid-feedback">
                  <div *ngIf="language.errors.required" >Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <select
                id="country"
                name="country"
                #country="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && country.invalid,
                'select-not-empty': profile.address.country}"
                [(ngModel)]="profile.address.country"
                class="form-select"
                >
                <option *ngFor="let c of countries" [value]="c.name">{{c.name}}</option>
                </select>
                <label for="country">Country</label>
                <div *ngIf="f.submitted && country.invalid" class="invalid-feedback">
                  <div *ngIf="country.errors.required" >Field is required</div>
                </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <select
                id="nationality"
                name="nationality"
                #nationality="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && nationality.invalid,
                'select-not-empty': profile.nationality }"
                [(ngModel)]="profile.nationality"
                class="form-select"
                >
                <option *ngFor="let n of countries" [value]="n.name">{{n.name}}</option>
                </select>
                <label for="nationality">Nationality</label>
                <div *ngIf="f.submitted && nationality.invalid" class="invalid-feedback">
                  <div *ngIf="nationality.errors.required" >Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="gender-input">
                <h6 class="text-start gender-header text-muted">Gender</h6>
                <div class="input-group">
                    <div class="form-check me-2" *ngFor="let gender of genders">
                        <input class="form-check-input"
                            type="radio" name="gender"
                            id="gender" [checked]="gender === profile.gender"
                            #gender="ngModel" [(ngModel)]="profile.gender" name="gender"
                            [value]="gender" [ngClass]="{ 'is-invalid': f.submitted && f.controls.gender.invalid}">
                        <label class="form-check-label xsmall" for="gender">
                            {{ gender }}
                        </label>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                placeholder="address"
                class="form-control"
                name="address-street"
                [(ngModel)]="profile.address.line1"
                >
                <label>Address</label>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                placeholder="city"
                class="form-control"
                name="address-city"
                [(ngModel)]="profile.address.city"
                >
                <label>City</label>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                placeholder="state"
                class="form-control"
                name="address-state"
                [(ngModel)]="profile.address.county"
                >
                <label>State / County</label>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                placeholder="postcode"
                class="form-control"
                name="address-post"
                [(ngModel)]="profile.address.postal"
                >
                <label>Postcode</label>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                placeholder="phone"
                class="form-control"
                name="phone"
                [(ngModel)]="phone.value"
                >
                <label>Phone</label>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                placeholder="skype"
                class="form-control"
                name="skype"
                [(ngModel)]="skype.value"
                >
                <label>Skype</label>
              </div>
            </div>
            <div class="col-12 col-xl-6 mb-4">
              <div class="form-floating">
                <input
                type="date"
                id="dob"
                name="dob"
                #dob="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && dob.invalid}"
                [(ngModel)]="profile.birthDate"
                class="form-control"
                >
                <label for="dob">Date of birth</label>
                <div *ngIf="f.submitted && dob.invalid" class="invalid-feedback">
                  <div *ngIf="dob.errors.required">Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-floating" *ngIf="role == 'student'">
                <textarea
                class="form-control"
                id="studyReason"
                name="studyReason"
                rows="3"
                placeholder="reason"
                #studyReason="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && studyReason.invalid}"
                [(ngModel)]="profile.studyReason"
                ></textarea>
                <label for="studyReason" >Reason for studying</label>
                <div *ngIf="f.submitted && studyReason.invalid" class="invalid-feedback">
                  <div *ngIf="studyReason.errors.required" >Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-floating">
                <textarea
                placeholder="biography"
                id="biography" rows="3"
                class="form-control"
                name="biography"
                #biography="ngModel"
                [(ngModel)]="profile.biography"
                [ngClass]="{ 'is-invalid': f.submitted && biography.invalid }"
                ></textarea>
                <label for="biography" >About you</label>
                <div *ngIf="f.submitted && biography.invalid" class="invalid-feedback">
                  <div *ngIf="biography.errors.required">Field is required</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ng-content select="[form-bottom]"></ng-content>
      <div class="text-end">
        <ng-content select="[action-buttons]"></ng-content>
      </div>
    </div>
  </div>
  <ng-content select="[additional-card]"></ng-content>
  <div class="row">
    <div class="col-auto ms-auto">
      <button type="submit" class="btn btn-primary rounded-pill px-4 py-3" [disabled]="busy">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="busy"></span>
        Submit
      </button>
    </div>
  </div>
</form>
