import { GenericDataProvider } from 'src/app/model/internal';
import { RoomProgressReport} from 'src/app/model/server';
import { SearchingContextService } from 'src/app/services/searching-context.service';
import { VideoServerRestService } from 'src/app/services/rest/video-server-rest.service';
import { Observable } from 'rxjs';
import {Page, Pageable} from "../../model/rest/base";

export class ProgressDataProvider extends GenericDataProvider<RoomProgressReport, Pageable> {
    constructor(private schoolId: number,
                private templateId: number,
                searchingContextPath: string,
                searchingContextService: SearchingContextService,
                private rest: VideoServerRestService,
                pageable: Pageable) {
        super(searchingContextPath, pageable, searchingContextService);
    }
    doLoad(): Observable<Page<RoomProgressReport>> {
      return this.rest.getTemplateHistoryProgress(this.schoolId, this.templateId, this.pageable.consumeInitial());
    }
}

export class RoomProgressProvider extends GenericDataProvider<RoomProgressReport, Pageable> {

    constructor(private roomUuid: string,
                searchingPath: string,
                searchingContext: SearchingContextService,
                private rest: VideoServerRestService,
                pageable: Pageable) {
        super(searchingPath, pageable, searchingContext);
    }

    doLoad(): Observable<Page<RoomProgressReport>> {
        return this.rest.getRoomProgressHistory(this.roomUuid, this.pageable.consumeInitial());
    }
}

export class ParticipantRoomProgressProvider extends GenericDataProvider<RoomProgressReport, Pageable> {

    constructor(private participant: string,
                searchingPath: string,
                searchingContext: SearchingContextService,
                private rest: VideoServerRestService,
                pageable: Pageable) {
        super(searchingPath, pageable, searchingContext);
    }

    doLoad(): Observable<Page<RoomProgressReport>> {
        return this.rest.getRoomProgressHistoryByTeacher(this.participant, this.pageable.consumeInitial());
    }
}
