<div class="card mb-5" [hidden]="!personalProfileReady">
  <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Your profile</h5>
      <a (click)="editPersonalProfile()" *ngIf="!personalProfileUnderEdit" class="btn btn-outline-secondary rounded-circle px-2 ms-auto">
        <app-icon name="edit" class="icon-secondary"></app-icon>
      </a>
  </div>
  <div class="card-body">
    <app-student-personal-profile-form #studentPersonalProfileFormComponent [schoolId]="schoolId" [studentId]="studentId" (stateEvent)="personalProfileEvent($event)"></app-student-personal-profile-form>
  </div>
</div>
<div class="card" [hidden]="!technicalProfileReady">
  <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Settings</h5>
      <a (click)="editTechnicalProfile()" *ngIf="!technicalProfileUnderEdit" class="btn btn-outline-secondary rounded-circle px-2 ms-auto">
        <app-icon name="edit" class="icon-secondary"></app-icon>
      </a>
  </div>
  <div class="card-body">
    <app-student-technical-profile-form #studentTechnicalProfileFormComponent [schoolId]="schoolId" [studentId]="studentId" (stateEvent)="technicalProfileEvent($event)"></app-student-technical-profile-form>
  </div>
</div>
