<div class="table-responsive-sm">
<table class="table table-sm">
    <thead>
        <tr class="table-row table-row-head">
            <th>Group</th>
            <th>Lessons</th>
            <th>Group time [mins]</th>
            <th>Student time [mins]</th>
        </tr>
    </thead>
    <tbody class="table-body">
        <tr *ngFor="let row of data" class="table-row">
            <td>{{getName(row)}}</td>
            <td>{{getRooms(row)}}</td>
            <td>{{getTime(row)}}</td>
            <td>{{getBillingTime(row)}}</td>
        </tr>
        <tr *ngIf="isEmpty(data)" class="table-row">
            <td colspan="4" class="text-center">No results</td>
        </tr>
        <tr *ngIf="notEmpty(data)" class="table-row">
            <th>Total:</th>
            <th>{{getTotalRooms()}}</th>
            <th>{{getTotalTime()}}</th>
            <th>{{getTotalBilling()}}</th>
        </tr>
    </tbody>
</table>
</div>
