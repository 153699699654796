import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {  Page, RoomDef } from 'src/app/model/server';
import {PageableDataProvider} from 'src/app/model/internal';
import {Router} from "@angular/router";


@Component({
  selector: 'app-rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.css']
})
export class RoomsListComponent implements OnInit {

  rooms: RoomDef[];
  lastPage: Page<RoomDef>;

  constructor() { }

  @Input()
  data: Page<RoomDef>;

  @Output()
  details = new EventEmitter<RoomDef>();

  resetData() {
    this.rooms = [];
    this.data = null;
    this.lastPage = null;
  }

  getName(room: RoomDef) {
    return room.name;
  }

  getCreated(room: RoomDef) {
    return room.createDate;
  }

  getStatus(room: RoomDef) {
    if (!room || !room.provisioning) {
      return null;
    }
    if (room.provisioning.status === 'PENDING') { return 'Pending'; }
    if (room.provisioning.status === 'IN_PROGRESS') { return 'In progress'; }
    if (room.provisioning.status === 'COMPLETE') { return 'Completed'; }
    return null;
  }

  getStarted(room: RoomDef) {
    if (!room || !room.provisioning || !room.provisioning.created) {return null; }
    return room.provisioning.created;
  }

  getFinished(room: RoomDef) {
    if (!room || !room.provisioning || !room.provisioning.endTime) {return null; }
    return room.provisioning.endTime;
  }

  getDuration(room: RoomDef) {
    if (!room || !room.provisioning || !room.provisioning.duration) {return null; }
    if (room.provisioning.duration < 60) {
      return room.provisioning.duration + ' sec.';
    }
    return Math.round(room.provisioning.duration / 60) + ' mins';
  }

  getTeacher(room: RoomDef) {
    return room.participants.find( p => p.role === 'Teacher').name;
  }

  getNbOfStudents(room: RoomDef) {
    return room.participants.filter( p => p.role === 'Student').length;
  }

  ngOnInit() {
  }

  openRoom(room: RoomDef) {
    this.details.emit(room);
  }
}
