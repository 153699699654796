import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {finalize, map, switchMap, tap} from "rxjs/operators";
import {EMPTY, Observable, of} from "rxjs";
import {Location} from "@angular/common";
import {
  Address,
  ContactEntry,
  ContactType,
  PersonDetails,
  PersonInvitation,
  School, Student
} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {
  AccountProductsComponent,
  ProductsListDataAdapter
} from "../../components/account-products/account-products.component";
import {ApiOrderItem, ApiProduct} from "../../model/rest/old-casa-api.model";

export class InvitationComponentData {
  constructor(public action: InvitationComponentAction, public role: InvitationRoleType) {
  }
}

export enum InvitationComponentAction {
  ActionInvite, ActionUpdate
}

export class InvitationRoleType {
  constructor(public hasParentView, public resourceName: string) {
  }

  static Student = new InvitationRoleType(true, "student");
  static Teacher = new InvitationRoleType(true, "teacher");
  static Manager = new InvitationRoleType(false, "manager");
}

@Component({
  selector: 'app-manager-person-invitation-form-page',
  templateUrl: './manager-person-invitation-form-page.component.html',
  styleUrls: ['./manager-person-invitation-form-page.component.css']
})
export class ManagerPersonInvitationFormPageComponent implements OnInit {
  schoolId: number;
  private school: School;
  invitation: PersonInvitation;
  private readOnly = false;
  private role: InvitationRoleType;
  private invitationRole: InvitationComponentAction;
  private finished = false;
  private errors = false;
  @ViewChild("studentProducts")
  productsComponent: AccountProductsComponent;
  adapter: ProductsListDataAdapter;

  //this field is used only for the student form
  createdStudentId: number;

  constructor(
    route: ActivatedRoute,
    private casa: CasaRestApiV2,
    private _location: Location
  ) {
    route.parent.paramMap
      .pipe(
        map( params => Number(params.get('schoolId'))),
        tap<number>( schoolId => this.schoolId = schoolId),
        tap( _ => this.clearForm())
      ).subscribe( _ => this.reload());
    const data = route.snapshot.data as InvitationComponentData;
    this.role = data.role;
    this.invitationRole = data.action;
    const self = this;
    this.adapter = new class implements ProductsListDataAdapter {
      listProducts(): Observable<ApiOrderItem[]> {
       if (!self.createdStudentId || !self.schoolId) return of([]);
       return casa.studentProducts(self.schoolId, self.createdStudentId);
      }

      syncProducts(products: ApiProduct[]): Observable<any> {
        return casa.syncStudentProducts(self.schoolId, self.createdStudentId, products);
      }
    }
  }

  ngOnInit() {}

  clearForm() {
    this.invitation = new PersonInvitation();
    this.finished = false;
    this.errors = false;
    this.invitation.details = new PersonDetails();
    const contact = new ContactEntry();
    contact.entryType = ContactType.PHONE;
    this.invitation.details.address = new Address();
    this.invitation.details.contacts = [contact];
  }

  isSchoolReady() {
    return this.school;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  private reload() {
    if (!this.schoolId) return;
    this.loadSchool().subscribe();
  }

  private loadSchool() {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.storeSchool(school))
    );
  }

  private storeSchool(school: School) {
    this.school = school;
  }

  getType() {
    return this.role.resourceName;
  }

  isReadOnly() {
    return this.readOnly;
  }

  onSave() {
    this.readOnly = true;
    this.finished = false;
    this.errors = false;

    let actionOrder = this.dispatchUpdateAction();

    if (this.invitationRole === InvitationComponentAction.ActionInvite && this.role === InvitationRoleType.Student) {
      actionOrder = (actionOrder as Observable<Student>).pipe(
        tap( student => this.createdStudentId = student.id),
        switchMap(_ => this.productsComponent.postOrder()),
        finalize( () => {
          this.createdStudentId = null;
          this.readOnly = false;
        })
      )
    } else {
      actionOrder = actionOrder.pipe(
        finalize(() => this.readOnly = false)
      );
    }

    actionOrder.subscribe(
      {
        next: value => {
          this.finished = true;
          this.invitation = null;
        },
        error: err => {
          this.errors = true;
        }
      }
    )
  }

  hasParent() {
    return this.role.hasParentView;
  }

  isInvitation() {
    return this.invitationRole === InvitationComponentAction.ActionInvite;
  }

  clear() {
    this.clearForm();
    this.finished = false;
    this.readOnly = false;
    this.errors = false;
  }

  isFinished() {
    return this.finished;
  }

  hasErrors() {
    return this.errors;
  }

  private dispatchUpdateAction() {
    if (this.invitationRole === InvitationComponentAction.ActionInvite) {
      switch (this.role) {
        case InvitationRoleType.Student:
          return this.casa.inviteStudentInstantly(this.schoolId, this.invitation);
        case InvitationRoleType.Manager:
          return this.casa.inviteManager(this.schoolId, this.invitation);
        case InvitationRoleType.Teacher:
          return this.casa.inviteTeacher(this.schoolId, this.invitation);
      }
    } else return EMPTY;
  }

  back() {
    this._location.back();
  }

  doSave() {
    if (!this.invitation.email || this.invitation.email.length <= 3) return;
    this.onSave();
  }

  getFormRole() {
    switch (this.role) {
      case InvitationRoleType.Student:
        return 'student';
      case InvitationRoleType.Manager:
        return 'manager';
      case InvitationRoleType.Teacher:
        return 'teacher';
      default:
        return null;
    }
  }

  productListType(): "teacher" | "student" {
    const formRole = this.getFormRole();
    if (!formRole || formRole === 'manager') return null;
    return formRole;
  }
}
