import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiLessonStatus,
  ApiLessonType,
  ApiPersonalProfile
} from "../../model/rest/rest-model";

export class Utils {
    public static jsonClone<T>(entry: T): T {
        if (!entry) {
            return entry;
        }
        return JSON.parse(JSON.stringify(entry));
    }

  public static padToCurrency(value: number) {
    if (!value) return 0.0.toFixed(2);
    return value.toFixed(2);
  }

}

export class LessonTypeColors {
  static getTeacherLessonColorBorderClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
    switch (lessonStatus) {
      case ApiLessonStatus.Booked: return 'booked';
      case ApiLessonStatus.Cancel: return 'cancel';
      case ApiLessonStatus.Complete: {
        return 'complete';
      }
      case ApiLessonStatus.Due: return 'due';
      case ApiLessonStatus.InProgress: return 'in-progress';
      case ApiLessonStatus.InvalidatedByStudent: return 'student-invalidate';
      case ApiLessonStatus.InvalidatedByTeacher: return 'teacher-invalidate';
      default: return '';
    }
  }

    public static getLessonHistoryTableColorClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
      if (!lesson) {return ''; }
      const lessonType = ApiLessonType[lesson.lessonType];
      if (lessonType === ApiLessonType.Revision || lessonType === ApiLessonType.QuickStageRevision) {
        return 'lesson-revision';
      }
      if (lesson && lesson.flags && lesson.flags.some( f => f.flagType === 'StudentNotShow')) {
        return 'lesson-student-no-show';
      }
      if (lesson && lesson.flags && lesson.flags.some( f => f.flagType === 'TeacherNotShow')) {
        return 'lesson-tutor-no-show';
      }
      const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
      switch (lessonStatus) {
        case ApiLessonStatus.Complete: return '';
        case ApiLessonStatus.InvalidatedByTeacher: return 'lesson-tutor-no-show';
        case ApiLessonStatus.Cancel: return 'text-muted text-del';
        default: return '';
      }
    }

    public static getStudentLessonBorderColorClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
          const lessonStatus = ApiLessonStatus[lesson.lessonStatus];
          switch (lessonStatus) {
            case ApiLessonStatus.Booked: return 'lesson-status booked';
            case ApiLessonStatus.Complete: {
              return 'lesson-status complete';
            }
            case ApiLessonStatus.Due: return 'lesson-status due';
            case ApiLessonStatus.InProgress: return 'lesson-status in-progress';
            case ApiLessonStatus.InvalidatedByTeacher:
            case ApiLessonStatus.Cancel:
            case ApiLessonStatus.InvalidatedByStudent: return 'lesson-status student-invalidate';
            default: return '';
          }
    }
}
