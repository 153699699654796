<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Dashboard'"></top-bar>
  <div class="container py-4 px-md-5" *ngIf="getSchoolName()">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <!-- <li class="breadcrumb-item active" aria-current="page">{{getSchoolName()}}</li> -->
        <li class="breadcrumb-item active" aria-current="page">Home</li>
      </ol>
    </nav>
    <app-dashboard-school-info-block [school]="school"></app-dashboard-school-info-block>
    <div class="row g-4 g-md-5">
      <div class="col-12 col-lg-6">
        <app-students-summary-block [school]="school"></app-students-summary-block>
      </div>
      <div class="col-12 col-lg-6">
        <app-teachers-summary-block [school]="school"></app-teachers-summary-block>
      </div>
      <div class="col-12 col-lg-6">
        <app-students-payment-summary-block [school]="school"></app-students-payment-summary-block>
      </div>
      <div class="col-12 col-lg-6">
        <app-credits-summary-block [school]="school"></app-credits-summary-block>
      </div>
      <div class="col-12">
        <app-managers-block [school]="school"></app-managers-block>
      </div>
      <div class="col-12">
        <app-invitations-block [school]="school"></app-invitations-block>
      </div>
    </div>
  </div>
</div>

