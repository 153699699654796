import { BrowserModule } from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ManagerMenuComponent} from "./layout/with-menu/manager-menu/manager-menu.component";
import {NoMenuComponent} from "./layout/no-menu/no-menu.component";
import {EmptyNavBarComponent} from "./layout/with-menu/empty-nav-bar/empty-nav-bar.component";
import {ForbiddenComponent} from "./pages/forbidden/forbidden.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {RootComponent} from "./pages/root/root.component";
import {LogoutComponent} from "./pages/logout/logout.component";
import {LoginComponent} from "./pages/login/login.component";
import {OauthComponent} from "./pages/oauth/oauth.component";
import {RegisterComponent} from "./pages/register/register.component";
import {OpenComponent} from "./pages/open/open.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {JwtInterceptorService} from "./services/col2/jwt-interceptor.service";
import {ManagerDashboardComponent} from "./pages/manager-dashboard/manager-dashboard.component";
import {ToastNotificationsComponent} from "./components/toast-notifications/toast-notifications.component";
import {HttpErrorsInterceptorService} from "./services/http-errors-interceptor.service";
import {DashboardSchoolInfoBlockComponent} from "./components/blocks/dashboard-school-info-block/dashboard-school-info-block.component";
import {StudentsSummaryBlockComponent} from "./components/blocks/students-summary-block/students-summary-block.component";
import {StudentsPaymentSummaryBlockComponent} from "./components/blocks/students-payment-summary-block/students-payment-summary-block.component";
import {TeachersSummaryBlockComponent} from "./components/blocks/teachers-summary-block/teachers-summary-block.component";
import {CreditsSummaryBlockComponent} from "./components/blocks/credits-summary-block/credits-summary-block.component";
import {ManagersBlockComponent} from "./components/blocks/managers-block/managers-block.component";
import {InvitationsBlockComponent} from "./components/blocks/invitations-block/invitations-block.component";
import {BuyCreditsCartComponent} from "./components/buy-credits-cart/buy-credits-cart.component";
import {ManagerStudentsListPageComponent} from "./pages/manager-students-list-page/manager-students-list-page.component";
import {ManagerTeachersListPageComponent} from "./pages/manager-teachers-list-page/manager-teachers-list-page.component";
import {LessonsReportPageComponent} from "./pages/lessons-report-page/lessons-report-page.component";
import {LessonsReportComponent} from "./components/lessons-report/lessons-report.component";
import {ReportsFilterComponent} from "./components/reports-filter/reports-filter.component";
import {ManagerPersonInvitationFormPageComponent} from "./pages/manager-person-invitation-form-page/manager-person-invitation-form-page.component";
import {TeachersLessonTypesReportPageComponent} from "./pages/teachers-lesson-types-report-page/teachers-lesson-types-report-page.component";
import {TeachersLessonTypesReportComponent} from "./components/teachers-lesson-types-report/teachers-lesson-types-report.component";
import {ManagerStudentDetailsLayoutComponent} from "./layout/manager-student-details-layout/manager-student-details-layout.component";
import {ManagerTeacherDetailsLayoutComponent} from "./layout/manager-teacher-details-layout/manager-teacher-details-layout.component";
import {ManagerTeacherLessonsPageComponent} from "./pages/manager-teacher-lessons-page/manager-teacher-lessons-page.component";
import {TeacherLessonsComponent} from "./components/teacher-lessons/teacher-lessons.component";
import {ManagerLessonDetailsPageComponent} from "./pages/manager-lesson-details-page/manager-lesson-details-page.component";
import {TeacherAllowancesComponent} from "./components/teacher-allowances/teacher-allowances.component";
import {TeacherPersonalProfileFormComponent} from "./components/teacher-personal-profile-form/teacher-personal-profile-form.component";
import {TeacherProfileFormComponent} from "./components/teacher-profile-form/teacher-profile-form.component";
import {ManagerTeacherProfilePageComponent} from "./pages/manager-teacher-profile-page/manager-teacher-profile-page.component";
import {StudentLessonsComponent} from "./components/student-lessons/student-lessons.component";
import {ManagerStudentLessonsPageComponent} from "./pages/manager-student-lessons-page/manager-student-lessons-page.component";
import {CreateStudentsCreditsBundleComponent} from "./components/create-students-credits-bundle/create-students-credits-bundle.component";
import {StudentPurchaseHistoryComponent} from "./components/student-purchase-history/student-purchase-history.component";
import {StudentTransactionsComponent} from "./components/student-transactions/student-transactions.component";
import {ManagerStudentCreditsPageComponent} from "./pages/manager-student-credits-page/manager-student-credits-page.component";
import {StudentPersonalProfileFormComponent} from "./components/student-personal-profile-form/student-personal-profile-form.component";
import {StudentTechnicalProfileFormComponent} from "./components/student-technical-profile-form/student-technical-profile-form.component";
import {ManagerStudentProfilePageComponent} from "./pages/manager-student-profile-page/manager-student-profile-page.component";
import {StudentsProductsPageComponent} from "./pages/students-products-page/students-products-page.component";
import {ModalComponent} from "./components/modal/modal.component";
import {PasswordVerifyValidatorDirective} from "./directives/password-verify-validator.directive";
import { ManagerStudentCspaMonitorComponent } from './pages/manager-student-cpsa-monitor/manager-student-cspa-monitor.component';
import { ManagerStudentCasaProfilePageComponent } from './pages/manager-student-casa-profile-page/manager-student-casa-profile-page.component';
import { CasaPersonalProfileFormComponent } from './components/casa-personal-profile-form/casa-personal-profile-form.component';
import { ManagerTeacherCasaProfilePageComponent } from './pages/manager-teacher-casa-profile-page/manager-teacher-casa-profile-page.component';
import { ManagerCasaTeacherProfileComponent } from './components/manager-casa-teacher-profile/manager-casa-teacher-profile.component';
import { PersonPasswordChangeComponent } from './components/person-password-change/person-password-change.component';
import {SchoolGroupsPageComponent} from "./pages/school-groups-page/school-groups-page.component";
import {RoomTemplatesListComponent} from "./components/room-templates-list/room-templates-list.component";
import {RoomTemplateEditComponent} from "./components/room-template-edit/room-template-edit.component";
import {ProgressHistoryListComponent} from "./components/progress-history-list/progress-history-list.component";
import {SchedulesListComponent} from "./components/schedules-list/schedules-list.component";
import {CreateScheduleComponent} from "./components/create-schedule/create-schedule.component";
import {RoomsListComponent} from "./components/rooms-list/rooms-list.component";
import {RoomTemplateDetailsPageComponent} from "./pages/room-template-details-page/room-template-details-page.component";
import {ScheduleDetailsPageComponent} from "./pages/schedule-details-page/schedule-details-page.component";
import {RoomDetailsComponent} from "./components/room-details/room-details.component";
import {RoomDetailsPageComponent} from "./pages/room-details-page/room-details-page.component";
import {SchedulesWeekSummaryComponent} from "./components/schedules-week-summary/schedules-week-summary.component";
import {ManagerDashboardPageComponent} from "./pages/video-dashboard-page/manager-dashboard-page.component";
import {FileDropDirective} from "./directives/file-drop.directive";
import {SchoolActivitiesPageComponent} from "./pages/school-activities-page/school-activities-page.component";
import {ActivityReportComponent} from "./components/activity-report/activity-report.component";
import {TeacherAttendanceReportComponent} from "./components/teacher-attendance-report/teacher-attendance-report.component";
import {TeacherAttendanceReportPageComponent} from "./pages/teacher-attendance-report-page/teacher-attendance-report-page.component";
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { PersonAutocompleteComponent } from './components/person-autocomplete/person-autocomplete.component';
import { StudentRootPageComponent } from './pages/student/student-root-page/student-root-page.component';
import { TeacherRootPageComponent } from './pages/teacher/teacher-root-page/teacher-root-page.component';
import { StudentAreaLayoutComponent } from './layout/student-area-layout/student-area-layout.component';
import { TeacherAreaLayoutComponent } from './layout/teacher-area-layout/teacher-area-layout.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { StudentRoomDetailsPageComponent } from './pages/student/student-room-details-page/student-room-details-page.component';
import { StudentScheduleDetailsPageComponent } from './pages/student/student-schedule-details-page/student-schedule-details-page.component';
import { TeacherScheduleDetailsPageComponent } from './pages/teacher/teacher-schedule-details-page/teacher-schedule-details-page.component';
import { TeacherRoomDetailsPageComponent } from './pages/teacher/teacher-room-details-page/teacher-room-details-page.component';
import {WeekCalendarComponent} from "./components/calendar/week-calendar/week-calendar.component";
import {TeacherMainCalendarComponent} from "./components/teacher/teacher-main-calendar/teacher-main-calendar.component";
import {StudentContextBasedProgressComponent} from "./components/student/student-context-based-progress/student-context-based-progress.component";
import {StudentCreditsComponent} from "./components/student/student-credits/student-credits.component";
import { PersonEmailChangeComponent } from './components/person-email-change/person-email-change.component';
import { StudentBundleExtendComponent } from './components/student-bundle-extend/student-bundle-extend.component';
import { DocumentsBrowserComponent } from './pages/documents-browser/documents-browser.component';
import {TeachersProductsPageComponent} from "./pages/teachers-products-page/teachers-products-page.component";
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PaymentCancelComponent } from './pages/manager/payment-cancel/payment-cancel.component';
import { PaymentConfirmComponent } from './pages/manager/payment-confirm/payment-confirm.component';
import { AccountProductsComponent } from './components/account-products/account-products.component';
import { ManagerMainNavComponent } from './components/manager-main-nav/manager-main-nav.component';
import { IconComponent } from './services/utils/icon/icon.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { AdminMenuComponent } from './layout/with-menu/admin-menu/admin-menu.component';
import { AdminMainNavComponent } from './components/admin-main-nav/admin-main-nav.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { SchoolListPageComponent } from './pages/school-list-page/school-list-page.component';
import { AdminSchoolDetailsPageComponent } from './pages/admin/admin-school-details-page/admin-school-details-page.component';
import { AdminSchoolDashboardComponent } from './components/admin/admin-school-dashboard/admin-school-dashboard.component';
import { AdminSchoolNotesComponent } from './components/admin/admin-school-notes/admin-school-notes.component';
import { AdminSchoolEditComponent } from './components/admin-school-edit/admin-school-edit.component';
import { AdminSchoolPaymentListComponent } from './components/admin-school-payment-list/admin-school-payment-list.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NewsItemComponent } from './components/news-item/news-item.component';
import { NewsDetailsPageComponent } from './pages/news-details-page/news-details-page.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { TeacherLessonsListComponent } from './components/teacher/teacher-lessons-list/teacher-lessons-list.component';
import { SchedulesWeekSummaryCalendarComponent } from './components/schedules-week-summary/schedules-week-summary-calendar/schedules-week-summary-calendar.component';
import { TeacherSchedulesWeekSummaryCalendarComponent } from './components/teacher/teacher-schedules-week-summary-calendar/teacher-schedules-week-summary-calendar.component';
import { GroupListPageComponent } from './pages/groups/group-list-page/group-list-page.component';
import { GroupEditPageComponent } from './pages/groups/group-edit-page/group-edit-page.component';
import { GroupDetailsPageComponent } from './pages/groups/group-details-page/group-details-page.component';
import { GroupParticipantListComponent } from './components/groups/group-participant-list/group-participant-list.component';
import { GroupTeacherCreateComponent } from './components/groups/group-participant-create/group-teacher-create.component';
import { GroupStudentCreateComponent } from "./components/groups/group-participant-create/group-student-create.component";
import { TeacherSubscriptionModalComponent } from './components/teacher/teacher-subscription-modal/teacher-subscription-modal.component';
import { ManagerStudentExamResultsComponent } from './components/manager-student-exam-results/manager-student-exam-results.component';
import { AudioResultComponent } from './components/exam-part-results/audio-result/audio-result.component';
import { CorResultComponent } from './components/exam-part-results/cor-result/cor-result.component';
import { DictResultComponent } from './components/exam-part-results/dict-result/dict-result.component';
import { FillMultiResultComponent } from './components/exam-part-results/fill-multi-result/fill-multi-result.component';
import { PicturesResultComponent } from './components/exam-part-results/pictures-result/pictures-result.component';
import { JumbleResultComponent } from './components/exam-part-results/jumble-result/jumble-result.component';
import { ExamPartResultComponent } from './components/exam-part-result/exam-part-result.component';
import { LoadExamPartResultDirective } from './directives/load-exam-part-result.directive';

@NgModule({
  declarations: [
    AppComponent,
    ManagerMenuComponent,
    NoMenuComponent,
    EmptyNavBarComponent,
    ForbiddenComponent,
    NotFoundComponent,
    RootComponent,
    LogoutComponent,
    LoginComponent,
    OauthComponent,
    RegisterComponent,
    OpenComponent,
    ManagerDashboardComponent,
    ToastNotificationsComponent,
    DashboardSchoolInfoBlockComponent,
    StudentsSummaryBlockComponent,
    StudentsPaymentSummaryBlockComponent,
    TeachersSummaryBlockComponent,
    CreditsSummaryBlockComponent,
    ManagersBlockComponent,
    InvitationsBlockComponent,
    BuyCreditsCartComponent,
    ManagerStudentsListPageComponent,
    ManagerTeachersListPageComponent,
    LessonsReportPageComponent,
    LessonsReportComponent,
    ReportsFilterComponent,
    ManagerPersonInvitationFormPageComponent,
    TeachersLessonTypesReportPageComponent,
    TeachersLessonTypesReportComponent,
    ManagerStudentDetailsLayoutComponent,
    ManagerTeacherDetailsLayoutComponent,
    ManagerTeacherLessonsPageComponent,
    TeacherLessonsComponent,
    ManagerLessonDetailsPageComponent,
    TeacherAllowancesComponent,
    TeacherPersonalProfileFormComponent,
    TeacherProfileFormComponent,
    ManagerTeacherProfilePageComponent,
    StudentLessonsComponent,
    ManagerStudentLessonsPageComponent,
    CreateStudentsCreditsBundleComponent,
    StudentPurchaseHistoryComponent,
    StudentTransactionsComponent,
    ManagerStudentCreditsPageComponent,
    StudentPersonalProfileFormComponent,
    StudentTechnicalProfileFormComponent,
    ManagerStudentProfilePageComponent,
    StudentsProductsPageComponent,
    ModalComponent,
    PasswordVerifyValidatorDirective,
    ManagerStudentCspaMonitorComponent,
    ManagerStudentCasaProfilePageComponent,
    CasaPersonalProfileFormComponent,
    ManagerTeacherCasaProfilePageComponent,
    ManagerCasaTeacherProfileComponent,
    PersonPasswordChangeComponent,
    SchoolGroupsPageComponent,
    RoomTemplatesListComponent,
    RoomTemplateEditComponent,
    ProgressHistoryListComponent,
    SchedulesListComponent,
    CreateScheduleComponent,
    RoomsListComponent,
    RoomTemplateDetailsPageComponent,
    ScheduleDetailsPageComponent,
    RoomDetailsComponent,
    RoomDetailsPageComponent,
    SchedulesWeekSummaryComponent,
    ManagerDashboardPageComponent,
    FileDropDirective,
    SchoolActivitiesPageComponent,
    ActivityReportComponent,
    TeacherAttendanceReportComponent,
    TeacherAttendanceReportPageComponent,
    AutocompleteComponent,
    PersonAutocompleteComponent,
    StudentRootPageComponent,
    TeacherRootPageComponent,
    StudentAreaLayoutComponent,
    TeacherAreaLayoutComponent,
    PaginatorComponent,
    StudentRoomDetailsPageComponent,
    StudentScheduleDetailsPageComponent,
    TeacherScheduleDetailsPageComponent,
    TeacherRoomDetailsPageComponent,
    WeekCalendarComponent,
    TeacherMainCalendarComponent,
    StudentContextBasedProgressComponent,
    StudentCreditsComponent,
    PersonEmailChangeComponent,
    StudentBundleExtendComponent,
    DocumentsBrowserComponent,
    TeachersProductsPageComponent,
    ConfirmDialogComponent,
    PaymentCancelComponent,
    PaymentConfirmComponent,
    AccountProductsComponent,
    ManagerMainNavComponent,
    IconComponent,
    TopBarComponent,
    AdminMenuComponent,
    AdminMainNavComponent,
    AdminDashboardComponent,
    SchoolListPageComponent,
    AdminSchoolDetailsPageComponent,
    AdminSchoolDashboardComponent,
    AdminSchoolNotesComponent,
    AdminSchoolEditComponent,
    AdminSchoolPaymentListComponent,
    NewsPageComponent,
    NewsItemComponent,
    NewsDetailsPageComponent,
    NotificationsComponent,
    TeacherLessonsListComponent,
    SchedulesWeekSummaryCalendarComponent,
    TeacherSchedulesWeekSummaryCalendarComponent,
    GroupListPageComponent,
    GroupEditPageComponent,
    GroupDetailsPageComponent,
    GroupParticipantListComponent,
    GroupTeacherCreateComponent,
    GroupStudentCreateComponent,
    TeacherSubscriptionModalComponent,
    ManagerStudentExamResultsComponent,
    AudioResultComponent,
    CorResultComponent,
    DictResultComponent,
    FillMultiResultComponent,
    PicturesResultComponent,
    JumbleResultComponent,
    ExamPartResultComponent,
    LoadExamPartResultDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptorService, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
