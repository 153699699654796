import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Page, Pageable } from 'src/app/model/rest/base';
import {
  CorpDetails,
  Note,
  NoteContent,
  PersonSearchResult,
  School,
  SchoolBase,
  SchoolDetails,
  SchoolStatus,
  SchoolStatusDetails,
  SchoolStudentsStats,
  StudentsFeePaymentConfirmationDetails,
  StudentsFeeRequest,
} from 'src/app/model/rest/casa-apiv2.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminRestService {
  private apiEndpoint = environment.casaEndpoint;

  constructor(private http: HttpClient) {}

  private url(schoolId: number, path?: string) {
    let url = `${this.apiEndpoint}/api/v2/schools`;
    if (schoolId) url = url.concat(`/${schoolId}`);
    if (path) url = url.concat(`${path}`);
    return url;
  }

  /* SCHOOL */
  getSchool(schoolId: number): Observable<School> {
    return schoolId ? this.http.get<School>(this.url(schoolId)) : of(null);
  }

  listSchools(
    pageable: Pageable,
    name?: String,
    id?: String,
    oldSchoolId?: String,
    country?: String,
    status?: SchoolStatus,
    expDateAfter?: Date,
    expDateBefore?: Date
  ): Observable<Page<School>> {
    let params = new HttpParams();
    params = Pageable.appedPageableParams(params, pageable);
    if (name) {
      params = params.append('name', name.toString());
    }
    if (id) {
      params = params.append('id', id.toString());
    }
    if (oldSchoolId) {
      params = params.append('oldSchoolId', oldSchoolId.toString());
    }
    if (country) {
      params = params.append('country', country.toString());
    }
    if (status) {
      params = params.append('status', status.toString());
    }
    if (expDateBefore) {
      params = params.append(
        'expDateBefore',
        new Date(expDateBefore).toISOString()
      );
    }
    if (expDateAfter) {
      params = params.append(
        'expDateAfter',
        new Date(
          new Date(expDateAfter).getTime() + 24 * 60 * 60 * 1000 - 1
        ).toISOString()
      );
    }

    return this.http.get<Page<School>>(this.url(null), { params });
  }

  updateSchoolDetails(schoolId: number, details: SchoolDetails) {
    return this.http.put(this.url(schoolId, '/details'), details);
  }
  updateSchoolCorpDetails(schoolId: number, details: CorpDetails) {
    return this.http.put(this.url(schoolId, '/corp-details'), details);
  }
  updateSchoolOldId(schoolId: number, newOldSchoolId: string) {
    return this.http.put(this.url(schoolId, '/old-school-id'), newOldSchoolId);
  }
  updateSchoolLabels(schoolId: number, labels: string[]) {
    return this.http.put(this.url(schoolId, '/labels'), labels);
  }
  updateStatusDetails(schoolId: number, statusDetails: SchoolStatusDetails) {
    return this.http.put(this.url(schoolId, '/status-details'), statusDetails);
  }

  /* NOTES */

  listNotes(
    schoolId: number,
    pageable: Pageable,
    noteType?: string,
    after?: Date,
    before?: Date
  ): Observable<Page<Note>> {
    let params = new HttpParams();
    params = Pageable.appedPageableParams(params, pageable);
    if (noteType) {
      params = params.append('noteType', noteType);
    }
    if (after) {
      params = params.append('after', after.toString());
    }
    if (before) {
      params = params.append('before', before.toString());
    }
    return this.http.get<Page<Note>>(this.url(schoolId, '/notes'), { params });
  }

  createNote(schoolId: number, content: NoteContent) {
    return this.http.post(this.url(schoolId, '/notes'), content);
  }

  updateNote(schoolId: number, noteId: number, content: NoteContent) {
    return this.http.put(this.url(schoolId, `/notes/${noteId}`), content);
  }

  deleteNote(schoolId: number, noteId: number) {
    return this.http.delete(this.url(schoolId, `/notes/${noteId}`));
  }

  /* STUDENT FEES */
  listSchoolFees(
    schoolId: number,
    pageable: Pageable
  ): Observable<Page<StudentsFeeRequest>> {
    const params = Pageable.appedPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<StudentsFeeRequest>>(
      this.url(schoolId, `/payments/fees`),
      { params }
    );
  }
  savePaymentConfirmation(
    schoolId: number,
    feeRequestId: number,
    details: StudentsFeePaymentConfirmationDetails
  ) {
    return this.http.put(
      this.url(schoolId, `/payments/fees/${feeRequestId}/confirmation`),
      details
    );
  }
  schoolStudentsStats(schoolId: number): Observable<SchoolStudentsStats> {
    return this.http.get<SchoolStudentsStats>(
      this.url(schoolId, `reports/student-stats`)
    );
  }

  // ONE SEARCH
  searchPeople(
    query: string,
    pageable?: Pageable
  ): Observable<Page<PersonSearchResult>> {
    let params = Pageable.appedPageableParams(new HttpParams(), pageable);
    if (!query) return of(null);
    params = params.append('query', query);
    return this.http.get<Page<PersonSearchResult>>(
      `${this.apiEndpoint}/api/v2/one-search/people`,
      { params }
    );
  }
  searchSchool(
    query: string,
    pageable?: Pageable
  ): Observable<Page<SchoolBase>> {
    let params = Pageable.appedPageableParams(new HttpParams(), pageable);
    if (!query) return of(null);
    params = params.append('query', query);
    return this.http.get<Page<SchoolBase>>(
      `${this.apiEndpoint}/api/v2/one-search/schools`,
      { params }
    );
  }
}
