import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {map, switchMap, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {ApiOrderItem, ApiProduct, SupportedSubs} from "../../model/rest/old-casa-api.model";
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {
  ProductsListDataAdapter,
} from "../../components/account-products/account-products.component";

@Component({
  selector: 'app-teachers-products-page',
  templateUrl: './teachers-products-page.component.html',
  styleUrls: ['./teachers-products-page.component.css']
})
export class TeachersProductsPageComponent implements OnInit {
  schoolId: number;
  private teacherId: number;
  productsAdapter: ProductsListDataAdapter;
  isTeacherSubscribed = false
  subscriptions: ApiProduct[]
  constructor(
    route: ActivatedRoute,
    private managerRest: ManagerRestService,
    private casaRest: CasaRestApiV2
    ) {
    route.parent.paramMap.pipe(
      map( params => [Number(params.get("schoolId")), Number(params.get("teacherId"))]),
      tap( ([schoolId, teacherId]) => this.storeIds(schoolId, teacherId)),
      tap(([schoolId, teacherId]) => {
        this.productsAdapter = {
          listProducts(): Observable<ApiOrderItem[]> {
            return casaRest.teacherProducts(schoolId, teacherId);
          },
          syncProducts(products: ApiProduct[]): Observable<any> {
            return casaRest.syncTeacherProducts(schoolId, teacherId, products);
          }
        }
      }),
      switchMap(_ => this.checkSubscriptionStatus())
    ).subscribe();
  }

  ngOnInit() {
  }

  private storeIds(schoolId: number, teacherId: number) {
    this.schoolId = schoolId;
    this.teacherId = teacherId;
  }

  isSubscribed() {
    return this.isTeacherSubscribed
  }

  checkSubscriptionStatus() {
    return this.casaRest.findTeacherSubscriptions(this.schoolId, this.teacherId).pipe(
      tap(subscriptions => this.subscriptions = subscriptions.map(s => s.product)),
      map(subs => this.isTeacherSubscribed = subs.some(it => it.product.productCode == SupportedSubs.TeacherSub.productCode)),
    )
  }

  changeSubscription() {
    let subscriptions
      if(this.isTeacherSubscribed)
        subscriptions = this.subscriptions.filter(s => s.productCode != SupportedSubs.TeacherSub.productCode)
      else {
        this.subscriptions.push(SupportedSubs.TeacherSub)
        subscriptions = this.subscriptions
      }
    this.casaRest.syncTeacherSubscriptions(this.schoolId, this.teacherId, subscriptions).pipe(
      switchMap(_ => this.checkSubscriptionStatus() )
    ).subscribe()
  }

}
