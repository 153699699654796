import {
  ApiCompetence,
  ApiCourse,
  ApiCourseProduct,
  ApiLearningUnitStudentBase,
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile
} from '../rest/rest-model';

export class ApiBase {
    id: number;
}


export class ApiStudentSchedule extends ApiBase {
    startTime: Date;
    duration: number;
    lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
    course: ApiCourse;
    competence: ApiCompetence;
    teacher: ApiLearningUnitTeacher;
    students: ApiLearningUnitStudentBase[];
}

export class ApiTeacherScore {
    teacher: ApiLearningUnitTeacher;
    score: number;
}

export class ApiTeacherScoringFact extends ApiBase {
    studentRelated: ApiLearningUnitStudentBase;
    score: number;
    tag: string;
    addDate: Date;
    removeDate: Date;
}

export class ApiTeacherWorktime extends ApiBase {
    teacher: ApiLearningUnitTeacher;
    startDate: Date;
    duration: number;
    product: ApiCourseProduct;
    competences: ApiCompetence[];
    minimumOvertake: number;
}

export class ScheduleEvents<S extends ScheduleReference, E extends EventReference> {
    schedule: S;
    events: E[];
}

export class ProductAvailabilityDetails {
    product: ApiCourseProduct;
    competences: ApiCompetence[];
    overtake: number;
}

export class ScheduleReference {
    id: number;
    name: string;
}

export class EventReference {
    eventId: number;
    eventDate: Date;
    duration: number;
}

export class ProductAvailability<
  S extends ScheduleReference,
  E extends EventReference
  > extends ScheduleEvents<S,E> {
  details: ProductAvailabilityDetails;
}

export class SimpleProductAvailability extends ProductAvailability<ScheduleReference, EventReference> {}

export class SimpleScheduleEvents extends ScheduleEvents<ScheduleReference, EventReference> {}

export enum RecurringType {
    Single, Daily, Weekly, Monthly
}

export class RecurringTypeUtils {
    public static values = [RecurringType.Single, RecurringType.Daily, RecurringType.Weekly, RecurringType.Monthly ];
    public static getName(type: RecurringType) {
        switch (type) {
            case RecurringType.Single:
            return 'Single';
            case RecurringType.Monthly:
            return 'Monthly';
            case RecurringType.Daily:
            return 'Daily';
            case RecurringType.Weekly:
            return 'Weekly';
        }
    }
}

export class ApiWorktimeDefinition extends ApiBase {
    static days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    recurring: RecurringType;
    recurringFinish: Date;
    baseWorktime: ApiTeacherWorktime;
    timezoneOffset: number;
    weekDays: string[];
}


