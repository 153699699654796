<app-modal #modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Email Change</h5>
      <button type="button" class="btn-close" (click)="modal.hide()"  aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div  *ngIf="complete && !error">
        <div class="alert alert-success">
          Email change has been registered. The person will receive confirmation request
          on the new email address.
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-primary" (click)="modal.hide()">Close</button>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="complete && error">
        Got error: {{error}}. <br>
        Please try again or contact the administrator.
      </div>
      <form *ngIf="model" #f="ngForm" (ngSubmit)="f.form.valid && submitActivation()" >
        <div class="row gy-3">
          <div class="col-12">
            <div class="form-floating">
              <input
              class="form-control form-control-sm"
              name="currentPassword"
              placeholder="currentPassword"
              type="password"
              autocomplete="off"
              [(ngModel)]="model.managerPassword"
              #currentPassword="ngModel"
              required="required"
              [ngClass]="{'is-invalid': f.submitted && currentPassword.invalid}"
              minlength="3"
              >
              <label>Your password</label>
              <div *ngIf="f.submitted && currentPassword.invalid" class="invalid-feedback">
                <div *ngIf="currentPassword.errors.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="currentPassword.errors.minlength" class="text-danger xsmall"> {{currentPassword.errors.minlength.requiredLength}} characters or more required</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input
              class="form-control form-control-sm"
              name="email"
              placeholder="email"
              type="text"
              [(ngModel)]="model.newEmail"
              autocomplete="off"
              #email="ngModel"
              required="required"
              minlength="5"
              [ngClass]="{'is-invalid': f.submitted && email.invalid}"
              >
              <label>New email address</label>
              <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                <div *ngIf="email.errors.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="email.errors.minlength" class="text-danger xsmall"> {{email.errors.minlength.requiredLength}} characters or more required</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-secondary  rounded-pill px-3 me-2" (click)="modal.hide()">Cancel</button>
            <button class="btn btn-primary rounded-pill px-3" [disabled]="inProgress">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</app-modal>
