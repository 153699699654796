import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Page, IdentifiedRoomTemplate} from 'src/app/model/server';
import { GroupsDataProvider } from './room-templates-list.provider';



export class ListEvent {
  public static EVENT_ENTER = 'enter';
  public static EVENT_START = 'start';
  constructor(public event: string, public template: IdentifiedRoomTemplate) {}
}

@Component({
  selector: 'app-room-templates-list',
  templateUrl: './room-templates-list.component.html',
  styleUrls: ['./room-templates-list.component.css']
})
export class RoomTemplatesListComponent implements OnInit {

  @Output()
  listEvent = new EventEmitter<ListEvent>();
  @Output()
  count = new EventEmitter<number>();

  @Input()
  showOpenClassroom = true;

  @Input()
  showEnter = true;

  @Input()
  data: Page<IdentifiedRoomTemplate>;


  constructor() {
  }

  onEnter(template: IdentifiedRoomTemplate) {
    this.listEvent.emit(new ListEvent(ListEvent.EVENT_ENTER, template));
  }

  getName(template: IdentifiedRoomTemplate) {
    return template.details.name;
  }

  getTeacherName(template: IdentifiedRoomTemplate) {
    const teacher = template.participants.find( p => p.details.role = 'Teacher');
    let email = teacher.details.email;
    if (email == null) email = "empty"
    return `${teacher.details.name} (${email})`;
  }

  getNbOfStudents(template: IdentifiedRoomTemplate) {
    return template.participants.length - 1;
  }

  getCreateDate(template: IdentifiedRoomTemplate) {
    return template.created;
  }

  ngOnInit() {
  }

  isEmpty() {
    return this.data && this.data.empty;
  }
}
