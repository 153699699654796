import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Page, Pageable} from "../../model/rest/base";
import {IdentifiedRoomTemplate, RoomDef} from "../../model/server";
import {Observable} from "rxjs";
import {ScheduleRow} from "../../model/schedule";

@Injectable({
  providedIn: 'root'
})
export class TeacherVideoRestService {
  constructor(private http: HttpClient) {
  }

  prepareUrl(schoolId: number, teacherId: number, path: string): string {
    return `${environment.videoApiBase}/sec/school/${schoolId}/teacher/${teacherId}${path}`;
  }

  public listTemplates(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    const params = Pageable.appedPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<IdentifiedRoomTemplate>>(this.prepareUrl(schoolId, teacherId, "/templates"), {params});
  }

  public listRooms(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    const params = Pageable.appedPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<RoomDef>>(this.prepareUrl(schoolId, teacherId, "/rooms"), {params});
  }

  public listSchedules(schoolId: number, teacherId: number, focusDate: number) {
    const params = new HttpParams().append("focusDate", focusDate.toString());
    return this.http.get<ScheduleRow[]>(this.prepareUrl(schoolId, teacherId, "/schedules"), {params})
  }

  public getRoomDetails(schoolId: number, teacherId: number, roomUuid: string): Observable<RoomDef> {
    return this.http.get<RoomDef>(this.prepareUrl(schoolId, teacherId, `/rooms/${roomUuid}`));
  }

  public getScheduleDetails(schoolId: number, teacherId: number, scheduleId: number) {
    return this.http.get<ScheduleRow>(this.prepareUrl(schoolId, teacherId, `/schedules/${scheduleId}`), );
  }
}
