<div [@loading]="isLoading ? 'loading' : 'loaded'" class="progress bg-primary mb-1" style="height: 4px">
  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
</div>

<div class="row" *ngIf="dataRows?.length > 0">
  <div class="col-12 col-lg-6 col-xxl-4" *ngFor="let row of dataRows">
       <div class="card pt-0 h-100 border-0" (click)="onOpen(row)">
           <div class="row g-0 h-100">
               <div class="col-6 col-sm-5">
                   <div class="card-body p-3 rounded-start h-100 d-flex flex-column justify-content-between" [ngClass]="getScheduleBgClass(row)">
                    <div class="mb-3">
                      <p class="card-title text-white schedule-time">
                        {{getScheduleTime(row) | date: 'HH:mm'}}
                        <span *ngIf="row.schedule.details.durationMin" class="text-nowrap text-white schedule-time">
                          - {{getScheduleEndTime(row) | date: 'HH:mm'}}
                        </span>
                      </p>
                      <p class="card-text text-white">{{getScheduleTime(row) | date: 'E'}} • {{getScheduleTime(row) | date: 'dd MMM'}}</p>
                    </div>
                    <span class="badge bg-transparent border border-white border-2 text-white text-uppercase w-fit-content">
                      {{ getScheduleStatus(row)}}
                    </span>
                   </div>
               </div>
               <div class="col-6 col-sm-7">
                  <div class="card-body p-3 rounded-end h-100 text-end bg-light d-flex flex-column justify-content-between">
                    <div>
                      <div class="d-flex flex-column flex-sm-row">
                        <h5 class="card-title order-sm-1">{{getTemplateName(row)}}</h5>
                        <div class="d-flex ms-auto order-sm-0 me-sm-auto ms-sm-0">
                          <a tabindex=""
                             *ngIf="mayDelete(row)"
                             class="me-2 cursor-pointer btn btn-sm rounded-circle btn-outline-light px-1 lh-sm border border-2 my-auto"
                             (click)="onDelete(row)"
                             title="Delete lesson"
                             data-bs-toggle="tooltip">
                            <app-icon name="delete" class="icon-danger"></app-icon>
                          </a>
                          <a tabindex=""
                            *ngIf="mayStart(row)"
                            class="cursor-pointer btn btn-sm rounded-circle btn-outline-light px-1 lh-sm border border-2 my-auto"
                            (click)="onStart(row)"
                            data-bs-toggle="tooltip"
                            title="Manually open the classroom for this lesson now"
                          >
                            <app-icon name="play-arrow" class="icon-primary icon-md"></app-icon>
                          </a>
                        </div>
                      </div>
                      <p class="card-text">{{ getTeacherName(row) }}</p>
                    </div>
                    <p class="card-text">
                      <small *ngIf="countActiveStudents(row)">
                        <span class="text-muted me-1">{{countActiveStudents(row)}}</span>
                        <app-icon name="group" class="icon-secondary"></app-icon>
                      </small>
                      <small class="ms-3" *ngIf="countOfflineStudents(row)">
                        <span class="text-muted me-1">{{countOfflineStudents(row)}} </span>
                        <app-icon name="school" class="icon-secondary"></app-icon>
                      </small>
                    </p>
                  </div>
               </div>
           </div>
       </div>
   </div>
</div>
<div class="row">
  <p class="text-center text-muted m-0" *ngIf="isEmpty()">No results.</p>
</div>

