import {Component, Input, OnInit} from '@angular/core';
import {ScheduleCalendarDisplay, ScheduleTableDisplay,} from "../../../services/schedule-search.service";
import {ScheduleRowSimplified} from "../../../model/schedule";
import {RoomTemplateBase, RoomTemplateDetailsBase} from "../../../model/server";
import {SchedulesListComponent} from "../../schedules-list/schedules-list.component";

@Component({
  selector: 'app-schedules-week-summary-calendar',
  templateUrl: './schedules-week-summary-calendar.component.html',
  styleUrls: ['./schedules-week-summary-calendar.component.scss']
})
export class SchedulesWeekSummaryCalendarComponent implements OnInit {

  @Input() conflictedSchedules: Set<number> = new Set();
  @Input() toShow: ScheduleTableDisplay | ScheduleCalendarDisplay;
  @Input() schoolId: number;
  @Input() getWeekStartDate: number;

  constructor() { }

  ngOnInit(): void {}

  dataIsEmpty() {
    return !this.toShow?.daysSorted?.length
  }

  getWeekDates() {
    let weekDays = [this.getWeekStartDate]
    let date = this.getWeekStartDate
    for (let i = 0; i < 6; i++) {
      date = date + 24 * 60 * 60 * 1000
      weekDays.push(date)
    }
    return weekDays
  }

  getCalendarDisplayViewDay(day: number): ScheduleRowSimplified[] {
    const rowsByDay = (this.toShow as ScheduleCalendarDisplay).rowsByDay;
    if (!rowsByDay) return [];
    const rows = rowsByDay.get(day);
    if (!rows) return [];
    return rows;
  }

  isScheduleInDateTime(schedule, hour, dayIndex) {
    return (new Date(schedule.schedule.details.startDate)).getHours() == hour
      && new Date(schedule.schedule.details.startDate).toDateString() === new Date(this.getWeekDates()[dayIndex]).toDateString()
  }

  getBadgeColorClassByStatus(row: ScheduleRowSimplified) {
    if (this.conflictedSchedules.has(row.schedule.id)) {
      return 'bg-danger';
    }

    switch(row.schedule.state) {
      case 'COMPLETE': return 'bg-success';
      case 'PLANED': return 'bg-warning';
      case 'PENDING': return 'bg-info';
    }
    return '';
  }

  getTooltipContent(row: ScheduleRowSimplified) {
    if (this.conflictedSchedules.has(row.schedule.id)) {
      return 'Conflict detected';
    }

    switch(row.schedule.state) {
      case 'COMPLETE': return 'Complete';
      case 'PLANED': return 'Planned';
      case 'PENDING': return 'Links sent';
    }
    return '';
  }

  getEndDate(row: ScheduleRowSimplified) {
    if (!row || !row.schedule || !row.schedule.details.durationMin) return 0;
    return row.schedule.details.startDate + row.schedule.details.durationMin * 60 * 1000;
  }

  getPlace(row: ScheduleRowSimplified) {
    if (!row || !row.schedule) return null;
    if (row.schedule.details.place) return row.schedule.details.place;
    return row.template.details.place;
  }

  hasAnyOffline(schedule: ScheduleRowSimplified) {
    return (this.isOffline(schedule) || schedule.schedule.details.participants.find( p => p.offline));
  }

  getGroupName(group: RoomTemplateBase<RoomTemplateDetailsBase>) {
    return group.details.name;
  }

  isOffline(row: ScheduleRowSimplified) {
    return SchedulesListComponent.isOffline(row);
  }

}
