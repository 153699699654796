import {Component, Input, OnInit} from '@angular/core';
import {ReportsRestService} from 'src/app/services/rest/reports-rest.service';
import {
  ApiPerson,
  ApiPersonalProfileBaseWithPhoto,
  ApiTeacherProfile,
  EntityRelatedRow
} from 'src/app/model/rest/rest-model';
import {map} from 'rxjs/operators';
import {ReportGenericFilterData} from '../reports-filter/reports-filter.component';
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";

class ReportEntry {
  teacher: ApiTeacherProfile;
  completed = 0;
  studentNoShow = 0;
  invalidatedByStudent = 0;
  invalidatedByTeacher = 0;
  cancelled = 0;
}

@Component({
  selector: 'app-teachers-lesson-types-report',
  templateUrl: './teachers-lesson-types-report.component.html',
  styleUrls: ['./teachers-lesson-types-report.component.css']
})
export class TeachersLessonTypesReportComponent implements OnInit {
  reportResult: ReportEntry[];


  constructor(private reportService: ReportsRestService) { }

  @Input()
  schoolId: number;
  ngOnInit() {
  }

  getTeacherName(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    return PersonNameExtractor.getPersonName(person);
  }

  updateReport(data: ReportGenericFilterData): any {
    this.reportService.listSchoolTeacherLessonsTypesReport(this.schoolId, data.dateFrom, data.dateTo, data.productCode).pipe(
      map( rows => this.mapReport(rows))
    ).subscribe(
      reportEntrties => this.reportResult = reportEntrties
    );
    console.log(data);
  }
  mapReport(rows: EntityRelatedRow<ApiTeacherProfile>[]): any {
    const resultDictionary: { [teacherId: number]: ReportEntry} = {};
    rows.forEach( r => {
      let entry = resultDictionary[r.entity.teacher.id];
      if (!entry) {
        entry = new ReportEntry();
        resultDictionary[r.entity.teacher.id] = entry;
        entry.teacher = r.entity;
      }
      const type = Number(r.values[0]);
      const amount = Number(r.values[1]);
      if (type === 4) {
        entry.completed = amount;
      } else if (type === 5) {
        entry.cancelled = amount;
      } else if (type === 6) {
        entry.invalidatedByTeacher = amount;
      } else if (type === 7) {
        entry.invalidatedByStudent = amount;
      } else if (type === 8) {
        entry.studentNoShow = amount;
      }
    });

    const result: ReportEntry[] = [];
    for (const key in resultDictionary) {
      if (resultDictionary.hasOwnProperty(key)) {
        const element = resultDictionary[key];
        result.push(element);
      }
    }
    console.log(result);
    return result;
  }

}
