import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterEvent, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'admin-main-nav',
  templateUrl: './admin-main-nav.component.html',
  styleUrls: ['./admin-main-nav.component.scss'],
})
export class AdminMainNavComponent implements OnInit {
  private activeSegments: string[];
  private activePath: string;
  @Input() isOffcanvas: boolean = false;

  disableVideo = true;
  theme: string;

  constructor(
    private authService: AuthServiceProvider,
    public themeService: ThemeService,
    private router: Router
  ) {
    themeService.getTheme().subscribe((theme) => (this.theme = theme));
    router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        this.activePath = e['urlAfterRedirects'];
        this.activePath
          ? (this.activeSegments = this.activePath.split('/'))
          : '';
      });
  }

  ngOnInit(): void {}

  logout() {
    this.authService.get().subscribe((api) => api.logout());
  }

  getDismiss() {
    return this.isOffcanvas && 'offcanvas';
  }

  isActive(segments: string) {
    return this.activeSegments[1] === 'admin' &&
      segments
        .split(';')
        .some((segment) => this.activeSegments[2]?.includes(segment))
      ? true
      : false;
  }
}
