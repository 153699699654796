import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {Chapter, ExamSession, ExerciseSession, ExerciseSet, ItemAvailability} from "../../model/rest/cspa.model";
import {Page, Pageable} from "../../model/rest/base";


@Injectable({
  providedIn: 'root'
})
export class CspaRestService {
  constructor(
    private http: HttpClient
  ) {}

  private url(method: string) {
    return `${environment.cspaEndpoint}/api${method}`;
  }

  public listExerciseSets(schoolId: number): Observable<ExerciseSet[]> {
    return this.http.get<ExerciseSet[]>(this.url(`/school/${schoolId}/sets`));
  }

  public listChapters(schoolId: number, path: string): Observable<Chapter[]> {
    return this.http.get<Chapter[]>(this.url(`/school/${schoolId}/sets/${path}`))
  }

  public listPersonAvailabilities(schoolId: number, personId: number, path: string, depth: number): Observable<ItemAvailability[]> {
    const params = new HttpParams().append("path", path).append("depth", depth.toString());
    return this.http.get<ItemAvailability[]>(this.url(`/school/${schoolId}/person/${personId}/availability`), {params});
  }

  public findSessions(schoolId: number, personId: number, pathPrefix: string, pageable?: Pageable): Observable<Page<ExerciseSession>> {
    const params = Pageable.appedPageableParams(new HttpParams().append("pathPrefix", pathPrefix), pageable)
    return this.http.get<Page<ExerciseSession>>(this.url(`/school/${schoolId}/person/${personId}/sessions`), {params});
  }

  public findExamSessions(schoolId: number, personId: number, pathPrefix: string): Observable<ExamSession[]> {
    const params = new HttpParams()
      if(pathPrefix)
        params.append("pathPrefix", pathPrefix)
    return this.http.get<ExamSession[]>(this.url(`/school/${schoolId}/person/${personId}/exam-sessions`), {params});
  }

}
