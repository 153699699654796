<div class="container-fluid pt-2">
  <div class="mb-4">
    <div class="float-end">
      <div *ngIf="hasScheduleQueryArgs()" class="btn-group me-2">
        <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleWeekOffset(-1)">&lt;</button>
        <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleToNow()"><strong>{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</strong></button>
        <button [disabled]="isScheduleLoading" class="btn btn-sm btn-outline-primary" (click)="moveScheduleWeekOffset(1)">&gt;</button>
      </div>
    </div>
    <h5 class="text-uppercase">Weekly Planned Lessons: </h5>
    <app-schedules-list
      (dataSet)="onSchedulesDataSetUpdate($event)"
      (loading)="onSchedulesLoading($event)"
      [showTemplateName]="true"
      #schedulesListComponent
      [dataProvider]="getSchedulesDataProvider()"
      [showDelete]="false"
      [showPlay]="false"
      (listEvent)="onScheduleEvent($event)"
    ></app-schedules-list>
  </div>
  <div class="mb-4" *ngIf="hasGroups()">
    <div class="row">
      <div class="col">
        <h5 class="text-uppercase">Groups, you belong to:</h5>
      </div>
      <div class="col-auto">
        <app-paginator class="mt-n1 d-block" [data]="groupsData" [pageable]="groupsState" (newPage)="switchGroupPage($event)"></app-paginator>
      </div>
    </div>
    <app-room-templates-list [showEnter]="false" [showOpenClassroom]="false"  [data]="groupsData"  ></app-room-templates-list>
  </div>
  <div class="mb-4" *ngIf="hasRooms()">
    <div class="row">
      <div class="col">
        <h5 class="text-uppercase">Rooms, you belong to:</h5>
      </div>
      <div class="col-auto">
        <app-paginator class="mt-n1 d-block" [data]="roomsData" [pageable]="roomsState" (newPage)="switchRoomsPage($event)"></app-paginator>
      </div>
    </div>
    <app-rooms-list [data]="roomsData" (details)="openRoomDetails($event)" ></app-rooms-list>
  </div>
</div>
