import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef, EventEmitter, Input, OnDestroy,
  OnInit, Output,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T> implements OnInit, AfterViewInit, OnDestroy {

  private show = false;
  private input: any;
  private autocompleteContainer: any;

  @Output()
  term = new EventEmitter<string>();
  private mData: T[];
  private documentClickListener = _ => {
    this.hide();
  };

  @Input()
  set data(data: T[]) {
    this.mData = data;
    if (data && data.length > 0) {
      if (document.activeElement == this.input) {
        this.show = true;
      }
    }
    this.clearFocus();
  }

  @Output()
  activeItem = new EventEmitter<T>();
  @Output()
  itemSelected = new EventEmitter<T>();

  private activeIndex = -1;

  constructor(private vc: ElementRef) {

  }

  findKeyElements(){
    this.input = this.vc.nativeElement.getElementsByTagName("input")[0];
    this.autocompleteContainer = this.vc.nativeElement.getElementsByClassName("auto-complete")[0];
    this.input.autocomplete = false;
  }

  ngAfterViewInit(): void {
    this.findKeyElements();
    this.listenForInputEvents();
  }

  ngOnInit(): void {

  }

  hide() {
    this.show = false;
    this.clearFocus();
  }

  isShow() {
    return this.show;
  }

  ngOnDestroy(): void {
    document.removeEventListener("click", this.documentClickListener);
  }

  private listenForInputEvents() {
    this.input.addEventListener("input", (event) => {
      this.term.emit(this.input.value);
      this.show = false;
    });
    this.input.addEventListener("keydown", (event) => {
        if (event.key === 'ArrowUp') {
          if (this.activeIndex >= 0) event.preventDefault();
          this.moveIndex(-1);
        } else if (event.key === 'ArrowDown') {
          if (this.activeIndex >= 0) event.preventDefault();
          this.moveIndex(1);
        } else if (event.key === 'Enter') {
          if (this.activeIndex >=0) {
            event.preventDefault();
            this.itemSelected.emit(this.mData[this.activeIndex]);
            this.hide();
          }
        } else if (event.key === 'Escape') {
          console.log("exit");
          if (this.show) {
            this.hide();
            event.preventDefault();
          }
        }
      }
    );

    document.addEventListener("click", this.documentClickListener);
     this.input.addEventListener("blur", event => {
       setTimeout(() => this.hide(), 200);
     });
    this.input.setAttribute("autocomplete", "off");
  }

  clearFocus() {
    this.activeIndex = -1;
    this.emmitActive();
  }

  private moveIndex(value: number) {
    this.activeIndex += value;
    if (this.activeIndex < -1) this.activeIndex = -1;
    if (this.activeIndex >= this.mData.length) this.activeIndex = this.mData.length - 1;
    this.emmitActive();
  }

  private emmitActive() {
    if (this.activeIndex < 0) {
      this.activeItem.emit(null);
    }
    else {
      this.activeItem.emit(this.mData[this.activeIndex]);
    }
  }
}
