import { Injectable } from '@angular/core';
import {AuthServiceImpl, AuthServiceProvider} from './auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import {map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
/*
Authorization guard
*/
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthServiceProvider, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.authService.get().pipe(
          map( api => {
              if (api.isTokenValid()) {
                return true;
              }
              api.logout();
              return false;
            }
          )
        )

        // return new Observable<boolean>(observer => {
        //   this.authService.init().subscribe(() => {
        //     if (this.authService.isTokenValid() && this.authService.serverProfile) {
        //       observer.next(true);
        //       observer.complete();
        //       return;
        //     }
        //     observer.next(false);
        //     observer.complete();
        //     this.authService.logout();
        //   });
        // });
    }
}
