import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiFile } from 'src/app/model/rest/rest-model';

@Injectable({
  providedIn: 'root'
})
export class FilesRestService {

  constructor(private http: HttpClient) { }

  public saveFile(fileToUpload: File) {
    const url = environment.apiEndpoint + '/files';
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const req = new HttpRequest('PUT', url, formData, {reportProgress: true});
    return this.http.request(req);
  }
}
