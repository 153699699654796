<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Schools'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <a href="/" routerLink="/admin">Home</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a href="/" routerLink="/admin/school">Schools</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ school?.details.name }}
        </li>
      </ol>
    </nav>
    <div class="card border-0 py-2" *ngIf="school">
      <div class="card-body row">
        <div class="col mt-3 col-sm-auto mt-sm-0">
          <div class="my-auto">
            <p class="text-muted small mb-0">School:</p>
            <span class="me-2">{{ school?.details.name }}</span>
            <span
              class="text-uppercase badge xsmall rounded-2 bg-warning w-fit-content"
              >{{ school.status }}</span
            >
          </div>
        </div>
        <div class="col-auto ms-auto">
          <div class="dropleft my-auto ms-auto">
            <a class="dropdown-toggle-split btn btn-outline-secondary-2 rounded-circle py-2" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
              <app-icon name="more-vertical" class="icon-primary"></app-icon>
            </a>
            <div class="dropdown-menu py-2" aria-labelledby="dropdownMenuReference">
              <a class="dropdown-item" href="https://casa.callanonline.com">
                <app-icon name="refresh" class="icon-white"></app-icon>
                Start renewal
              </a>
              <a class="dropdown-item" (click)="logInAsManager()">
                <app-icon name="login" class="icon-white"></app-icon>
                Log in as manager
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-pills py-4">
      <li class="nav-item p-0 m-1">
        <a
          class="btn rounded-pill btn-sm px-4 py-2"
          href="#"
          [ngClass]="{ active: currentFragment === 'dashboard' }"
          routerLink="/admin/school/{{ schoolId }}/dashboard"
          >Dashboard</a
        >
      </li>
      <li class="nav-item p-0 m-1">
        <a
          class="btn rounded-pill btn-sm px-4 py-2"
          href="#"
          [ngClass]="{ active: currentFragment === 'notes' }"
          routerLink="/admin/school/{{ schoolId }}/notes"
          >Notes</a
        >
      </li>
      <li class="nav-item p-0 m-1">
        <a
          class="btn rounded-pill btn-sm px-4 py-2"
          href="#"
          [ngClass]="{ active: currentFragment === 'edit' }"
          routerLink="/admin/school/{{ schoolId }}/edit"
          >Edit details</a
        >
      </li>
      <li class="nav-item p-0 m-1">
        <a
          class="btn rounded-pill btn-sm px-4 py-2"
          href="#"
          [ngClass]="{ active: currentFragment === 'payments' }"
          routerLink="/admin/school/{{ schoolId }}/payments"
          >Payments</a
        >
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</div>
