<form #f="ngForm" (ngSubmit)="f.form.valid && onSave()">
  <div class="form-floating mb-4 mb-4" *ngIf="profile">
    <input
    type="text"
    class="form-control"
    name="language"
    placeholder="language"
    [(ngModel)]="profile.language"
    [ngClass]="{ 'is-invalid': f.submitted && language.invalid}"
    required=""
    #language="ngModel"
    >
    <label class="field-required">Native language</label>
    <div *ngIf="f.submitted && language.invalid" class="invalid-feedback">
      <div *ngIf="language.errors.required">Your native language is required</div>
    </div>
  </div>

  <nav>
    <div class="nav nav-tabs border-0 mb-4" id="nav-tab" role="tablist">
      <a class="nav-item nav-link my-1 mb-2 me-2 btn btn-sm"
         [ngClass]="{'active': i == activeIdx}"
         *ngFor="let course of courses; let i = index"
         (click)="activate(i)"
      >{{courseNames[i]}}</a>
    </div>
  </nav>

<div class="tab-content" >
  <div class="tab-pane fade"
       [ngClass]="{'show active':i == activeIdx}"
        *ngFor="let course of courses; let i = index">
    <button *ngIf="!hasCourse(course)"
       class="btn btn-link text-decoration-none mb-4"
      (click)="activateLang(course)">{{courseNames[i]}} course isn't active. Click here to activate.</button>
    <div *ngIf="hasCourse(course)">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="form-floating mb-4">
            <input
            placeholder="accent"
            type="text"
            class="form-control"
            [name]="'accent-' + i"
            [(ngModel)]="getCourse(course).accent"
            [ngClass]="{ 'is-invalid': f.submitted && accent.invalid }"
            #accent="ngModel"
            required>
            <label class="field-required">Accent</label>
            <div *ngIf="f.submitted && accent.invalid" class="invalid-feedback">
              <div *ngIf="accent.errors.required">Accent is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="form-floating mb-4">
            <input
            placeholder="url"
            type="text"
            class="form-control"
            [name]="'video-' + i"
            [(ngModel)]="getCourse(course).videoUrl"
            [ngClass]="{ 'is-invalid': f.submitted && video.invalid }"
            #video="ngModel"
            >
            <label>Video URL</label>
            <div *ngIf="f.submitted && video.invalid" class="invalid-feedback">
              <div *ngIf="video.errors.required">Video is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating mb-4">
            <textarea
            placeholder="description"
            [name]="'description-' + i"
            [(ngModel)]="getCourse(course).description"
            class="form-control"
            [ngClass]="{ 'is-invalid': f.submitted && description.invalid }"
            #description="ngModel"
            required></textarea>
            <div *ngIf="f.submitted && description.invalid" class="invalid-feedback">
              <div *ngIf="description.errors.required">Description is required</div>
            </div>
            <label class="field-required">Description</label>
          </div>
        </div>
      </div>
      <div>
        <p class="small mb-2">Competences</p>
        <div class="mb-4">
          <div class="form-check form-check-inline" *ngFor="let competence of competences; let i = index">
            <input class="form-check-input"
                   type="checkbox"
                   name="prova"
                   [checked]="hasCompetence(course, competence)"
                   (change)="changeCompetence(course, competence)"
            >
            <label class="form-check-label small">
              {{competence}}
            </label>
          </div>
        </div>
      </div>
      <button class="btn btn-danger rounded-pill px-3" (click)="removeCourse(course)">Remove this language profile.</button>
    </div>
  </div>
</div>
  <div class="d-flex flex-column text-end">
    <button class="btn btn-primary ms-auto rounded-pill py-2 px-3" [disabled]="busy || (f.submitted && f.invalid)">
      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="busy"></span>
      Save</button>
    <p class="text-danger mt-3 mb-0" *ngIf="f.submitted && f.invalid">
      correct the form errors before saving
    </p>
  </div>
</form>
