import { Injectable } from '@angular/core';
import { AuthServiceProvider, UserRole } from './auth.service';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(
    private authService: AuthServiceProvider,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const roles = route.data['roles'] as Array<UserRole>;
    return forkJoin([
      this.authService.get().pipe(
        switchMap((api) => api.getUserRole()),
        map((userRole) => roles.some((expected) => expected === userRole))
      ),
      this.authService.get().pipe(
        map(auth => auth.isLoggedInAsManager())
      ),
    ]).pipe(
      map(([approved, isLoggedInAsManager]) => {
        if (!(approved || isLoggedInAsManager))
          this.router.navigate(['/forbidden']);
        return approved || isLoggedInAsManager;
      })
    );
    // return api.serverProfileSubject.pipe(
    //   take(1),
    //   map( profile => {
    //     if (profile && profile.roles && roles.some( expectedRole => profile.roles.indexOf(expectedRole) >= 0)) {
    //       return true;
    //     } else {
    //       this.router.navigate(['/forbidden']);
    //       return false;
    //     }
    //   })
    // )
    // return new Observable<boolean>(observer => {
    //   this.authService.serverProfileSubject.pipe(take(1)).subscribe(profile => {
    //
    //     if (profile && profile.roles && roles.some( expectedRole => profile.roles.indexOf(expectedRole) >= 0)) {
    //       observer.next(true); observer.complete();
    //     } else {
    //       this.router.navigate(['/forbidden']);
    //       observer.next(false); observer.complete();
    //     }
    //   });
    // });
  }
}
