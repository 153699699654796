import {Injectable} from "@angular/core";
import {EMPTY, Observable, of} from "rxjs";
import {shareReplay, tap} from "rxjs/operators";

class CacheEntryOld<T>{
   constructor(public value:T, public expiry: number) {
   }
}

class CacheEntry{
  constructor(
    public key: string,
    public value: Observable<any>,
    public invalidateAt: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cacheRegistry = new Map<String, CacheEntry>();
  private invalidateList: Array<CacheEntry> = [];

  handleWitchCache<T>(key: string, call: Observable<T>, time: number = 1000): Observable<T> {
    this.invalidateEntries();
    let result = this.cacheRegistry.get(key);
    if (result && result.invalidateAt > Date.now()) {
      return result.value as Observable<T>;
    }
    result = new CacheEntry(
      key,
      call.pipe(
        shareReplay(1)
      ), Date.now() + time
    )
    this.cacheRegistry.set(key,result);
    this.registerForInvalidation(result)
    return result.value;
  }

  private registerForInvalidation(result: CacheEntry) {
    this.invalidateList.push(result);
    this.invalidateList.sort((l,r) =>
      l.invalidateAt - r.invalidateAt
    )
  }

  private invalidateEntries() {
    let i = 0;

    // find the first which should be keept
    for (; i < this.invalidateList.length; i++) {
      if (this.invalidateList[i].invalidateAt > Date.now()) break;
    }

    let removed = this.invalidateList.splice(0, i);
    removed.forEach( item => this.cacheRegistry.delete(item.key))
  }
}
