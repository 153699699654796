

<form *ngIf="profile" #technicalProfileForm="ngForm" (ngSubmit)="technicalProfileForm.form.valid && onSave()">
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select class="form-select"
          id="language"
          #language="ngModel"
          [(ngModel)]="profile.lang"
          name="language"
          [attr.disabled]="editable ? null : ''"
          required
          [ngClass]="{ 'is-invalid': technicalProfileForm.submitted && language.invalid }"
          selectFloating
          >
            <option *ngFor="let lang of languages"
              [value]="lang.code">
              {{lang.name}}
            </option>
        </select>
        <label for="language" class="field-required">Language</label>
        <div *ngIf="technicalProfileForm.submitted && language.invalid" class="invalid-feedback">
          <div *ngIf="language.errors.required" >Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="tel" class="form-control" id="phone"
            placeholder="Phone"
            #phone="ngModel"
            [(ngModel)]="profile.phone" name="phone"
            [ngClass]="{ 'is-invalid': technicalProfileForm.submitted && phone.invalid }"
            [attr.disabled]="editable ? null : ''"
            required>
        <label for="phone" class="field-required">Phone</label>
        <div *ngIf="technicalProfileForm.submitted && phone.invalid" >
            <span *ngIf="phone.errors.required" class="d-block invalid-feedback f-12" >Field is required</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="skype"
            placeholder="Skype"
            #skype="ngModel"
            [(ngModel)]="profile.skype" name="skype"
            [ngClass]="{ 'is-invalid': technicalProfileForm.submitted && skype.invalid }"
            [attr.disabled]="editable ? null : ''"
            required>
        <app-icon name="skype" class="icon-primary skype-icon"></app-icon>
        <label for="skype" class="field-required">Skype</label>
        <div *ngIf="technicalProfileForm.submitted && skype.invalid" >
            <span *ngIf="skype.errors.required" class="d-block invalid-feedback f-12" >Field is required</span>
        </div>
      </div>
    </div>

  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      name="notifBookingConfirm"
      [(ngModel)]="profile.notifBookingConfirm"
      [attr.disabled]="editable ? null : ''"
      >
    <label class="form-check-label f-14">
      Receive booking confirmations
    </label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      name="notifOffers"
      [(ngModel)]="profile.notifOffers"
      [attr.disabled]="editable ? null : ''"
      >
    <label class="form-check-label f-14">
      Receive offers and discounts
    </label>
  </div>
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      name="allowBookingTeacherChange"
      [(ngModel)]="profile.allowBookingTeacherChange"
      [attr.disabled]="editable ? null : ''"
      >
    <label class="form-check-label f-14">
      When my tutor cancels a lesson, find me another
    </label>
  </div>
  <div class="d-flex justify-content-end">
    <button [hidden]="!editable" class="btn btn-primary me-3">Save</button>
    <button type="button" [hidden]="!editable" class="btn btn-secondary" (click)="editable = false;">Cancel</button>
  </div>

</form>
