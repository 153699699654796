import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentEvent } from 'src/app/model/rest/base';
import {
  StudentPersonalProfileFormComponent,
  StudentPersonalProfileFormComponentEvent
} from "../../components/student-personal-profile-form/student-personal-profile-form.component";
import {
  StudentTechnicalProfileFormComponent,
  StudentTechnicalProfileFormComponentEvent
} from "../../components/student-technical-profile-form/student-technical-profile-form.component";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {map, switchMap, tap} from "rxjs/operators";
import {combineLatest, of} from "rxjs";
import {Student} from "../../model/rest/casa-apiv2.model";

@Component({
  selector: 'app-manager-student-profile-page',
  templateUrl: './manager-student-profile-page.component.html',
  styleUrls: ['./manager-student-profile-page.component.css']
})
export class ManagerStudentProfilePageComponent implements OnInit {


  @ViewChild('studentPersonalProfileFormComponent', {static: true})
  private studentProfileForm: StudentPersonalProfileFormComponent;
  @ViewChild('studentTechnicalProfileFormComponent', {static: true})
  private studentTechnicalProfileForm: StudentTechnicalProfileFormComponent;
  personalProfileUnderEdit: boolean;
  technicalProfileUnderEdit: boolean;
  personalProfileReady = false;
  technicalProfileReady = false;
  schoolId: number;
  teacherId: string;
  studentId: number;

  constructor(
    activatedRoute: ActivatedRoute,
    private casa: CasaRestApiV2) {
    activatedRoute.parent.paramMap.pipe(
      map( params => [
        Number(params.get('schoolId')),
        Number(params.get('studentId'))
      ]),
      tap( ([schoolId, studentId]) => {
        this.schoolId = schoolId.valueOf();
        this.studentId = studentId.valueOf();
        }
      )
    ).subscribe( );
  }
  personalProfileEvent( event: StudentPersonalProfileFormComponentEvent) {
    if (event.eventType === StudentPersonalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.personalProfileUnderEdit = true;
    } else if (event.eventType === StudentPersonalProfileFormComponentEvent.ET_FORM_READ_ONLY) {
      this.personalProfileUnderEdit = false;
    } else if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.personalProfileReady = true;
    }
  }

  technicalProfileEvent( event: StudentTechnicalProfileFormComponentEvent) {
    if (event.eventType === StudentTechnicalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.technicalProfileUnderEdit = true;
    } else if (event.eventType === StudentTechnicalProfileFormComponentEvent.ET_FORM_READONLY) {
      this.technicalProfileUnderEdit = false;
    }  else if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.technicalProfileReady = true;
    }
  }

  editPersonalProfile() {
    this.studentProfileForm.editable = true;
  }

  editTechnicalProfile() {
    this.studentTechnicalProfileForm.editable = true;
  }

  ngOnInit() {
  }


}
