import {Component, Input, OnInit} from '@angular/core';
import {ReportGenericFilterData} from '../reports-filter/reports-filter.component';
import {ReportsRestService} from 'src/app/services/rest/reports-rest.service';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile,
  ApiPersonalProfileBaseWithPhoto
} from 'src/app/model/rest/rest-model';
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";

@Component({
  selector: 'app-lessons-report',
  templateUrl: './lessons-report.component.html',
  styleUrls: ['./lessons-report.component.css']
})
export class LessonsReportComponent implements OnInit {

  @Input()
  schoolId: number;
  lessons: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[];

  lessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonMetric.plannedStartDate;
  }

  lessonStatus(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonStatus;
  }

  lessonType(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonType;
  }

  students(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.students.map( student =>
      PersonNameExtractor.getPersonName(student.person) + ' (' + student.person.emailAddress + ')'
    ).join(', ');
  }

  lessonCourse(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.course.code;
  }

  teacher(lesson: ApiLessonInstance<ApiPersonalProfileBaseWithPhoto, ApiLearningUnitTeacher>) {
    return PersonNameExtractor.getPersonName(lesson.teacher.person);
  }

  flags(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson.flags) {
      return '';
    }
    return lesson.flags.map ( flag => flag.flagType).join(', ');
  }

  updateReport(data: ReportGenericFilterData): any {
    if (!data.lessonType || !data.lessonStatus) {
      alert('lesson type and lesson status is required');
    }
    this.reportService.listLessonDetails(
      this.schoolId,
      data.dateFrom,
      data.dateTo,
      data.productCode,
      data.lessonType,
      data.lessonStatus,
      data.lessonFlag).subscribe(lessons => this.lessons = lessons);
  }

  constructor(private reportService: ReportsRestService) { }

  ngOnInit() {
  }

}
