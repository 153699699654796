import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, finalize, switchMap, tap } from 'rxjs/operators';
import {
  ContactEntry,
  CountryDefinition,
  School,
  SchoolStatus,
  SchoolStatusDetails,
  SchoolStatusEnum,
} from 'src/app/model/rest/casa-apiv2.model';
import { AdminRestService } from 'src/app/services/rest/admin-rest.service';
import { CasaRestApiV2 } from 'src/app/services/rest/casa-rest.service';

enum SaveStatus {
  Save = 'Save',
  Saving = 'Saving',
  Saved = 'Saved',
  None = 'None',
}
@Component({
  selector: 'app-admin-school-edit',
  templateUrl: './admin-school-edit.component.html',
  styleUrls: ['./admin-school-edit.component.scss'],
})
export class AdminSchoolEditComponent implements OnInit {
  school: School;
  schoolId: number;
  countries: CountryDefinition[];
  oldSchoolIdSaveStatus = SaveStatus.Save;
  schoolDetailsSaveStatus = SaveStatus.Save;
  schoolCorpDetailsSaveStatus = SaveStatus.Save;
  schoolLabelsSaveStatus = SaveStatus.Save;
  schoolStatusDetailsSaveStatus = SaveStatus.Save;

  constructor(
    private casa: CasaRestApiV2,
    private route: ActivatedRoute,
    private adminRest: AdminRestService
  ) {
    this.casa
      .getCountries()
      .pipe(
        tap<CountryDefinition[]>((countries) => (this.countries = countries))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.route.parent.paramMap
      .pipe(
        tap((params) => (this.schoolId = +params.get('schoolId'))),
        switchMap((_) => this.adminRest.getSchool(this.schoolId)),
        tap((school) => (this.school = school))
      )
      .subscribe();
  }

  getSchoolStatusList() {
    return SchoolStatus.ALL;
  }

  getSchoolStatusName(key: string) {
    return SchoolStatusEnum[key];
  }

  addContact() {
    this.school.details.contacts.push(new ContactEntry());
  }

  removeContact(contactIdx: number) {
    this.school.details.contacts.splice(contactIdx, 1);
  }

  saveOldSchoolId() {
    this.adminRest
      .updateSchoolOldId(this.schoolId, this.school.oldId)
      .pipe(
        tap((_) => (this.oldSchoolIdSaveStatus = SaveStatus.Saving)),
        delay(1000),
        finalize(() => {
          this.oldSchoolIdSaveStatus = SaveStatus.Saved;
          setTimeout(() => {
            this.oldSchoolIdSaveStatus = SaveStatus.Save;
          }, 5000);
        })
      )
      .subscribe();
  }

  saveSchoolDetails() {
    this.adminRest
      .updateSchoolDetails(this.schoolId, this.school.details)
      .pipe(
        tap((_) => (this.schoolDetailsSaveStatus = SaveStatus.Saving)),
        delay(1000),
        finalize(() => {
          this.schoolDetailsSaveStatus = SaveStatus.Saved;
          setTimeout(() => {
            this.schoolDetailsSaveStatus = SaveStatus.Save;
          }, 5000);
        })
      )
      .subscribe();
  }

  saveCorpDetails() {
    this.adminRest
      .updateSchoolCorpDetails(this.schoolId, this.school.corpDetails)
      .pipe(
        tap((_) => (this.schoolCorpDetailsSaveStatus = SaveStatus.Saving)),
        delay(1000),
        finalize(() => {
          this.schoolCorpDetailsSaveStatus = SaveStatus.Saved;
          setTimeout(() => {
            this.schoolCorpDetailsSaveStatus = SaveStatus.Save;
          }, 5000);
        })
      )
      .subscribe();
  }

  saveSchoolLabels() {
    this.adminRest
      .updateSchoolLabels(this.schoolId, this.school.labels.split(','))
      .pipe(
        tap((_) => (this.schoolLabelsSaveStatus = SaveStatus.Saving)),
        delay(1000),
        finalize(() => {
          this.schoolLabelsSaveStatus = SaveStatus.Saved;
          setTimeout(() => {
            this.schoolLabelsSaveStatus = SaveStatus.Save;
          }, 5000);
        })
      )
      .subscribe();
  }
  saveSchoolStatusDetails() {
    this.adminRest
      .updateStatusDetails(
        this.schoolId,
        new SchoolStatusDetails(
          new Date(this.school.expDate),
          this.school.status
        )
      )
      .pipe(
        tap((_) => (this.schoolStatusDetailsSaveStatus = SaveStatus.Saving)),
        delay(1000),
        finalize(() => {
          this.schoolStatusDetailsSaveStatus = SaveStatus.Saved;
          setTimeout(() => {
            this.schoolStatusDetailsSaveStatus = SaveStatus.Save;
          }, 5000);
        })
      )
      .subscribe();
  }

  isDisabled(status: SaveStatus) {
    return status == SaveStatus.Saving;
  }
}
