import { Component, OnInit } from '@angular/core';
import {ScheduleRestService} from "../../../services/rest/schedule-rest.service";
import {Schedule, ScheduleRow, ScheduleRowSimplified} from "../../../model/schedule";
import {ActivatedRoute} from "@angular/router";
import {
  ConflictResults
} from "../../../services/schedule-search.service";
import {SchedulesListProvider} from "../../schedules-list/schedules-list.provider";
import {DateUtils} from "../../../services/helpers/date-utils";

@Component({
  selector: 'app-teacher-lessons-list',
  templateUrl: './teacher-lessons-list.component.html',
  styleUrls: ['./teacher-lessons-list.component.scss']
})
export class TeacherLessonsListComponent implements OnInit {
  schoolId: number
  teacherId: number
  scheduleRows: ScheduleRow[]
  schedulesDataProvider: SchedulesListProvider;
  isScheduleLoading = false;
  conflicts: ConflictResults;
  weekOffset = 0

  constructor(private scheduleRest: ScheduleRestService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      this.schoolId = +params.get("schoolId")
      this.teacherId = +params.get("teacherId")
      this.loadData()
    })
  }

  loadData() {
    let dayStart = new Date(DateUtils.weekStart(DateUtils.queryDate(this.weekOffset))).getTime()
    this.scheduleRest.listTeacherSchedules(
      this.schoolId, this.teacherId, dayStart
    ).subscribe(scheduleRows => this.scheduleRows = scheduleRows)
  }

  hasScheduleQueryArgs() {
    return this.schedulesDataProvider
      && this.schedulesDataProvider?.queryArgs
      && this.schedulesDataProvider?.queryArgs.weekOffset != null;
  }

  onSchedulesLoading($event: boolean) {
    this.isScheduleLoading = $event;
  }

  moveScheduleWeekOffset(number: number) {
    this.weekOffset += number
    this.loadData()
  }

  getWeekStartDate() {
    return DateUtils.weekStart(DateUtils.queryDate(this.weekOffset));
  }

  getWeekEndDate() {
    return DateUtils.weekEnd(DateUtils.queryDate(this.weekOffset));
  }

  moveScheduleToNow() {
    this.weekOffset = 0
    this.loadData()
  }

  getScheduleStartTime(schedule: Schedule){
    return schedule.details.startDate
  }

  getScheduleEndTime(schedule: Schedule){
    return schedule.details.startDate + schedule.details.durationMin * 60 * 1000
  }

  getScheduleLink(scheduleId: number) {
    return `/school/${this.schoolId}/schedules/${scheduleId}`
  }

  getBadgeColorClassByStatus(row: ScheduleRowSimplified) {
    switch(row.schedule.state) {
      case 'COMPLETE': return 'bg-success';
      case 'PLANED': return 'bg-warning';
      case 'PENDING': return 'bg-info';
    }
    return '';
  }
}
