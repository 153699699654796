
<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Groups'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page" ><a routerLink="/school/{{schoolId}}/groups">Groups</a></li>
        <li class="breadcrumb-item" aria-current="page"><strong *ngIf="roomData">{{roomData.name}}</strong></li>
      </ol>
    </nav>
    <app-room-details [roomData]="roomData" [progress]="progressData">
      <div bottom-section [hidden]="historyLength === 0">
        <div class="card-header border-top border-secondary pt-5">
          <h5>Previous lesson history</h5>
        </div>
        <div class="card-body pb-5">
          <app-progress-history-list (itemsNumber)="historyLength = $event" [dataProvider]="historyDataProvider"></app-progress-history-list>
        </div>
      </div>
    </app-room-details>
  </div>

</div>


