<form #f="ngForm">
  <div class="row gy-2 g-md-3">
    <div class="col-12 col-md-6 col-xl-3">
      <div class="form-floating">
        <select name="productCode" class="form-select" [(ngModel)]="productCode" [ngClass]="{ 'select-not-empty': productCode }">
          <option *ngFor="let langCode of langCodes" [value]="langCode">{{getLangName(langCode)}}</option>
        </select>
        <label>Product</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-3">
      <div class="form-floating">
        <select (change)="preselectedChange()" name="preselectedPeriod" class="form-select" [(ngModel)]="preselectedPeriod" [ngClass]="{ 'select-not-empty': preselectedPeriod != null}">
          <option value="0">current month</option>
          <option value="-1">previous month</option>
          <option value="-2">2 months ago</option>
          <option value="-3">3 months ago</option>
          <option value="-4">4 months ago</option>
          <option value="-5">5 months ago</option>
          <option value="-6">6 months ago</option>
          <option value="-7">7 months ago</option>
        </select>
        <label>Preselect period of set dates</label>
      </div>
    </div>
    <!-- dates for xs -->
    <div class="col-12 d-sm-none">
      <div class="form-floating mb-2">
        <input name="dateFrom" placeholder="dd/MM/yyyy HH:mm" class="form-control" type="text" [(ngModel)]="dateFrom">
        <app-icon name="calendar" [class]="{'hidden': dateFrom}" class="icon-primary d-flex my-sm-auto input-icon"></app-icon>
        <button type="button" [class]="{'hidden': !dateFrom}" (click)="resetDates()" class="btn order-sm-3 ms-auto p-0 input-icon">
          <app-icon name="close" class="icon-primary d-flex my-auto p-0"></app-icon>
        </button>
        <label>Date from</label>
      </div>
      <div class="form-floating">
        <input name="dateTo" placeholder="dd/MM/yyyy HH:mm" class="form-control" type="text" [(ngModel)]="dateTo">
        <app-icon name="calendar" [class]="{'hidden': dateFrom}" class="icon-primary d-flex my-sm-auto input-icon"></app-icon>
        <button type="button" [class]="{'hidden': !dateFrom}" (click)="resetDates()" class="btn order-sm-3 ms-auto p-0 input-icon">
          <app-icon name="close" class="icon-primary d-flex my-auto p-0"></app-icon>
        </button>
        <label>Date to</label>
      </div>
    </div>
    <!-- dates for sm and bigger -->
    <div class="d-none  d-sm-inline-flex col-12 col-xl-6">
      <div class="form-control-group d-flex rounded-2 flex-column flex-sm-row w-100 position-relative p-2 py-sm-0 p-sm-3">
        <app-icon name="calendar" class="icon-primary d-flex my-sm-auto"></app-icon>
        <button type="button" [class]="{'hidden': !(dateFrom || dateTo)}" (click)="resetDates()" class="btn btn-reset order-sm-3 ms-auto p-0">
          <app-icon name="close" class="icon-primary d-flex my-auto p-0"></app-icon>
        </button>
        <div class="form-floating input-width">
          <input name="dateFrom" placeholder="dd/MM/yyyy HH:mm" class="form-control" type="text" [(ngModel)]="dateFrom">
          <label>Date from</label>
        </div>
        <div class="vertical-rule"></div>
        <div class="form-floating input-width">
          <input name="dateTo" placeholder="dd/MM/yyyy HH:mm" class="form-control" type="text" [(ngModel)]="dateTo">
          <label>Date to</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row gy-2 g-md-3 mt-0" *ngIf="withLessonType || withLessonStatus || withLessonFlag">
    <div class="col">
      <div *ngIf="withLessonType" class="multiselect w-100" [ngClass]="{'border-danger' : f.submitted && lessonType.length === 0}">
        <label class="multiselect-header mb-0 field-required">
            Lesson Type
        </label>
        <div class="multiselect-body">
          <div class="form-check" *ngFor="let type of lessonTypes"
          (change)="onTypeChange(type, $event)"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="isTypeChecked(type)"
              >
              <label class="small text-muted">{{ type }}</label>
          </div>
        </div>
      </div>
      <span *ngIf="f.submitted && lessonType.length === 0" class="text-danger xsmall">Field is required</span>
    </div>
    <div class="col">
      <div *ngIf="withLessonStatus" class="multiselect w-100" [ngClass]="{'border-danger' : f.submitted && lessonStatus.length === 0}">
        <label class="multiselect-header mb-0 field-required">
            Lesson Status
        </label>
        <div class="multiselect-body">
          <div class="form-check" *ngFor="let status of lessonStatuses"
          (change)="onStatusChange(status, $event)"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="isStatusChecked(status)"
              >
              <label class="small text-muted">{{ status }}</label>
          </div>
        </div>
      </div>
      <span *ngIf="f.submitted && lessonStatus.length === 0" class="text-danger xsmall">Field is required</span>
    </div>
    <div class="col">
      <div *ngIf="withLessonFlag" class="multiselect w-100">
        <label class="multiselect-header mb-0">
            Lesson Flag
        </label>
        <div class="multiselect-body">
          <div class="form-check" *ngFor="let flag of lessonFlags"
          (change)="onFlagChange(flag, $event)"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="isFlagChecked(flag)"
              >
              <label class="small text-muted">{{ flag }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div *ngIf="withLessonFlag && withLessonStatus && withLessonType" class="list">
        <label class="list-header">Predefined</label>
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <a tabindex="" class="btn btn-link text-decoration-none small p-0" (click)="predefProva()">
              Prova
            </a>
          </li>
          <li class="list-inline-item">
            <a tabindex="" class="btn btn-link text-decoration-none small p-0" (click)="predefProvaNoShow()">
              Prova no show
            </a>
          </li>
          <li class="list-inline-item">
            <a tabindex="" class="btn btn-link text-decoration-none small p-0" (click)="predefNoShow()">
              No show
            </a>
          </li>
        </ul>
     </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-end">
      <button type="submit" (click)="generate()" class="btn btn-primary px-4 py-2 rounded-pill">Generate</button>
    </div>
  </div>
  <div class="clearfix"></div>

</form>
