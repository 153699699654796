import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchingContextService} from "../../../services/searching-context.service";
import {LocalStateService} from "../../../services/local-state.service";
import {TeacherVideoRestService} from "../../../services/rest/teacher-video-rest.service";
import {map, switchMap, take, tap, timeoutWith} from "rxjs/operators";
import {
  ScheduleListQuery,
  StudentSchedulesListProvider, TeacherSchedulesListProvider
} from "../../../components/schedules-list/schedules-list.provider";
import {of} from "rxjs";
import {DateUtils} from "../../../services/helpers/date-utils";
import {ScheduleRow} from "../../../model/schedule";
import {ListEvent, SchedulesListComponent} from "../../../components/schedules-list/schedules-list.component";
import {Page, Pageable} from "../../../model/rest/base";
import {IdentifiedRoomTemplate, RoomDef} from "../../../model/server";

@Component({
  selector: 'app-teacher-root-page',
  templateUrl: './teacher-root-page.component.html',
  styleUrls: ['./teacher-root-page.component.scss']
})
export class TeacherRootPageComponent implements OnInit {
  schoolId: number;
  teacherId: number;
  private schedulesDataProvider: TeacherSchedulesListProvider;
  @ViewChild('schedulesListComponent', {static: false}) schedulesListComponent: SchedulesListComponent;
  schedulesCount: number;
  isScheduleLoading = false;
  groupsState: Pageable;
  groupsData: Page<IdentifiedRoomTemplate>;
  roomsState: Pageable;
  roomsData: Page<RoomDef>;



  constructor(
    activatedRoute: ActivatedRoute,
    private teacherRest: TeacherVideoRestService,
    private searchingContext: SearchingContextService,
    private localStateService: LocalStateService,
    private router: Router
  ) {
    activatedRoute.paramMap.pipe(
      map(params => [Number(params.get("schoolId")), Number(params.get("teacherId"))]),
      tap( ([schoolId, studentId]) => this.storeParams(schoolId, studentId)),
      switchMap( _ => this.restoreSchedules()),
      switchMap( _ => this.restoreGroupsLocalState()),
      switchMap( groupsState => this.loadGroups(groupsState)),
      switchMap( _ => this.restoreRoomsLocalState()),
      switchMap( roomsState => this.loadRooms(roomsState))
    ).subscribe();
  }

  private storeParams(schoolId: number, teacherId: number) {
    this.schoolId = schoolId;
    this.teacherId = teacherId;
  }

  ngOnInit(): void {
  }

  private restoreSchedules() {
    const contextPath = `/schools/${this.schoolId}/teacher/${this.teacherId}/schedules`;
    return this.searchingContext.getOrCreateContext<ScheduleListQuery>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new ScheduleListQuery(0 , null))),
      map( query => new TeacherSchedulesListProvider(contextPath, this.searchingContext, this.teacherRest, this.schoolId, this.teacherId, query)),
      tap<TeacherSchedulesListProvider>( provider => this.schedulesDataProvider = provider)
    );
  }

  hasScheduleQueryArgs() {
    return this.schedulesDataProvider && this.schedulesDataProvider.queryArgs && this.schedulesDataProvider.queryArgs.weekOffset != null;
  }

  moveScheduleWeekOffset(number: number) {
    this.schedulesDataProvider.queryArgs.weekOffset += number;
    this.schedulesListComponent.refreshData();
  }

  moveScheduleToNow() {
    this.schedulesDataProvider.queryArgs.weekOffset = 0;
    this.schedulesListComponent.refreshData();
  }

  getWeekStartDate() {
    return DateUtils.weekStart(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  getWeekEndDate() {
    return DateUtils.weekEnd(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  onSchedulesDataSetUpdate(dataSet: ScheduleRow[]) {
    this.schedulesCount = dataSet.length;
  }

  onSchedulesLoading($event: boolean) {
    this.isScheduleLoading = $event;
  }

  getSchedulesDataProvider() {
    return this.schedulesDataProvider;
  }

  private groupsLocalStatePath() {
    return `/schools/${this.schoolId}/teachers/${this.teacherId}/templates`;
  }

  private roomsLocalStatePath() {
    return `/schools/${this.schoolId}/teachers/${this.teacherId}/rooms`;
  }

  private restoreGroupsLocalState() {
    return this.localStateService.get<Pageable>(this.groupsLocalStatePath(),() =>
      Pageable.of(0,10, ['created,desc'])
    );
  }

  private loadGroups(groupsState: Pageable) {
    this.groupsState = groupsState;
    return this.localStateService.set<Pageable>(this.groupsLocalStatePath(), groupsState).pipe(
      switchMap( state => this.teacherRest.listTemplates(this.schoolId, this.teacherId, state)),
      tap( groupsData => this.storeGroupsData(groupsData))
    )
  }

  private storeGroupsData(groupsData: Page<IdentifiedRoomTemplate>) {
    this.groupsData = groupsData;
  }

  private restoreRoomsLocalState() {
    return this.localStateService.get<Pageable>(this.roomsLocalStatePath(),() =>
      Pageable.of(0,10, ['createDate,desc'])
    );
  }

  private loadRooms(roomsState: Pageable) {
    this.roomsState = roomsState;
    return this.localStateService.set<Pageable>(this.roomsLocalStatePath(), roomsState).pipe(
      switchMap( state => this.teacherRest.listRooms(this.schoolId, this.teacherId, state)),
      tap( roomsData => this.storeRoomsData(roomsData))
    )
  }

  private storeRoomsData(roomsData: Page<RoomDef>) {
    this.roomsData = roomsData;
  }

  hasGroups() {
    return this.groupsData && this.groupsData.totalElements > 0;
  }

  hasRooms() {
    return this.roomsData && this.roomsData.totalElements > 0;
  }

  switchGroupPage(toPage: Pageable) {
    this.loadGroups(toPage).subscribe();
  }

  switchRoomsPage(toPage: Pageable) {
    this.loadRooms(toPage).subscribe();
  }

  openRoomDetails(room: RoomDef) {
    this.router.navigate(["ta", "school",this.schoolId, "teacher", this.teacherId ,"room",room.uuid ]);
  }

  onScheduleEvent($event: ListEvent) {
    if ($event.event == ListEvent.EVENT_OPEN){
      this.router.navigate(["ta", "school",this.schoolId, "teacher", this.teacherId ,"schedule", $event.item.schedule.id ]);
    }
  }

}
