<div class="modal fade" id="creditsCart" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Buy eBook credits</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-borderless table-sm">
            <thead>
              <tr>
                <th></th>
                <th class="text-center">price</th>
                <th class="text-center">quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of products">
                <td class="pb-2 lh-1">{{product.name}}<br/>
                  <span class="text-muted xsmall">{{product.description}}</span>
                </td>
                <td class="text-center">{{getProductPrice(product)}}</td>
                <td class="text-end">
                  <div class="btn-group btn-group-sm w-100">
                    <a class="btn btn-outline-secondary rounded-end rounded-pill" (click)="add(product, -1)">
                      <app-icon name="minus"></app-icon>
                    </a>
                    <span class="btn btn-outline-secondary text-black">{{getQuantity(product)}}</span>
                    <a class="btn btn-outline-secondary rounded-start rounded-pill" (click)="add(product, 1)">
                      <app-icon name="plus"></app-icon>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm rounded-pill px-4 py-2" data-bs-dismiss="modal" >Close</button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill px-4 py-2" (click)="checkout()" [disabled]="loading">
          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Checkout
        </button>
      </div>
    </div>
  </div>
</div>
