<div class="position-relative w-100 d-flex">
  <div #cardHeightPlaceholder></div>
  <div class="card z-index-10 w-100" *ngIf="isReady()" #ebookCreditsCard>
    <div class="card-header">
      <app-icon name="book" class="icon-primary icon-bg bg-gray"></app-icon>
      <div class="d-flex">
        <h6 class="card-title my-auto">eBooks and printed books</h6>
      </div>
    </div>
    <div class="card-body d-flex position-relative">
      <p *ngIf="!hasCredits()" class="m-auto">
        <strong>You have no credits</strong>
      </p>
      <button (click)="toggleContentFoggy()"
        class="btn text-primary position-absolute bottom-0 m-0 px-1 z-index-10"
        *ngIf="isContentFoggy()">
        view more
      </button>
      <button (click)="toggleContentFoggy()"
        class="btn text-primary position-absolute bottom-0 m-0 px-1 z-index-10"
        *ngIf="isContentExpanded()">
        view less
      </button>
      <div *ngIf="hasCredits()" [ngClass]="{ 'content-foggy': isContentFoggy() }">
        <p class="xsmall">Your current credit balance:</p>
        <ul class="list-unstyled separated-list">
          <li *ngFor="let entry of getCreditsEntriesSorted()" class="small">
            {{ getEntryDescription(entry) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="card-footer text-end">
      <a
        type="button"
        class="btn btn-secondary btn-sm rounded-pill px-3 px-md-4 py-2 me-2"
        href="https://shop.callan.co.uk/"
        target="_blank"
      >
        Buy books
      </a>
      <button
        type="button"
        class="btn btn-primary btn-sm rounded-pill px-3 px-md-4 py-2"
        data-bs-toggle="modal" data-bs-target="#creditsCart"
      >
        Buy eBooks
      </button>
    </div>
  </div>
</div>
