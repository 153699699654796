<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Dashboard'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Home</li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-header row">
        <div class="col-12">
          <div class="position-relative">
            <input
              name="search-term"
              type="text"
              class="form-control p-3 mb-3 mb-md-0"
              [(ngModel)]="searchTerm"
              (ngModelChange)="searchUpdated()"
              placeholder="Search"
            />
            <button
              type="submit"
              class="btn position-absolute end-0 top-0 bottom-0"
            >
              <app-icon name="search" class="icon-primary"></app-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="searchedPeople || searchedSchools">
        <ng-container *ngIf="getSearchedPeople()?.length">
          <div class="row mb-4">
            <div class="col d-flex">
              <h5 class="my-auto">
                People
                <span class="text-primary"
                  >( {{ getSearchedPeople()?.length }} )</span
                >
              </h5>
            </div>
          </div>
          <ul class="list-group mb-5">
            <li class="list-group-item d-flex flex-column p-4 pt-3" *ngFor="let personSR of getSearchedPeople(); let i = index">
              <h5 class="text-center border-bottom border-primary mx-auto p-1">{{personSR.person.details.name}} {{personSR.person.details.surname}}</h5>
              <div *ngIf="personSR.person.registrationEmail">
                <strong class="small">Email:</strong>
                <small class="ms-2">{{personSR.person.registrationEmail}}</small>
              </div>
              <div *ngIf="getAddress(personSR.person.details.address)">
                <strong class="small">Address:</strong>
                <small class="ms-2">{{getAddress(personSR.person.details.address)}}</small>
              </div>
              <div *ngIf="personSR.person.details.contacts.length">
                <strong class="small">Contacts: </strong>
                <small class="ms-2" *ngFor="let contact of getContact(personSR.person.details.contacts)">{{contact}}<br></small>
              </div>
              <div *ngIf="personSR.managerOf.length">
                <strong class="small">Manager of: </strong>
                <small class="ms-2" *ngFor="let role of personSR.managerOf">{{role.school.name}}
                  <span class="badge bg-primary rounded me-1">{{role.school.status}}</span>
                  <span *ngIf="role.school.expDate" class="text-danger xsmall mx-1">[{{role.school.expDate | date: 'dd/MM/yyyy'}}]</span>
                </small><br>
              </div>
              <div *ngIf="personSR.studentOf.length">
                <strong class="small">Student role of: </strong>
                <small class="ms-2" *ngFor="let role of personSR.studentOf">{{role.school.name}}
                  <span class="badge bg-primary rounded me-1">{{role.school.status}}</span>
                  <span *ngIf="role.school.expDate" class="text-danger xsmall mx-1">[{{role.school.expDate | date: 'dd/MM/yyyy'}}]</span>
                </small><br>
              </div>
              <div *ngIf="personSR.teacherOf.length">
                <strong class="small">Teacher of: </strong>
                <small class="ms-2" *ngFor="let role of personSR.teacherOf">{{role.school.name}}
                  <span class="badge bg-primary rounded me-1">{{role.school.status}}</span>
                  <span *ngIf="role.school.expDate" class="text-danger xsmall mx-1">[{{role.school.expDate | date: 'dd/MM/yyyy'}}]</span>
                </small><br>
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="getSearchedSchools()?.length">
          <div class="row mb-4">
            <div class="col d-flex">
              <h5 class="my-auto">
                Schools
                <span class="text-primary"
                  >( {{ getSearchedSchools().length }} )</span
                >
              </h5>
            </div>
          </div>
          <ul class="list-group">
            <li class="list-group-item d-flex flex-column p-4 pt-3" *ngFor="let school of getSearchedSchools(); let i = index">
              <a class="h5 text-center border-bottom border-primary mx-auto cursor-pointer p-2" routerLink="/admin/school/{{school.id}}/dashboard">{{school.details.name}}</a>
              <div *ngIf="school.status">
                <strong class="small">Status:</strong>
                <small class="ms-2">{{school.status}}</small>
              </div>
              <div *ngIf="school.expDate">
                <strong class="small">Expiry date:</strong>
                <small class="ms-2">{{school.expDate}}</small>
              </div>
              <div *ngIf="getAddress(school.details.address)">
                <strong class="small">Address:</strong>
                <small class="ms-2">{{getAddress(school.details.address)}}</small>
              </div>
              <div *ngIf="school.details.contacts.length">
                <strong class="small">Contacts: </strong>
                <small class="ms-2" *ngFor="let contact of getContact(school.details.contacts)">{{contact}}</small><br>
              </div>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>
</div>
