<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Reports'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Reports</li>
      </ol>
    </nav>
    <div class="card border-0 mb-4">
      <div class="card-body">
          <app-reports-filter (generateReport)="updateReport($event)"></app-reports-filter>
      </div>
    </div>
    <app-teachers-lesson-types-report #reportView [schoolId]="schoolId"></app-teachers-lesson-types-report>

  </div>
</div>
