<div class="card" *ngIf="reportResult">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr class="table-row table-row-head">
            <th class="">Teacher</th>
            <th class="text-center">Complete</th>
            <th class="text-center">Student no show</th>
            <th class="text-center">Invalidated by student</th>
            <th class="text-center">Invalidated by teacher</th>
            <th class="text-center">Canceled</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let entry of reportResult" class="table-row">
            <td class="text-break">{{getTeacherName(entry.teacher.teacher.person)}}</td>
            <td class="text-center">{{entry.completed}}</td>
            <td class="text-center">{{entry.studentNoShow}}</td>
            <td class="text-center">{{entry.invalidatedByStudent}}</td>
            <td class="text-center">{{entry.invalidatedByTeacher}}</td>
            <td class="text-center">{{entry.cancelled}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
