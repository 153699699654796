import {Component, OnInit, ViewChild} from '@angular/core';
import {
  DetailedGroup,
  GroupDetails,
  GroupReference, GroupStudent, GroupTeacher
} from "../../../model/group";
import {ActivatedRoute, Router} from "@angular/router";
import {GroupRestServiceImpl} from "../../../services/rest/group-rest.service";
import {map, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {GroupTeacherCreateComponent} from "../../../components/groups/group-participant-create/group-teacher-create.component";
import {Student, Teacher} from "../../../model/rest/casa-apiv2.model";
import {
  GroupStudentCreateComponent
} from "../../../components/groups/group-participant-create/group-student-create.component";

@Component({
  selector: 'app-group-edit-page',
  templateUrl: './group-edit-page.component.html',
  styleUrls: ['./group-edit-page.component.scss']
})
export class GroupEditPageComponent implements OnInit {
  schoolId: number;
  groupDetails = new GroupDetails()
  isLoading = false
  groupId: number = null
  group: DetailedGroup
  @ViewChild('groupTeacherCreate') groupTeacherCreate: GroupTeacherCreateComponent
  @ViewChild('groupStudentCreate') groupStudentCreate: GroupStudentCreateComponent

  constructor(private activatedRoute: ActivatedRoute,
              private groupRest: GroupRestServiceImpl,
              private router: Router) {
    activatedRoute.paramMap.pipe(
      map( params => {
        this.schoolId = Number(params.get('schoolId'))
        this.groupId = Number(params.get('groupId'))
      })
    ).subscribe(_ =>  this.loadGroupDetails());
  }

  ngOnInit(): void {
  }

  addTeacher() {
    this.groupTeacherCreate.addParticipant();
    this.loadGroupDetails()
  }
  addStudent() {
    this.groupStudentCreate.addParticipant();
    this.loadGroupDetails()
  }

  loadGroupDetails() {
    if(this.groupId)
      return this.groupRest.getGroupDetails(this.schoolId, this.groupId).subscribe(
      group => {
        this.group = group
        this.groupDetails.name = group.details.name
      })
  }

  onGroupNameSave() {
    this.isLoading = true
    let observable: Observable<any>

    if(!this.groupId)
      observable = this.groupRest.createNewGroup(this.schoolId, this.groupDetails).pipe(
        tap((groupRef: GroupReference) => this.groupId = groupRef.id),
        tap(_ => this.router.navigate(['school', this.schoolId, 'v2_groups','group', this.groupId, 'edit']))
      )
    else
      observable = this.groupRest.updateGroup(this.schoolId, this.groupId, this.groupDetails)

    observable.subscribe(_ => {
      this.isLoading = false
      this.loadGroupDetails()
    })
  }

  getGroupName() {
    return this.group?.details.name
  }

  deleteParticipant(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    this.groupRest.deleteParticipant(this.schoolId, this.groupId, parti.id)
      .subscribe(_ => this.loadGroupDetails())
  }

  editTeacher(parti: GroupTeacher<Teacher>) {
    this.groupTeacherCreate.prepareParticipantEdition(parti)
  }
  editStudent(parti: GroupStudent<Student>) {
    this.groupStudentCreate.prepareParticipantEdition(parti)
  }
}
