// product variant
export class VariantBase {
  code: string;
}

export class Price {
  value: number;
  currency: string;
}

export class PriceList {
  name: String;
}

export class PriceListPrice extends Price {
  priceList: PriceList;
}

export class VariantPrice<V extends VariantBase> extends PriceListPrice {
  variant: V;
}

export class TransactionEntryBase<V extends VariantBase, P extends VariantPrice<V>> {
  quantity: number;
  price: P;
}

export class TransactionBase<E extends TransactionEntryBase<any, any>> {
  entries: Array<E>;
}

export class SimpleOrderEntryMetadata {
  quantityAdjustable: boolean;
}

export class SimpleOrderEntry extends TransactionEntryBase<VariantBase, VariantPrice<VariantBase>> {
  metadata: SimpleOrderEntryMetadata;
}

export class SimpleOrder extends TransactionBase<SimpleOrderEntry> {}

export interface EntryBuilder {
  withPrice(priceList: string, value: number, currency: string): EntryBuilder
  finish(): OrderBuilder
  allowQuantityAdjust(): EntryBuilder;
}

export interface OrderBuilder {
  newEntry(productCode: string, quantity: number): EntryBuilder
  build(): SimpleOrder
}

export function orderBuilder(): OrderBuilder {
  const order = new SimpleOrder();
  order.entries = [];

  const orderBuilder =  {
    build(): SimpleOrder {
      return order;
    },
    newEntry(productCode: string, quantity: number): EntryBuilder {
      const entry: SimpleOrderEntry = new SimpleOrderEntry();
      entry.quantity = quantity;
      entry.price = new VariantPrice<VariantBase>();
      entry.price.variant = new VariantBase();
      entry.price.variant.code = productCode;
      return {
        allowQuantityAdjust(): EntryBuilder {
          if (!entry.metadata) {
            entry.metadata = new SimpleOrderEntryMetadata();
          }
          entry.metadata.quantityAdjustable = true;
          return this;
        },
        finish(): OrderBuilder {
          order.entries.push(entry);
          return orderBuilder;
        },
        withPrice(priceList: string, value: number, currency: string): EntryBuilder {
          entry.price.value = value
          entry.price.currency = currency
          entry.price.priceList = new PriceList();
          entry.price.priceList.name = priceList;

          return this;
        }
      }
    }
  }
  return orderBuilder;
}
