import { Component, OnInit } from '@angular/core';
import {AuthServiceImpl, AuthServiceProvider} from 'src/app/services/col2/auth.service';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private authService: AuthServiceProvider) { }

  ngOnInit() {
  }

  public logout(): void {
    this.authService.get().subscribe(api => api.logout());
  }

  isAuthorized(): Observable<boolean> {
    return this.authService.get().pipe(map( api => api.isTokenValid()));
  }
}
