<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Groups'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page" ><a routerLink="/school/{{schoolId}}/v2_groups">Groups</a></li>
        <li *ngIf="!groupId" class="breadcrumb-item active" aria-current="page">New group</li>
        <ng-container *ngIf="groupId">
          <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/v2_groups/group/{{groupId}}">{{getGroupName()}}</a></li>
          <li class="breadcrumb-item active" aria-current="page">Edition</li>
        </ng-container>
      </ol>
    </nav>
    <div class="card mb-5">
      <div class="card-body pb-0">
        <form (submit)="onGroupNameSave()" autocomplete="off">
          <div class="row">
            <div class="col-12 mb-4">
              <div class="form-floating">
                <input class="form-control"
                       [(ngModel)]="groupDetails.name"
                       placeholder="Group name"
                       name="room-name">
                <label>Group name</label>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-sm btn-primary px-4 py-2 rounded-pill" [disabled]="isLoading">
              <div
                class="spinner-grow spinner-grow-sm text-white"
                role="status"
                *ngIf="isLoading"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
    <ng-container *ngIf="group">
      <div class="card mb-5" >
        <div class="card-header">
          <div class="d-flex w-100">
            <h5 class="w-fit-content my-auto">Group Teachers</h5>
            <button type="button"
                    class="btn btn-primary rounded-circle p-1 lh-sm ms-auto"
                    (click)="addTeacher()"
                    data-bs-toggle="tooltip"
                    title="Add a new teacher."
            >
              <app-icon name="plus" class="icon-white icon-sm"></app-icon>
            </button>
          </div>
        </div>
        <div class="card-body pb-0">
          <app-group-teacher-create (reloadGroupEmitter)="loadGroupDetails()" #groupTeacherCreate [groupId]="groupId"></app-group-teacher-create>
          <app-group-participant-list (editPartiEvent)="editTeacher($event)" (deletePartiEvent)="deleteParticipant($event)" [participants]="group.teachers" [isEdited]="true"></app-group-participant-list>
        </div>
      </div>
      <div class="card mb-5">
        <div class="card-header">
          <div class="d-flex w-100">
            <h5 class="w-fit-content my-auto">Group Students</h5>
            <button type="button"
                    class="btn btn-primary rounded-circle p-1 lh-sm ms-auto"
                    (click)="addStudent()"
                    data-bs-toggle="tooltip"
                    title="Add a new student."
            >
              <app-icon name="plus" class="icon-white icon-sm"></app-icon>
            </button>
          </div>
        </div>
        <div class="card-body pb-0">
          <app-group-student-create (reloadGroupEmitter)="loadGroupDetails()" #groupStudentCreate [groupId]="groupId"></app-group-student-create>
          <app-group-participant-list (editPartiEvent)="editStudent($event)" (deletePartiEvent)="deleteParticipant($event)" [participants]="group.students" [isEdited]="true"></app-group-participant-list>
        </div>
    </div>
    </ng-container>
  </div>
</div>
