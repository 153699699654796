import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalComponent} from "../../modal/modal.component";

@Component({
  selector: 'app-teacher-subscription-modal',
  templateUrl: './teacher-subscription-modal.component.html',
  styleUrls: ['./teacher-subscription-modal.component.scss']
})
export class TeacherSubscriptionModalComponent implements OnInit {

  @ViewChild('teacherSubscription', { static: true })
  teacherSubscription: ModalComponent;
  @Output() subscriptionEvent = new EventEmitter<void>()
  @Input() isTeacherSubscribed = false

  constructor() { }

  ngOnInit(): void {
  }

  addTeacherToSubscription() {
    this.subscriptionEvent.emit()
    this.teacherSubscription.hide()
  }
  removeTeacherFromSubscription() {
    this.subscriptionEvent.emit()
    this.teacherSubscription.hide()
  }

}
