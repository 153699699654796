import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceImpl, AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-teacher-area-layout',
  templateUrl: './teacher-area-layout.component.html',
  styleUrls: ['./teacher-area-layout.component.scss']
})
export class TeacherAreaLayoutComponent implements OnInit {
  schoolId: number;
  teacherId: number;

  constructor(
    activatedRoute: ActivatedRoute,
    private auth: AuthServiceProvider
  ) {
    activatedRoute.paramMap.subscribe(params => {
      this.schoolId = Number(params.get('schoolId'));
      this.teacherId = Number(params.get('teacherId'));
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.auth.get().subscribe(api => api.logout());
  }

}
