import { Component, Input, OnInit } from '@angular/core';
import { Router, Event, RouterEvent } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { School } from 'src/app/model/rest/casa-apiv2.model';
import { CallanOnlineRecognizeService } from 'src/app/services/callan-online-recognize.service';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { FunctionalAccessService } from 'src/app/services/functional-access.service';
import { CasaRestApiV2 } from 'src/app/services/rest/casa-rest.service';
import { ThemeService } from 'src/app/services/theme.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'manager-main-nav',
  templateUrl: './manager-main-nav.component.html',
  styleUrls: ['./manager-main-nav.component.scss'],
})
export class ManagerMainNavComponent implements OnInit {
  private activeSegments: string[];
  private activePath: string;
  private managedSchools: School[]
  isGroupVisible = environment.isGroupVisible

  @Input() isOffcanvas: boolean = false;
  @Input() schoolId: String;

  disableVideo = true;
  theme: string;

  constructor(
    private authService: AuthServiceProvider,
    private callanOnlineRecognize: CallanOnlineRecognizeService,
    functionalityAccessService: FunctionalAccessService,
    public themeService: ThemeService,
    private router: Router,
    private casaRest: CasaRestApiV2
  ) {
    functionalityAccessService.getAccessState().subscribe((accessList) => {
      this.disableVideo = accessList.videoDisabled;
    });
    themeService.getTheme().subscribe((theme) => (this.theme = theme));
    router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        this.activePath = e['urlAfterRedirects'];
        this.activePath
          ? (this.activeSegments = this.activePath.split('/'))
          : '';
      });
  }

  ngOnInit(): void {
    this.casaRest.getSchools().subscribe(schools => this.managedSchools = schools.content)
  }

  hasManyManagedSchools() {
    return this.managedSchools?.length > 1
  }

  isCallanOnline() {
    if (!this.schoolId) return false;
    return this.callanOnlineRecognize.isCallanOnlineFranchiseById(
      Number(this.schoolId)
    );
  }

  logout() {
    this.authService.get().subscribe((api) => api.logout());
  }

  getDismiss() {
    return this.isOffcanvas && 'offcanvas';
  }

  isActive(segments: string) {
    return this.activeSegments[1] === 'school' &&
      segments
        .split(';')
        .some((segment) => this.activeSegments[3].includes(segment));
  }

  logOutFromManager() {
    this.authService.get().subscribe((auth) => auth.logOutFromManager());
  }

  isAdminLoggedInAsManager() {
    return this.authService
      .get()
      .pipe(map((auth) => auth.isLoggedInAsManager()));
  }
}
