
<div *ngIf="viewRows && viewRows.length>0"  class="table-responsive">
  <table class="table table-sm">
    <thead>
      <tr class="table-row table-row-head">
        <th>Date</th>
        <th>Name</th>
        <th>Plan</th>
        <th>Amount</th>
        <th>Balance</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let viewRow of viewRows" class="table-row">
        <td>{{viewRow.date | date:'dd MMM yyyy HH:mm'}}</td>
        <td >
          <span>{{viewRow.action}}</span></td>
        <td>{{viewRow.bundleName}}</td>
        <td>{{viewRow.amount}}</td>
        <td>{{viewRow.balance}}</td>
      </tr>
      <tr *ngIf="hasNext" >
        <td colspan="5" class="text-center rounded-3">
          <a (click)="moveNext()" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
