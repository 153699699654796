import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { ApiCompetence, ApiCourseProduct, ApiLearningUnitTeacher,
  ApiCourse, ApiLessonInstance, ApiLearningUnitStudent } from 'src/app/model/rest/rest-model';
import { tap, map } from 'rxjs/operators';
import {ApiStudentSchedule, ApiTeacherWorktime, ApiWorktimeDefinition, SimpleProductAvailability, SimpleScheduleEvents} from "../../model/booking/booking-rest.model";
import {Dates} from "../utils/calendar-utils";

@Injectable({
  providedIn: 'root'
})
export class TeacherBookRestService {
  mock = true;
  mockedWorktimes: Array<ApiTeacherWorktime[]> = [];
  mockedSchedules: Array<ApiStudentSchedule[]> = [];
  mockedWorktimeId = 1;
  mockedScheduleId = 1;

  constructor(private http: HttpClient) {
    for (let dayNb = -14; dayNb < 14 ; dayNb++ ) {
      const scheduleDate = new Date();
      scheduleDate.setTime(scheduleDate.getTime() + dayNb * 1000 * 60 * 60 * 24);
      scheduleDate.setHours(8);
      scheduleDate.setMinutes(0);
      for (let i = 0; i < 3 ; i++ ) {
        scheduleDate.setTime( scheduleDate.getTime() + 1000 * 60 * 90);
        const schedule = new ApiStudentSchedule();
        schedule.competence = new ApiCompetence();
        schedule.competence.code = 'A';
        schedule.course = new ApiCourse();
        schedule.course.code = 'en.st1';
        schedule.course.name = 'Stage 1';
        schedule.course.product = new ApiCourseProduct();
        schedule.course.product.code = 'en';
        schedule.course.product.name = 'English';
        schedule.duration = 1000 * 60 * 30;
        schedule.id = this.mockedScheduleId ++;
        schedule.lesson = new ApiLessonInstance();
        schedule.lesson.id = schedule.id;
        schedule.startTime = new Date(scheduleDate);
        schedule.students = [new ApiLearningUnitStudent()];
        schedule.teacher = new ApiLearningUnitTeacher();
        const weekNumber = Dates.dateToWeekNumber(scheduleDate);
        if (!this.mockedSchedules[weekNumber]) {
          this.mockedSchedules[weekNumber] = [];
        }
        this.mockedSchedules[weekNumber].push(schedule);
      }
    }
  }

  private buildUrl(teacherId: number) {
    return environment.bookingEndpoint + '/v2/teacher/' + teacherId;
  }

  // verified
  public listWorktimeWeekSchedules(teacherId: number, focusDate: Date): Observable<SimpleProductAvailability[]> {
    const url = this.buildUrl(teacherId) + '/availabilities/events';
    let weekStart = Dates.getWeekStartDate(focusDate)
    let mocked = this.mock;
    mocked = false;
    if (!mocked) {
      return this.http.get<SimpleProductAvailability[]>(url, {
        params: {
          dateFrom: weekStart.toISOString(),
          dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        }
      }).pipe(
        tap(schedules => {
          schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
        }),
        map(worktimes => worktimes.map( wt => Object.assign(new SimpleProductAvailability(), wt)))
      );
    }
  }

  // verified
  public listWeekSchedules(teacherId: number, focusDate: Date): Observable<SimpleScheduleEvents[]> {
    const url = this.buildUrl(teacherId) + '/lessons';
    let weekStart = Dates.getWeekStartDate(focusDate)
    let mocked = this.mock;
    mocked = false;
    if (!mocked) {
      return this.http.get<SimpleScheduleEvents[]>(url, {
        params: {
          dateFrom: weekStart.toISOString(),
          dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        }
      }).pipe(
        tap( schedules => {
          schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
        })
      );
    }
  }

  // verified
  public createWorktime(teacherId: number, definition: ApiWorktimeDefinition): Observable<ApiWorktimeDefinition> {
    const url = this.buildUrl(teacherId) + '/worktimes';
    let mocked = this.mock;
    mocked = false;
    if (!mocked) {
      return this.http.put<ApiWorktimeDefinition>(url, definition);
    }

    const worktime = definition.baseWorktime;
    worktime.id = this.mockedWorktimeId++;
    const week = Dates.dateToWeekNumber(worktime.startDate);
    if (!this.mockedWorktimes[week]) {
      this.mockedWorktimes[week] = [];
    }
    this.mockedWorktimes[week].push(worktime);
    return of(definition);
  }

  public deleteWorktime(teacherId: number, worktimeId: number, applyRecurring: boolean): Observable<void> {
    const url = this.buildUrl(teacherId) + '/worktimes/' + worktimeId;

    let mocked = this.mock;
    mocked = false;

    if (!mocked) {
      return this.http.delete<void>(url, {
        params: {
          'applyRecuring': applyRecurring.toString()
        }
      });
    }

    const weekToApply = this.mockedWorktimes
      .find( weekWorktimes => weekWorktimes && weekWorktimes.map(wt => wt.id ).some( id => id === worktimeId));
    const indexOf = weekToApply.map( wt => wt.id).indexOf( worktimeId);
    weekToApply.splice(indexOf, 1);
    return of(null);
  }

}
