<div class="table-responsive-sm">
  <table class="table table-sm">
    <thead>
      <tr class="table-row table-row-head">
        <th>Teacher email</th>
        <th>Lessons</th>
        <th>Duration [mins]</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let row of getData()" class="table-row">
        <td>{{row.teacherEmail}}</td>
        <td>{{row.numberOfLessons}}</td>
        <td>{{calculateDuration(row)}}</td>
      </tr>
      <tr *ngIf="isEmpty()" class="table-row">
        <td colspan="3" class="text-center">No results</td>
      </tr>
      <tr *ngIf="notEmpty()" class="table-row">
        <th>Total:</th>
        <th>{{calculateTotalLessons()}}</th>
        <th>{{calculateTotalDuration()}}</th>
      </tr>
    </tbody>
  </table>
</div>
