import { Component, OnInit } from '@angular/core';
import {AuthServiceImpl, AuthServiceProvider} from 'src/app/services/col2/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.css']
})
export class OpenComponent implements OnInit {

  constructor(
    private authService: AuthServiceProvider,
    activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    activatedRoute.queryParams.subscribe( params => {
      this.openState(params['state']);
    });

  }

  openState(state: string) {
    this.authService.get().subscribe(api => {
      if (api.isTokenValid()) {
        this.router.navigateByUrl(api.resolveState(state));
      } else {
        this.router.navigateByUrl('/login?state=' + encodeURIComponent(state));
      }
    });
  }

  ngOnInit() {
  }


}
