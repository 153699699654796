<div class="card">
  <div class="card-header">
    <h4>Payments</h4>
    <app-paginator class="col-auto ms-auto"
      [data]="studentsFeeRequest"
      [pageable]="pageable"
      (newPage)="switchPage($event)"
    ></app-paginator>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr class="table-row table-row-head">
            <th class="text-nowrap">Year - Quarter</th>
            <th class="text-nowrap">New student</th>
            <th>Renewed</th>
            <th>Blocked</th>
            <th>Unblocked</th>
            <th>Deleted</th>
            <th class="text-nowrap">Pay for</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let fee of studentsFeeRequest?.content" class="table-row">
            <td>{{ fee.qId }}</td>
            <td>{{ fee.newStudents }}</td>
            <td>{{ fee.renewedStudents }}</td>
            <td>{{ fee.blockedStudents }}</td>
            <td>{{ fee.unblockedStudents }}</td>
            <td>{{ fee.deletedStudents }}</td>
            <td>{{ fee.totalStudentsToPayFor }}</td>
            <td>
              <button *ngIf="!isPaid(fee)"
                class="btn btn-primary rounded-pill float-end text-nowrap px-3 py-2"
                (click)="confirmPayment(fee.id)"
              >
                Confirm payment
              </button>
              <div *ngIf="isPaid(fee)" class="float-end">
                <p class="text-nowrap mb-0"><span class="fw-bold">Paid on:</span> {{ fee.confirmation.paymentDate | date: 'dd MMM yyyy' }}</p>
                <p class="text-nowrap mb-0"><span class="fw-bold">Order ID:</span> {{ fee.confirmation.paymentOrderId }}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-modal #confirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Confirm payment</h5>
      <button
        type="button"
        class="btn-close"
        (click)="confirmationModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <form
      #f="ngForm"
      (ngSubmit)="f.form.valid && onConfirmPayment()"
      class="my-2"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input
                type="text"
                name="orderId"
                #orderId="ngModel"
                placeholder="orderId"
                id="orderId"
                [(ngModel)]="paymentConfirmation.paymentOrderId"
                [ngClass]="{ 'is-invalid': f.submitted && orderId.invalid }"
                class="form-control"
                required
              />
              <label for="name" class="field-required">Order ID</label>
              <div
                *ngIf="f.submitted && orderId.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="orderId.errors.required">Field is required</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input
                type="date"
                name="orderDate"
                #orderDate="ngModel"
                placeholder="orderDate"
                id="orderDate"
                [(ngModel)]="paymentConfirmation.paymentDate"
                [ngClass]="{ 'is-invalid': f.submitted && orderDate.invalid }"
                class="form-control"
                required
              />
              <label for="name" class="field-required">Order Date</label>
              <div
                *ngIf="f.submitted && orderDate.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="orderDate.errors.required">Field is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary rounded-pill py-2 px-3 ms-auto me-2"
        >
          Confirm
        </button>
        <button type="reset" class="btn btn-secondary rounded-pill py-2 px-3" (click)="confirmationModal.hide()">Cancel</button>
      </div>
    </form>
  </div>
</app-modal>
