<div class="bg-workspace min-vh-100">
  <top-bar [title]="'News'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <a href="/" routerLink="/school/{{ schoolId }}/dashboard">Home</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a routerLink="../">News</a>
        </li>
        <li class="breadcrumb-item active title-ellipsis" aria-current="page">
          {{ article?.newsItem?.content?.title }}
        </li>
      </ol>
    </nav>
    <div class="d-none d-md-flex mb-md-4">
      <h4>News and Highlights</h4>
    </div>
    <div class="card">
      <div class="card-header pt-4 px-4 px-md-5">
        <button
          class="btn btn-outline-secondary text-primary rounded-pill px-3 py-2"
          routerLink="../"
        >
          <app-icon name="angle-left" class="icon-primary icon-md"></app-icon>
          <span class="text-primary small ms-2">back to home</span>
        </button>
      </div>
      <div class="card-body pt-4 px-4 px-md-5">
        <h3 class="mb-5" [innerHTML]="article?.newsItem?.content?.title"></h3>
        <h4 *ngIf="article?.newsItem?.content?.subtitle" class="mb-4" [innerHTML]="article?.newsItem?.content?.subtitle"></h4>
        <div class="row g-1 mb-4">
          <div class="col-auto">
            <span class="small text-muted me-2">
              {{ getAuthor() }}
            </span>
          </div>
          <div class="col-auto">
            <span class="small text-muted me-2">
              {{ article?.newsItem?.publicationDate | date : "d MMMM y | h:mm" }}
            </span>
          </div>
          <div class="col-auto">
            <span
              *ngFor="let label of getLabels()"
              class="badge badge-outline-secondary fw-lighter text-uppercase ms-1 ms-md-3"
              >{{ label }}</span
            >
          </div>
        </div>
        <div *ngIf="article?.newsItem?.content?.image" class="mb-5 ratio ratio-16x9">
          <img
            [src]="article?.newsItem?.content?.image"
            class="rounded-1 object-fit-contain"/>
        </div>
        <p class="mb-5" [innerHTML]="article?.newsItem?.content?.copy"></p>

        <div class="form-check">
          <input class="form-check-input" [(ngModel)]="articleRead" (ngModelChange)="markAsRead()" type="checkbox" id="item-read" name="item-read">
          <label class="form-check-label small" for="item-read">
            I have read this item; don't show it in notifications
          </label>
        </div>


        <ng-template *ngIf="footerArticles">
          <hr class="mb-5 border" />
          <h4 class="mb-5">Today I'd like to share</h4>
          <div class="row row-cols-1 row-cols-md-2 gy-5 mb-5">
            <app-news-item
              *ngFor="let news of footerArticles"
              [entry]="news"
              [isSmall]="true"
              [schoolId]="schoolId"
              class="col"
            ></app-news-item>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
