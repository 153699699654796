<div class="row">
  <div class="col-12 col-md-7">
    <div class="position-relative">
      <input name="groups-search" type="text" class="form-control p-3 mb-3 mb-md-0" [(ngModel)]="searchQuery.queryTerm" (change)="doSearch()" placeholder="Search">
      <button type="submit" class="btn position-absolute end-0 top-0 bottom-0">
        <app-icon name="search" class="icon-primary"></app-icon>
      </button>
    </div>
  </div>
  <div class="col-12 col-md-5" >
    <select name="groups-sorting" [(ngModel)]="searchQuery.searchType" (change)="doSearch()" class="form-select p-3 mb-3" [ngClass]="{ 'select-not-empty': searchQuery.searchType}">
      <option value="all">Search All</option>
      <option value="group">By group name</option>
      <option value="teacher">By teacher</option>
      <option value="student">By student</option>
      <option value="class">By class</option>
    </select>
  </div>
</div>

<div class="row py-4 border-top border-secondary" *ngIf="hasAnyMatch()">
  <div *ngIf="getGroupsMatched().length">
    <span class="me-2">Matched with groups:</span>
    <a tabindex="" class="me-1 cursor-pointer text-muted"
        (click)="searchForGroup(group)"
        *ngFor="let group of first(getGroupsMatched(),probeSize)"
    >
      <span class="small text-muted me-2">{{getGroupName(group)}}</span>
    </a>
    <span class="me-1" *ngIf="countMore(getGroupsMatched(),probeSize)">and {{countMore(getGroupsMatched(),probeSize)}} more</span>
  </div>

  <div *ngIf="getTeachersMatched().length">
    <span class="me-2">Matched with teachers: </span>
    <div class="d-flex flex-column d-md-inline gap-2">
      <a tabindex="" class="d-flex flex-column d-md-inline-block me-1 cursor-pointer text-muted"
        *ngFor="let teacher of first(getTeachersMatched(),probeSize)"
        (click)="searchTeacherGroup(teacher)"
        >
        <span class="small text-muted me-2">{{getPersonName(teacher)}}</span>
        <span *ngIf="getPersonEmail(teacher)" class="xsmall border border-secondary rounded text-primary py-1 px-2 w-fit-content me-2">
          {{getPersonEmail(teacher)}}
        </span>
      </a>
      <span class="me-1" *ngIf="countMore(getTeachersMatched(),probeSize)">and {{countMore(getTeachersMatched(),probeSize)}} more</span>
    </div>
  </div>

  <div *ngIf="getStudentsMatched().length">
    <span class="me-2">Matched with students: </span>
    <div class="d-flex flex-column d-md-inline gap-2">
      <a tabindex="" class="d-flex flex-column d-md-inline-block me-1 cursor-pointer text-muted"
      *ngFor="let student of first(getStudentsMatched(),probeSize)"
      (click)="searchForStudent(student)"
      >
      <span class="small text-muted me-2">{{getPersonName(student)}}</span>
      <span *ngIf="getPersonEmail(student)" class="xsmall border border-secondary rounded text-primary py-1 px-2 w-fit-content me-2">
        {{getPersonEmail(student)}}
      </span>
    </a>
    <span class="me-1" *ngIf="countMore(getStudentsMatched(),probeSize)">and {{countMore(getStudentsMatched(),probeSize)}} more</span>
    </div>
  </div>

  <div *ngIf="getClassesMatched().length">
    <span class="me-2">Matched with classes: </span>
    <a tabindex="" class="me-1 cursor-pointer text-muted"
      *ngFor="let className of first(getClassesMatched(),probeSize)"
      (click)="searchForClass(className)"
      >
      <span class="small text-muted me-2">{{className}}</span>
    </a>
    <span class="me-1" *ngIf="countMore(getClassesMatched(),1)">and {{countMore(getClassesMatched(),probeSize)}} more</span>
  </div>

</div>

<div class="alert bg-warning fade show alert-conflicts" role="alert" *ngIf="(hasConflicts() && !isPlainTextDisplay()) || (hasConflicts() && plainTextDisplayAvailable())">
  <button type="button" class="btn-close btn-close-white float-end" data-bs-dismiss="alert" aria-label="Close"></button>
  <div class="d-flex flex-column gap-2" *ngIf="conflicts.byTeacher.length > 0">
    <p class="text-white mb-2">Teachers have conflicts: </p>
    <a tabindex="" class="cursor-pointer"
      *ngFor="let teacher of conflicts.conflictedTeacherEmails" (click)="searchTeacherMail(teacher)">
      <span class="small text-white me-2">{{teacher}}</span>
    </a>
  </div>
  <div class="d-flex flex-column gap-2" *ngIf="conflicts.byClass.length > 0">
    <p class="text-white mb-2">Classes have conflicts: </p>
    <a tabindex="" class="cursor-pointer"
      *ngFor="let room of conflicts.conflictedClassRooms" (click)="searchClassroomName(room)">
      <span class="small text-white me-2">{{room}}</span>
    </a>
  </div>
</div>

<p class="text-muted text-center pt-3 mb-0" *ngIf="dataIsEmpty()">
  No results
</p>

<p class="text-muted text-center pt-3 mb-0" *ngIf="isPlainTextDisplay() && !plainTextDisplayAvailable()">
  No teacher specified
</p>
<div *ngIf="!dataIsEmpty() && isPlainTextDisplay() && plainTextDisplayAvailable()" class="border-top border-2 border-secondary">
  <div *ngFor="let day of toShow.daysSorted" class="row gx-0">
    <div class="col-5 col-sm-4 d-flex border-bottom border-1 border-secondary">
      <div class="my-auto px-sm-3">
        <p class="small mb-0">{{day | date: "dd MMM"}}</p>
        <p class="text-muted xsmall mb-0">{{day | date: "EEEE"}}</p>
      </div>
    </div>
    <div class="col-7 d-flex flex-column border-bottom border-1 border-secondary gap-2 py-3 p-sm-3 ms-auto">
      <div *ngFor="let schedule of getCalendarDisplayViewDay(day)">
        <a [ngClass]="getBadgeColorClassByStatus(schedule)"
            class="badge rounded px-2 py-1 text-white w-fit-content"
           data-bs-toggle="tooltip"
           [title]="getTooltipContent(schedule)"
          >
          <span class="xsmall text-white">
            {{schedule.schedule.details.startDate | date: 'HH:mm'}}
          </span>
          <span *ngIf="getEndDate(schedule)"
            class="xsmall text-white">
            - {{getEndDate(schedule) | date: "HH:mm"}}
          </span>
        </a>
        <p class="text-muted mb-0 xsmall">
          {{getGroupName(schedule.template)}}
          {{getPlace(schedule)}}
          <app-icon name="school" *ngIf="isOffline(schedule)" class="icon-secondary icon-xs"></app-icon>
        </p>
      </div>
    </div>
  </div>
  <div class="text-end mt-4">
    <a class="btn btn-primary rounded-pill py-2 px-3" [href]="preparePlainViewEmailLink()">Open as email</a>
  </div>
</div>
<app-schedules-week-summary-calendar
  *ngIf="!dataIsEmpty() && isCalendarDisplay()"
  [schoolId]="schoolId"
  [getWeekStartDate]="getWeekStartDate"
  [conflictedSchedules]="conflictedSchedules"
  [toShow]=" toShow"
></app-schedules-week-summary-calendar>
<div class="table-responsive" *ngIf="!dataIsEmpty() && isTableDisplay()">
  <table class="table table-sm table-bordered">
    <thead class="text-center border-bottom border-2 border-secondary">
      <tr>
        <th (click)="sort(null)" class="cursor-pointer"><span *ngIf="searchQuery.sortBy == null">
          <!-- <i class="fa fa-sort-down"></i>  -->
        </span></th>
        <th *ngFor="let dayDate of toShow.daysSorted"
            (click)="sort(dayDate)" class="cursor-pointer"
        >
          <span class="xsmall">{{dayDate | date: "EEE"}}</span>
          <span *ngIf="searchQuery.sortBy === dayDate"><i class="fa fa-sort-down"></i> </span><br>
          <span class="small text-muted fw-normal">{{dayDate | date: "d"}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of getTableViewGroupsSorted()" class="border-bottom border-secondary">
        <td class="p-3">
          <div class="d-block">
            <p class="mb-0 text-muted xsmall">Group</p>
            <a class="small text-black" routerLink="/school/{{schoolId}}/groups/{{group.id}}">
              {{getGroupName(group)}}
            </a>
          </div>
        </td>
        <td *ngFor="let dayDate of toShow.daysSorted"
            [ngClass]="{'table-active' : searchQuery.sortBy === dayDate }"
            class="border-start border-secondary p-3">
            <div *ngFor="let schedule of getGroupDaySchedules(group, dayDate)" class="d-flex flex-column gap-1 class">
              <a [ngClass]="getBadgeColorClassByStatus(schedule)"
                 class="badge rounded px-2 py-1 text-white w-fit-content"
                 routerLink="/school/{{schoolId}}/schedules/{{schedule.schedule.id}}"
                 data-bs-toggle="tooltip"
                 [title]="getTooltipContent(schedule)"
                >
                <span class="text-white">
                  {{schedule.schedule.details.startDate | date: 'HH:mm'}}
                </span>
                <span class="text-white" *ngIf="getEndDate(schedule)">
                  - {{getEndDate(schedule) | date: "HH:mm"}}
                </span>
              </a>
              <p class="text-muted mb-0 xsmall">
                {{getPlace(schedule)}}
                <app-icon name="school" *ngIf="hasAnyOffline(schedule)" class="icon-secondary icon-xs"></app-icon>
              </p>
            </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
