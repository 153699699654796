import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiCountry } from 'src/app/model/rest/rest-model';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicRestService {

  constructor(private http: HttpClient) { }

  private baseUrl = environment.apiEndpoint + '/public';


  public listCountries(locale?: string): Observable<ApiCountry[]> {

    const url = this.baseUrl + '/countries';

    let params = new HttpParams();
    if (locale) {
      params = params.append('locale', locale);
    }

    return this.http.get<ApiCountry[]>(url, {params : params}).pipe(
    );
  }
}
