import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {
  DetailedGroup,
  GroupDetails, GroupParticipant,
  GroupReference,
  GroupSimple,
  ParticipantBaseDetails,
  ParticipantDetails
} from "../../model/group";
import {StudentBase, TeacherBase} from "../../model/rest/casa-apiv2.model";
import {Page, Pageable} from "../../model/rest/base";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

export interface GroupRestService {
  listSchoolGroups(schoolId: number, pageable: Pageable): Observable<Page<GroupSimple>>
  getGroupDetails(schoolId: number, groupId: number): Observable<DetailedGroup>
  createNewGroup(schoolId: number, details: GroupDetails): Observable<GroupReference>
  updateGroup(schoolId: number, groupId: number, details: GroupDetails)
  addGroupStudent(
    schoolId: number,
    groupId: number,
    details: ParticipantDetails<StudentBase>
  ): Observable<GroupParticipant<StudentBase>>
  addGroupTeacher(
    schoolId: number,
    groupId: number,
    details: ParticipantDetails<TeacherBase>
  ): Observable<GroupParticipant<TeacherBase>>
  updateParticipantDetails(
    schoolId: number,
    groupId: number,
    participantId: number,
    details: ParticipantBaseDetails
  )
  deleteParticipant(
    schoolId: number,
    groupId: number,
    participantId: number
  ): Observable<Boolean>
  deleteGroup(
    schoolId: number,
    groupId: number
  ):Observable<Boolean>
}
@Injectable({
  providedIn: 'root'
})
export class GroupRestServiceImpl implements GroupRestService {

  constructor(private http: HttpClient) { }

  private url(schoolId: number, path: string = "") {
    return `${environment.casaEndpoint}/api/v2/schools/${schoolId}/groups${path}`
  }

  addGroupStudent(schoolId: number, groupId: number, details: ParticipantDetails<StudentBase>): Observable<GroupParticipant<StudentBase>> {
    let params = new HttpParams();
    params = params.append('type', 'student')
    return this.http.post<GroupParticipant<StudentBase>>(this.url(schoolId, `/${groupId}/participants`), details, {params});
  }

  addGroupTeacher(schoolId: number, groupId: number, details: ParticipantDetails<TeacherBase>): Observable<GroupParticipant<TeacherBase>> {
    let params = new HttpParams()
    params = params.append('type', 'teacher')
    return this.http.post<GroupParticipant<TeacherBase>>(this.url(schoolId, `/${groupId}/participants`), details, {params});
  }

  createNewGroup(schoolId: number, details: GroupDetails): Observable<GroupReference> {
    return this.http.post<GroupReference>(this.url(schoolId), details);
  }
  deleteGroup(schoolId: number, groupId: number): Observable<Boolean> {
    return this.http.delete<Boolean>(this.url(schoolId, `/${groupId}`));
  }

  deleteParticipant(schoolId: number, groupId: number, participantId: number): Observable<Boolean> {
    return this.http.delete<Boolean>(this.url(schoolId, `/${groupId}/participants/${participantId}`));
  }

  getGroupDetails(schoolId: number, groupId: number): Observable<DetailedGroup> {
    return this.http.get<DetailedGroup>(this.url(schoolId, `/${groupId}`));
  }

  listSchoolGroups(schoolId: number, pageable: Pageable): Observable<Page<GroupSimple>> {
    let params = Pageable.appedPageableParams(new HttpParams(), pageable)
    return this.http.get<Page<GroupSimple>>(this.url(schoolId), {params});
  }

  updateGroup(schoolId: number, groupId: number, details: GroupDetails) {
    return this.http.patch(this.url(schoolId, `/${groupId}`), details);
  }

  updateParticipantDetails(schoolId: number, groupId: number, participantId: number, details: ParticipantBaseDetails) {
    return this.http.patch(this.url(schoolId, `/${groupId}/participants/${participantId}`), details);
  }
}
