
<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Reports'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page">Reports</li>
        <li class="breadcrumb-item active" aria-current="page">Teacher times</li>
      </ol>
    </nav>

    <div class="card mb-4" *ngIf="query">
      <div class="card-body">
        <form class="mb-3">
          <div class="row">
            <div class="col-12 col-sm mb-2">
              <div class="form-floating">
                <select name="report-month" (change)="onTimeChange()" [(ngModel)]="query.month" class="form-select"
                [ngClass]="{ 'select-not-empty': query.month != null}">
                  <option value="0">This month</option>
                  <option value="-1">Last month</option>
                  <option value="-2">2 months ago</option>
                  <option value="-3">3 months ago</option>
                  <option value="-6">6 months ago</option>
                  <option value="-12">12 months ago</option>
                </select>
                <label>Start</label>
              </div>
            </div>
            <div class="col mb-2">
              <div class="form-floating">
                <select name="report-duration" (change)="onTimeChange()" [(ngModel)]="query.duration" class="form-select"
                [ngClass]="{ 'select-not-empty': query.duration}">
                  <option value="1">for 1 month</option>
                  <option value="2">for 2 months</option>
                  <option value="3">for 3 months</option>
                  <option value="6">for 6 months</option>
                  <option value="12">for 12 months</option>
                </select>
                <label>Duration</label>
              </div>
            </div>
          </div>
        </form>

        <app-teacher-attendance-report [data]="reportData"></app-teacher-attendance-report>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-header row gap-3 gap-sm-0" *ngIf="roomsQuery">
        <div class="col-12 col-sm-7"><h4 class="mb-0">Teachers' lessons <span class="text-primary">( {{getRoomsCount()}} )</span></h4></div>
        <div class="col-12 col-sm-5 col-lg-4 ms-auto">
          <select class="form-select" (change)="onTeacherChange()" name="group-id" [(ngModel)]="roomsQuery.teacherMail"
          [ngClass]="{ 'select-not-empty': roomsQuery.teacherMail }">
            <option [ngValue]="null" >All Teachers</option>
            <option *ngFor="let teacher of teachers" [value]="teacher">
              {{teacher}}
            </option>
          </select>
        </div>

        <div class="col-auto ms-auto mt-sm-3" *ngIf="hasRoomsPrev() || hasRoomsNext()">
          <div class="btn-group-sm btn-group">
            <button class="btn btn-outline-secondary rounded-pill rounded-end" type="button" (click)="prevRoomsPage()" [disabled]="!hasRoomsPrev()">
              <app-icon name="angle-left" class="icon-secondary"></app-icon>
              </button>
            <button class="btn btn-outline-secondary px-3" type="button" (click)="firstRoomsPage()"> {{roomsPageNumber()}} </button>
            <button class="btn btn-outline-secondary rounded-pill rounded-start" type="button" (click)="nextRoomsPage()" [disabled]="!hasRoomsNext()">
              <app-icon name="angle-right" class="icon-secondary"></app-icon>
              </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <app-rooms-list [data]="currentRoomsPage" (details)="openRoomDetails($event)"></app-rooms-list>
      </div>
    </div>
  </div>
</div>
