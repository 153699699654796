<div class="card mb-5">
  <div class="card-header border-bottom border-secondary pb-4">
    <h5 class="mb-0">Credits & Progress</h5>
  </div>
  <div class="card-body">
    <div class="row mb-4">
      <div class="col-12">
        <div class="form-floating">
          <select class="form-select" [(ngModel)]="lang"
            [ngClass]="{ 'select-not-empty': lang }">
            <option value="en">English</option>
            <option value="sp">Spanish</option>
            <option value="en.ch">Kids</option>
            <option value="en.bs">Business</option>
          </select>
          <label>Select course</label>
        </div>
      </div>
    </div>
    <app-student-context-based-progress [schoolId]="schoolId" [studentId]="studentId" [langCode]="lang"></app-student-context-based-progress>
    <app-student-credits [schoolId]="schoolId" [studentId]="studentId" [langCode]="lang"></app-student-credits>
  </div>
</div>
<div class="card mb-5" >
  <div class="card-header border-bottom border-secondary pb-4">
    <div class="row gap-2 gap-sm-0 w-100">
      <div class="col-12 col-sm-auto">
        <h5 class="mb-0">
          All future Lessons <span class="text-muted" *ngIf="futureLessonsPage">( {{futureLessonsPage.totalElements}} )</span>
        </h5>
      </div>
      <app-paginator class="col-auto ms-auto"
                      [data]="futureLessonsPage"
                      [pageable]="futureLessonsPageable"
                      (newPage)="futureLessons.switchPage($event)"
      ></app-paginator>
    </div>
    </div>
  <div class="card-body">
    <app-student-lessons #futureLessons
      [lessonType]='lessonTypes.Upcomming'
      [studentId]="studentId"
      [schoolId]="schoolId"
      (dataLoaded)="futureLessonsPageLoaded($event)"
      (currentPageable)="futureLessonsPageable = $event"
    ></app-student-lessons>
  </div>
</div>

<div class="card mb-5" >
  <div class="card-header border-bottom border-secondary pb-4">
    <div class="row gap-2 gap-sm-0 w-100">
      <div class="col-12 col-sm-auto">
        <h5 class="mb-0">
          All past Lessons <span class="text-muted" *ngIf="pastLessonsPage">( {{pastLessonsPage.totalElements}} )</span>
        </h5>
      </div>
      <app-paginator class="col-auto ms-auto"
                      [data]="pastLessonsPage"
                      [pageable]="pastLessonsPageable"
                      (newPage)="pastLessons.switchPage($event)"
      ></app-paginator>
    </div>
  </div>
  <div class="card-body">
    <app-student-lessons
      #pastLessons
      [lessonType]='lessonTypes.Past'
      [studentId]="studentId"
      [schoolId]="schoolId"
      (dataLoaded)="pastLessonsPage = $event"
      (currentPageable)="pastLessonsPageable = $event"
    ></app-student-lessons>
  </div>
</div>
