<div class="card mb-5">
  <div class="card-header flex-column border-bottom border-secondary border-2 pb-4">
    <h4>{{getRoomName()}} video classroom</h4>
    <span class="text-muted xsmall" *ngIf="getStartPlannedDate()">planned to start {{getStartPlannedDate() | date: "dd MMM yyyy HH:mm"}}</span>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm mb-4">
        <thead>
          <tr class="table-row table-row-head">
            <th>Name</th>
            <th class="text-center">Attendance</th>
            <th>Copy Link</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngIf="teacherParti" class="table-row">
            <td>
              <strong>{{getTeacherName()}}</strong>
            </td>
            <td class="text-center">
              <app-icon name="check" class="me-2 icon-success" *ngIf="wasAttended(teacherParti)"></app-icon>
              <p class="mb-0" *ngIf="!wasAttended(teacherParti)">-</p>
            </td>
            <td class="text-end d-flex gap-2 gap-lg-3 gap-xl-4">
              <input type="text" readonly="readonly" class="form-control pe-none bg-secondary text-white px-3 link-width" [value]="createLink(teacherParti)">
              <div class="btn btn-outline-secondary-2 rounded-circle p-2"
                  title="Copy the invitation link for the participant."
                  data-bs-toggle="tooltip" (click)="copyToClipboard(createLink(teacherParti))">
                <button *ngIf="teacherParti.uuid" type="button" class="btn btn-primary rounded-circle p-1 pe-none position-relative">
                  <app-icon name="copy" class="icon-white icon-sm"></app-icon>
                  <span *ngIf="isShowLinkCopied(createLink(teacherParti))" class="copy-label badge bg-secondary">Copied</span>
                </button>
              </div>
            </td>
          </tr>
          <tr *ngFor="let student of studentsPartis" class="table-row">
            <td>
              {{student.name}}
            </td>
            <td class="text-center">
              <app-icon name="check" class="me-2 icon-success" *ngIf="wasAttended(student)"></app-icon>
              <p class="mb-0" *ngIf="!wasAttended(student)">-</p>
            </td>
            <td class="text-end d-flex gap-2 gap-lg-3 gap-xl-4">
              <input type="text" readonly="readonly" class="form-control pe-none bg-secondary text-white px-3 link-width" [value]="createLink(student)">
              <div class="btn btn-outline-secondary-2 rounded-circle p-2"
                  title="Copy the invitation link for the participant."
                  data-bs-toggle="tooltip" (click)="copyToClipboard(createLink(student))">
                <button type="button" class="btn btn-primary rounded-circle p-1 pe-none position-relative">
                  <app-icon name="copy" class="icon-white icon-sm"></app-icon>
                  <span *ngIf="isShowLinkCopied(createLink(student))" class="copy-label badge bg-secondary">Copied</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-header pt-5" *ngIf="hasAnyProgress()">
    <h5>Progress reported by the teacher for the lesson</h5>
  </div>
  <div class="card-body" *ngIf="hasAnyProgress()">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="table-row table-row-head">
            <th>Para</th>
            <th>Last Hw</th>
            <th>Reading</th>
            <th>Dict</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="table-row">
            <td>
              <span *ngIf="progress.details.paragraph">{{progress.details.paragraph}}</span>
              <span *ngIf="!progress.details.paragraph">-</span>
            </td>
            <td>
              <span *ngIf="progress.details.lastHw">{{progress.details.lastHw}}</span>
              <span *ngIf="!progress.details.lastHw">-</span>
            </td>
            <td>
              <span *ngIf="progress.details.reading">{{progress.details.reading}}</span>
              <span *ngIf="!progress.details.reading">-</span>
            </td>
            <td>
              <span *ngIf="progress.details.dictation">{{progress.details.dictation}}</span>
              <span *ngIf="!progress.details.dictation">-</span>
            </td>
            <td>
              <span *ngIf="progress.details.comment">{{progress.details.comment}}</span>
              <span *ngIf="!progress.details.comment">-</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4">
        <div class="card card-border border-secondary h-100">
          <div class="card-header" *ngIf="room">
            <h5>Reported timings</h5>
          </div>
          <div class="card-body" *ngIf="room">
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary">
              <span class="small mb-0">Classroom created:</span>
              <span class="small text-muted mb-0">{{getCreateDate() | date: "dd MMM yyyy HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getStartPlannedDate()">
              <span class="small mb-0">Planned to start:</span>
              <span class="small text-muted mb-0">{{getStartPlannedDate() | date: "dd MMM yyyy HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getLessonStartDate()">
              <span class="small mb-0">Lesson was started:</span>
              <span class="small text-muted mb-0">{{getLessonStartDate() | date: "dd MMM yyyy HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary">
              <span class="small mb-0">Total timer count:</span>
              <span class="small text-muted mb-0">{{getTotalTime()}} mins</span>
            </div>
              <!--<dt *ngIf="getTimerStartDate()" class="col-sm-5">Timer was started at (but it wasn't stopped yet):</dt>
              <dd *ngIf="getTimerStartDate()" class="col-sm-7">{{getTimerStartDate() | date: "dd MMM yyyy HH:mm"}}</dd> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 mb-4">
        <div class="card card-border border-secondary h-100">
          <div class="card-header" *ngIf="room && room.provisioning">
            <h5>System details</h5>
          </div>
          <div class="card-body" *ngIf="room && room.provisioning">
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getPCreated()">
              <p class="small mb-0">First participant connected:</p>
              <p class="small text-muted mb-0">{{getPCreated() | date: "dd MMM yyyy HH:mm"}}</p>
            </div>
            <!--<dt *ngIf="getPUpdated()" class="col-sm-3">Updated:</dt>
            <dd *ngIf="getPUpdated()" class="col-sm-9">{{getPUpdated() | date: "dd MMM yyyy HH:mm"}}</dd>-->
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getPEndTime()">
              <p class="small mb-0">Classroom closed:</p>
              <p class="small text-muted mb-0">{{getPEndTime() | date: "dd MMM yyyy HH:mm"}}</p>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getPDuration()">
              <p class="small mb-0">Video connection duration:</p>
              <p class="small text-muted mb-0">{{getPDuration()}} mins</p>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary">
              <p class="small mb-0">Status:</p>
              <p class="small text-muted mb-0">{{getPCStatus()}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-content select="[bottom-section]"></ng-content>
</div>




