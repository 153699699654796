import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {
  ApiCourse,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonBundle,
  ApiLessonBundleUpdate,
  ApiLessonCommit,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonMetric,
  ApiLessonProgress,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonalProfileBaseWithPhoto,
  ApiPersonTechnicalProfile,
  ApiProductContext,
  ApiTeacherLessonInstance,
  ApiTeacherProductCompetences,
  ApiTeacherProfile,
  LessonBundeFilter,
  SchoolPeopleQueryFilter,
  StudentCommitsFilter
} from 'src/app/model/rest/rest-model';
import {Page, Pageable} from 'src/app/model/rest/base';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Dates} from "../utils/calendar-utils";

@Injectable({
  providedIn: 'root'
})
export class ManagerRestService {


  constructor( private http: HttpClient) {}

  prepareUrl( schoolId: number) {
    return environment.apiEndpoint + '/school/' + schoolId;
  }

  public listStudents(schoolId: number, filter: SchoolPeopleQueryFilter, pageable: Pageable) {
    const url = this.prepareUrl(schoolId) + '/students';
    if (!filter) {
      filter = new SchoolPeopleQueryFilter();
    }

    return this.http.get<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>>(url,
      {
        params: filter.apply(Pageable.appedPageableParams(new HttpParams(), pageable))
      }).pipe(
        tap( studentsPage =>
          studentsPage.content = studentsPage.content.map(student => Object.assign(new ApiLearningUnitStudent(), student)))
      );
  }

  public listTeachers(schoolId: number, filter: SchoolPeopleQueryFilter, pageable: Pageable) {
    const url = this.prepareUrl(schoolId) + '/teachers';
    if (!filter) {
      filter = new SchoolPeopleQueryFilter();
    }

    return this.http.get<Page<ApiTeacherProfile>>(url,
      {
        params: filter.apply(Pageable.appedPageableParams(new HttpParams(), pageable))
      }).pipe(
        tap( teachersPage =>
          teachersPage.content = teachersPage.content.map(teacherProfile => Object.assign(new ApiTeacherProfile(), teacherProfile)))
      );
  }

  public findStudentLessonBundles(schoolId: number, studentId: number, filter: LessonBundeFilter, pageable: Pageable) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/bundles';

    return this.http.get<Page<ApiLessonBundle>>(url, {
      params: filter.apply(Pageable.appedPageableParams( new HttpParams(), pageable))
    });
  }

  public updateStudentLessonBundle(schoolId: number, studentId: number, bundleId: number, updateDetails: ApiLessonBundleUpdate) {
    const url = `${this.prepareStudentUrl(schoolId, studentId)}/bundles/${bundleId}`;
    return this.http.put<any>(url, updateDetails);
  }

  public findStudentCommits(schoolId: number, studentId: number, filter: StudentCommitsFilter, pageable: Pageable) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/lessons/commits';
    return this.http.get<Page<ApiLessonCommit>>(url, {
      params: filter.apply(Pageable.appedPageableParams(new HttpParams(), pageable))
    });
  }

  public saveLessonBundle(schoolId: number, studentId: number, bundle: ApiLessonBundle) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/bundles';

    return this.http.put<ApiLessonBundle>( url, bundle);
  }

  public getStudent(schoolId: number, studentId: number) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId ;

    return this.http.get<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>(url);
  }

  public getTeacher(schoolId: number, teacherId: number) {
    const url = this.prepareUrl(schoolId) + '/teachers/' + teacherId;
    return this.http.get<ApiTeacherProfile>(url);
  }

  public listTeacherAllowances(schoolId: number, teacherId: number) {
    const url = this.prepareTeacherUrl(schoolId, teacherId) + '/allowance';
    return this.http.get<string[]>(url);
  }

  public saveTeacherPersonalProfile(schoolId: number,
    teacherId: number, profile: ApiPersonalProfileBaseWithPhoto) {
      const url = this.prepareTeacherUrl(schoolId, teacherId) + '/person/profile';
      return this.http.put<ApiPersonalProfile>(url, profile);
    }

  public saveTeacherProfile(
    school: number,
    teacherId: number,
    profile: ApiTeacherProfile
  )  {
    const url = this.prepareTeacherUrl(school, teacherId) + '/profile';
    return this.http.put<ApiTeacherProfile>(url, profile);
  }

  public saveTeacherCompetences(
    schoolId: number,
    teacherId: number,
    competences: ApiTeacherProductCompetences) {
      const url = this.prepareTeacherUrl(schoolId, teacherId) + '/profile/competences';
      return this.http.patch<ApiTeacherProductCompetences>(url, competences);
    }

  public findStudentUpcomingLessons(schoolId: number, studentId: number, pageable: Pageable) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/lessons/upcoming';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    }).pipe(
      tap( lessonsPage =>
        lessonsPage.content.forEach(
          lesson => lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate))
        )
      )
    );
  }

  public findStudentPastLessons(schoolId: number, studentId: number, pageable: Pageable) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/lessons/past';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    }).pipe(
      tap( lessonsPage =>
        lessonsPage.content.forEach(
          lesson => {
            lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
            lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
          }
        )
      )
    );
  }

  listTeacherIncompleteLessons(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.prepareUrl(schoolId) + '/teachers/' + teacherId + '/lessons/incomplete';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    });
  }

  listTeacherPastLessons(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.prepareUrl(schoolId) + '/teachers/' + teacherId + '/lessons/past';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    });
  }

  listTeacherUpcomingLessons(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.prepareUrl(schoolId) + '/teachers/' + teacherId + '/lessons/upcoming';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
      params: Pageable.appedPageableParams(new HttpParams(), pageable)
    });
  }

  fixLessonDates(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher> {
    lesson.lessonMetric.started = Dates.parse(String(lesson.lessonMetric.started));
    lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate));
    return lesson;
  }

  getLessonDetails(schoolId: number, lessonId: number) {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId;

    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url).pipe(map(this.fixLessonDates));
  }

  public updateLessonProgress(schoolId: number, lessonId: number, progress: ApiLessonProgress):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/progress';

    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, progress);
  }

  public bookLesson(schoolId: number, lessonId: number):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
      const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/status/booked';

      return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
    }

  public commitLessonBooking(schoolId: number, lessonId: number):
  Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/status/due';

    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {});
  }

  public startLesson(schoolId: number, lessonId: number, startDate: Date):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/status/start';
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', startDate.toISOString());
    }
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, {}, { params: params });
  }

  public cancelLesson(schoolId: number, lessonId: number, reason: string):
  Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/status/cancel';

    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, reason);
  }

  public finishLesson(schoolId: number, lessonId: number, progress: ApiLessonProgress, finishDate: Date):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/status/finish';
    let params = new HttpParams();
    if (finishDate) {
      params = params.append('finishDate', finishDate.toISOString());
    }
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, progress, { params: params });
  }

  public registerLessonFlag(schoolId: number, lessonId: number, lessonFlag: ApiLessonFlag):
    Observable<ApiLessonFlag> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/flags';

    return this.http.post<ApiLessonFlag>(url, lessonFlag);
  }

  public squanderLesson(schoolId: number, lessonId: number, reason: string):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/status/squander';

    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, reason);
  }

  public postLessonComment(schoolId: number, lessonId: number, message: ApiLessonMessage):
    Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/messages';

    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, message);
  }

  public postLessonMessageForStudent(schoolId: number, lesssonId: number, studentId: number, message: string):
    Observable<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lesssonId + '/students/' + studentId + '/messages';

    return this.http.post<ApiTeacherLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, message);
  }

  public updateLessonType(schoolId: number, lessonId: number, lessonType: string ) {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/type';

    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, lessonType);
  }

  public getStudentProductContext(schoolId: number,  studentId: number, productCode: string) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/product-context/' + productCode;

    return this.http.get<ApiProductContext>(url);
  }

  public updateLessonMetric(schoolId: number, lessonId: number, metric: ApiLessonMetric):
  Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/metric';

    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(url, metric);
  }

  public listProductCourses(schoolId: number, productCode: string) {
    const url = this.prepareUrl(schoolId) + '/product/' + productCode + '/courses';

    return this.http.get<ApiCourse[]>(url);
  }

  public getStudentProgress(schoolId: number,
    studentId: number) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/progress';

    return this.http.get<ApiLessonProgress[]>(url);
  }

  public getStudentProgressNextEstimation(schoolId: number,
    studentId: number,
    baseProgress: ApiLessonProgress,
    limit?: number) {
      const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/progress/estimate';
      let params = new HttpParams();
      if (limit) {
        params = params.append('limit', limit.toString());
      }
      return this.http.post<ApiLessonProgress[]>(url, baseProgress, {params: params});
    }

  public saveStudentProductContext(
    schoolId: number,
    studentId: number,
    productCode: string,
    context: ApiProductContext) {
    const url = this.prepareUrl(schoolId) + '/students/' + studentId + '/product-context/' + productCode;

    return this.http.put<ApiProductContext>(url, context);
  }

  public listLessonHistory(
    schoolId: number,
    lessonId: number,
    studentId: number,
    pageable?: Pageable
  ): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    const url = this.prepareUrl(schoolId) + '/lessons/' + lessonId + '/students/' + studentId + '/history';

    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {params: Pageable.appedPageableParams(new HttpParams(), pageable)});
  }

  public getStudentPerson(schoolId: number, studentId: number) {
    const url = this.prepareStudentUrl(schoolId, studentId) + '/person';

    return this.http.get<ApiPerson<ApiPersonalProfile>>(url);
  }

  public saveStudentPersonalProfile(schoolId: number, studentId: number, profile: ApiPersonalProfile) {
    const url = this.prepareStudentUrl(schoolId, studentId) + '/person/profile';

    return this.http.put<ApiPersonalProfile>(url, profile);
  }

  public getStudentTechnicalProfile(schoolId: number, studentId: number) {
    const url = this.prepareStudentUrl(schoolId, studentId) + '/person/technical-profile';

    return this.http.get<ApiPersonTechnicalProfile>(url);
  }

  public saveStudentTechnicalProfile(schoolId: number, studentId: number, profile: ApiPersonTechnicalProfile) {
    const url = this.prepareStudentUrl(schoolId, studentId) + '/person/technical-profile';

    return this.http.put<ApiPersonTechnicalProfile>(url, profile);
  }

  prepareTeacherUrl(schoolId: number, teacherId: number) {
    return this.prepareUrl(schoolId) + '/teachers/' + teacherId ;
  }

  prepareStudentUrl(schoolId: number, studentId: number) {
    return this.prepareUrl(schoolId) + '/students/' + studentId;
  }
}
