<div class="container-fluid bg-watermark bg-workspace my-0">
  <div class="container d-flex flex-column justify-content-around mt-0 pt-5 vh-100 position-relative">
      <div>
          <div class="row justify-content-center mb-5">
            <div class="col-6 col-md-4 mx-auto text-center">
              <app-icon name="logo" class="mb-3 icon-black"></app-icon>
            </div>
          </div>
          <div class="row text-center">
              <span class="fs-5">Oops!</span>
              <span class="text-danger fs-4 mb-2">404 - PAGE NOT FOUND</span>
              <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto">
                <p class="mb-5">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
                <a routerLink="/" class="btn btn-primary btn-lg w-100 text-uppercase mb-3 fs-6">
                  Take me home
                </a>
                <button *ngIf="isAuthorized() | async" type="button" class="btn btn-secondary btn-lg w-100 text-uppercase fs-6"
                (click)="logout()">
                Logout
                </button>
              </div>
          </div>
      </div>
      <div class="row text-center">
          <span class="text-muted small">
              © 2021 Callan. All rights reserved.
          </span>
      </div>
  </div>
</div>

