
<div class="btn-group-sm btn-group" *ngIf="(hasNext() || hasPrev())">
  <button class="btn btn-outline-secondary rounded-pill rounded-end" type="button" (click)="prev()" [disabled]="!hasPrev()">
    <app-icon name="angle-left" class="icon-primary icon-md"></app-icon>
    </button>
  <button class="btn btn-outline-secondary px-3" type="button" (click)="first()"> {{getPage()}} </button>
  <button class="btn btn-outline-secondary rounded-pill rounded-start" type="button" (click)="next()" [disabled]="!hasNext()">
    <app-icon name="angle-right" class="icon-primary icon-md"></app-icon>
    </button>
</div>
