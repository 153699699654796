import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild} from '@angular/core';
import {ScheduleRestService} from "../../services/rest/schedule-rest.service";
import {ScheduleDefinitionDetails} from "../../model/schedule";
import {DateUtils} from "../../services/helpers/date-utils";
import {ModalComponent} from "../modal/modal.component";
import {VideoServerRestService} from "../../services/rest/video-server-rest.service";
import {IdentifiedRoomTemplate, RoomTemplateBase, RoomTemplateDetailsBase} from "../../model/server";
import {NgForm} from "@angular/forms";


class CreateScheduleForm {
  dateFrom: string;
  dateTo: string;
  durationMin: number;
  recurring: {[recurringType: string]: boolean} = {};
}

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.css']
})
export class CreateScheduleComponent implements OnInit {
  @ViewChild('dialog', {static: true})
  dialog: ModalComponent;
  @ViewChild('f', {static: true})
  form: NgForm;
  _schoolId: number
  @Input() set schoolId(schoolId: number) {
    this._schoolId = schoolId
    this.rest.listTemplates(this.schoolId, null, null).subscribe(groups =>
      this.allGroups = groups.content)
  }
  get schoolId() {
    return this._schoolId
  }
  @Input()
  templateId?: number;
  @Input()
  isGroupDefault: boolean

  @Output()
  finish = new EventEmitter<any>()

  formInput = new CreateScheduleForm();
  serverReportedErrors: any = null;

  searchTerm = ""
  selectedGroup: IdentifiedRoomTemplate
  matchedGroups: IdentifiedRoomTemplate[]
  allGroups: IdentifiedRoomTemplate[]

  constructor(
    private scheduleRest: ScheduleRestService,
    @Inject(LOCALE_ID) private locale: string,
    private rest: VideoServerRestService,
  ) { }

  ngOnInit() {}

  matchGroups() {
      setTimeout(() => {
        this.matchedGroups = !this.searchTerm?
          [] :
          this.matchedGroups = this.allGroups.filter(group => group.details.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      }, 100)
  }

  onGroupSelection(group: IdentifiedRoomTemplate) {
    this.selectedGroup = group
    this.searchTerm = group.details.name
  }

  getGroupName(group: RoomTemplateBase<RoomTemplateDetailsBase>) {
    return group.details.name;
  }

  show() {
    this.dialog.show(true)
  }

  onSubmit() {
    const def = new ScheduleDefinitionDetails();
    def.startDate = Date.parse(this.formInput.dateFrom);
    def.cycleFinish = this.formInput.dateTo != null ? DateUtils.getDayEndDate(new Date(Date.parse(this.formInput.dateTo))).getTime() : null;
    def.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    def.durationMin = this.formInput.durationMin;
    const resultRecurrings: string[] = [];
    const recurrings = this.formInput.recurring;
    for (const recType in recurrings) {
      if (recurrings[recType]) {
        resultRecurrings.push(recType);
      }
    }
    def.scheduleCycle = resultRecurrings;
    if(!this.templateId) this.templateId = this.selectedGroup.id
    this.serverReportedErrors = null;
    this.scheduleRest.populateSchedule(this.schoolId, this.templateId, def).subscribe( _ => {
      this.formInput = new CreateScheduleForm();
      this.searchTerm = ""
      this.selectedGroup = null
      if(!this.isGroupDefault)
        this.templateId = null
      this.matchedGroups = []
      this.formInput = new CreateScheduleForm()
      this.form.reset()
      this.form.resetForm()
      this.dialog.hide();
      this.finish.emit();
    },error => {
      if (!error) this.serverReportedErrors = new Error("Unknown Error");
      else if (!error.error || !error.error['developerMessage']) this.serverReportedErrors = error;
      else this.serverReportedErrors = error.error['developerMessage'];
    });
  }

  hasAnyRecurring() {
    const recurring = this.formInput.recurring;
    for (const recType in recurring) {
      if (recurring[recType] === true) return true;
    }
    return false;
  }

  isValidDate() {
    return (new Date(this.formInput.dateFrom)).getTime() <= new Date().getTime() + 10 * 60 * 1000
  }

  isRepeatLaterThanStart() {
    if(!this.formInput.dateTo) return true
    return (new Date(this.formInput.dateFrom)).getTime() < (new Date(this.formInput.dateTo)).getTime()
  }

  areDatesValid() {
    return !!this.formInput.recurring ? !this.isValidDate() && this.isRepeatLaterThanStart() : !this.isValidDate()
  }
}
