<app-modal #confirmDialog>
<div class="modal-content" [ngClass]="confirmDialogService.classes">
  <div class="modal-header" *ngIf="confirmDialogService.title">
    <h5 class="modal-title" >{{confirmDialogService.title}}</h5>
    <button type="button" class="btn-close" (click)="confirmDialogService.cancel()"  aria-label="Close">
    </button>
  </div>
  <div class="modal-body">
    <div>
      {{confirmDialogService.message}}
    </div>
  </div>
  <div class="modal-footer text-end">
    <button type="button" class="btn btn-primary" (click)="confirmDialogService.confirm()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="confirmDialogService.cancel()">No</button>

  </div>
</div>
</app-modal>
