import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewsListEntry } from 'src/app/model/rest/casa-apiv2.model';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {

  @Input() entry: NewsListEntry
  @Input() isWide: boolean = false
  @Input() isSmall: boolean = false
  @Input() schoolId: number
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToDetails() {
    this.router.navigate([`/school/${this.schoolId}/news/${this.entry.id}`])
  }

  getAuthor() {
    return `${this.entry?.newsItem?.author.details.name} ${this.entry?.newsItem?.author.details.surname? this.entry?.newsItem?.author.details.surname : ''}`
  }
  getLabels() {
    return this.entry?.newsItem?.content?.labels.split(',').map(l => l.trim())
  }
}
