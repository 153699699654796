import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentEvent } from 'src/app/model/rest/base';
import {
  TeacherPersonalProfileFormComponent,
  TeacherPersonalProfileFormComponentEvent
} from "../../components/teacher-personal-profile-form/teacher-personal-profile-form.component";
import {
  TeacherProfileFormComponent,
  TeacherProfileFormComponentEvent
} from "../../components/teacher-profile-form/teacher-profile-form.component";

@Component({
  selector: 'app-manager-teacher-profile-page',
  templateUrl: './manager-teacher-profile-page.component.html',
  styleUrls: ['./manager-teacher-profile-page.component.css']
})
export class ManagerTeacherProfilePageComponent implements OnInit {
  schoolId: string;
  teacherId: string;
  personalProfileReady = false;
  personalProfileUnderEdit = false;
  teacherProfileReady = false;
  teacherProfileUnderEdit = false;
  @ViewChild('teacherPersonalProfileFormComponent', {static: true})
  teacherPersonalProfileForm: TeacherPersonalProfileFormComponent;
  @ViewChild('teacherProfileFormComponent', {static: true})
  teacherProfileForm: TeacherProfileFormComponent;


  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.paramMap.subscribe( params => {
      this.schoolId = params.get('schoolId');
      this.teacherId = params.get('teacherId');
    });
  }

  onPersonalProfileEvent(event: TeacherPersonalProfileFormComponentEvent) {
    if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.personalProfileReady = true;
    } else if (event.eventType === TeacherPersonalProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.personalProfileUnderEdit = true;
    } else if (event.eventType === TeacherPersonalProfileFormComponentEvent.ET_FORM_READ_ONLY) {
      this.personalProfileUnderEdit = false;
    }
  }

  onTeacherProfileEvent(event: TeacherProfileFormComponentEvent) {
    if (event.eventType === ComponentEvent.ET_INITIALIZED) {
      this.teacherProfileReady = true;
    } else if (event.eventType === TeacherProfileFormComponentEvent.ET_FORM_EDITABLE) {
      this.teacherProfileUnderEdit = true;
    } else if (event.eventType === TeacherProfileFormComponentEvent.ET_FORM_READ_ONLY) {
      this.teacherProfileUnderEdit = false;
    }
  }

  editTeacherProfile() {
    this.teacherProfileForm.editable = true;
  }

  editPersonalProfile() {
    this.teacherPersonalProfileForm.editable = true;
  }


  ngOnInit() {
  }

}
