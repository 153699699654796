<div class="table-responsive" *ngIf="data">
  <table class="table">
    <thead>
      <tr class="table-row table-row-head">
        <th>Group</th>
        <th></th>
        <th style="width: 36px;"></th>
      </tr>
    </thead>
    <tbody class="table-body cursor-pointer">
      <tr *ngFor="let template of data.content" class="table-row" (click)="onEnter(template)">
        <td>{{getName(template)}}</td>
        <td>{{getTeacherName(template)}}<br>
          <small class="text-muted">{{getNbOfStudents(template)}} students</small>
        </td>
        <td>
          <button *ngIf="showEnter" class="btn btn-outline-secondary-2 rounded-circle" type="button" (click)="onEnter(template)">
            <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="isEmpty()">
        <td colspan="3" class="text-center text-muted pt-5">
          No results found
        </td>
      </tr>
    </tbody>
  </table>
</div>
