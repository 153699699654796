import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {forkJoin} from "rxjs";
import {switchMap, take, tap} from "rxjs/operators";
import {TeacherVideoRestService} from "../../../services/rest/teacher-video-rest.service";
import {ScheduleRow} from "../../../model/schedule";

@Component({
  selector: 'app-teacher-schedule-details-page',
  templateUrl: './teacher-schedule-details-page.component.html',
  styleUrls: ['./teacher-schedule-details-page.component.scss']
})
export class TeacherScheduleDetailsPageComponent implements OnInit {
  private schoolId: number;
  private teacherId: number;
  private scheduleId: number;
  data: ScheduleRow;

  constructor(
    activatedRoute: ActivatedRoute,
    private rest: TeacherVideoRestService
  ) {
    forkJoin([
      activatedRoute.paramMap.pipe(take(1)),
      activatedRoute.parent.paramMap.pipe(take(1))
    ]).pipe(
      tap<[ParamMap, ParamMap]>( ([params, parentParams]) => this.storeParams(params, parentParams)),
      switchMap( _ => this.loadSchedule())
    ).subscribe();
  }

  private storeParams(params: ParamMap, parentParams: ParamMap) {
    this.schoolId = Number(parentParams.get('schoolId'));
    this.teacherId = Number(parentParams.get('teacherId'));
    this.scheduleId = Number(params.get('scheduleId'));
  }

  private loadSchedule() {
    return this.rest.getScheduleDetails(this.schoolId, this.teacherId, this.scheduleId).pipe(
      tap( scheduleRow => this.data = scheduleRow)
    )
  }

  ngOnInit(): void {
  }

}
