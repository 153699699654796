<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Schedule'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Schedule</li>
      </ol>
    </nav>

    <div *ngIf="getSchoolName()" class="card border-0 mb-5">
      <div class="card-body row">
        <div class="col-12 col-lg order-lg-1 my-auto mx-lg-2">
          <h5>{{getSchoolName()}}</h5>
          <p class="small text-muted">
            You can see and edit all of your scheduled online and offline lessons here. To schedule new lessons, go to Groups, click on the chosen group and then click the “Schedule lessons” button.
          </p>
        </div>
        <div class="order-lg-0 mb-3 mb-sm-0 mx-auto" [class]="getLogoUrl()? 'col-auto' : 'col-12 col-sm-6 col-lg-3'">
          <div  appFileDrop (click)="fileInput.click()" (fileDrop)="uploadFile($event)" class="d-flex h-100">
            <img class="school-logo m-auto" *ngIf="getLogoUrl()" src="{{getLogoUrl()}}">

            <div class="row mx-auto form-file p-0" *ngIf="!getLogoUrl()">
              <label for="photo" class="form-label d-flex justify-content-between w-100 px-0">
                <div class="progress mb-2" *ngIf="uploadProgress">
                    <div class="progress-bar" role="progressbar" [ngStyle]="getUploadProgressStyle()"></div>
                </div>
                <div class="photo-square d-flex flex-column justify-content-center align-items-center w-100 p-3 text-center">
                  <app-icon name="photo-add" class="icon-secondary mx-auto"></app-icon>
                  <p class="text-muted xsmall mb-0">
                    To upload your own logo, click on the placeholder or drop a file there.
                  </p>
                </div>
              </label>
            </div>
            <input
              #fileInput
              hidden
              class="form-control"
              (change)="uploadFile($event.target.files)"
              accept="image/svg, image/png, image/jpg, image/gif"
              type="file" id="photo"
              >
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 my-auto border-lg-start border-secondary text-center text-sm-end order-lg-2 d-flex flex-column mx-auto">
          <a href="https://s3.amazonaws.com/static.casa.callan.co.uk/documents/Online+Callan+Classroom+Guide.pdf" target="_blank" class="btn btn-primary rounded-pill small py-3 px-4 mb-2 mb-sm-4">Instructions & FAQ</a>
          <a routerLink="/school/{{schoolId}}/reports/video/activities" class="btn btn-secondary rounded-pill small py-3 px-4">Usage information</a>
        </div>
      </div>
    </div>

    <div class="row gap-2 mb-3">
      <div class="col-12 col-sm-auto">
        <ul class="nav nav-pills gap-2" id="pills-tab" role="tablist">
          <li class="nav-item p-0 m-0" role="presentation">
            <a class="nav-link btn rounded-pill btn-sm px-4 py-2 active" id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming" aria-selected="true">
              Upcoming
            </a>
          </li>
          <li class="nav-item p-0 m-0" role="presentation">
            <a (click)="schedulesWeekSummaryComponent.displayType = 'list'" class="nav-link btn rounded-pill btn-sm px-4 py-2" data-bs-toggle="pill" data-bs-target="#pills-list" type="button" role="tab" aria-controls="pills-list" aria-selected="false">
              <app-icon name="list"></app-icon>
              List
            </a>
          </li>
          <li class="nav-item p-0 m-0" role="presentation">
            <a (click)="schedulesWeekSummaryComponent.displayType = 'calendar'" class="nav-link btn rounded-pill btn-sm px-4 py-2" data-bs-toggle="pill" data-bs-target="#pills-list" type="button" role="tab" aria-controls="pills-list" aria-selected="false" >
              <app-icon name="calendar"></app-icon>
              Calendar
            </a>
          </li>
          <li class="nav-item p-0 m-0" role="presentation">
            <a (click)="schedulesWeekSummaryComponent.displayType = 'email'" class="nav-link btn rounded-pill btn-sm px-4 py-2" data-bs-toggle="pill" data-bs-target="#pills-list" type="button" role="tab" aria-controls="pills-list" aria-selected="false">
              <app-icon name="email"></app-icon>
              Email view
            </a>
          </li>
        </ul>
      </div>
      <div class="col-auto ms-auto">
        <div class="row gap-2">
          <div class="col-auto ms-auto p-0">
            <button class="btn btn-primary rounded-pill" (click)="createNewSchedule()">
              <app-icon [name]="'plus'" class="icon-white"></app-icon>
              Schedule a lesson
            </button>
          </div>
          <div class="col-auto text-end ps-0" *ngIf="hasScheduleQueryArgs()">
            <div class="btn-group my-auto">
              <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-end" (click)="moveScheduleWeekOffset(-1)">
                <app-icon name="angle-left" class="icon-primary icon-lg"></app-icon>
              </button>
              <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary px-3 text-muted" (click)="moveScheduleToNow()">{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</button>
              <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-start" (click)="moveScheduleWeekOffset(1)">
                <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab">
        <div class="card mb-5">
          <div class="card-header row border-bottom border-secondary pb-4">
            <h5>
              Weekly upcoming lessons <span class="text-muted">( {{getSchedulesCount()}} )</span>
            </h5>
            <p *ngIf="scheduleDataSet?.length" class="text-muted small mb-0">Below are shown all lessons in progress and the most immediate upcoming lessons. For the full schedule see the List tab.</p>
          </div>
          <div class="card-body px-2 px-md-4">
            <app-schedules-list (loading)="onScheduleLoading($event)" (dataSet)="onScheduleDataSet($event)" [showUpcomingOnly]="true" [showDelete]="false" #schedulesListComponent [dataProvider]="getSchedulesDataProvider()"
                                (listEvent)="onSchedulesEvent($event)"></app-schedules-list>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab">
        <div class="card">
          <div class="card-header" *ngIf="getSchedulesCount()">
            <h5 class="mb-0">All weekly lessons</h5>
          </div>
          <div class="card-body position-relative">
            <app-schedules-week-summary
              #schedulesWeekSummaryComponent
              [schoolId]="schoolId"
              [dataSet]="scheduleDataSet"
              [isScheduleLoading]="isScheduleLoading"
              [getWeekStartDate]="getWeekStartDate()"
              [getWeekEndDate]="getWeekEndDate()"
              [hasScheduleQueryArgs]="hasScheduleQueryArgs()"
              (moveToNowEmitter)="moveScheduleToNow()"
              (moveWeekOffsetEmitter)="moveScheduleWeekOffset($event)"
            ></app-schedules-week-summary>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal #startConfirmModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Manually open a classroom for this lesson now</h5>
            <button type="button" class="btn-close" (click)="startConfirmModal.hide()"  aria-label="Close">
            </button>
        </div>
      <div class="modal-body" *ngIf="scheduleToStart">
        Are you sure you want to open a classroom for the group <strong>{{scheduleToStart.template.details.name}}</strong> and send emails inviting the students to a new lesson?
      </div>
        <div class="modal-footer text-end">
            <button class="btn btn-secondary" (click)="startConfirmModal.hide()">No</button>
            <button class="btn btn-primary" (click)="startPlannedTemplate()">Yes</button>
        </div>
    </div>
</app-modal>
<app-create-schedule #createScheduleComponent [schoolId]="schoolId" (finish)="schedulesList.refreshData()" [isGroupDefault]="false"></app-create-schedule>

