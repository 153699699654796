<div class="card mb-5">
  <div class="card-header">
    <h5 class="mb-0">Lessons list</h5>
    <div class="btn-group my-3 my-sm-0 ms-auto">
      <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-end" (click)="moveScheduleWeekOffset(-1)">
        <app-icon name="angle-left" class="icon-primary icon-lg"></app-icon>
      </button>
      <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary px-3 text-muted" (click)="moveScheduleToNow()">{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</button>
      <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-start" (click)="moveScheduleWeekOffset(1)">
        <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="table-row table-row-head">
          <th>Date, time</th>
          <th>Duration (min.)</th>
          <th>Group name</th>
          <th>Status</th>
          <th style="width: 36px;"></th>
        </tr>
        </thead>
        <tbody class="table-body cursor-pointer">
          <tr *ngFor="let scheduleRow of scheduleRows" class="table-row cursor-pointer" [routerLink]="getScheduleLink(scheduleRow.schedule.id)">
            <td>{{ getScheduleStartTime(scheduleRow.schedule) | date: 'E dd.MM.yy, HH:mm'}} - {{ getScheduleEndTime(scheduleRow.schedule) | date : 'HH:mm'}}</td>
            <td>{{ scheduleRow.schedule.details.durationMin}}</td>
            <td>{{ scheduleRow.template.details.name}}</td>
            <td>
              <span [ngClass]="getBadgeColorClassByStatus(scheduleRow)" class="badge rounded px-2 py-1 text-white w-fit-content xsmall mb-2">
                {{ scheduleRow.schedule.state}}
              </span>
            </td>
            <td>
              <button class="btn btn-outline-secondary-2 rounded-circle" type="button">
                <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
              </button>
            </td>
          </tr>
          <tr *ngIf="!scheduleRows">
            <td colspan="3" class="text-center text-muted pt-5">
              No results found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
