import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ApiCountry, ApiFile, ApiPerson, ApiPersonalProfile} from 'src/app/model/rest/rest-model';
import {map, tap} from 'rxjs/operators';
import {ComponentEvent, StateAwareComponent} from 'src/app/model/rest/base';
import {FilesRestService} from 'src/app/services/rest/files-rest.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {PublicRestService} from "../../services/rest/public-rest.service";
import {ProfilePhotoUrlExtractor} from "../../services/utils/profile-photo-url-extractor";
import {Utils} from "../../services/utils/utils";

export class StudentPersonalProfileFormComponentEvent extends ComponentEvent {
  static ET_FORM_EDITABLE = 'underEdit';
  static ET_FORM_READ_ONLY = 'readOnly';

  static fromEditableFlag(editable: boolean) {
    const res = new StudentPersonalProfileFormComponentEvent();
    if (editable) {
      res.eventType = this.ET_FORM_EDITABLE;
    } else {
      res.eventType = this.ET_FORM_READ_ONLY;
    }
    return res;
  }
}

@Component({
  selector: 'app-student-personal-profile-form',
  templateUrl: './student-personal-profile-form.component.html',
  styleUrls: ['./student-personal-profile-form.component.css']
})
export class StudentPersonalProfileFormComponent extends StateAwareComponent<StudentPersonalProfileFormComponentEvent> implements OnInit {

  _studentId: number;
  genders = [ 'Male', 'Female', 'Other' ];
  profile: ApiPersonalProfile;
  _editable = true;
  uploadProgress: number = null;

  @ViewChild('fileInput', {static: false}) fileInput;
  countries: ApiCountry[];
  _schoolId: number;

  constructor(
    private fileRest: FilesRestService,
    private mangerRest: ManagerRestService,
    publicRest: PublicRestService) {
    super();
    publicRest.listCountries("en").subscribe(countries => this.processCountries(countries));
  }

  processCountries(countries: ApiCountry[]): void {
    this.countries = countries.concat().sort((l, r) => l.name.localeCompare(r.name));
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadProfile();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadProfile();
  }

  @Input()
  set editable(editable: boolean) {
    this._editable = editable;
    this.stateEvent.emit(StudentPersonalProfileFormComponentEvent.fromEditableFlag(this._editable));
  }

  public openFileChoose() {
    if (!this._editable) {return; }
    this.fileInput.nativeElement.click();
  }

  get editable() {
    return this._editable;
  }

  public getProfilePhoto(profile: ApiPersonalProfile) {
    return ProfilePhotoUrlExtractor.getProfilePhoto(profile);
  }

  public onProfileImageUpload(files: File[]) {
    this.uploadProgress = 0;
    this.fileRest.saveFile(files[0]).subscribe( event => {
      if (event.type === HttpEventType.UploadProgress ) {
        this.uploadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.profile.profilePhoto = event.body as ApiFile;
        this.uploadProgress = null;
      }
    }, () => this.uploadProgress = null);
  }

  saveProfile() {
    return this.mangerRest.saveStudentPersonalProfile(this._schoolId, this._studentId, this.profile);
  }

  public onSave() {
    this.editable = false;
    this.saveProfile().pipe(
      map( p => Utils.jsonClone(p))
    )
    .subscribe(profileAfterUpdate => {
      this.profile = profileAfterUpdate;
    });
  }

  loadPerson() {
    return this.mangerRest.getStudentPerson(this._schoolId, this._studentId);
  }

  loadProfile(): any {
    if ( !this._schoolId || !this._studentId) {return; }

    this.loadPerson().pipe(
      tap( selfPerson => StudentPersonalProfileFormComponent.fillProfileIfEmpty(selfPerson)),
      tap (selfPerson => selfPerson.personalProfile.email = selfPerson.emailAddress ),
      map (selfPerson => Utils.jsonClone(selfPerson.personalProfile)),
      tap<ApiPersonalProfile> ( profile => this.profile = profile )
    ).subscribe( () => this.stateEvent.emit(ComponentEvent.initialized()));
  }

  ngOnInit() {
    this.editable = false;
  }

  private static fillProfileIfEmpty(selfPerson: ApiPerson<ApiPersonalProfile>) {
    if ( !selfPerson.personalProfile ) {
      selfPerson.personalProfile = new ApiPersonalProfile();
      selfPerson.personalProfile.name = selfPerson.name;
      selfPerson.personalProfile.surname = selfPerson.surname;
      selfPerson.personalProfile.email = selfPerson.emailAddress;
    }
  }
}
