<form #groupParticipantForm="ngForm" autocomplete="off" (ngSubmit)="isParticipantSelected && saveParticipant()" class="mb-3">
  <div class="row">
    <ng-container *ngIf="isParticipantBeingAdded">
      <div class="col-12 col-md-6 mb-4">
        <app-person-autocomplete
          [adapter]="participantAdapter"
          (itemSelected)="participantSelected(participant, $event)"
        >
          <div class="form-floating">
            <input class="form-control"
                   id="participant-name"
                   #participantName="ngModel"
                   name="participant-name"
                   placeholder="Participant's name"
                   [(ngModel)]="participant.details.participant.person.details.name"
                   [class]="isParticipantSelected ? 'linked' : 'unlinked'"
                   [ngClass]="{'is-invalid': groupParticipantForm.submitted && (participantName.invalid || !isParticipantSelected)}"
                   required
            >
            <label class="field-required">Participant's name</label>
            <app-icon
              name="unlink"
              class="icon-primary position-absolute top-0 end-0 mt-3 me-4"
              *ngIf="isParticipantSelected"
              (click)="unlinkParticipant()"
            ></app-icon>
            <div *ngIf="groupParticipantForm.submitted && (participantName.invalid || !isParticipantSelected)" class="invalid-feedback">
              <small *ngIf="participantName.errors?.required" class="text-danger">Field is required</small>
              <small *ngIf="!isParticipantSelected && !participantName.invalid" class="text-danger">No participant selected</small>

            </div>
          </div>
        </app-person-autocomplete>
      </div>
      <div class="col-12 col-md-6 mb-4">
        <div class="form-floating">
          <input class="form-control"
                 type="date"
                 name="participant-date-from"
                 placeholder="Participant's date from"
                 [(ngModel)]="participant.details.from"
          >
          <label>Date from</label>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4">
        <div class="form-floating">
          <input class="form-control"
                 type="date"
                 name="participant-date-to"
                 placeholder="Participant's date to"
                 [(ngModel)]="participant.details.to"
          >
          <label>Date to</label>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4">
        <div class="form-floating">
          <input class="form-control"
                 name="participant-labels"
                 placeholder="Participant's labels"
                 [(ngModel)]="labels"
          >
          <label>Labels</label>
        </div>
      </div>
      <div class="text-end">
        <button class="btn btn-sm btn-secondary px-4 py-2 rounded-pill me-3"
                [disabled]="isLoading"
                (click)="cancelAddingParticipant()">
          Cancel
        </button>
        <button type="submit"
                class="btn btn-sm btn-primary px-4 py-2 rounded-pill"
                [disabled]="isLoading">
          <div
            class="spinner-grow spinner-grow-sm text-white"
            role="status"
            *ngIf="isLoading"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          {{ this.isParticipantBeingEdited ? 'Edit' : 'Add' }}
        </button>
      </div>
    </ng-container>
  </div>
</form>
