import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {AccessList, FunctionalAccessService} from "./functional-access.service";
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class AccessListGuardService implements CanActivate {
  constructor(
    private functionalAccessService: FunctionalAccessService
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const functionalities = route.data['require'] as Array<string>
    if (!functionalities) return of(true);
    return this.functionalAccessService.getAccessState().pipe(
      take(1),
      map( (accessState: AccessList) => accessState.disabledList()),
      map( (disabled: Array<string>) => !functionalities.find( requested => disabled.find(found => found === requested)))
    );
  }

}
