import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ComponentEvent, StateAwareComponent} from 'src/app/model/rest/base';
import {
  ApiCountry,
  ApiFile,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonalProfileBaseWithPhoto
} from 'src/app/model/rest/rest-model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FilesRestService} from 'src/app/services/rest/files-rest.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {PublicRestService} from "../../services/rest/public-rest.service";
import {ProfilePhotoUrlExtractor} from "../../services/utils/profile-photo-url-extractor";
import {Utils} from "../../services/utils/utils";

export class TeacherPersonalProfileFormComponentEvent extends ComponentEvent {
  static ET_FORM_EDITABLE = 'underEdit';
  static ET_FORM_READ_ONLY = 'readOnly';

  static fromEditableFlag(editable: boolean) {
    const res = new TeacherPersonalProfileFormComponentEvent();
    if (editable) {
      res.eventType = this.ET_FORM_EDITABLE;
    } else {
      res.eventType = this.ET_FORM_READ_ONLY;
    }
    return res;
  }
}

@Component({
  selector: 'app-teacher-personal-profile-form',
  templateUrl: './teacher-personal-profile-form.component.html',
  styleUrls: ['./teacher-personal-profile-form.component.css']
})
export class TeacherPersonalProfileFormComponent extends StateAwareComponent<TeacherPersonalProfileFormComponentEvent> implements OnInit {

  _teacherId: number;
  genders = [ 'Male', 'Female' ];
  profile: ApiPersonalProfileBaseWithPhoto;
  _editable = true;
  uploadProgress: number = null;

  @ViewChild('fileInput', {static: false}) fileInput;
  countries: ApiCountry[];
  _schoolId: number;

  constructor(
      private fileRest: FilesRestService,
      private managerRest: ManagerRestService,
      publicRest: PublicRestService) {
    super();
    publicRest.listCountries("en").subscribe(countries => this.keepCountries(countries));
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadProfile();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadProfile();
  }

  keepCountries(countries: ApiCountry[]): void {
    this.countries = countries.concat().sort((l, r) => l.name.localeCompare(r.name));
  }

  @Input()
  set editable(editable: boolean) {
    this._editable = editable;
    this.stateEvent.emit(TeacherPersonalProfileFormComponentEvent.fromEditableFlag(this._editable));
  }

  public openFileChoose() {
    if (!this._editable) { return; }
    this.fileInput.nativeElement.click();
  }
  get editable() {
    return this._editable;
  }

  public getProfilePhoto(profile: ApiPersonalProfileBaseWithPhoto) {
    return ProfilePhotoUrlExtractor.getProfilePhoto(profile);
  }

  public onProfileImageUpload(files: File[]) {
    this.uploadProgress = 0;
    this.fileRest.saveFile(files[0]).subscribe( event => {
      if (event.type === HttpEventType.UploadProgress ) {
        this.uploadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.profile.profilePhoto = event.body as ApiFile;
        this.uploadProgress = null;
      }
    }, () => this.uploadProgress = null);
  }

  public onSave() {
    this.editable = false;
    const saveObservable: Observable<ApiPersonalProfile> = this.managerRest.saveTeacherPersonalProfile(this._schoolId, this._teacherId, this.profile);

    saveObservable.pipe(
      map(profile => Utils.jsonClone(profile))
    )
    .subscribe(profileAfterUpdate => {
      this.profile = this.fixDate(profileAfterUpdate);
    });
  }

  loadProfile(): any {
    this.loadProfileAsManager();
  }

  loadProfileAsManager() {
    if (this._teacherId == null
      || this._schoolId == null) {
        return;
      }
      this.managerRest.getTeacher(this._schoolId, this._teacherId).pipe(
        map( teacherProfile => teacherProfile.teacher.person),
        map ( person => {
          if (!person.personalProfile) {
            return this.createProfileFromPerson(person);
          }
          return person.personalProfile;
        }),
        map ( profile => Utils.jsonClone(profile)),
      ).subscribe( profile => {
        this.profile = this.fixDate(profile);
        this.stateEvent.emit(ComponentEvent.initialized());
      });
  }

  createProfileFromPerson(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    const newProfile = new ApiPersonalProfile();
    newProfile.name = person.name;
    newProfile.surname = person.surname;
    newProfile.email = person.emailAddress;
    return newProfile;
  }

  fixDate(profile: ApiPersonalProfileBaseWithPhoto): ApiPersonalProfileBaseWithPhoto {
   if (!profile || !profile.birthDate) { return profile; }
   profile.birthDate = new Date(profile.birthDate);
   return profile;
  }

  ngOnInit() {
    this.editable = false;
  }
}
