import {Injectable} from "@angular/core";
import {
  Schedule,
  ScheduleActionBaseWithScheduleRef,
  ScheduleBaseWithTemplateReference,
  ScheduleDetailsBase,
  ScheduleListOptimized,
  ScheduleParticipant,
  ScheduleParticipantAction,
  ScheduleParticipantBase, ScheduleRowSimplified
} from "../../model/schedule";
import {RoomTemplate, TemplateParticipant} from "../../model/server";

@Injectable({
  providedIn: 'root'
})
export class ScheduleActionsResolverService {
  public resolve(source: ScheduleListOptimized): ScheduleRowSimplified[] {
    // fill schedules with participants
    const groupsById = source.groups.reduce((map, current) => {
      map.set(current.id, current);
      return map;
    }, new Map<number, RoomTemplate<TemplateParticipant>>());

    source.schedules.forEach( schedule => {
      schedule.details.participants = this.mapTemplateParticipants(groupsById.get(schedule.templateId));
    })

    const scheduleById = source.schedules.reduce((map, el) => {
      map.set(el.id, el)
      return map;
    }, new Map<number, ScheduleBaseWithTemplateReference<ScheduleDetailsBase<ScheduleParticipantBase>>>());

    // apply remove actions
    source.actions
      .filter(it => it.action === ScheduleParticipantAction.REMOVE)
      .forEach( toRemove => {
        const scheduleToUpdate = scheduleById.get(toRemove.scheduleId);
        if (!scheduleToUpdate || !scheduleToUpdate.details.participants) return;
        const partiIndex = scheduleToUpdate.details.participants.findIndex( parti => parti.id === toRemove.partiId)
        if (partiIndex < 0) return;
        scheduleToUpdate.details.participants.splice(partiIndex, 1);
      });

    source.actions.filter( it => it.action === ScheduleParticipantAction.MODIFY)
      .forEach( updateAction => {
        const scheduleToUpdate = scheduleById.get(updateAction.scheduleId);
        if (!scheduleToUpdate || !scheduleToUpdate.details.participants) return;
        const parti = scheduleToUpdate.details.participants.find(parti => parti.id === updateAction.partiId)
        if (!parti) return;
        this.mapAction(updateAction, parti);
      });

    source.actions.filter( it => it.action === ScheduleParticipantAction.ADD).forEach( addAction => {
      const scheduleToUpdate = scheduleById.get(addAction.scheduleId);
      if (!scheduleToUpdate) return;
      if (!scheduleToUpdate.details.participants) scheduleToUpdate.details.participants = [];
      scheduleToUpdate.details.participants.push(this.mapAction(addAction, new ScheduleParticipant()))
    });

    return source.schedules.map( schedule => {
      const res = new ScheduleRowSimplified();
      res.schedule = schedule;
      res.template = groupsById.get(schedule.templateId)
      return res;
    });

  }

  private mapTemplateParticipants(template: RoomTemplate<TemplateParticipant>): ScheduleParticipant[] {
    if (!template || !template.participants) return [];

    return template.participants.map( parti => this.mapParti(parti,new ScheduleParticipant()))
  }

  private mapParti(s: TemplateParticipant, d: ScheduleParticipant): ScheduleParticipant {
    d.id = s.id;
    d.name = s.details.name;
    d.email = s.details.email;
    d.role = s.details.role;
    d.offline = s.details.offline;
    return d;
  }

  private mapAction(s: ScheduleActionBaseWithScheduleRef, d: ScheduleParticipant | ScheduleParticipantBase): ScheduleParticipant | ScheduleParticipantBase {
    d.id = s.partiId;
    d.name = s.name;
    d.role = s.role;
    d.offline = s.offline;
    d.email = s.email;
    return d;
  }
}
