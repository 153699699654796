import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {LessonsReportComponent} from "../../components/lessons-report/lessons-report.component";
import {ReportGenericFilterData} from "../../components/reports-filter/reports-filter.component";

@Component({
  selector: 'app-lessons-report-page',
  templateUrl: './lessons-report-page.component.html',
  styleUrls: ['./lessons-report-page.component.css']
})
export class LessonsReportPageComponent implements OnInit {
  schoolId: string;
  @ViewChild('reportView', {static: true})
  reportView: LessonsReportComponent;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.parent.parent.paramMap.subscribe(params => this.schoolId = params.get('schoolId'));
  }

  public updateReport(data: ReportGenericFilterData) {
    this.reportView.updateReport(data);
  }

  ngOnInit() {
  }

}
