<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Teachers'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{_schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{_schoolId}}/teachers">Teachers</a></li>
        <li class="breadcrumb-item active" *ngIf="teacher">{{getPersonName()}}</li>
      </ol>
    </nav>

    <div class="card border-0" *ngIf="teacher">
      <div class="card-body row">
        <div class="col-auto order-0">
          <div class="initials-circle-box-lg">
            <span class="m-auto small text-white">{{ getInitials(teacher) }}</span>
          </div>
        </div>
        <div class="col-12 order-2 mt-3 col-sm-auto order-sm-1 mt-sm-0">
          <div class="my-auto" style="max-width: 13rem;">
            <p class="text-muted small mb-0">Teacher:</p>
            <span class="me-2">{{getPersonName()}}</span>
            <span class="text-uppercase badge xsmall rounded-2 bg-warning w-fit-content">{{getStatus()}}</span>
            <p class="text-muted small mb-0">{{getAccountEmail()}}</p>
          </div>
        </div>
        <div class="col-auto ms-auto order-1 order-sm-2">
          <div class="dropleft my-auto ms-auto">
            <a class="dropdown-toggle-split btn btn-outline-secondary-2 rounded-circle py-2" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
              <app-icon name="more-vertical" class="icon-primary"></app-icon>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuReference">
              <a class="dropdown-item" (click)="changePassword()">
                <app-icon name="password" class="icon-white"></app-icon>
                Change password
              </a>
              <ng-container *ngIf="isActive()">
                <a class="dropdown-item" (click)="reloadTeacher(teacher)">
                  <app-icon name="refresh" class="icon-white"></app-icon>
                  Refresh eBooks and exercises
                </a>
                <a class="dropdown-item" (click)="resetBookShares(teacher)">
                  <app-icon name="restart" class="icon-white"></app-icon>
                  Reset eBooks download limit
                </a>
                <a class="dropdown-item" (click)="block()">
                  <app-icon name="block" class="icon-white"></app-icon>
                  Block
                </a>
              </ng-container>   
              <a class="dropdown-item" (click)="activate()" *ngIf="canActivate()">
                <app-icon name="renew" class="icon-white"></app-icon>
                Activate
              </a>
              <a class="dropdown-item" (click)="delete()">
                <app-icon name="delete" class="icon-white"></app-icon>
                Delete
              </a>
              <a class="dropdown-item" (click)="changeEmail()">
                <app-icon name="email" class="icon-white"></app-icon>
                Change login email
              </a>
              <a class="dropdown-item" (click)="delete()"
               *ngIf="functionalAccessService.isSuperUser() | async">
                <app-icon name="delete" class="icon-white"></app-icon>
                Permanent delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ul class="nav nav-pills py-4">
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm px-4 py-2" *ngIf="isCallanOnline()" href="#" [ngClass]="{active: currentFragment === 'lessons'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/lessons">Lessons</a>
      </li>
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm px-4 py-2" *ngIf="isCallanOnline()" href="#" [ngClass]="{active: currentFragment === 'profile'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/profile">Profile</a>
      </li>
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm px-4 py-2" *ngIf="!isCallanOnline()" href="#" [ngClass]="{active: currentFragment === 'lessons-list'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/lessons-list">Lessons list</a>
      </li>
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm px-4 py-2" *ngIf="!isCallanOnline()" href="#" [ngClass]="{active: currentFragment === 'lessons-calendar'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/lessons-calendar">Lesson calendar</a>
      </li>
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm px-4 py-2" href="#" [ngClass]="{active: currentFragment === 'products'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/products">Products</a>
      </li>
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm px-4 py-2" href="#" [ngClass]="{active: currentFragment === 'casa-profile'}" routerLink="/school/{{_schoolId}}/teacher/{{_teacherId}}/casa-profile">Casa Profile</a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</div>

<app-person-password-change #passwordModal [changePasswordCallback]="changePasswordCallback"></app-person-password-change>
<app-person-email-change #emailModal [changeEmailCallback]="changeEmailCallback"></app-person-email-change>

