import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subscriber} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  title: string;
  message: string;
  private subscriber: Subscriber<boolean>;
  classes: string;

  constructor() { }
  show = new BehaviorSubject<boolean>(false);


  public askConfirmation(message: string, title?: string, classes?: string): Observable<boolean> {
    this.cancel();
    return new Observable<boolean>(subscriber => {
        this.show.next(true);
        this.title = title;
        this.message = message;
        this.classes = classes;
        this.subscriber = subscriber
        return () => {
          this.show.next(false);
          subscriber = null;
        }
      }
    );
  }

  public doOnConfirm(message: string, action: () => void, title?: string, classes?: string) {
    this.askConfirmation(message, title, classes).subscribe(
      response => {
        if (response) action()
      }
    )
  }

  confirm() {
    this.show.next(false);
    if (this.subscriber) {
      this.subscriber.next(true);
      this.subscriber.complete();
      this.subscriber = null;
    }
  }

  cancel() {
    this.show.next(false);
    if (this.subscriber) {
      this.subscriber.next(false);
      this.subscriber.complete();
      this.subscriber = null;
    }
  }
}
