import {GenericSimpleDataProvider} from "../../model/internal";
import {ScheduleRowSimplified} from "../../model/schedule";
import {Observable} from "rxjs";
import {SearchingContextService} from "../../services/searching-context.service";
import {ScheduleRestService} from "../../services/rest/schedule-rest.service";
import {map} from "rxjs/operators";
import {DateUtils} from "../../services/helpers/date-utils";
import {StudentVideoRestService} from "../../services/rest/student-video-rest.service";
import {TeacherVideoRestService} from "../../services/rest/teacher-video-rest.service";

export class ScheduleListQuery {
  constructor(public weekOffset:number, public templateId: number) {
  }
  public filterCurrentWeek = true;
}


abstract class ScheduleListProviderBase<T> implements GenericSimpleDataProvider<ScheduleRowSimplified[]> {
  constructor(
    private searchingContextPath: string,
    private searchingContextService: SearchingContextService,
    private rest: T,
    public schoolId: number,
    public queryArgs: ScheduleListQuery
  ) {}

  abstract doRestQuery(rest: T, schoolId: number, queryDate: number, templateId: number): Observable<ScheduleRowSimplified[]>;

  doLoad(): Observable<ScheduleRowSimplified[]> {
    const queryDateMs = DateUtils.queryDate(this.queryArgs.weekOffset);
    const weekStart = DateUtils.weekStart(queryDateMs);
    const weekEnd = DateUtils.weekEnd(queryDateMs);

    this.searchingContextService.updateContext(this.searchingContextPath, this.queryArgs);

    return this.doRestQuery(this.rest, this.schoolId, queryDateMs, this.queryArgs.templateId).pipe(
      map(rows => {
        return rows.filter(row => {
          if (this.queryArgs.filterCurrentWeek) {
            if (row.schedule.details.startDate < weekStart) return false;
            if (row.schedule.details.startDate > weekEnd) return false;
          }
          return true;
        });
      })
    )
  }
}

export class SchedulesListProvider extends ScheduleListProviderBase<ScheduleRestService> {
  constructor(
    searchingContextPath: string,
    searchingContextService: SearchingContextService,
    rest: ScheduleRestService,
    schoolId: number,
    queryArgs: ScheduleListQuery
  ) {
    super(searchingContextPath, searchingContextService, rest, schoolId, queryArgs);
  }

  doRestQuery(rest: ScheduleRestService, schoolId: number, queryDate: number, templateId: number): Observable<ScheduleRowSimplified[]> {
    return rest.listSchedulesOptimized(schoolId, queryDate, this.queryArgs.templateId);
  }
}

export class StudentSchedulesListProvider extends ScheduleListProviderBase<StudentVideoRestService> {
  constructor(
    searchingContextPath: string,
    searchingContextService: SearchingContextService,
    rest: StudentVideoRestService,
    schoolId: number,
    private studentId: number,
    queryArgs: ScheduleListQuery
  ) {
    super(searchingContextPath, searchingContextService, rest, schoolId, queryArgs);
  }

  doRestQuery(rest: StudentVideoRestService, schoolId: number, queryDate: number, templateId: number): Observable<ScheduleRowSimplified[]> {
    return rest.listSchedules(schoolId, this.studentId, queryDate);
  }
}

export class TeacherSchedulesListProvider extends ScheduleListProviderBase<TeacherVideoRestService> {
  constructor(
    searchingContextPath: string,
    searchingContextService: SearchingContextService,
    rest: TeacherVideoRestService,
    schoolId: number,
    private teacherId: number,
    queryArgs: ScheduleListQuery
  ) {
    super(searchingContextPath, searchingContextService, rest, schoolId, queryArgs);
  }

  doRestQuery(rest: TeacherVideoRestService, schoolId: number, queryDate: number, templateId: number): Observable<ScheduleRowSimplified[]> {
    return rest.listSchedules(schoolId, this.teacherId, queryDate);
  }
}
