<div class="table-responsive text-center">
  <table class="table table-sm">
    <thead>
      <tr class="table-row table-row-head">
        <th class="text-start">Lesson</th>
        <th *ngIf="!short">Start / End</th>
        <th *ngIf="!short">Headword</th>
        <th *ngIf="!short">Reading</th>
        <th *ngIf="!short">Dict</th>
        <th *ngIf="short">Progress</th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let historyItem of progressHistory" class="table-row">
        <td class="text-start">
          {{getHistoryItemHeader(historyItem)}}<br/>
          <small class="text-muted" *ngIf="getLessonType(historyItem)">{{getLessonType(historyItem)}},</small><small class="text-muted">{{getCreated(historyItem) | date: 'EEE dd MMM HH:mm'}}, {{getAttendance(historyItem)}}</small>
          <small class="text-muted" *ngIf="historyItem.details.comment"><br/>comment: {{historyItem.details.comment}}</small>
        </td>
        <td *ngIf="!short">
          {{getStart(historyItem)}} / {{getPara(historyItem)}}
        </td>
        <td *ngIf="!short">
          <span class="rounded border border-secondary border-2 py-1 px-2">{{getHw(historyItem)}}</span>
        </td>
        <td *ngIf="!short">
          <span *ngIf="getReadingLesson(historyItem)">{{getReadingLesson(historyItem)}}</span>
          <small *ngIf="getReading(historyItem)"><br/>({{getReading(historyItem)}})</small>
        </td>
        <td *ngIf="!short">
          {{getDict(historyItem)}}
        </td>
        <td *ngIf="short" [innerHTML]="getProgress(historyItem)">
        </td>
      </tr>
      <tr class="table-row">
        <td colspan="6" *ngIf="!isLastPage()">
          <a tabindex="" class="cursor-pointer" (click)="nextHistoryPage()"><i class="fa fa-chevron-down"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
