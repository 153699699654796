<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Groups'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page" ><a routerLink="/school/{{schoolId}}/v2_groups">Groups</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{group?.details.name}}</li>
      </ol>
    </nav>
    <ng-container *ngIf="group">
      <div class="card border-0 py-2 mb-5">
        <div class="card-body row">
          <div class="col-auto order-0">
            <div class="initials-circle-box-lg">
              <span class="m-auto small text-white">{{ getInitials() }}</span>
            </div>
          </div>
          <div class="col-12 order-2 mt-3 col-sm-auto order-sm-1 mt-sm-0">
            <div class="my-auto" style="max-width: 13rem;">
              <p class="text-muted small mb-0">Group:</p>
              <span class="me-2">{{group?.details.name}}</span>
              <span class="text-uppercase badge xsmall rounded-2 bg-warning w-fit-content">{{group?.status}}</span>
            </div>
          </div>
          <div class="col-auto ms-auto order-1 order-sm-2">
            <div class="dropleft my-auto ms-auto">
              <a class="dropdown-toggle-split btn btn-outline-secondary-2 rounded-circle py-2" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                <app-icon name="more-vertical" class="icon-primary"></app-icon>
              </a>
              <div class="dropdown-menu py-2" aria-labelledby="dropdownMenuReference">
                <a class="dropdown-item" routerLink="./edit">
                  <app-icon name="edit" class="icon-white"></app-icon>
                  Edit
                </a>
                <a class="dropdown-item" (click)="deleteConfirmModal.show()">
                  <app-icon name="delete" class="icon-white"></app-icon>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-5">
          <div class="card-header border-bottom border-secondary pb-4">
            <h5 class="mb-0">Group Teachers</h5>
          </div>
          <div class="card-body">
            <app-group-participant-list [participants]="group.teachers"></app-group-participant-list>
          </div>
        </div>
      <div class="card">
          <div class="card-header border-bottom border-secondary pb-4">
            <h5 class="mb-0">Group Students</h5>
          </div>
          <div class="card-body">
            <app-group-participant-list [participants]="group.students"></app-group-participant-list>
          </div>
        </div>
    </ng-container>
  </div>
</div>
<app-modal #deleteConfirmModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title fw-bold">Delete</h5>
      <button type="button" class="btn-close" (click)="deleteConfirmModal.hide()"  aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div>
        <strong>Are you sure you want to delete this group?</strong>
      </div>
    </div>
    <div class="modal-footer text-end">
      <button type="button" class="btn btn-danger" (click)="deleteGroup()">Yes, delete</button>
      <button type="button" class="btn btn-primary" (click)="deleteConfirmModal.hide()">No</button>
    </div>
  </div>
</app-modal>
