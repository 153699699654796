import {Component, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {ScheduleRestService} from "../../services/rest/schedule-rest.service";
import {ActivatedRoute} from "@angular/router";
import {ScheduleDetails, ScheduleParticipant, ScheduleRow} from "../../model/schedule";
import {zip} from "rxjs";
import {map, tap} from "rxjs/operators";
import {formatDate, Location} from "@angular/common";
import {HybridState, ParticipantDef} from "../../model/server";
import {ModalComponent} from "../../components/modal/modal.component";
import {DomSanitizer} from "@angular/platform-browser";
import {VideoServerRestService} from "../../services/rest/video-server-rest.service";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {School, Student, Teacher} from "../../model/rest/casa-apiv2.model";
import {environment} from "../../../environments/environment";
import {
  StudentAutocompleteAdapter,
  TeacherAutocompleteAdapter
} from "../../components/person-autocomplete/person-autocomplete-adapters";
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";

@Component({
  selector: 'app-schedule-details-page',
  templateUrl: './schedule-details-page.component.html',
  styleUrls: ['./schedule-details-page.component.css']
})
export class ScheduleDetailsPageComponent implements OnInit {
  schoolId: number;
  private scheduleId: number;
  data: ScheduleRow;
  teacher: ScheduleParticipant;
  students: ScheduleParticipant[] = [];
  startDate: string;
  place: string;
  durationMin: number;
  saved = false;
  hasError: any;
  editParticipantSettings: ScheduleParticipant;
  deleteFollowing = false;
  notes: string;


  @ViewChild("startConfirmModal", {static: true}) startConfirmModal: ModalComponent
  @ViewChild("deleteConfirmModal", {static: true}) deleteConfirmModal: ModalComponent
  @ViewChild("settingsModal", {static: true}) settingsModal: ModalComponent
  private linksCopied = new Set<String>();
  onlineEvent = true;
  private school: School;
  teacherAutoAdapter: TeacherAutocompleteAdapter;
  studentAutoAdapter: StudentAutocompleteAdapter;

  constructor(
    private scheduleRest: ScheduleRestService,
    private rest: VideoServerRestService,
    route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string,
    private location: Location,
    private sanitizer: DomSanitizer,
    private casa: CasaRestApiV2
  ) {
    zip(
      route.parent.paramMap.pipe(
        map( params => Number(params.get('schoolId')))
      ),
      route.paramMap.pipe(
        map( params => Number(params.get('id')))
      )
    ).subscribe(
      ([schoolId, scheduleId]) => this.setupData(schoolId, scheduleId)
    );
  }

  ngOnInit() {
  }

  private loadData() {
    this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.school = school)
    ).subscribe();
    this.scheduleRest.getScheduleById(this.schoolId, this.scheduleId).subscribe(
      scheduleRow => {
        this.storeData(scheduleRow);
      }
    )
  }

  private setupData(schoolId: number, scheduleId: number) {
    this.schoolId = schoolId;
    this.scheduleId = scheduleId;
    this.loadData();
    this.teacherAutoAdapter = new TeacherAutocompleteAdapter(schoolId, this.casa);
    this.studentAutoAdapter = new StudentAutocompleteAdapter(schoolId, this.casa);
  }

  public hasRoom() {
    return this.data && this.data.room;
  }

  public isRoomQualityReporting() {
    if (!this.hasRoom()) return false;
    return this.data.room.reportStats;
  }

  public registerReports() {
    if (!this.hasRoom()) return;
    this.rest.updateRoomVideoStatsReportFlag(
      this.schoolId,
      this.data.template.id,
      this.data.room.uuid,
      true
    ).subscribe( _ => this.loadData())
  }

  private storeData(scheduleRow: ScheduleRow) {
    this.data = scheduleRow;
    this.onlineEvent = this.isScheduleOnline();
    if (scheduleRow.schedule.details.place) {
      this.place = scheduleRow.schedule.details.place;
    } else {
      this.place = scheduleRow.template.details.place;
    }
    this.durationMin = scheduleRow.schedule.details.durationMin;
    this.notes = scheduleRow.schedule.details.notes;

    this.teacher = scheduleRow.schedule.details.participants.find(p => p.role === 'Teacher');
    if (this.teacher == null) {
      this.teacher = new ScheduleParticipant();
      this.teacher.role = 'Teacher';
    }
    this.students = scheduleRow.schedule.details.participants.filter( p => p.role === 'Student');
    this.startDate =  formatDate(scheduleRow.schedule.details.startDate, "yyyy-MM-dd HH:mm", this.locale);
  }

  onSave() {
    this.saved = false;
    const detailsToSave = this.prepareScheduleDetails();
    detailsToSave.durationMin = this.durationMin;
    detailsToSave.notes = this.notes;

    detailsToSave.place = this.place;
    this.scheduleRest.updateSchedule(this.schoolId, this.scheduleId, detailsToSave).subscribe(
      _ => {
        this.saved = true;
        setTimeout(_ => this.saved = false, 3000);
        this.loadData();
      },
      error => {
        this.hasError = (error.error.developerMessage) ? error.error.developerMessage : error.error;
        setTimeout(_ => this.hasError = false, 5000);
      }
    );
  }

  addStudent() {
    const student = new ScheduleParticipant();
    student.role = 'Student';
    this.students.push(student);
  }

  canAdd() {
    return this.isModifiable() && this.students.length < 20;
  }

  removeStudent(student: ScheduleParticipant) {
    if (!this.mayDeleteStudent()) return;
    this.students.splice(this.students.indexOf(student),1);
  }

  getScheduleName() {
    return this.data.template.details.name;
  }

  onBack() {
    this.location.back();
  }

  showOpenConfirm() {
    this.startConfirmModal.show(true);
  }

  onDelete() {
    this.deleteConfirmModal.hide();
    this.scheduleRest.deleteSchedule(this.schoolId, this.data.schedule.id, this.deleteFollowing).subscribe( _ => {
      this.onBack();
    });
    this.deleteFollowing = false;
  }

  isModifiable() {
    return this.data && this.data.schedule.state === 'PLANED';
  }

  isOpenable() {
    return this.isModifiable();
  }

  mayDeleteStudent() {
    return this.students.length > 1;
  }

  private prepareScheduleDetails(): ScheduleDetails {
    const result = new ScheduleDetails();
    result.startDate = Date.parse(this.startDate);
    result.participants = [].concat(this.teacher, ...this.students);

    const scheduleIsOnlineFromCalculation = this.isScheduleOnline();
    if (scheduleIsOnlineFromCalculation != this.onlineEvent) {
      result.hybridState = this.onlineEvent ? HybridState.Online : HybridState.Offline;
    }
    return result;
  }
  getPartiFullName(participant: ParticipantDef) {
    if (!participant) return '';
    const email = this.getPartiEmail(participant);
    const name = participant.name != null ? `${participant.name}` : '';
    return `${name} ${email}`;
  }

  getPartiEmail(participant: ParticipantDef) {
    let scheduleParticipants = this.data.schedule.details.participants.filter(it => it.name == participant.name)
    return scheduleParticipants.length == 1 && scheduleParticipants[0].email ? `(${scheduleParticipants[0].email})` : ''
  }

  getRoomTeacher(): ParticipantDef {
    if (!this.data || !this.data.room) return null;
    return this.data.room.participants.find(p => p.role === 'Teacher');
  }

  createLink(parti: ParticipantDef): string {
    if (!parti) {
      return '';
    }
    const origin = environment.videoWebBase;
    if (parti.role === 'Teacher') {
      return `${origin}/participants/${parti.uuid}/teacher-room`;
    } else {
      return `${origin}/participants/${parti.uuid}/student-room`;
    }
  }

  isShowLinkCopied(link: string) {
    return this.linksCopied.has(link);
  }

  copyToClipboard(link: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.linksCopied.clear();
    this.linksCopied.add(link);
    setTimeout(() => this.linksCopied.delete(link), 3000);
  }
  getRoomStudents() {
    if (!this.data || !this.data.room) return [];
    return this.data.room.participants.filter(p => p.role === 'Student');
  }

  getScheduleStatus() {
    switch(this.data.schedule.state) {
      case 'PENDING': return 'waiting for first connection';
      case 'IN_PROGRESS': return 'in progress';
      case 'COMPLETE': return 'finished';
      case 'PLANED': return 'planned';
    }
    return this.data.schedule.state;
  }
  getStatusColorClass(): string {
    switch(this.data.schedule.state) {
      case 'PENDING': return 'bg-primary text-light';
      case 'IN_PROGRESS': return 'bg-primary text-light';
      case 'COMPLETE': return 'bg-light text-muted';
      case 'PLANED': return 'bg-warning';
    }
    return 'bg-warning';
  }

  private prepareStatus(parti: ParticipantDef): [string, number] {
    let status: string;
    let pingAge: number;

    if (parti && parti.videoInfo) {
      status = parti.videoInfo.status;
      pingAge = parti.videoInfo.pingAge;
      if (pingAge != null) pingAge /= 1000;
    }

    if (status == null) status = "never connected to video service";
    if (pingAge == null) pingAge = Number.MAX_VALUE;
    return [status, pingAge];
  }

  getConnectionStatus(parti: ParticipantDef) {
    let [status, pingAge] = this.prepareStatus(parti);

    if (pingAge < 60) return "Connected";
    if (pingAge < 3600) {
      status += ` (seen ${Math.round(pingAge / 60)} min ago)`;
    }
    return status;
  }

  getConnectionStatusColor(parti: ParticipantDef) {
    let [status, pingAge] = this.prepareStatus(parti);
    if (pingAge < 60) return "text-success";
    if (status == 'Connected') return "text-warning";
    return "text-danger";
  }

  getScheduleStartDate() {
    return this.data.schedule.details.startDate;
  }


  getRoomCreateDate() {
    if (!this.data ||!this.data.room) return null;
    return this.data.room.createDate;
  }

  getRoomLessonStartDate() {
    if (!this.data ||!this.data.room) return null;
    return this.data.room.lessonStartDate;
  }

  getRoomTotalTime() {
    if (!this.data ||!this.data.room) return null;
    return Math.round(this.data.room.totalTime / 60000);
  }

  getRoomVideoCreated() {
    if (!this.data ||!this.data.room || !this.data.room.provisioning) return null;
    return this.data.room.provisioning.created;
  }

  getRoomVideoFinished() {
    if (!this.data ||!this.data.room || !this.data.room.provisioning) return null;
    return this.data.room.provisioning.endTime;
  }

  getRoomVideoDuration() {
    if (!this.data ||!this.data.room || !this.data.room.provisioning) return null;
    return Math.round(this.data.room.provisioning.duration / 60);
  }

  hasProgressReported() {
    return this.data
      && this.data.room
      && this.data.room.progress
      && this.data.room.progress.details
      && this.data.room.progress.details.paragraph
      && this.data.room.progress.details.paragraph.length > 0;

  }

  getProgressReport() {
    return this.data.room.progress.details;
  }

  getParticipantVideoConnectionTime(student: ParticipantDef) {
    if (!student || !student.videoInfo) return 0;
    return Math.round(student.videoInfo.duration / 60);
  }


  studentWasAttended(student: ParticipantDef) {
    if (!student) return false;
    if (!this.data || !this.data.room || !this.data.room.progress || !this.data.room.progress.attenders) return false;
    return this.data.room.progress.attenders.find( a => a.attended === true && a.participant.uuid === student.uuid)
  }

  onStart() {
    this.scheduleRest.updateScheduleState(this.schoolId, this.data.schedule.id, "PENDING").subscribe( _ => {
      this.loadData();
      this.startConfirmModal.hide();
    })
  }

  showDeleteConfirm() {
    this.deleteConfirmModal.show(true);
  }

  getGroupName() {
    if (!this.data || !this.data.template) return '';
    return this.data.template.details.name;
  }

  getTemplateId() {
    if (!this.data || !this.data.template) return '';
    return this.data.template.id;
  }

  getScheduleTime() {
    return this.data?.schedule.details.startDate;
  }

  getScheduleEndDate() {
    return this.data.schedule.details.startDate + this.durationMin * 60 * 1000;
  }

  editSettings(student: ScheduleParticipant) {
    this.editParticipantSettings = student;
    this.settingsModal.show();
  }

  hasFlags(student: ScheduleParticipant) {
    return this.isOffline(student);
  }

  isOffline(student: ScheduleParticipant) {
    if (!student) return false;
    return student.offline;
  }

  private isScheduleOnline() {
    if (this.data.schedule.details.hybridState === HybridState.Offline) return false;
    if (this.data.schedule.details.hybridState === HybridState.Online) return true;
    return this.data.template.details.hybridState !== HybridState.Offline;

  }

  isComplete() {
    return this.data.schedule.state === 'COMPLETE';
  }

  isOfflineEvent() {
    return !this.onlineEvent;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  isSchoolReady() {
    return this.school != null;
  }

  casaTeacherItemSelected(teacher: Teacher) {
    this.teacher.name = PersonNameExtractor.getCasaPersonName(teacher.person);
    this.teacher.email = teacher.person.registrationEmail;
    this.teacher.eid = `casa:${teacher.id}`;
  }

  casaStudentItemSelected(student: ScheduleParticipant, casaStudent: Student) {
    student.name = PersonNameExtractor.getCasaPersonName(casaStudent.person);
    student.email = casaStudent.person.registrationEmail;
    student.eid = `casa:${casaStudent.id}`;
  }

  unlink(parti: ScheduleParticipant) {
    parti.id = null;
    parti.eid = null;
    parti.name = null;
    parti.email = null;
  }

  isCasaConnected(parti: ScheduleParticipant) {
    return parti && parti.eid;
  }

  studentFieldIndex = new Map<ScheduleParticipant, number>();
  currentIndexValue = 1;

  getStudentIndex(parti: ScheduleParticipant) {
    let res = this.studentFieldIndex.get(parti);
    if (res) return res;
    res = this.currentIndexValue++;
    this.studentFieldIndex.set(parti, res);
    return res;
  }

  getGroupNotes() {
    return this.data && this.data.template && this.data.template.details.notes;
  }

  getInitials(person: ScheduleParticipant) {
    return person.name ? person.name.split(' ').map(part => {return part[0]}).join('') : '-'
  }
}
