<div class="container-fluid pt-1">
  <div class="mt-5" *ngIf="data && !data.room">
    <h5 class="card-title text-center">This class is planned on</h5>
      <h4 class="card-title text-center">{{data.schedule.details.startDate | date: "dd MMM yyyy HH:mm"}}</h4>
    <h5 class="card-title text-center">
      but is not ready yet. Come back
      in one hour before lesson starts.
    </h5>
  </div>

  <app-room-details [roomData]="data.room" *ngIf="data && data.room"></app-room-details>
</div>
