<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Schedule'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/groups">Groups</a></li>
        <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/groups/{{getTemplateId()}}">Group {{getGroupName()}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">Schedule at {{getScheduleTime() | date: 'dd MMM HH:mm'}}</li>
      </ol>
    </nav>
    <div class="card mb-5" *ngIf="data && isModifiable()">
      <div class="card-header px-3 gap-3 flex-column flex-sm-row">
        <div class="d-flex gap-3">
          <div>
            <button class="btn btn-outline-secondary py-2 px-3 py-md-3 px-md-4 rounded-circle" (click)="onBack()"><app-icon name="angle-left" class="icon-primary icon-lg"></app-icon></button>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <h5 class="mb-0">Schedule for {{getScheduleName()}}</h5>
            <span class="text-uppercase badge xsmall rounded-2 w-fit-content" [ngClass]="getStatusColorClass()">{{getScheduleStatus()}}</span>
          </div>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button class="btn btn-outline-secondary rounded-circle px-2 p-md-3 me-2" (click)="showOpenConfirm()" title="Start schedule event now" data-bs-toggle="tooltip">
              <app-icon name="play-arrow" class="icon-primary icon-md"></app-icon>
            </button>
            <button class="btn btn-outline-secondary rounded-circle px-2 p-md-3 me-2" (click)="showDeleteConfirm()" title="Delete the event" data-bs-toggle="tooltip">
              <app-icon name="delete" class="icon-danger"></app-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form  #f="ngForm" (submit)="f.form.valid && onSave()">
          <div class="row">
            <div class="col-12 mb-4" *ngIf="getGroupNotes()">
              <div class="form-floating">
                <textarea
                class="form-control"
                disabled="disabled"
                placeholder="notes"
                rows="1"
                >{{getGroupNotes()}}</textarea>
                <label>Notes about the Group <small class="text-muted">(shown to the teacher inside the Online Classroom)</small></label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <div class="form-floating">
                <input
                [disabled]="!isModifiable()"
                [(ngModel)]="startDate"
                class="form-control"
                name="scheduleStartDate"
                type="text"
                placeholder="yyyy-mm-dd HH:mm"
                #startDateInput="ngModel"
                required pattern="\d{4}-\d{2}-\d{2}[T| ]?\d{2}:\d{2}"
                [ngClass]="{ 'is-invalid': f.submitted && startDateInput.invalid}"
                >
                <label>Planned date</label>
                <div *ngIf="f.submitted && startDateInput.invalid" class="invalid-feedback">
                  <div *ngIf="startDateInput.errors.required">Field is required</div>
                  <div *ngIf="startDateInput.errors.pattern">Remember about the correct pattern yyyy-MM-dd HH:mm</div>
                </div>
            </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <div class="form-floating">
                <input
                [disabled]="!isModifiable()"
                [(ngModel)]="durationMin"
                class="form-control"
                name="duration"
                type="number"
                placeholder="minutes"
                >
                <label>Duration</label>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-floating">
                <input
                [disabled]="!isModifiable()"
                [(ngModel)]="place"
                class="form-control"
                name="classroom"
                type="text"
                placeholder="number / name"
                >
                <label>Classroom name <small class="text-muted">(optional for offline groups)</small></label>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-floating">
                <textarea
                class="form-control"
                rows="1"
                name="sschedule-notes"
                placeholder="sschedule-notes"
                [(ngModel)]="notes"
                ></textarea>
                <label>Notes about this lesson <small class="text-muted">(shown to the teacher inside the Online Classroom)</small></label>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" #lessonOnline id="online-group" name="online-group" [(ngModel)]="onlineEvent">
                <label class="form-check-label text-muted" *ngIf="lessonOnline.checked" for="online-group">The lesson is online</label>
                <label class="form-check-label text-muted" *ngIf="!lessonOnline.checked" for="online-group">The lesson is offline (in a physical classroom<app-icon name="school" class="icon-secondary align-text-bottom icon-sm ms-2"></app-icon>)</label>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <div class="d-flex mb-4">
              <h5 class="w-fit-content my-auto">Default teacher</h5>
              <div class="btn-group p-2 rounded-pill border border-2 border-secondary ms-auto" *ngIf="isCasaConnected(teacher)">
                <button type="button"
                  class="btn btn-primary rounded-circle p-2 lh-sm"
                  (click)="unlink(teacher)"
                  data-bs-toggle="tooltip"
                  title="Unlink this account from the user list. IMPORTANT: They will not be able to see the data related to this group or lesson in their User Interface."
                >
                  <app-icon name="unlink" class="icon-white"></app-icon>
                </button>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <app-person-autocomplete
                [adapter]="teacherAutoAdapter"
                (itemSelected)="casaTeacherItemSelected($event)"
              >
                <div class="form-floating">
                  <input class="form-control"
                          name="teacher-name"
                          placeholder="Teacher's name"
                          [(ngModel)]="teacher.name"
                          [disabled]="!isModifiable()"
                          #teacherName="ngModel"
                          required
                          [class]="isCasaConnected(teacher) ? 'linked' : 'unlinked'"
                          [ngClass]="{ 'is-invalid': f.submitted && teacherName.invalid}"
                  >
                  <label>Teacher's name</label>
                  <div *ngIf="f.submitted && teacherName.invalid" class="invalid-feedback">
                    <div *ngIf="teacherName.errors.required">Field is required</div>
                  </div>
                </div>
              </app-person-autocomplete>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <div class="form-floating">
                <input
                  class="form-control"
                  name="teacher-mail" type="email"
                  placeholder="Teacher's email"
                  [(ngModel)]="teacher.email"
                  [disabled]="!isModifiable()"
                  [class]="isCasaConnected(teacher) ? 'linked' : 'unlinked'"
                  [readOnly]="isCasaConnected(teacher)"
                >
                <label>Teacher's email</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="d-flex mb-4">
              <h5 class="w-fit-content my-auto">Students</h5>
              <div class="btn-group p-2 rounded-pill border border-2 border-secondary ms-auto" *ngIf="canAdd()">
                <button type="button"
                        class="btn btn-primary rounded-circle p-2 lh-sm"
                        (click)="addStudent()"
                        data-bs-toggle="tooltip"
                        title="Add a new participant."
                >
                  <app-icon name="plus" class="icon-white"></app-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row mb-4" *ngFor="let student of students; let i = index">
            <div class="col-12 col-lg mb-4 mb-md-2">
              <app-person-autocomplete
              [adapter]="studentAutoAdapter"
              (itemSelected)="casaStudentItemSelected(student, $event)"
              >
                <div class="form-floating">
                    <input
                      class="form-control"
                      [disabled]="!isModifiable()"
                      name="student-name-{{getStudentIndex(student)}}"
                      placeholder="Student name"
                      [(ngModel)]="student.name"
                      #studentName="ngModel"
                      required
                      [class]="isCasaConnected(student) ? 'linked' : 'unlinked'"
                      [ngClass]="{ 'is-invalid': f.submitted && studentName.invalid}"
                    >
                    <label>Student's name</label>
                </div>
              </app-person-autocomplete>
              <div *ngIf="f.submitted && studentName.invalid" class="invalid-feedback">
                <div *ngIf="studentName.errors.required">Field is required</div>
              </div>
            </div>
            <div class="col-12 col-lg mb-2">
              <div class="form-floating">
                <input
                  class="form-control"
                  [disabled]="!isModifiable()"
                  name="student-mail-{{getStudentIndex(student)}}"
                  type="email" placeholder="Student email"
                  [(ngModel)]="student.email"
                  [class]="isCasaConnected(student) ? 'linked' : 'unlinked'"
                >
                <label>Student's email</label>
              </div>
            </div>
            <div class="col-5 col-lg-12 order-lg-3">
              <span *ngIf="student.offline"><app-icon name="school" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.videoOff"><app-icon name="camera-off" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.audioOff"><app-icon name="audio-off" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.passive"><app-icon name="front-hand" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.chartOff"><app-icon name="chart" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.secret"><app-icon name="secret" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.muteOff"><app-icon name="mic-off" class="icon-secondary me-1"></app-icon></span>
              <span *ngIf="student.chatOff"><app-icon name="no-chat" class="icon-secondary me-1"></app-icon></span>
            </div>
            <div class="col-7 col-lg-auto text-end ms-auto"  *ngIf="isModifiable()">
              <div class="btn-group p-2 rounded-pill border border-2 border-secondary ms-auto">
                <button
                    *ngIf="isCasaConnected(student)"
                    type="button" class="btn btn-primary rounded-circle p-2 lh-sm me-2" (click)="unlink(student)"
                    data-bs-toggle="tooltip"
                    title="Unlink this account from the user list. IMPORTANT: They will not be able to see the data related to this group or lesson in their User Interface."
                  >
                  <app-icon name="unlink" class="icon-white"></app-icon>
                </button>
                <button *ngIf="mayDeleteStudent()" type="button" class="btn btn-primary rounded-circle p-2 lh-sm me-2" (click)="removeStudent(student)"
                          data-bs-toggle="tooltip"
                          title="Delete user from the list"
                  >
                  <app-icon name="delete" class="icon-white"></app-icon>
                </button>
                  <button type="button" class="btn btn-primary rounded-circle p-2 lh-sm" (click)="editSettings(student)"
                          data-bs-toggle="tooltip"
                          title="Manage user's settings"
                  >
                  <app-icon name="settings" class="icon-white"></app-icon>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="hasError" class="alert alert-danger">
            Server error received: {{hasError}}
          </div>
          <div class="text-end">
              <button type="submit" class="btn btn-primary px-4 py-2 rounded-pill" *ngIf="isModifiable()">
                <span *ngIf="!saved" class="text-white">Save</span>
                <span *ngIf="saved" class="text-white">Saved correctly</span>
              </button>
          </div>
        </form>
      </div>
    </div>


    <div *ngIf="data && !isModifiable()" class="card">
      <div class="card-header px-3 gap-3">
        <div>
          <button class="btn btn-outline-secondary mb-3 py-2 px-3 mb-md-0 py-md-3 px-md-4 rounded-circle" (click)="onBack()"><app-icon name="angle-left" class="icon-primary icon-lg"></app-icon></button>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <h5 class="mb-0">Schedule for {{getScheduleName()}}</h5>
          <span class="text-uppercase badge xsmall rounded-2 w-fit-content" [ngClass]="getStatusColorClass()">{{getScheduleStatus()}}</span>
        </div>
      </div>
      <div class="card-body">
        <strong *ngIf="hasRoom() && isRoomQualityReporting()">
          The audio/video quality report are registering for this room.
        </strong>
        <p *ngIf="notes || getGroupNotes()">
          <span class="small" *ngIf="getGroupNotes()"><strong>Group notes: </strong>{{getGroupNotes()}}<br/></span>
          <span class="small" *ngIf="notes"><strong>This lesson notes: </strong>{{notes}}</span>
        </p>
        <div class="table-responsive" *ngIf="hasProgressReported()">
          <table class="table">
            <thead>
              <tr class="table-row table-row-head">
                <th>Para</th>
                <th>Last Hw</th>
                <th>Reading</th>
                <th>Dict</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr class="table-row">
                <td>
                  <span *ngIf="getProgressReport().paragraph">{{getProgressReport().paragraph}}</span>
                  <span *ngIf="!getProgressReport().paragraph">-</span>
                </td>
                <td>
                  <span *ngIf="getProgressReport().lastHw">{{getProgressReport().lastHw}}</span>
                  <span *ngIf="!getProgressReport().lastHw">-</span>
                </td>
                <td>
                  <span *ngIf="getProgressReport().reading">{{getProgressReport().reading}}</span>
                  <span *ngIf="!getProgressReport().reading">-</span>
                </td>
                <td>
                  <span *ngIf="getProgressReport().dictation">{{getProgressReport().dictation}}</span>
                  <span *ngIf="!getProgressReport().dictation">-</span>
                </td>
                <td>
                  <span *ngIf="getProgressReport().comment">{{getProgressReport().comment}}</span>
                  <span *ngIf="!getProgressReport().comment">-</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center py-4" *ngIf="isComplete() && isOfflineEvent()">
          <h5>This room was opened in Offline mode.</h5>
        </div>
        <div class="text-end mb-1" *ngIf="hasRoom()">
          <span class="text-muted xsmall">Click the icon<app-icon name="copy" class="icon-xs icon-secondary"></app-icon>to copy the invitation link for
            the participant.</span>
        </div>
        <div class="table-responsive" *ngIf="hasRoom()">
          <table class="table table-sm mb-5">
            <tbody class="table-body-not-even">
              <tr class="table-row">
                <td>
                  <div class="d-inline-flex align-items-center">
                    <div class="initials-circle-box-lg me-3">
                      <span class="m-auto text-white">{{ getInitials(teacher) }}</span>
                    </div>
                    <div class="d-flex flex-column">
                      <p class="text-muted xsmall mb-0">Teacher</p>
                      <h6 class="mb-0">{{getPartiFullName(getRoomTeacher())}}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column justify-content-center gap-md-1">
                    <p class="mb-0" [ngClass]="getConnectionStatusColor(getRoomTeacher())">{{getConnectionStatus(getRoomTeacher())}}</p>
                    <p *ngIf="getParticipantVideoConnectionTime(getRoomTeacher())" class="text-muted mb-0">Was
                      connected {{getParticipantVideoConnectionTime(getRoomTeacher())}} minutes in total.</p>
                  </div>
                </td>
                <td class="text-end">
                  <a tabindex="" class="btn btn-primary rounded-circle p-2 my-auto position-relative"
                    (click)="copyToClipboard(createLink(getRoomTeacher()))"
                    title="Copy the invitation link for the participant."
                    data-bs-toggle="tooltip">
                    <app-icon name="copy" class="icon-sm icon-white"></app-icon>
                    <span *ngIf="isShowLinkCopied(createLink(getRoomTeacher()))" class="copy-label badge bg-secondary">Copied</span>
                  </a>
                </td>
              </tr>
              <tr class="table-row" *ngFor="let student of getRoomStudents()">
                <td>
                  <div class="d-inline-flex align-items-center me-5 me-md-0">
                    <div class="initials-circle-box-lg me-3">
                      <span class="m-auto text-white">{{ getInitials(student) }}</span>
                    </div>
                    <div class="d-flex flex-column">
                      <p class="text-muted xsmall mb-0">Student</p>
                      <h6 class="mb-0">{{getPartiFullName(student)}}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column justify-content-center gap-md-1">
                    <p class="mb-0" [ngClass]="getConnectionStatusColor(student)">{{getConnectionStatus(student)}}</p>
                    <p *ngIf="getParticipantVideoConnectionTime(student)" class="text-muted mb-0">Was
                      connected {{getParticipantVideoConnectionTime(student)}} minutes in total.</p>
                    <p *ngIf="studentWasAttended(student)" class="text-success mb-0">Marked as present by the teacher.</p>
                    <p *ngIf="!studentWasAttended(student)" class="text-warning mb-0">Marked as absent by the teacher.</p>
                  </div>
                </td>
                <td class="text-end">
                  <a tabindex="" class="btn btn-primary rounded-circle p-2 my-auto position-relative"
                    (click)="copyToClipboard(createLink(student))"
                    title="Copy the invitation link for the participant."
                    data-bs-toggle="tooltip">
                    <app-icon name="copy" class="icon-sm icon-white"></app-icon>
                    <span *ngIf="isShowLinkCopied(createLink(student))" class="copy-label xsmall badge bg-secondary">Copied</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card card-border border-secondary h-100 mb-4">
          <div class="card-header" *ngIf="hasRoom()">
            <h5>Reported timings</h5>
          </div>
          <div class="card-body" *ngIf="hasRoom()">
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary">
              <span class="small mb-0">Planned to start:</span>
              <span class="small text-muted mb-0">{{getScheduleStartDate() | date: "dd MMM HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="durationMin">
              <span class="small mb-0">Planned to finish:</span>
              <span class="small text-muted mb-0">{{getScheduleEndDate() | date: "dd MMM HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="place">
              <span class="small mb-0">Place:</span>
              <span class="small text-muted mb-0">{{place}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary">
              <span class="small mb-0">Room created:</span>
              <span class="small text-muted mb-0">{{getRoomCreateDate() | date: "dd MMM HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getRoomLessonStartDate()">
              <span class="small mb-0">Teacher started the timer:</span>
              <span class="small text-muted mb-0">{{getRoomLessonStartDate() | date: "dd MMM HH:mm"}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getRoomLessonStartDate() && getRoomTotalTime()">
              <span class="small mb-0">The timer summary time:</span>
              <span class="small text-muted mb-0">{{getRoomTotalTime()}} mins</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getRoomVideoCreated()">
              <span class="small mb-0">Video stream started:</span>
              <span class="small text-muted mb-0">{{getRoomVideoCreated()  | date: 'dd MMM HH:mm'}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getRoomVideoCreated() && getRoomVideoFinished()">
              <span class="small mb-0">Video stream finished:</span>
              <span class="small text-muted mb-0">{{getRoomVideoFinished()  | date: 'dd MMM HH:mm'}}</span>
            </div>
            <div class="d-flex justify-content-between py-1 border-bottom border-secondary" *ngIf="getRoomVideoCreated() && getRoomVideoDuration()">
              <span class="small mb-0">Video stream duration:</span>
              <span class="small text-muted mb-0">{{getRoomVideoDuration()}} mins</span>
            </div>
              <!--<dt *ngIf="getTimerStartDate()" class="col-sm-5">Timer was started at (but it wasn't stopped yet):</dt>
              <dd *ngIf="getTimerStartDate()" class="col-sm-7">{{getTimerStartDate() | date: "dd MMM yyyy HH:mm"}}</dd> -->
          </div>
        </div>
        <div class="text-end mb-4" *ngIf="hasRoom() && !isRoomQualityReporting() && !isComplete() && !isOfflineEvent()">
          If you know about any audio/video quality issues in this room, please click the link:
          <a tabindex="" class="cursor-pointer" (click)="registerReports()">Register quality reports</a>
        </div>
        <div class="text-end">
          <a class="btn btn-primary rounded-pill py-2 px-3" *ngIf="hasRoom()" routerLink="/school/{{schoolId}}/rooms/{{data.room.uuid}}/details">Show room details</a>
        </div>
      </div>
    </div>

  </div>
<app-modal #startConfirmModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Create new classroom</h5>
      <button type="button" class="btn-close" (click)="startConfirmModal.hide()"  aria-label="Close">
      </button>
    </div>

    <div class="modal-body">
      Are you sure you want to open a classroom for this event and send emails inviting the students to a new lesson?
    </div>

    <div class="modal-footer text-end">
      <button type="button" class="btn btn-secondary" (click)="startConfirmModal.hide()">No</button>
      <button type="button" class="btn btn-primary" (click)="onStart()">Yes</button>
    </div>
  </div>
</app-modal>


<app-modal #deleteConfirmModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title fw-bold">Delete</h5>
      <button type="button" class="btn-close" (click)="deleteConfirmModal.hide()"  aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div>
        <strong>Are you sure you want to delete this item?</strong>
      </div>
      <div class="form-check mt-4">
        <input class="form-check-input" type="checkbox" [(ngModel)]="deleteFollowing" >
        <label class="form-check-label" >
          Delete all following schedules too
        </label>
      </div>
    </div>
    <div class="modal-footer text-end">
      <button type="button" class="btn btn-danger" (click)="onDelete()">Yes, delete</button>
      <button type="button" class="btn btn-primary" (click)="deleteConfirmModal.hide()">No</button>

    </div>
  </div>
</app-modal>

<app-modal #settingsModal size="lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="staticBackdropLabel">Participant settings</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body" *ngIf="editParticipantSettings">
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="front-hand" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent random prompting</p>
          <p class="text-muted xsmall mb-0">
            The student will never be selected by the randomizer (prompting with the space bar). To prompt the student, you will need to select them manually.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-passive" [(ngModel)]="editParticipantSettings.passive">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="chart" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent chart interaction</p>
          <p class="text-muted xsmall mb-0">
            The student won’t be able to interact with the demonstration charts.
            <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-chart-off" [(ngModel)]="editParticipantSettings.chartOff">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="no-chat" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent chat interaction</p>
          <p class="text-muted xsmall mb-0">
            The student won’t be able to send chat messages, but will see other chat messages.
            <strong class="text-danger">This can’t be reversed during the lesson.</strong>
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-chat-off" [(ngModel)]="editParticipantSettings.chatOff">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="secret" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Observation mode</p>
          <p class="text-muted xsmall mb-0">
            The observer won’t be seen by the other participants, and won’t be able to broadcast audio or video, use the chat feature, or interact with the demonstration charts.
          </p>
        </div>
        <div class="form-check form-switch my-0">
          <input type="checkbox" class="form-check-input" id="settings-secret" [(ngModel)]="editParticipantSettings.secret">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="mic-off" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Prevent muting</p>
          <p class="text-muted xsmall mb-0">
            The participant can’t be muted by the teacher and won’t be muted automatically when another student is selected. The participant can still mute themselves.
          </p>
        </div>
        <div class="form-check form-switch my-0">
          <input type="checkbox" class="form-check-input" id="settings-mute-off" [(ngModel)]="editParticipantSettings.muteOff">
        </div>
      </div>
      <div class="d-flex gap-3 mb-4">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="camera-off" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Video Off</p>
          <p class="text-muted xsmall mb-0">
            The student’s video will be off when they join the lesson. You can turn their video back on during the lesson.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-video-off" [(ngModel)]="editParticipantSettings.videoOff">
        </div>
      </div>
      <div class="d-flex gap-3">
        <div>
          <div class="p-1 border border-2 rounded-circle lh-1">
            <app-icon name="audio-off" class="icon-primary"></app-icon>
          </div>
        </div>
        <div class="d-flex flex-column">
          <p class="mb-1">Audio Off</p>
          <p class="text-muted xsmall mb-0">
            The student’s audio will be off when they join the lesson. You can turn their audio back on during the lesson.
          </p>
        </div>
        <div class="form-check form-switch my-auto ms-auto">
          <input type="checkbox" class="form-check-input" id="settings-audio-off" [(ngModel)]="editParticipantSettings.audioOff">
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-secondary rounded-pill px-md-5 py-md-3" data-bs-dismiss="modal">Close</button>
    </div>
  </div>

</app-modal>
</div>
