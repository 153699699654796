
<form *ngIf="profile" #personalForm="ngForm" (ngSubmit)="personalForm.form.valid && onSave()">
  <div class="row mx-auto form-file mb-4" (click)="openFileChoose()">
    <label for="photo" class="form-label d-flex justify-content-between w-100">
        <div class="d-flex flex-column" *ngIf="!profile || !profile.profilePhoto">
            <span class="f-12 text-muted">No photo</span>
            <h4 *ngIf="_editable" class="mb-0">Click to upload</h4>
        </div>
        <div class="progress mb-2" *ngIf="uploadProgress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{'width':uploadProgress + '%'}"></div>
        </div>
        <div class="photo-square" [ngClass]="profile.profilePhoto? 'border-0' : ''">
            <div  class="photo-circle img-thumbnail d-flex justify-content-center align-items-center"
            [style.background-image]="getProfilePhoto(profile)">
            <app-icon name="photo-add" *ngIf="!profile.profilePhoto" class="icon-secondary m-auto"></app-icon>
            </div>
        </div>
    </label>
    <input
      #fileInput
      class="form-control d-none"
      (change)="onProfileImageUpload($event.target.files)"
      accept="image/svg, image/png, image/jpg, image/gif"
      type="file" id="photo"
    >
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input
        type="text"
        name="name"
        #name="ngModel"
        placeholder="Name"
        id="name"
        [(ngModel)]="profile.name"
        [ngClass]="{ 'is-invalid': personalForm.submitted && name.invalid}"
        class="form-control required-field"
        [attr.disabled]="editable ? null : ''"
        required>
        <label class="field-required">Name</label>
        <div *ngIf="personalForm.submitted && name.invalid" class="invalid-feedback">
            <div *ngIf="name.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input
        type="text"
        placeholder="Surname"
        [ngClass]="{ 'is-invalid': personalForm.submitted && surname.invalid}"
        class="form-control"
        id="surname"
        name="surname"
        #surname="ngModel"
        [(ngModel)]="profile.surname"
        [attr.disabled]="editable ? null : ''"
        required>
        <label class="field-required">Surname</label>
        <div *ngIf="personalForm.submitted && surname.invalid" class="invalid-feedback">
            <div *ngIf="surname.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input
        type="date"
        id="dob"
        name="dob"
        #dob="ngModel"
        [ngClass]="{ 'is-invalid': personalForm.submitted && dob.invalid}"
        [ngModel]="profile.birthDate | date:'yyyy-MM-dd'"
        (ngModelChange)="profile.birthDate = $event"
        [attr.disabled]="editable ? null : ''"
        class="form-control"
        required>
        <label for="dob" class="field-required">Date of Birth</label>
        <div *ngIf="personalForm.submitted && dob.invalid" class="invalid-feedback">
          <div *ngIf="dob.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select
          id="nationality"
          name="nationality"
          #nationality="ngModel"
          [ngClass]="{ 'is-invalid': personalForm.submitted && nationality.invalid,
          'select-not-empty': profile.nationalityIso}"
          [(ngModel)]="profile.nationalityIso"
          [attr.disabled]="editable ? null : ''"
          class="form-select"
          required>
          <option *ngFor="let n of countries" [value]="n.isoCode">{{n.name}}</option>
        </select>
        <label for="nationality" class="field-required">Where are you from?</label>
        <div *ngIf="personalForm.submitted && nationality.invalid" class="invalid-feedback">
            <div *ngIf="nationality.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select
        id="country"
        name="country"
        #country="ngModel"
        [ngClass]="{ 'is-invalid': personalForm.submitted && country.invalid,
        'select-not-empty': profile.countryIso}"
        [(ngModel)]="profile.countryIso"
        [attr.disabled]="editable ? null : ''"
        class="form-select"
        required>
        <option *ngFor="let c of countries" [value]="c.isoCode">{{c.name}}</option>
      </select>
      <label for="country" class="field-required">Where do you live?</label>
        <div *ngIf="personalForm.submitted && country.invalid" class="invalid-feedback">
            <div *ngIf="country.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input
        type="email"
        id="email"
        name="email"
        placeholder="email"
        #email="ngModel"
        [(ngModel)]="profile.email"
        [ngClass]="{ 'is-invalid': personalForm.submitted && email.invalid}"
        class="form-control"
        [attr.disabled]="editable ? null : ''"
        required>
        <div *ngIf="personalForm.submitted && email.invalid" class="invalid-feedback">
          <div *ngIf="email.errors.required">Field is required</div>
        </div>
        <label class="field-required">Email</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="gender-input"  [class]="{'gender-input-disabled' : !editable}">
        <h6 class="text-start gender-header text-muted">Gender</h6>
        <div class="input-group">
          <div class="form-check me-3" *ngFor="let gender of genders">
            <input
            class="form-check-input"
            type="radio"
            name="gender"
            [value]="gender"
            [ngClass]="{ 'is-invalid': personalForm.submitted && personalForm.controls.gender.invalid}"
            [(ngModel)]="profile.gender"
            [attr.disabled]="editable ? null : ''"
            required>
            <label class="form-check-label small">{{gender}}</label>
          </div>
          <div *ngIf="personalForm.submitted && personalForm.controls.gender.invalid" class="invalid-feedback">
              Field is required
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="form-floating">
        <textarea
        id="biography" rows="3"
        class="form-control"
        name="biography"
        placeholder="biography"
        #biography="ngModel"
        [(ngModel)]="profile.biography"
        [ngClass]="{ 'is-invalid': personalForm.submitted && biography.invalid }"
        [attr.disabled]="editable ? null : ''" required
        ></textarea>
        <label for="biography" class="field-required">About you</label>
        <div *ngIf="personalForm.submitted && biography.invalid" class="invalid-feedback">
            <div *ngIf="biography.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="float-end btn-group">
        <button type="button" [hidden]="!editable" class="btn btn-secondary btn-sm rounded-pill px-3 py-2 me-3" (click)="editable = false;">Cancel</button>
        <button [hidden]="!editable" class="btn btn-primary btn-sm rounded-pill px-3 py-2">Save</button>
      </div>
    </div>
  </div>
</form>


