<div class="position-fixed" style="z-index:10000; pointer-events: none;left:0px;right: 0px; top: 0px; bottom: 0px;">
  <div class="toast fade show" *ngFor="let notification of messages" [ngClass]="notification.toastClasses">
    <div class="toast-header" *ngIf="notification.title">
      <strong class="me-auto">{{notification.title}}</strong>
    </div>
    <div class="toast-body">
      {{notification.message}}
    </div>
  </div>
</div>
