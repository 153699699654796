import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Page, Pageable } from 'src/app/model/rest/base';
import { StudentsFeePaymentConfirmationDetails, StudentsFeeRequest } from 'src/app/model/rest/casa-apiv2.model';
import { AdminRestService } from 'src/app/services/rest/admin-rest.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-admin-school-payment-list',
  templateUrl: './admin-school-payment-list.component.html',
  styleUrls: ['./admin-school-payment-list.component.scss'],
})
export class AdminSchoolPaymentListComponent implements OnInit {
  studentsFeeRequest: Page<StudentsFeeRequest>;
  paymentConfirmation = new StudentsFeePaymentConfirmationDetails();
  schoolId: number;
  pageable = new Pageable(0, 10, ['qId,DESC']);
  selectedFeeRequestId: number;
  @ViewChild('confirmationModal', { static: true })
  confirmationModal: ModalComponent;
  constructor(
    private route: ActivatedRoute,
    private adminRest: AdminRestService
  ) {}

  ngOnInit(): void {
    this.route.parent.paramMap
      .pipe(
        tap((params) => (this.schoolId = +params.get('schoolId'))),
        switchMap((_) => this.loadStudentsFeeRequest())
      )
      .subscribe();
  }

  loadStudentsFeeRequest() {
    return this.adminRest
      .listSchoolFees(this.schoolId, this.pageable)
      .pipe(
        tap(
          (studentsFeeRequest) => (this.studentsFeeRequest = studentsFeeRequest)
        )
      );
  }

  onConfirmPayment() {
    this.adminRest
      .savePaymentConfirmation(
        this.schoolId,
        this.selectedFeeRequestId,
        this.paymentConfirmation
      )
      .pipe(switchMap((_) => this.loadStudentsFeeRequest()))
      .subscribe();
    this.confirmationModal.hide();
  }

  confirmPayment(feeRequestId: number) {
    this.selectedFeeRequestId = feeRequestId;
    this.confirmationModal.show();
  }

  isPaid(paymentConfirmation: StudentsFeeRequest) {
    return (
      paymentConfirmation.confirmation.paymentDate &&
      paymentConfirmation.confirmation.paymentOrderId
    );
  }
  switchPage($event) {
    this.pageable = $event;
    this.loadStudentsFeeRequest().subscribe();
  }
}
