import {Component, Input, OnInit} from '@angular/core';
import {tap} from "rxjs/operators";
import {School} from "../../../model/rest/casa-apiv2.model";
import {CasaOldRestApi} from "../../../services/rest/casa-rest.service";
import {Stats} from "../../../model/rest/old-casa-api.model";


@Component({
  selector: 'app-students-summary-block',
  templateUrl: './students-summary-block.component.html',
  styleUrls: ['./students-summary-block.component.css']
})
export class StudentsSummaryBlockComponent implements OnInit {
  stats: Stats;

  @Input()
  set school(value: School) {
    this.mSchool = value;
    this.reload();
  }

  mSchool: School;

  constructor(private casaApi: CasaOldRestApi) { }

  ngOnInit() {
  }

  private reload() {
    if (!this.mSchool) return;

    this.loadStudentsStats(this.mSchool.id).pipe(
      tap(stats => this.store(stats))
    ).subscribe()

  }

  private loadStudentsStats(schoolId: number) {
    return this.casaApi.countStudentActions(schoolId);
  }

  private store(stats: Stats) {
    this.stats = stats;
  }

  getActive() {
    return this.mSchool.estimate.active;
  }

  getNew() {
    return this.stats.newStudents;
  }

  getRenewed() {
    return this.stats.prolongs;
  }

  getBlocked() {
    return this.stats.deactivations;
  }

  getDeleted() {
    return this.stats.deletions;
  }

  getUnblocked() {
    return this.stats.activations - this.stats.newStudents;
  }

  isReady() {
    return this.mSchool && this.stats;
  }

  getTotal() {
    return this.mSchool.estimate.total;
  }
}
