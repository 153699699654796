<div class="dropdown ms-3 ms-md-0">
  <button
    type="button"
    class="btn btn-primary rounded-circle btn-nav ms-4 d-block ms-md-3"
    id="notifications"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <app-icon name="notification-white"></app-icon>
    <span
      *ngIf="notifications?.content.length"
      class="position-absolute bottom-0 rounded-circle bg-danger p-2"
    >
      <span class="visually-hidden">unread messages</span>
    </span>
  </button>
  <ul
    class="dropdown-menu dropdown-notifications mt-3"
    aria-labelledby="notifications"
  >
    <li *ngIf="!notifications?.content.length">
      <p class="text-muted f-14 mb-0 text-center p-2">
        You have no current notifications
      </p>
    </li>
    <ng-container *ngIf="notifications?.content.length">
      <li *ngFor="let notification of notifications.content" class="mb-2-not-last">
        <a class="dropdown-item" (click)="goToDetails(notification)">
          <div class="d-flex flex-column justify-content-between w-100 me-3">
            <p class="title mb-1">{{ notification.newsItem.content.title }}</p>
            <p *ngIf="notification?.newsItem?.content?.subtitle" class="title small">{{ notification.newsItem.content.subtitle }}</p>
            <p class="text-muted xsmall mb-0">
              {{ notification.newsItem.publicationDate | date : "d MMMM y | h:mm" }}
            </p>
          </div>
          <div *ngIf="notification.newsItem.content.image" class="ratio ratio-16x9 w-100">
            <img
              [src]="notification.newsItem.content.image"
              class="rounded-1 object-fit-contain"
            />
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
