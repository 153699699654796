<div class="container">
  <app-casa-personal-profile-form
    [profile]="profileUnderEdit"
    role="teacher"
    (savePerson)="saveProfile($event)"
    [busy]="busy">
    <div form-header class="card-header border-bottom border-secondary pb-4">
      <h5 class="mb-0">Teacher's personal details</h5>
    </div>
    <div class="card mb-5" additional-card>
      <div class="card-header border-bottom border-secondary pb-4">
        <h5 class="mb-0">Teacher's language profile</h5>
      </div>
      <div class="card-body">
        <app-manager-casa-teacher-profile [busy]="teacherBusy" [profile]="teacherProfileUnderEdit" (saveProfile)="saveTeacherProfile($event)"></app-manager-casa-teacher-profile>
      </div>
    </div>
  </app-casa-personal-profile-form>
</div>
