<div class="container-fluid">
  <div class="row h-100 text-center align-items-center mx-0">
    <h5 class="mb-0">
      {{ getTitle() }}
      <p
        class="small text-muted w-50 text-nowrap overflow-hidden text-truncate mx-auto mb-0"
      >
        {{ school?.details?.name }}
      </p>
    </h5>
  </div>
</div>
