import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import {ComponentEvent, Page, Pageable, StateAwareComponent} from 'src/app/model/rest/base';
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile} from 'src/app/model/rest/rest-model';
import {filter, switchMap, tap} from 'rxjs/operators';
import {PersonNameExtractor, ProfilePhotoUrlExtractor} from "../../services/utils/profile-photo-url-extractor";
import {LessonTypeColors} from "../../services/utils/utils";
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {LocalStateService} from "../../services/local-state.service";

export enum LessonType {
  Upcomming, Past
}

export class StudentLessonsComponentEvent extends ComponentEvent {
}

@Component({
  selector: 'app-student-lessons',
  templateUrl: './student-lessons.component.html',
  styleUrls: ['./student-lessons.component.css']
})
export class StudentLessonsComponent extends StateAwareComponent<StudentLessonsComponentEvent> implements OnInit, OnDestroy {

  _lessonType: LessonType;
  _pageable: Pageable;
  lessonTypes = LessonType;
  _studentId: number;
  _schoolId: number = null;

  //input subjects
  private schoolIdSubject = new Subject<number>();
  private studentIdSubject = new Subject<number>();
  private lessonTypeSubject = new Subject<LessonType>();
  private contextPath: string;
  _data: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;

  @Output()
  dataLoaded = new EventEmitter<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>();
  @Output()
  currentPageable = new EventEmitter<Pageable>()

  set pageable(value: Pageable) {
    this._pageable = value;
    this.currentPageable.emit(value);
  }

  get pageable() {
    return this._pageable;
  }

  set data(value: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>){
    this._data = value;
    this.dataLoaded.emit(value);
  }

  get data() {
    return this._data;
  }

  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.studentIdSubject.next(value);
  }
  get studentId() {
    return this._studentId;
  }

  constructor(
    private managerRest: ManagerRestService,
    private localState: LocalStateService) {
      super();

      combineLatest(
        this.schoolIdSubject,
        this.studentIdSubject,
        this.lessonTypeSubject
      ).pipe(
        filter<[number, number, LessonType]>(( [schoolId, studentId, lessonType] ) => schoolId != null && studentId != null && lessonType != null),
        tap( _ => this.clearData()),
        tap( _ => this.prepareContextPath()),
        switchMap( _ => this.loadCurrentState()),
        switchMap( pageable => this.loadPage(pageable))
      ).subscribe()
    }

  private loadCurrentState() {
    return this.localState.get<Pageable>(this.contextPath, () => {
      if (this._lessonType === LessonType.Upcomming) {
        return Pageable.of(0, 10, ['lessonStudent.metricDetails.plannedStartDate,ASC']);
      } else {
        return Pageable.of(0, 10, ['lessonStudent.metricDetails.plannedStartDate,DESC']);
      }
    }).pipe(
      tap( pageable => this.pageable = pageable)
    );
  }

  private prepareContextPath() {
    this.contextPath = `/school/${this._schoolId}/students/${this._studentId}/lessons/${this._lessonType}`;
  }

  @Output()
  lessonDetailsEvent = new EventEmitter<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>();


  @Input()
  public set lessonType(lessonType: LessonType) {
    this._lessonType = lessonType;
    this.lessonTypeSubject.next(lessonType);
  }

  @Input()
  public set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.schoolIdSubject.next(schoolId);
  }

  public hasResults(): boolean {
    return this.data != null;
  }

  clearData(): any {
    this.pageable = null;
    this.data = null;
  }

  public getLessonTeacherPhotoUrl(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    let teacherPerson = null;
    if (lesson && lesson.teacher && lesson.teacher.person) {
      teacherPerson = lesson.teacher.person;
    }
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(teacherPerson);
  }

  public getLessonTeacher(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    if (!lesson.teacher || !lesson.teacher.person ) {
      return PersonNameExtractor.getPersonName(null);
    }
    return PersonNameExtractor.getPersonName(lesson.teacher.person);
  }

  public getLessonPlannedDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    return lesson.lessonMetric.plannedStartDate;
  }
  public getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    if (this._lessonType === LessonType.Upcomming ) {
      return lesson.lessonMetric.plannedStartDate;
    }
    return lesson.lessonMetric.plannedStartDate;
  }

  public mayBeViewed(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): boolean {
    return true;
  }

  public lessonBorderClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._lessonType === undefined) {
      return '';
    }
    return LessonTypeColors.getStudentLessonBorderColorClass(lesson);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  private loadPage(pageable: Pageable) {
    this.pageable = pageable;
    return this.localState.set<Pageable>(this.contextPath, pageable).pipe(
      switchMap( pageable => this.callForLessons(pageable)),
      tap( resultPage => this.fillMissingTeacherProfile(resultPage)),
      tap<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>( resultPage => this.data = resultPage)
    )
  }

  switchPage(pageable: Pageable) {
    this.loadPage(pageable).subscribe();
  }

  private fillMissingTeacherProfile(resultPage: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>) {
    resultPage.content
      .filter ( lesson => lesson.teacher && lesson.teacher.person && ! lesson.teacher.person.personalProfile )
      .forEach( lesson => {
        const p = lesson.teacher.person;
        const profile = new ApiPersonalProfile();
        profile.name = p.name;
        profile.surname = p.surname;
        profile.email = p.emailAddress;
      })
  }

  private callForLessons(pageable: Pageable) {
    if (this._lessonType === LessonType.Upcomming) {
      return this.managerRest.findStudentUpcomingLessons(this._schoolId, this.studentId, pageable);
    } else {
      return this.managerRest.findStudentPastLessons(this._schoolId, this.studentId, pageable);
    }
  }

  public lessonBadgeClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._lessonType === undefined) {
      return '';
    }
    return LessonTypeColors.getStudentLessonBorderColorClass(lesson);
  }

  public getInitials(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return PersonNameExtractor.getInitials(lesson.teacher.person)
  }
}
