<div #modal class="modal fade" tabindex="-1"  aria-hidden="true">
  <div class="modal-dialog "
       [ngClass]="{
       'modal-dialog-scrollable' : scrollable,
       'modal-xl': isExtraLarge(),
       'modal-lg' : isLarge(),
       'modal-sm' : isSmall()
       }"> <!-- modal-dialog-scrollable modal-xl -->
    <ng-content></ng-content>
  </div>
</div>
