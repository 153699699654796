import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";

export class ToastNotification {
  constructor(public title: string, public message: string, public toastClasses?: string) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationsService {

  toDisplay = new Subject<ToastNotification>();

  display(notification: ToastNotification) {
    this.toDisplay.next(notification);
  }
}
