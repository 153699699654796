<div class="card mb-5">
  <div class="card-body">
    <app-teacher-main-calendar [teacherId]="teacherId" [schoolId]="schoolId"></app-teacher-main-calendar>
  </div>
</div>

<div class="card mb-5">
  <div class="card-header border-bottom border-secondary pb-4">
    <div class="row w-100">
      <div class="col">
        <h5 class="mb-0">
          All future Lessons <span class="text-muted" *ngIf="futureData">( {{futureData.totalElements}} )</span>
        </h5>
      </div>
      <app-paginator class="col-auto ms-auto"
                      [data]="futureData"
                      [pageable]="futurePageable"
                      (newPage)="futureLessons.switchPage($event)"
      ></app-paginator>
    </div>
  </div>
  <div class="card-body">
    <app-teacher-lessons
      [schoolId]="schoolId"
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Upcoming"
      #futureLessons
      (dataLoaded)="futureData = $event"
      (currentPageable)="futurePageable = $event"
    ></app-teacher-lessons>
  </div>
</div>

<div class="card mb-5" >
  <div class="card-header border-bottom border-secondary pb-4">
    <div class="row w-100">
      <div class="col">
        <h5 class="mb-0">
        Incomplete lessons <span class="text-muted" *ngIf="incompleteData">( {{incompleteData.totalElements}} )</span>
        </h5>
      </div>
      <app-paginator class="col-auto"
                     [data]="incompleteData"
                     [pageable]="incompletePageable"
                     (newPage)="incompleteLessons.switchPage($event)"
      ></app-paginator>
    </div>
  </div>
  <div class="card-body">
    <app-teacher-lessons
      [schoolId]="schoolId"
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Incomplete"
      #incompleteLessons
      (dataLoaded)="incompleteData = $event"
      (currentPageable)="incompletePageable = $event"

    ></app-teacher-lessons>
  </div>
</div>

<div class="card mb-5" >
  <div class="card-header border-bottom border-secondary pb-4">
    <div class="row w-100">
      <div class="col">
        <h5 class="mb-0">
          Past lessons <span class="text-muted" *ngIf="pastData">( {{pastData.totalElements}} )</span>
        </h5>
      </div>
      <app-paginator class="col-auto ms-auto"
                     [data]="pastData"
                     [pageable]="pastPageable"
                     (newPage)="pastLessons.switchPage($event)"
      ></app-paginator>
    </div>
  </div>
  <div class="card-body">
    <app-teacher-lessons
      [schoolId]="schoolId"
      [teacherId]="teacherId"
      [lessonType]="lessonTypes.Past"
      #pastLessons
      (dataLoaded)="pastData = $event"
      (currentPageable)="pastPageable = $event"
    ></app-teacher-lessons>
  </div>
</div>
