import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {forkJoin} from "rxjs";
import {switchMap, take, tap} from "rxjs/operators";
import {StudentVideoRestService} from "../../../services/rest/student-video-rest.service";
import {ScheduleRow} from "../../../model/schedule";

@Component({
  selector: 'app-student-schedule-details-page',
  templateUrl: './student-schedule-details-page.component.html',
  styleUrls: ['./student-schedule-details-page.component.scss']
})
export class StudentScheduleDetailsPageComponent implements OnInit {
  private schoolId: number;
  private studentId: number;
  private scheduleId: number;
  data: ScheduleRow;

  constructor(
    activatedRoute: ActivatedRoute,
    private rest: StudentVideoRestService
  ) {
    forkJoin([
      activatedRoute.paramMap.pipe(take(1)),
      activatedRoute.parent.paramMap.pipe(take(1))
    ]).pipe(
      tap<[ParamMap, ParamMap]>( ([params, parentParams]) => this.storeParams(params, parentParams)),
      switchMap( _ => this.loadSchedule())
    ).subscribe();
  }

  private storeParams(params: ParamMap, parentParams: ParamMap) {
    this.schoolId = Number(parentParams.get('schoolId'));
    this.studentId = Number(parentParams.get('studentId'));
    this.scheduleId = Number(params.get('scheduleId'));
  }

  ngOnInit(): void {
  }

  private loadSchedule() {
    return this.rest.getScheduleDetails(this.schoolId, this.studentId, this.scheduleId).pipe(
      tap( scheduleRow => this.data = scheduleRow)
    )
  }


}
