<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Documents'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" [ngClass]="{active: item == last()}" aria-current="page" *ngFor="let item of documentsPath">
          <a routerLink="/school/{{schoolId}}/documents/{{item.key}}">{{item.shortName}}</a>
        </li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-header justify-content-between">
        <a class="btn btn-primary btn-back rounded-circle cursor-pointer my-auto mt-sm-0" *ngIf="documentRoot && documentRoot !== last()" (click)="up()">
          <app-icon name="angle-left" class="icon-white icon-md"></app-icon>
        </a>
        <div class="btn-back" *ngIf="!documentRoot || documentRoot === last()"></div>
        <h4 class="my-auto mx-2 text-center">Documents repository
        </h4>
        <button class="btn btn-primary rounded-circle p-2 lh-1 my-auto mt-sm-0" (click)="refresh()" data-bs-toggle="tooltip" title="Refresh">
          <app-icon name="refresh" class="icon-sm icon-white"></app-icon>
        </button>
      </div>
      <div class="card-body">
        <ul *ngIf="lastAsDir()" class="list-group w-100 gap-2">
          <div *ngFor="let item of lastAsDir().innerEntries">
            <!-- dir -->
            <a *ngIf="isDir(item)"
              class="list-group-item d-inline-flex row w-100 p-3 p-md-4 gx-0"
              routerLink="/school/{{schoolId}}/documents/{{item.key}}"
            >
              <button class="btn border border-2 circle rounded-circle p-2 lh-1 me-2 me-md-3">
                <app-icon name="folder" class="icon-primary icon-sm"></app-icon>
              </button>
              <span class="my-auto col-auto">{{item.name}}</span>
              <button class="btn border border-2 circle rounded-circle d-none d-sm-block ms-auto">
                <app-icon name="angle-right" class="icon-primary icon-md"></app-icon>
              </button>
            </a>
            <!-- file -->
            <a *ngIf="!isDir(item)"
              class="list-group-item d-inline-flex row w-100 p-3 p-md-4 gx-0"
              (click)="download(item)"
            >
              <button class="btn border border-2 circle rounded-circle p-2 lh-1 me-2 me-md-3">
                <app-icon name="file" class="icon-primary icon-sm"></app-icon>
              </button>
              <span class="my-auto text-primary col-8 col-lg-10">
                {{item.name}}
                <span class="badge bg-secondary rounded p-1 xsmall my-auto">
                  <span class="text-white xsmall" *ngIf="getExtension(item)">
                    {{getExtension(item)}}
                  </span>
                  <span *ngIf="getSize(item)">
                    / {{getSize(item)}}
                  </span>
                </span>
              </span>
              <button class="btn border border-2 circle rounded-circle d-none d-sm-block ms-auto px-0">
                <app-icon name="download" class="icon-primary"></app-icon>
              </button>
            </a>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

