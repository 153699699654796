import { Component, OnInit, Input } from '@angular/core';
import { StudentCommitsFilter, ApiLessonCommit, LessonBundeFilter, ApiLessonBundle } from 'src/app/model/rest/rest-model';
import { Pageable, Page } from 'src/app/model/rest/base';
import { Observable } from 'rxjs';
import {ManagerRestService} from "../../services/col2/manager-rest.service";

export class WalletRow {
  date: Date;
  action: string;
  bundleName: string;
  amount: number;
  balance: number;

  constructor(date: Date, action: string, bundleName: string, amount: number) {
    this.date = date;
    this.action = action;
    this.bundleName = bundleName;
    this.amount = amount;
  }
}
@Component({
  selector: 'app-student-transactions',
  templateUrl: './student-transactions.component.html',
  styleUrls: ['./student-transactions.component.css']
})
export class StudentTransactionsComponent implements OnInit {

  _lang: string;
  private searchFilter: StudentCommitsFilter;
  private pageable: Pageable;
  private lastLoadedPage: Page<ApiLessonCommit>;
  private allListedResults: ApiLessonCommit[];
  viewRows: WalletRow[];
  availableLessons: number;
  hasNext = false;
  private _studentId: number;
  private _schoolId: number;

  constructor(
    private managerRest: ManagerRestService) {}

  ngOnInit() {
    this.reloadProductCommits();
  }

  @Input()
  public set lang(lang: string) {
    this._lang = lang;
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
  }

  loadCommits(filter: StudentCommitsFilter, pageable: Pageable): Observable<Page<ApiLessonCommit>> {
    if (this._schoolId) {
      return this.managerRest.findStudentCommits(this._schoolId, this._studentId, filter, pageable)
    }
  }

  loadAvailableStudentLessonBundles() {
    if (this._schoolId) {
      return this.managerRest.findStudentLessonBundles(this._schoolId, this._studentId,
        new LessonBundeFilter(this._lang, true),
        Pageable.of(0, 100, null));
    } else {
    }
  }

  reloadProductCommits(): any {
    if (!this._studentId) return;
    this.clear();

    this.availableLessons = 0;
    this.loadAvailableStudentLessonBundles()
    .subscribe(
      availableBundlesPage => {
        availableBundlesPage.content.forEach(b => this.availableLessons += b.available);
        this.searchFilter = new StudentCommitsFilter(this._lang, null, null, null);
        this.pageable = Pageable.of(0, 10, ['lessonCommit.updateDate,desc']);
        this.computeNextPage();
      }
    );
  }

  computeNextPage(): any {
    this.loadCommits(this.searchFilter, this.pageable)
    .subscribe( page => {
      this.lastLoadedPage = page;
      this.allListedResults = this.allListedResults.concat(page.content);
      this.calculateViewRows();
      this.hasNext = !page.last;
    });
  }

  public moveNext() {
    if (!this.hasNext) {return; }
    this.pageable = this.pageable.next();
    this.computeNextPage();
  }

  calculateViewRows() {
    this.viewRows = [];
    const bundles: {[id: number]: ApiLessonBundle} = {};

    this.allListedResults.forEach( lessonCommit => bundles[lessonCommit.bundle.id] = lessonCommit.bundle );

    for (const bundle in bundles) {
      if (bundles.hasOwnProperty(bundle)) {
        const element = bundles[bundle];
          this.viewRows.push(new WalletRow(element.date, 'purchased', element.name, element.registeredLessons ));
      }
    }

    this.allListedResults.forEach( commit => {
      if (commit.status === 'PENDING') {
        this.viewRows.push(new WalletRow(commit.createDate, 'reserved', commit.bundle.name, -1));
      } else if (commit.status === 'CANCELLED') {
        this.viewRows.push(new WalletRow(commit.createDate, 'reserved', commit.bundle.name, -1));
        this.viewRows.push(new WalletRow(commit.updateDate, 'cancelled', commit.bundle.name, +1));
      } else {
        this.viewRows.push(new WalletRow(commit.updateDate, 'finished', commit.bundle.name, -1));
      }
    });

    this.viewRows = this.viewRows.sort((l, r): number => {
      return new Date(r.date).getTime() - new Date(l.date).getTime();
    });

    let currentlyAvailable = this.availableLessons;
    this.viewRows.forEach( r =>  {
      r.balance = currentlyAvailable;
      currentlyAvailable -= r.amount;
    });
  }

  clear(): any {
    this.searchFilter = null;
    this.pageable = null;
    this.hasNext = false;
    this.lastLoadedPage = null;
    this.allListedResults = [];
  }
}
