
export class SnipcartItem {
  public constructor(
    public quantity: number,
    public id: string,
    public name: string,
    public description: string,
    public price: number,
    public unitPrice: number,
    public totalPrice: number,
    public url: string
  ) {}
  public uniqueId: string;
}

