<div class="card mb-5" id="noteForm">
  <div class="card-header">
    <h5>Add a note to the school</h5>
  </div>
  <div class="card-body">
    <form #f="ngForm" (ngSubmit)="f.form.valid && onSave()" *ngIf="noteContent">
      <div class="row">
        <div class="col-12 col-xl-6 mb-4">
          <div class="form-floating">
            <select
              id="type"
              name="type"
              #type="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && type.invalid,
                'select-not-empty': getNoteTypeName(noteContent?.type)
              }"
              [(ngModel)]="noteContent.type"
              class="form-select"
              required
            >
              <option
                *ngFor="let type of getNoteTypes(); let i = index"
                [selected]="i == 0"
                [value]="getNoteTypeName(type)"
              >
                {{ getNoteTypeName(type) }}
              </option>
            </select>
            <label class="field-required">Note type</label>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="form-floating">
            <textarea
              class="form-control"
              id="content"
              name="content"
              rows="3"
              placeholder="content"
              #content="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && content.invalid }"
              [(ngModel)]="noteContent.value"
              required
            ></textarea>
            <label for="content" class="field-required">Note content</label>
            <div
              *ngIf="f.submitted && content.invalid"
              class="invalid-feedback"
            >
              <div *ngIf="content.errors.required">Field is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto ms-auto">
          <button type="submit" class="btn btn-sm btn-primary rounded-pill px-4 py-2">
            {{ editingNote? 'Update note' : 'Add note' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="card mb-5">
  <div class="card-header row">
    <div class="col">
      <h5>Notes list</h5>
    </div>
    <app-paginator class="col-auto ms-auto"
      [data]="notePage"
      [pageable]="pageable"
      (newPage)="switchPage($event)"
    ></app-paginator>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr class="table-row table-row-head">
            <th class="text-nowrap">Date
              <div class="d-inline-flex">
                <button class="btn btn-sm scale-icon-up-hover p-0" (click)="sortBy('DateDesc')">
                  <app-icon name="expand-more"></app-icon>
                </button>
                <button class="btn btn-sm scale-icon-up-hover p-0" (click)="sortBy('DateAsc')">
                  <app-icon name="expand-less"></app-icon>
                </button>
              </div>
            </th>
            <th class="text-nowrap">Author
              <div class="d-inline-flex">
                <button class="btn btn-sm scale-icon-up-hover p-0" (click)="sortBy('AuthorDesc')">
                  <app-icon name="expand-more"></app-icon>
                </button>
                <button class="btn btn-sm scale-icon-up-hover p-0" (click)="sortBy('AuthorAsc')">
                  <app-icon name="expand-less"></app-icon>
                </button>
              </div>
            </th>
            <th class="position-relative" style="min-width: 7rem;">
              <select class="form-select form-select-filter text-capitalize px-0" [(ngModel)]="filterType" (ngModelChange)="filterByType($event)">
                <option selected [value]="null">Type</option>
                <option *ngFor="let type of getNoteTypes(); let i = index"
                [value]="getNoteTypeName(type)">{{ getNoteTypeName(type)}}</option>
              </select>
            </th>
            <th style="min-width: 7rem;">Note</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="table-body" *ngIf="notePage">
          <tr *ngFor="let note of notePage.content" class="table-row">
            <td>{{note.createDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{note.author.details.name}} {{ note.author.details.surname }}</td>
            <td class="text-capitalize">{{note.content.type}}</td>
            <td class="note-content">{{note.content.value}}</td>
            <td class="text-nowrap pe-2">
              <button class="btn btn-outline-secondary rounded-circle p-1 me-1" (click)="startEditingNote(note)">
                <app-icon name="edit" class="icon-primary icon-sm"></app-icon>
              </button>
              <button class="btn btn-outline-secondary rounded-circle p-1" (click)="deleteNote(note)">
                <app-icon name="delete" class="icon-danger icon-sm"></app-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
