import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
  private theme: string
  private themeSource = new BehaviorSubject<string>('light');
  private themeSource$ = this.themeSource.asObservable();

  constructor() {}

  initialize() {
      this.theme = localStorage.getItem('defaultTheme')
      if(!this.theme)
          this.theme = 'light'
      this.changeTheme(this.theme)
  }

  getTheme(): Observable<string> {
      return this.themeSource$
  }

  changeTheme(theme: string) {
      if(theme === 'dark') {
          localStorage.setItem('defaultTheme', 'dark')
          document.body.classList.remove('light-theme')
      }
      else {
          localStorage.setItem('defaultTheme', 'light')
          document.body.classList.add('light-theme')
      }
      this.themeSource.next(theme)
  }

  onThemeChange(theme: string) {
      if(theme === 'dark')
          this.changeTheme('light')
      else
          this.changeTheme('dark')
  }

  getThemeName(theme: string) {
      if(theme === 'dark')
          return 'Light Mode'
      else
          return 'Dark Mode'
  }
}
