import {ApiCourseProduct} from "../../model/rest/rest-model";

export class LangProductMapper {
    static langCodes = ['en', 'sp', 'en.bs', 'en.ch'];
    static langCodeName = ['English', 'Spanish', 'Business English', 'English For Children'];
    static iconImages = ['/assets/img/english%20icon.png', '/assets/img/spanish%20icon.png', '/assets/img/business.png', ''];
    static activeLangCodes = [LangProductMapper.langCodes[0], LangProductMapper.langCodes[1], LangProductMapper.langCodes[2], ];
    private static cspaLangCodes = ['english' , 'spanish'];
    private static cspaNewLangCodes = [ 'en', 'sp', 'enbus', 'encfk'];

    public static getCourseProducts(): ApiCourseProduct[] {
        const res: ApiCourseProduct[] = [];
        for (let i = 0 ; i < this.langCodes.length ; i++) {
            const prod = new ApiCourseProduct();
            prod.code = this.langCodes[i];
            prod.name = this.langCodeName[i];
            res.push(prod);
        }
        return res;
    }

    public static getImg(lang: string): string {
        return this.iconImages[this.langCodes.indexOf(lang)];
    }

    public static mapLangCodeToLangName(lang: string): string {
        return this.langCodeName[this.langCodes.indexOf(lang)];
    }
    public static mapLangFromCspa(lang: string) {
        return this.langCodes[this.cspaLangCodes.indexOf(lang)];
    }

    public static mapLangToCspa(lang: string) {
        return this.cspaLangCodes[this.langCodes.indexOf(lang)];
      }

  static mapLangToNewCspa(langCode: string) {
    return this.cspaNewLangCodes[this.langCodes.indexOf(langCode)];
  }
}
