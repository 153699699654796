<app-modal #modal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Password Change</h5>
      <button type="button" class="btn-close" (click)="modal.hide()"  aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div  *ngIf="complete && !error">
        <div class="alert alert-success">
          Password has been changed successfully.
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-primary" (click)="modal.hide()">Close</button>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="complete && error">
        Got error: {{error}}. <br>
        Please try again or contact the administrator.
      </div>
      <form *ngIf="model" #f="ngForm" (ngSubmit)="f.form.valid && submitActivation()" appPasswordVerifyValidator>
        <div class="row gy-3">
          <div class="col-12">
            <div class="form-floating">
              <input
              class="form-control form-control-sm"
              name="currentPassword"
              placeholder="currentPassword"
              type="password"
              autocomplete="off"
              [(ngModel)]="model.managerPassword"
              #currentPassword="ngModel"
              required="required"
              [ngClass]="{'is-invalid': f.submitted && currentPassword.invalid}"
              minlength="3"
              >
              <label>Your ADMIN password</label>
              <div *ngIf="f.submitted && currentPassword.invalid">
                <div *ngIf="currentPassword.errors.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="currentPassword.errors.minlength" class="text-danger xsmall"> {{currentPassword.errors.minlength.requiredLength}} characters or more required</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input
              class="form-control form-control-sm"
              name="password"
              placeholder="password"
              type="password"
              [(ngModel)]="model.password"
              autocomplete="off"
              #password="ngModel"
              required="required"
              minlength="7"
              [ngClass]="{'is-invalid': f.submitted && password.invalid}"
              >
              <label>User's new password</label>
              <div *ngIf="f.submitted && password.invalid">
                <div *ngIf="password.errors.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="password.errors.minlength" class="text-danger xsmall"> {{password.errors.minlength.requiredLength}} characters or more required</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input
              class="form-control form-control-sm"
              name="password-verify"
              placeholder="password-verify"
              type="password"
              [(ngModel)]="passwordRepeat"
              autocomplete="off"
              #passwordVerify="ngModel"
              required="true"
              minlength="7"
              [ngClass]="{'is-invalid': f.submitted && (passwordVerify.invalid || f.errors?.differentPassword)}"
              >
              <label>Retype user's new password</label>
              <div *ngIf="f.submitted && (passwordVerify.invalid || f.errors?.differentPassword)" class="invalid-feedback">
                <div *ngIf="passwordVerify.errors?.required" class="text-danger xsmall">Field is required</div>
                <div *ngIf="passwordVerify.errors?.minlength" class="text-danger xsmall"> {{passwordVerify.errors.minlength.requiredLength}} characters or more required</div>
                <div *ngIf="f.errors?.differentPassword" class="text-danger xsmall">Passwords are different</div>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-secondary rounded-pill px-3 me-2" (click)="modal.hide()">Cancel</button>
            <button class="btn btn-primary rounded-pill px-3" [disabled]="inProgress">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</app-modal>
