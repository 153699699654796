<div class="jumbotron py-4 " *ngIf="hasSchool()">
  <div class="row">
    <div class="col-auto">
      <h5>
        {{ getName() }} {{ getStatusName() }}
      </h5>
    </div>
    <div class="col text-end">
      <span class="" [ngClass]="{'text-danger': expires(), 'text-muted small': !expires()}">Accreditation expires:  {{getExpiryDate() | date: 'dd MMMM yyyy'}}</span><br/>
      <a class="text-decoration-none small" href="{{getCasaAccreditationLink()}}" target="_blank">Manage my Accreditation</a>
    </div>
  </div>
</div>
