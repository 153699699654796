<div class="container pt-5">
  <h2 class="text-center mb-4">Payment cancelled</h2>
  <p class="text-center mb-4">
    If you experienced any problems or have any questions, please <a href="mailto:zibi.wisniewski@callan.co.uk">contact us</a>.
  </p>

  <div class="text-center">
    <a class="btn btn-primary" routerLink="/school/{{schoolId}}/dashboard">Back to dashboard</a>
  </div>
</div>
