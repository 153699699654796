<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Teachers'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Teachers</li>
      </ol>
    </nav>
    <div class="row mb-4">
      <div class="col-auto mb-3 mb-sm-0">
        <h4 class="mb-0">All teachers <span class="text-primary">( {{countTotal()}} )</span>
        </h4>
      </div>
      <div class="col-auto ms-auto">
        <a class="btn btn-primary px-4 py-2 rounded-pill" routerLink="/school/{{schoolId}}/teacher/new">Add new teacher</a>
      </div>
    </div>
    <div class="card">
      <form class="card-header flex-column flex-md-row" (ngSubmit)="doSearch()">
        <div class="col-12 col-md-6">
          <div class="position-relative">
            <input name="search-term" type="text" class="form-control p-3 mb-3 mb-md-0" [(ngModel)]="searchTerm" (ngModelChange)="searchUpdated()" placeholder="Search">
            <button type="submit" class="btn position-absolute end-0 top-0 bottom-0">
              <app-icon name="search" class="icon-primary"></app-icon>
            </button>
          </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 ms-auto" *ngIf="currentState">
          <select name="sorting-order" [(ngModel)]="currentState.sortingRule" (ngModelChange)="doSearch()" class="form-select p-3"
          [ngClass]="{ 'select-not-empty': currentState.sortingRule }">
            <option *ngFor="let rule of sortingRules" [ngValue]="rule">{{rule.name}}</option>
          </select>
        </div>
      </form>
      <div class="card-body">
        <div class="table-responsive" *ngIf="hasTeachers()">
          <table class="table" style=" line-height: 1.3;">
            <tbody class="table-body">
            <tr *ngFor="let teacher of getTeachersPage()" class="table-row table-row-hover">
              <td class="align-middle d-flex" (click)="open(teacher)">
                <div class="initials-circle-box me-3">
                  <span class="m-auto small text-white">{{ getInitials(teacher) }}</span>
                </div>
                <div class="my-auto" style="max-width: 13rem;">
                  <p class="text-muted small mb-0">Teacher:</p>
                  <div class="d-flex flex-column flex-lg-row d-lg-block">
                    <span class="me-2">{{getName(teacher)}}</span>
                    <span class="text-uppercase badge xsmall rounded-2 bg-warning w-fit-content">{{getStatus(teacher)}}</span>
                  </div>
                </div>
              </td>
              <td class="align-middle td-date" (click)="open(teacher)">
                <p class="text-muted small mb-0">Email:</p>
                <span>{{getMail(teacher)}}</span>
              </td>
              <td class="align-middle text-end">
                <div class="dropleft">
                  <a class="dropdown-toggle-split btn btn-outline-secondary-2 rounded-circle py-2" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                    <app-icon name="more-vertical" class="icon-primary"></app-icon>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuReference">
                    <a class="dropdown-item" (click)="open(teacher)">
                      <app-icon name="open" class="icon-white"></app-icon>
                      Open
                    </a>
                    <a class="dropdown-item" (click)="changePassword(teacher)">
                      <app-icon name="password" class="icon-white"></app-icon>
                      Change password
                    </a>
                    <ng-container *ngIf="isActive(teacher)">
                      <a class="dropdown-item" (click)="reloadTeacher(teacher)">
                        <app-icon name="refresh" class="icon-white"></app-icon>
                        Refresh eBooks and exercises
                      </a>
                      <a class="dropdown-item" (click)="resetBookShares(teacher)">
                        <app-icon name="restart" class="icon-white"></app-icon>
                        Reset eBooks download limit
                      </a>
                      <a class="dropdown-item" (click)="block(teacher)">
                        <app-icon name="block" class="icon-white"></app-icon>
                        Block
                      </a>
                    </ng-container>
                    <a class="dropdown-item" (click)="activate(teacher)"
                      *ngIf="canActivate(teacher)">
                      <app-icon name="open" class="icon-white"></app-icon>
                      Activate
                    </a>
                    <a class="dropdown-item" (click)="delete(teacher)">
                      <app-icon name="delete" class="icon-white"></app-icon>
                      Delete
                    </a>
                    <a class="dropdown-item" (click)="changeEmail(teacher)">
                      <app-icon name="email" class="icon-white"></app-icon>
                      Change login email
                    </a>
                    <a class="dropdown-item" (click)="delete(teacher)"
                      *ngIf="functionalAccessService.isSuperUser() | async"
                    >
                      <app-icon name="delete" class="icon-white"></app-icon>
                      Permanent delete
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center" *ngIf="getTeachersPage().length === 0">
                No results found
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer d-flex px-3" *ngIf="hasPages()">
        <div class="btn-group btn-group-sm ms-auto">
          <a class="btn btn-outline-secondary rounded-pill rounded-end" [ngClass]="{disabled: !hasPrev()}" (click)="prev()">
            <app-icon name="angle-left" class="icon-primary"></app-icon>
          </a>
          <a class="btn btn-outline-secondary px-3">{{getPageNumber()}}</a>
          <a class="btn btn-outline-secondary rounded-pill rounded-start" [ngClass]="{disabled: !hasNext()}" (click)="next()">
            <app-icon name="angle-right" class="icon-primary"></app-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-person-password-change #passwordModal [changePasswordCallback]="changePasswordCallback"></app-person-password-change>
<app-person-email-change #emailModal [changeEmailCallback]="changeEmailCallback"></app-person-email-change>
