<div class="card">
  <div class="card-header border-bottom border-secondary pb-4">
    <div class="d-flex flex-column flex-lg-row gap-2 w-100">
      <h5 class="text-nowrap my-auto me-lg-5">Assigned products</h5>
      <div class="d-flex flex-column flex-md-row gap-2 ms-xl-auto">
        <ng-container *ngIf="isSubscribed(); else notSubscribed">
          <p class="small my-auto">Teacher is included in your 3rd Edition Teacher eBooks subscription.</p>
          <button class="btn btn-primary rounded-pill ms-auto small" type="button" (click)="subscriptionModal.teacherSubscription.show()">Unsubscribe</button>
        </ng-container>
        <ng-template #notSubscribed>
          <p class="small my-auto">Teacher isn’t included in your 3rd Edition Teacher eBooks subscription.</p>
          <button class="btn btn-primary rounded-pill ms-auto small" type="button" (click)="subscriptionModal.teacherSubscription.show()">Subscribe</button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card-body">
    <app-account-products
      productList="teacher"
      [schoolId]="schoolId"
      [productsAdapter]="productsAdapter"
      [isTeacherSubscribed]="isTeacherSubscribed"
      (openModalEvent)="subscriptionModal.teacherSubscription.show()"
    ></app-account-products>
  </div>
</div>
<app-teacher-subscription-modal
  #subscriptionModal
  (subscriptionEvent)="changeSubscription()"
  [isTeacherSubscribed]="isTeacherSubscribed"
></app-teacher-subscription-modal>
