import { HttpParams } from '@angular/common/http';
import {Output, EventEmitter, Injectable} from '@angular/core';

export abstract class FilterBase {
  private params: HttpParams;

  public apply(params: HttpParams): HttpParams {
    this.params = params;
    this.doApply();
    return this.params;
  }

  protected append(name: string, value: string) {
    this.params = this.params.append(name, value);
  }

  protected appendArray(name: string, value: string[]) {
    value.forEach( v => {
      this.params = this.params.append(name, v);
    });
  }

  protected abstract doApply(): void;

}

export class ComponentEvent {

  static ET_TOTAL_ELEMENTS = 'ET_TOTAL_ELEMENTS';
  static ET_LOADING = 'ET_LOADING';
  static ET_LOADED = 'ET_LOADED';
  static ET_ERROR = 'ET_ERROR';
  static ET_INITIALIZED = 'ET_INITIALIZED';
  eventType: string;
  totalElements: number;
  data: any;

  static numberOfElements(nbOfElements: number): ComponentEvent {
    const res = new ComponentEvent();
    res.eventType = ComponentEvent.ET_TOTAL_ELEMENTS;
    res.totalElements = nbOfElements;
    return res;
  }

  static error(): ComponentEvent {
    const res = new ComponentEvent();
    res.eventType = ComponentEvent.ET_ERROR;
    return res;
  }

  static loading(): ComponentEvent {
    const res = new ComponentEvent();
    res.eventType = ComponentEvent.ET_LOADING;
    return res;
  }

  static loaded(data?: any): ComponentEvent {
    const res = new ComponentEvent();
    res.eventType = ComponentEvent.ET_LOADED;
    res.data = data;
    return res;
  }

  static initialized(): ComponentEvent {
    const res = new ComponentEvent();
    res.eventType = ComponentEvent.ET_INITIALIZED;
    return res;
  }
}

@Injectable()
export abstract class StateAwareComponent<T extends ComponentEvent> {

  @Output()
  protected stateEvent = new EventEmitter<T>();

  protected eventLoading() {
    this.stateEvent.next(ComponentEvent.loading() as T);
  }

  protected eventLoaded(data?: any) {
    this.stateEvent.next(ComponentEvent.loaded(data) as T);
  }

  protected eventError() {
    this.stateEvent.next(ComponentEvent.error() as T);
  }

  protected eventNumberOfElements(n: number) {
    this.stateEvent.next(ComponentEvent.numberOfElements(n) as T);
  }
}

export class Pageable {

  size: number;
  page: number;
  sort: string[];

  constructor(page: number, size: number, sort: string[]) {
    this.size = size;
    this.page = page;
    this.sort = sort;
  }

  public getHttpParams(): HttpParams {
    return this.appendParams(new HttpParams());
  }

  public appendParams(params: HttpParams): HttpParams {
    let result = params;
    if (this.page != null) {
      result = result.append('page', this.page.toString());
    }
    if (this.size != null) {
      result = result.append('size', this.size.toString());
    }
    if (this.sort != null) {
      this.sort.forEach( s => {
        result = result.append('sort', s );
      })
    }
    return result;
  }

  /**
return the http params with pagination applied. Method works even if pageable from
argument is an empty value. Params will be unchanged then.
  */
  public static appedPageableParams(params: HttpParams, pageable: Pageable) {
      return pageable.appendParams(params);
  }

  public static of(page: number, size: number, sort: string[]): Pageable {
    return new Pageable(page, size, sort);
  }

  next(): Pageable {
    return Pageable.of(this.page + 1, this.size, this.sort);
  }


  prev() {
    return Pageable.of(this.page - 1, this.size, this.sort);
  }

  first() {
    return Pageable.of(0, this.size, this.sort);
  }

  //to delete
  inital = false;
  cloneForInitial(): Pageable {
    const res = this.clone();
    res.inital = true;
    return res;
  }
  clone(): Pageable {
    return new Pageable(this.page, this.size, this.sort);
  }
  consumeInitial(): Pageable {
    if (this.inital) {
      this.inital = false;
      const res = this.clone();
      res.page = 0;
      res.size = (this.page + 1) * this.size;
      return res;
    } else {
      return this;
    }
  }
}

export class ResultPageableSort {
  sorted: boolean;
  unsorted: boolean;
}

export class ResultPageable {
  sort: ResultPageableSort;
  offset: number;
  pageSize: number;
  pageNumber: number;
  paged: boolean;
  unpaged: boolean;
}

export class Page<T> {
  content: T[];
  totalElements: number;
  pageable: ResultPageable;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  first: boolean;
  sort: ResultPageableSort;
  numberOfElements: number;
  empty: boolean;

  public static empty<E>(): Page<E> {
    const res = new Page<E>();
    res.content = [];
    res.totalElements = 0;
    res.totalPages = 0;
    res.last = true;
    res.size = 10;
    res.number = 0;
    res.first = true;
    res.numberOfElements = 0;
    return res;
  }
}

export class Language {
  name: string;
  code: string;

  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }
}

export class Timezone {
  name: string;
  code: string;
  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }
}


export class Languages {
  static English = new Language('en', 'English');
  static Spanish = new Language('es', 'Español');
  static Italian = new Language('it', 'Italiano');
  static Polish = new Language('pl', 'Polski');
  static German = new Language('de', 'Deutsch');
  static Russian = new Language('ru', 'Pусский');

  static values = [ Languages.English, Languages.Spanish, Languages.Italian, Languages.Polish, Languages.German, Languages.Russian ];
}
