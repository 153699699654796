<form *ngIf="profile" #f="ngForm" (ngSubmit)="f.form.valid && onSave()">
  <div class="row mx-auto form-file mb-4" (click)="openFileChoose()">
        <label for="photo" class="form-label d-flex justify-content-between w-100">
            <div class="d-flex flex-column" *ngIf="!profile || !profile.profilePhoto">
                <span class="f-12 text-muted">No photo</span>
                <h4 *ngIf="_editable" class="mb-0">Click to upload</h4>
            </div>
            <div class="progress mb-2" *ngIf="uploadProgress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width':uploadProgress + '%'}"></div>
            </div>
            <div class="photo-square" [ngClass]="profile.profilePhoto? 'border-0' : ''">
                <div  class="photo-circle img-thumbnail d-flex justify-content-center align-items-center"
                [style.background-image]="getProfilePhoto(profile)">
                <app-icon name="photo-add" *ngIf="!profile.profilePhoto" class="icon-secondary m-auto"></app-icon>
                </div>
            </div>
        </label>
        <input
          #fileInput
          class="form-control d-none"
          (change)="onProfileImageUpload($event.target.files)"
          accept="image/svg, image/png, image/jpg, image/gif"
          type="file" id="photo"
        >
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="name"
            placeholder="Name"
            #name="ngModel"
            [(ngModel)]="profile.name" name="name"
            [ngClass]="{ 'is-invalid': f.submitted && name.invalid}"
            [attr.disabled]="editable ? null : ''"
            required>
        <label for="name" class="field-required">Name</label>
        <div *ngIf="f.submitted && name.invalid" >
            <span *ngIf="name.errors.required" class="d-block invalid-feedback f-12">Field is required</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="text" class="form-control" id="surname"
            placeholder="Surname"
            #surname="ngModel"
            [(ngModel)]="profile.surname" name="surname"
            [ngClass]="{ 'is-invalid': f.submitted && surname.invalid}"
            [attr.disabled]="editable ? null : ''"
            required>
        <label for="surname" class="field-required">Surname</label>
        <div *ngIf="f.submitted && surname.invalid" >
            <span *ngIf="surname.errors.required" class="d-block invalid-feedback f-12">Field is required</span>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select class="form-select"
          id="nationality"
          #nationality="ngModel"
          [(ngModel)]="profile.nationalityIso"
          name="nationality"
          [attr.disabled]="editable ? null : ''"
          required
          [ngClass]="{ 'is-invalid': f.submitted && nationality.invalid,
            'select-not-empty': profile.nationalityIso}"
          >
            <option *ngFor="let n of countries"
              [value]="n.isoCode">
              {{n.name}}
            </option>
        </select>
        <label for="nationality" class="field-required">Nationality</label>
        <div *ngIf="f.submitted && nationality.invalid" class="invalid-feedback">
          <div *ngIf="nationality.errors.required">Field is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <select class="form-select"
          id="country"
          #country="ngModel"
          [(ngModel)]="profile.countryIso"
          name="country"
          [attr.disabled]="editable ? null : ''"
          required
          [ngClass]="{ 'is-invalid': f.submitted && country.invalid,
            'select-not-empty': profile.countryIso}"
          >
            <option *ngFor="let c of countries"
              [value]="c.isoCode">
              {{c.name}}
            </option>
        </select>
        <label for="country" class="field-required">Location</label>
        <div *ngIf="f.submitted && country.invalid" class="invalid-feedback">
          <div *ngIf="country.errors.required">Field is required</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="date" class="form-control" id="dob"
            placeholder="Date of Birth"
            #dob="ngModel"
            [ngModel]="profile.birthDate | date:'yyyy-MM-dd'"
            name="dob"
            [ngClass]="{ 'is-invalid': f.submitted && dob.invalid}"
            [attr.disabled]="editable ? null : ''"
            (ngModelChange)="profile.birthDate = $event"
            required>
        <label for="dob" class="field-required">Date of Birth</label>
        <div *ngIf="f.submitted && dob.invalid" >
            <span *ngIf="dob.errors.required" class="d-block invalid-feedback f-12">Field is required</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4">
      <div class="form-floating">
        <input type="email" class="form-control" id="email"
            placeholder="Email"
            #email="ngModel"
            [(ngModel)]="profile.email" name="email"
            [ngClass]="{ 'is-invalid': f.submitted && email.invalid}"
            [attr.disabled]="false ? null : ''"
            required>
        <label for="email" class="field-required">Email</label>
        <div *ngIf="f.submitted && email.invalid" >
            <span *ngIf="email.errors.required" class="d-block invalid-feedback f-12">Field is required</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-4 mb-4">
      <div class="gender-input" [class]="{'gender-input-disabled' : !editable}">
        <h6 class="text-start gender-header text-muted">Gender</h6>
        <div class="input-group">
          <div class="form-check me-3" *ngFor="let gender of genders">
              <input class="form-check-input"
                type="radio" name="gender"
                id="gender" [checked]="gender === profile.gender"
                #gender="ngModel"
                [(ngModel)]="profile.gender" name="gender"
                [ngClass]="{ 'is-invalid': f.submitted && f.controls.gender.invalid}"
                [value]="gender"
                [attr.disabled]="editable ? null : ''"
                required
                >
              <label class="form-check-label small">{{ gender }}</label>
          </div>
        </div>
        <div *ngIf="f.submitted && f.controls.gender.invalid" class="invalid-feedback">
          Field is required
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="form-floating">
        <textarea
          type="text"
          class="form-control textarea-height"
          id="studyReason"
          placeholder="Reason for studying"
          #studyReason="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && studyReason.invalid}"
          [(ngModel)]="profile.reasonForStuding" name="studyReason"
          [attr.disabled]="editable ? null : ''">
        </textarea>
        <label for="studyReason">Reason for studying</label>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="form-floating">
        <textarea
          type="text"
          class="form-control textarea-height"
          id="biography"
          placeholder="Biography"
          #biography="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && biography.invalid}"
          [(ngModel)]="profile.biography" name="biography"
          [attr.disabled]="editable ? null : ''">
        </textarea>
        <label for="biography">About you</label>
      </div>
    </div>
  </div>

    <!--
    <label for="whereLive" class="col-2 col-form-label">Where do you live: </label>
    <div class="col-4">
      <input type="text" class="form-control-plaintext" id="whereLive" value="TODO" [attr.disabled]="editable ? null : ''">
    </div>-->




  <div class="d-flex justify-content-end">
      <button [hidden]="!editable" class="btn btn-primary me-3">Save</button>
      <button type="button" [hidden]="!editable" class="btn btn-secondary" (click)="editable = false;">Cancel</button>
  </div>
</form>


