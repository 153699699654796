import { Component, OnInit } from '@angular/core';
import {GroupParticipantCreate} from "./group-participant-create";
import {
  Person,
  PersonDetails,
  Student,
  StudentBase,
  StudentPersonDetails
} from "../../../model/rest/casa-apiv2.model";
import {
  StudentAutocompleteAdapter
} from "../../person-autocomplete/person-autocomplete-adapters";
import {ActivatedRoute} from "@angular/router";
import {GroupRestServiceImpl} from "../../../services/rest/group-rest.service";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {GroupStudent, ParticipantDetails} from "../../../model/group";
import {PersonNameExtractor} from "../../../services/utils/profile-photo-url-extractor";
import {Adapter} from "../../person-autocomplete/person-autocomplete.component";
import {Observable} from "rxjs";

@Component({
  selector: 'app-group-student-create',
  templateUrl: './group-participant-create.component.html',
  styleUrls: ['./group-participant-create.component.scss']
})
export class GroupStudentCreateComponent extends GroupParticipantCreate<Student, StudentBase, PersonDetails> implements OnInit {

  participant = new GroupStudent<Student>()
  participantAdapter: Adapter<Student>;

  constructor(activatedRoute: ActivatedRoute,
              groupRest: GroupRestServiceImpl,
              casa: CasaRestApiV2) {
    super(activatedRoute, groupRest, casa)
  }

  ngOnInit(): void {
  }

  setupAutocompleteAdapters() {
    this.participantAdapter = new StudentAutocompleteAdapter(this.schoolId, this.casa);
  }
  participantSelected(student: GroupStudent<Student>, selectedStudent: Student) {
    this.isParticipantSelected = true
    this.participant = JSON.parse(JSON.stringify(student)) as GroupStudent<Student>
    this.participant.details.participant = JSON.parse(JSON.stringify(selectedStudent)) as Student
    this.participant.details.participant.person.details.name = PersonNameExtractor.getCasaPersonName(selectedStudent.person);
  }

  addParticipant() {
    this.isParticipantBeingAdded = true
    this.resetParticipantObject()
  }
  resetParticipantObject() {
    this.unlinkParticipant()
    this.groupParticipantForm.resetForm()
    this.isParticipantSelected = false
    this.participant = new GroupStudent<Student>();
    this.participant.details = new ParticipantDetails<Student>()
    this.participant.details.participant = new Student()
    this.participant.details.participant.person = new Person<StudentPersonDetails>()
    this.participant.details.participant.person.details = new StudentPersonDetails()
    this.participant.details.participant.person.details.name = ""
  }

  saveParticipant() {
    this.participant.details.labels = this.labels?.split(',')
    let observable: Observable<any>
    if(!this.isParticipantBeingEdited)
      observable = this.groupRest.addGroupStudent(this.schoolId, this.groupId, this.participant.details)
    else
      observable = this.groupRest.updateParticipantDetails(this.schoolId, this.groupId, this.participant.id, this.participant.details)

    observable.subscribe(_ => {
      this.isParticipantBeingAdded = false
      this.isParticipantBeingEdited = false
      this.reloadGroupEmitter.emit()
    })
    this.resetParticipantObject()
  }
}
