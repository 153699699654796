import {Component, Input, OnInit} from '@angular/core';
import {tap} from "rxjs/operators";
import {CasaOldRestApi} from "../../../services/rest/casa-rest.service";
import {School} from "../../../model/rest/casa-apiv2.model";
import {SchoolStudentsFee} from "../../../model/rest/old-casa-api.model";

@Component({
  selector: 'app-students-payment-summary-block',
  templateUrl: './students-payment-summary-block.component.html',
  styleUrls: ['./students-payment-summary-block.component.css']
})
export class StudentsPaymentSummaryBlockComponent implements OnInit {
  private mSchool: School;
  private payments: SchoolStudentsFee[];
  private lastPayments: SchoolStudentsFee;
  private notConfirmed: SchoolStudentsFee[];
  private notPaid: SchoolStudentsFee[];
  private current: SchoolStudentsFee;

  @Input()
  set school(value: School) {
    this.mSchool = value;
    this.reload();
  }

  constructor(private casaOldApi: CasaOldRestApi) { }

  ngOnInit() {
  }

  private reload() {
    if (!this.mSchool) return;
    this.loadPayments(this.mSchool.id).pipe(
      tap(payments => this.storePayments(payments))
    ).subscribe()
  }

  private loadPayments(schoolId: number) {
    return this.casaOldApi.queryStudentsFee(schoolId);
  }

  private storePayments(payments: SchoolStudentsFee[]) {
    this.payments = payments;
    if (payments.length > 0) {
      this.lastPayments = payments[0];
      this.notConfirmed = payments.filter( p => !p.paymentConfirmationDate);
      this.notPaid = payments.filter( p => !p.paymentOrderId);
      this.current = payments.find( p => p.id == null);
    }
  }

  hasAnyPaymentRecord() {
    return this.lastPayments;
  }

  isReady() {
    return this.mSchool;
  }

  getNotConfirmedCount() {
    if (!this.notConfirmed) return 0;
    return this.notConfirmed.length;
  }

  getNotPaidCount() {
    if (!this.notPaid) return 0;
    return this.notPaid.length;
  }

  getNotConfirmedQuarters() {
    if (!this.notConfirmed) return "";
    return this.notConfirmed.map( p => `${p.dateRangeName}(${p.totalStudentsToPayFor})` ).join(", ");
  }

  hasCurrent() {
    return this.current && (this.getCurrentNew() || this.getCurrentRenew() || this.getCurrentBlocked() || this.getCurrentUnblocked() || this.getCurrentDeleted());
  }

  getCurrentNew() {
    return this.current.newStudents;
  }

  getCurrentRenew() {
    return this.current.renewedStudents;
  }

  getCurrentBlocked() {
    return this.current.blockedStudents;
  }

  getCurrentUnblocked() {
    return this.current.unblockedStudents;
  }

  getCurrentDeleted() {
    return this.current.deletedStudent;
  }
}
