import { SortingRule } from '../services/utils/manager-utils';

export enum NoteTypes {
  general = 'General',
  meeting = 'Meeting',
  call = 'Call',
  email = 'Email',
}

export class NotesSortingRule extends SortingRule {
  constructor(public name: string, sorting: string[]) {
    super(sorting);
  }
  static DateAsc = new NotesSortingRule('DateAsc', ['createDate,ASC']);
  static DateDesc = new NotesSortingRule('DateDesc', ['createDate,DESC']);
  static AuthorAsc = new NotesSortingRule('AuthorAsc', ['author,ASC']);
  static AuthorDesc = new NotesSortingRule('AuthorDesc', ['author,DESC']);

  static getSortingRule(rule: string) {
    switch (rule) {
      case 'DateAsc':
        return NotesSortingRule.DateAsc;
      case 'DateDesc':
        return NotesSortingRule.DateDesc;
      case 'AuthorAsc':
        return NotesSortingRule.AuthorAsc;
      case 'AuthorDesc':
        return NotesSortingRule.AuthorDesc;
      default:
        break;
    }
  }
  static getNoteTypes() {
    return [
      NoteTypes.general,
      NoteTypes.meeting,
      NoteTypes.call,
      NoteTypes.email,
    ];
  }
}

export enum SearchTypeName {
  ALL_TYPES = 'All',
  SCHOOL_NAME = 'School name',
  SCHOOL_ID = 'School ID',
  SCHOOL_OLD_ID = 'Old school ID',
  COUNTRY = 'Country',
  STATUS = 'Status',
  EXPIRY_DATE = 'Expiry date',
}
export class SearchType {
  static SCHOOL_NAME = 'SCHOOL_NAME';
  static SCHOOL_ID = 'SCHOOL_ID';
  static SCHOOL_OLD_ID = 'SCHOOL_OLD_ID';
  static COUNTRY = 'COUNTRY';
  static STATUS = 'STATUS';
  static EXPIRY_DATE = 'EXPIRY_DATE';
  static ALL = [
    SearchType.SCHOOL_NAME,
    SearchType.SCHOOL_ID,
    SearchType.SCHOOL_OLD_ID,
    SearchType.COUNTRY,
    SearchType.STATUS,
    SearchType.EXPIRY_DATE,
  ];
}

export class SchoolsSortingRule extends SortingRule {
  constructor(public name: string, sorting: string[]) {
    super(sorting);
  }
  static Default = new SchoolsSortingRule('Default', []);
  static SchoolNameAsc = new SchoolsSortingRule('School name (ascending)', [
    'name,ASC',
  ]);
  static SchoolNameDesc = new SchoolsSortingRule('School name (descending)', [
    'name,DESC',
  ]);
  static IdAsc = new SchoolsSortingRule('School Id (ascending)', ['id,ASC']);
  static IdDesc = new SchoolsSortingRule('School Id (descending)', ['id,DESC']);
  static OldSchoolIdAsc = new SchoolsSortingRule('Old school Id (ascending)', [
    'oldSchoolId,ASC',
  ]);
  static OldSchoolIdDesc = new SchoolsSortingRule(
    'Old school Id (descending)',
    ['oldSchoolId,DESC']
  );
  static CountedActiveStudentsAsc = new SchoolsSortingRule(
    'Active students (ascending)',
    ['countedActiveStudents,ASC']
  );
  static CountedActiveStudentsDesc = new SchoolsSortingRule(
    'Active students (descending)',
    ['countedActiveStudents,DESC']
  );
  static CountryAsc = new SchoolsSortingRule('Country (ascending)', [
    'address.country.name,ASC',
  ]);
  static CountryDesc = new SchoolsSortingRule('Country (descending)', [
    'address.country.name,DESC',
  ]);
  static ExpiryAsc = new SchoolsSortingRule('Expiry (ascending)', [
    'expDate,ASC',
  ]);
  static ExpiryDesc = new SchoolsSortingRule('Expiry (descending)', [
    'expDate,DESC',
  ]);
  static values = [
    SchoolsSortingRule.Default,
    SchoolsSortingRule.SchoolNameAsc,
    SchoolsSortingRule.SchoolNameDesc,
    SchoolsSortingRule.IdAsc,
    SchoolsSortingRule.IdDesc,
    SchoolsSortingRule.OldSchoolIdAsc,
    SchoolsSortingRule.OldSchoolIdDesc,
    SchoolsSortingRule.CountedActiveStudentsAsc,
    SchoolsSortingRule.CountedActiveStudentsDesc,
    SchoolsSortingRule.CountryAsc,
    SchoolsSortingRule.CountryDesc,
    SchoolsSortingRule.ExpiryAsc,
    SchoolsSortingRule.ExpiryDesc,
  ];
}
