import { Component, ElementRef, Input } from "@angular/core";
import { IconRegistryService } from "../../icons/IconRegisteryService";

@Component({
    selector: 'app-icon',
    template: '<ng-content></ng-content>',
    styleUrls: ['./icon.component.scss']
  })
  
export class IconComponent {
    private iconElement: SVGElement;
    constructor(private element: ElementRef, private registry: IconRegistryService) {
    }

    @Input()
    set name(iconName: string) {
        if (this.iconElement) {
            this.element.nativeElement.removeChild(this.iconElement);
        }
        const data = this.registry.getIcon(iconName);
        this.iconElement = this.createSvgElement(data);
        this.element.nativeElement.appendChild(this.iconElement);
    }

    private createSvgElement(svgContent: string): SVGElement {
        const div = document.createElement('DIV');
        div.innerHTML = svgContent;
        return div.querySelector('svg') ||
            document.createElementNS('http://www.w3.org/2000/svg', 'path');    
    }
}