import {Injectable} from "@angular/core";
import {School} from "../model/rest/casa-apiv2.model";

@Injectable({
  providedIn: 'root'
})
export class CallanOnlineRecognizeService {

  isCallanOnlineFranchiseById(schoolId: number) {
    return schoolId === 1;
  }

  isCallanOnlineFranchise(school: School) {
    return this.isCallanOnlineFranchiseById(school.id);
  }
}
