import { Component, OnInit } from '@angular/core';
import { School } from 'src/app/model/rest/casa-apiv2.model';
import { CasaRestApiV2 } from 'src/app/services/rest/casa-rest.service';

@Component({
  selector: 'app-admin-school-dashboard',
  templateUrl: './admin-school-dashboard.component.html',
  styleUrls: ['./admin-school-dashboard.component.scss']
})
export class AdminSchoolDashboardComponent implements OnInit {

  school: School
  constructor(private casaRest: CasaRestApiV2) { }

  ngOnInit(): void {
    this.casaRest.getSchoolDetails(1).subscribe((school) => (this.school = school))
  }

  getSchoolName() {
    if (!this.school) return null;
    return this.school.details.name;
  }

}
