<div class="row border-bottom border-secondary py-4" *ngIf="hasAnyProgress()">
  <div class="col-3 col-md-4">
    <button *ngIf="hasPrev()" (click)="moveCourse(-1)"  class="btn btn-outline-secondary f rounded-pill px-3 pe-lg-4 py-2">
      <app-icon name="angle-left" class="icon-primary icon-md me-lg-3"></app-icon>
      <span class="text-primary d-none d-lg-inline">previous stage</span>
    </button>
  </div>
  <div class="col-6 col-md-4 text-uppercase text-center">
    <h5 class="my-2">{{getCurrentCourseName()}}</h5>
  </div>

  <div class="col-3 col-md-4 text-end">
    <button  *ngIf="hasNext()" (click)="moveCourse(1)"  class="btn btn-outline-secondary rounded-pill px-3 ps-lg-4 py-2">
      <span class="text-primary d-none d-lg-inline">next stage</span>
      <app-icon name="angle-right" class="icon-primary icon-md ms-lg-3"></app-icon>
    </button>
  </div>
</div>

<div class="py-3" *ngIf="hasNoProgress()">
  Before you start learning, we need to know your level.
  <br>
  After your level test, you will find your results here.</div>

<div *ngIf="hasAnyProgress()" class="row py-5">

  <!-- progress circle -->
  <div class="col-12 col-md d-flex
  flex-column justify-content-between">
  <div class="row mb-3">
    <div class="col">
      <span>
        New Work
      </span>
    </div>
    <div class="col">
      <div class="row">
        <div class="col d-flex justify-content-around">
          <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
            <div class="progress-bar bg-success" role="progressbar" [ngStyle]="getLessonProgressWidth()"></div>
          </div>
          <span class="text-success progress-text text-center">{{getProgress() | number:'1.0-0'}}%</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <span>
        Exam
      </span>
    </div>
    <div class="col">
      <div class="row">
        <div class="col d-flex justify-content-around">
          <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
            <div class="progress-bar bg-warning" role="progressbar" [ngStyle]="getExamProgressWidth()" ></div>
          </div>
          <span class="text-warning progress-text text-center">{{getExamProgressDescription()}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <span>
        Full Stage Revision
      </span>
    </div>
    <div class="col">
      <div class="row">
        <div class="col d-flex justify-content-around">
          <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
            <div class="progress-bar bg-info" role="progressbar" [ngStyle]="getRevisionProgressWidth()" ></div>
          </div>
          <span class="text-info progress-text text-center">{{getRevisionProgress() | number:'1.0-0'}}%</span>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- lesson dates -->
  <div class="card-text col-12 col-md border-md-start border-secondary ps-4" *ngIf="hasAnyLesson() && getFirstLessonDate()">
    <div class="row mb-4">
      <div class="col-6">
        <p class="text-muted mb-0 f-14">Start date:</p>
        <p>{{getFirstLessonDate() | date: 'dd/MM/yy'}}</p>
      </div>
      <div class="col-6">
        <p class="text-muted mb-0 f-14">End date:</p>
        <p>{{getLastLessonDate() | date: 'dd/MM/yy'}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <p class="text-muted mb-0 f-14">Lessons taken:</p>
        <p>{{getNumberOverallLessons()}}</p>
      </div>
      <div class="col-6">
        <p class="text-muted mb-0 f-14">Last exam date:</p>
        <p *ngIf="getLastExamDate()">{{getLastExamDate() | date: 'dd/MM/yy'}}</p>
        <p *ngIf="!getLastExamDate()">---</p>
      </div>
    </div>
  </div>
</div>
