export class SchoolStudentsFee {
  newStudents: number;
  renewedStudents: number;
  blockedStudents: number;
  unblockedStudents: number;
  deletedStudent: number;
  totalStudentsToPayFor: number;
  paymentDate: number;
  paymentOrderId: string;
  dateRangeStart: number;
  dateRangeEnd: number;
  dateRangeName: string;
  paymentConfirmationDate: number;
  qId: string;
  id: number;
}


class ApiProductReference {
  id: number;
  referenceId: string;
  platform: string;
}

export class ApiProduct {
  id: number;
  name: string;
  productCode: string;
  references: ApiProductReference[];
}

export class ApiOrder {
  id: number;
}

export class ApiOrderItem {
  id: number;
  product: ApiProduct;
  cancelationExpiryDate: number;
  order: ApiOrder;
  amount: number;
  price: number;
  currency: string;
}

export class WarehouseEntry {
  product: ApiProduct;
  amount: number;
}

export class Stats {
  activations: number;
  deactivations: number;
  deletions: number;
  newStudents: number;
  prolongs: number;
}

export class SupportedSubs {
  static TeacherSub = SupportedSubs.createApiProduct("teacher-books-subscription")
  static All = [SupportedSubs.TeacherSub]

  static isSubscription(productCode: string) {
    return SupportedSubs.All.find(it => it.productCode == productCode)
  }

  private static createApiProduct(productCode: string) {
    let product = new ApiProduct()
    product.productCode = productCode
    return product
  }
}

