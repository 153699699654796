import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild} from '@angular/core';
import {
  Address,
  ContactEntry,
  ContactType,
  CountryDefinition,
  FileItem,
  Gender,
  PersonDetails,
  StudentPersonDetails
} from "../../model/rest/casa-apiv2.model";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {tap} from "rxjs/operators";
import {NgForm} from "@angular/forms";
import {isNumeric} from "rxjs/internal-compatibility";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-casa-personal-profile-form',
  templateUrl: './casa-personal-profile-form.component.html',
  styleUrls: ['./casa-personal-profile-form.component.scss']
})
export class CasaPersonalProfileFormComponent implements OnInit {
  private _profile: StudentPersonDetails;
  uploadProgress: number = 0;
  @ViewChild('fileInput', {static: false}) fileInput;
  countries: CountryDefinition[];
  genders = [ Gender.Female, Gender.Male, Gender.Other ];
  phone: ContactEntry;
  skype: ContactEntry;
  @ViewChild('f', {static: false}) form: NgForm;

  @Input()
  role = "student"

  @Input()
  busy = false;

  @Input()
  set profile(value: StudentPersonDetails ) {
    this._profile = value;
    if (value) {
      if (!value.contacts) value.contacts = [];
      if (!value.address) value.address = new Address();
      this.decomposeContacts();
      this.convertBirthDate();
    }
  }

  @Output()
  savePerson = new EventEmitter<PersonDetails | StudentPersonDetails>();

  private decomposeContacts() {
    if (!this.profile) return;
    let contacts = this.profile.contacts;
    if (contacts == null) {
      contacts = [];
      this.profile.contacts = contacts;
    }
    this.phone = contacts.find( c => c.entryType === ContactType.PHONE);
    if (this.phone == null) {
      this.phone = new ContactEntry();
      this.phone.entryType = ContactType.PHONE;
      this.phone.name = "Phone";
      contacts.push(this.phone);
    }
    this.skype = contacts.find( c => c.entryType === ContactType.SKYPE);
    if (this.skype == null) {
      this.skype = new ContactEntry();
      this.skype.entryType = ContactType.SKYPE;
      this.skype.name = "Skype";
      contacts.push(this.skype);
    }
  }

  get profile(){
    return this._profile;
  }

  constructor(
    private casa: CasaRestApiV2,
    @Inject(LOCALE_ID) private locale: string
  ) {
    casa.getCountries().pipe(
      tap<CountryDefinition[]>( countries => this.countries = countries)
    ).subscribe()
  }

  public onProfileImageUpload(files: File[]) {
    this.uploadProgress = 0;
    this.casa.saveFile(files[0]).subscribe( event => {
      if (event.type === HttpEventType.UploadProgress ) {
        this.uploadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.profile.photo = event.body as FileItem;
        this.uploadProgress = null;
      }
    }, () => this.uploadProgress = null);
  }

  ngOnInit(): void {
  }

  onSave() {
    this.savePerson.emit(this.profile)
  }

  public openFileChoose() {
    this.fileInput.nativeElement.click();
  }

  getProfilePhoto(profile: PersonDetails) {
    if (profile.photo == null) return null;
    return profile.photo? 'url(' + profile.photo?.publicAccessUrl +')' : null
    ;
  }

  private convertBirthDate() {
    if (this._profile && this._profile.birthDate && isNumeric(this._profile.birthDate)) {
      this._profile.birthDate = formatDate(this._profile.birthDate, "yyyy-MM-dd", this.locale) as unknown as number;
    }
  }
}
