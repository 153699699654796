import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {map, switchMap, tap} from "rxjs/operators";
import {SearchingContextService} from "../../services/searching-context.service";
import {HybridState, VideoServerRestService} from "../../services/rest/video-server-rest.service";
import {ListEvent} from "../../components/room-templates-list/room-templates-list.component";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {School} from "../../model/rest/casa-apiv2.model";
import {LocalStateService} from "../../services/local-state.service";
import {LocalState, SortingRule} from "../../services/utils/manager-utils";
import {Page, Pageable} from "../../model/rest/base";
import {IdentifiedRoomTemplate} from "../../model/server";

class VideoGroupsSorting extends SortingRule {
  constructor(public name: string, sorting: string[]) {
    super(sorting);
  }
  static ByName = new VideoGroupsSorting("By Name", ['details.name,asc']);
  static ByNameDesc = new VideoGroupsSorting("By Name (descending)", ['details.name,desc']);
  static ByDate = new VideoGroupsSorting("By Date", ['created,asc']);
  static ByDateDesc = new VideoGroupsSorting("By Date (descending)", ['created,desc']);
  static ByTeacher = new VideoGroupsSorting("By Teacher", ['t.details.name,asc']);
  static ByTeacherDesc = new VideoGroupsSorting("By Teacher (descending)", ['t.details.name,desc']);
  static Offline = new VideoGroupsSorting("Offline", ['details.name,asc']);
  static Archived = new VideoGroupsSorting("Deleted", ['created,desc']);
  static values = [
    VideoGroupsSorting.ByName, VideoGroupsSorting.ByNameDesc, VideoGroupsSorting.ByDate,
    VideoGroupsSorting.ByDateDesc, VideoGroupsSorting.ByTeacher, VideoGroupsSorting.ByTeacherDesc,
    VideoGroupsSorting.Offline,
    VideoGroupsSorting.Archived
  ]
}

@Component({
  selector: 'app-school-groups-page',
  templateUrl: './school-groups-page.component.html',
  styleUrls: ['./school-groups-page.component.css']
})
export class SchoolGroupsPageComponent implements OnInit {
  schoolId: number;
  sortingRules = VideoGroupsSorting.values;
  currentState: LocalState<VideoGroupsSorting>
  school: School;
  groupsPage: Page<IdentifiedRoomTemplate>;

  constructor(
    activatedRoute: ActivatedRoute,
    private searchingContext: SearchingContextService,
    private rest: VideoServerRestService,
    private router: Router,
    private casa: CasaRestApiV2,
    private localStateService: LocalStateService
  ) {
    activatedRoute.parent.paramMap.pipe(
      map( params => Number(params.get('schoolId'))),
      tap( schoolId => this.setupSchool(schoolId)),
      switchMap( _ => this.loadSchool()),
      switchMap( _ => this.restoreGroupLocalState()),
      switchMap( state => this.loadPage(state))
    ).subscribe();

  }

  private restoreGroupLocalState() {
    const contextPath = `/schools/${this.schoolId}/groups`;
    return this.localStateService.get<LocalState<VideoGroupsSorting>>(contextPath,
      () => new LocalState<VideoGroupsSorting>("", VideoGroupsSorting.ByDateDesc, Pageable.of(0, 10, null))
      )
  }

  private loadPage(state: LocalState<VideoGroupsSorting>) {
    const contextPath = `/schools/${this.schoolId}/groups`;
    this.currentState = state;
    state.page.sort = state.sortingRule.sorting;
    return this.localStateService.set(contextPath, state).pipe(
      switchMap( state => {
        if (state.sortingRule === VideoGroupsSorting.Archived) {
          return this.rest.listArchivedTemplates(this.schoolId, state.term, state.page);
        }
        let hybridState: HybridState = null;
        if (state.sortingRule === VideoGroupsSorting.Offline) hybridState = HybridState.Offline; 
        return this.rest.listTemplatesWithHybridState(this.schoolId, state.term, hybridState, state.page);
      }),
      tap(groupsPage => this.storeGroupsPage(groupsPage))
    )
  }

  setupSchool(schoolId: number) {
    this.schoolId = schoolId;
  }

  ngOnInit() {
  }

  onTemplatesEvent(event: ListEvent) {
    if (event.event === ListEvent.EVENT_ENTER) {
      this.router.navigate(['school', this.schoolId, 'groups', event.template.id]);
    }
  }

  private loadSchool() {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.storeSchool(school))
    );
  }

  private storeSchool(school: School) {
    this.school = school;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  isSchoolReady() {
    return this.school != null;
  }

  private storeGroupsPage(groupsPage: Page<IdentifiedRoomTemplate>) {
    this.groupsPage = groupsPage;
  }

  getGroupsNumber() {
    if (!this.groupsPage) return null;
    return this.groupsPage.totalElements;
  }

  doSearch() {
    this.loadPage(this.currentState).subscribe();
  }

  restartSearch() {
    this.currentState.page = this.currentState.page.first();
    this.doSearch();
  }

  dataReady() {
    return !!this.groupsPage;
  }

  loadNewPage(pageToLoad: Pageable) {
    this.currentState.page = pageToLoad;
    this.doSearch();
  }
}
