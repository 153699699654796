<form *ngIf="formBack" #f="ngForm" (ngSubmit)="f.form.valid && onSave()">
  <div class="row">
    <div class="col-12 col-md-5 mb-4">
      <div class="form-floating">
        <select
          name="language"
          class="form-select"
          [(ngModel)]="formBack.language"
          [attr.disabled]="editable ? null : ''"
          required
          [ngClass]="{ 'is-invalid': f.submitted && language.invalid,
          'select-not-empty': formBack.language}"
          #language="ngModel"
          >
          <option *ngFor="let lang of languages" [value]="lang.code">{{lang.name}}</option>
        </select>
        <label class="field-required">Language</label>
        <div *ngIf="f.submitted && language.invalid" class="invalid-feedback">
            <div *ngIf="language.errors.required">Language is required</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 mb-4">
      <div class="form-floating">
        <input
        type="text"
        name="skype"
        placeholder="skype"
        class="form-control"
        [(ngModel)]="formBack.skype"
        [attr.disabled]="editable ? null : ''"
        [ngClass]="{ 'is-invalid': f.submitted && skype.invalid }"
        #skype="ngModel"
        required>
        <div *ngIf="f.submitted && skype.invalid" class="invalid-feedback">
          <div *ngIf="skype.errors.required">Skype is required</div>
        </div>
        <app-icon name="skype" class="icon-primary skype-icon"></app-icon>
        <label class="field-required">Skype</label>
      </div>
    </div>
  </div>
  <nav>
    <div class="nav nav-tabs border-0 mb-4" id="nav-tab" role="tablist">
      <a *ngIf="showLang('en')" class="nav-item nav-link my-1 mb-2 me-2 btn btn-sm active" id="nav-english-tab" data-bs-toggle="tab" href="#nav-english" role="tab" aria-controls="nav-english" aria-selected="true">English</a>
      <a *ngIf="showLang('sp')" class="nav-item nav-link my-1 mb-2 me-2 btn btn-sm" id="nav-spanish-tab" data-bs-toggle="tab" href="#nav-spanish" role="tab" aria-controls="nav-spanish" aria-selected="false">Spanish</a>
      <a *ngIf="showLang('en.ch')" class="nav-item nav-link my-1 mb-2 me-2 btn btn-sm" id="nav-kids-tab" data-bs-toggle="tab" href="#nav-kids" role="tab" aria-controls="nav-kids" aria-selected="false">Kids</a>
      <a *ngIf="showLang('en.bs')" class="nav-item nav-link my-1 mb-2 me-2 btn btn-sm" id="nav-business-tab" data-bs-toggle="tab" href="#nav-business" role="tab" aria-controls="nav-business" aria-selected="false">Business</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div *ngIf="showLang('en')" class="tab-pane fade show active" id="nav-english" role="tabpanel" aria-labelledby="nav-english-tab">
      <button *ngIf="!formBack.productCompetences['en'] && editable" class="btn btn-link text-decoration-none" (click)="activateLang('en')">English course isn't active. Click here to activate.</button>
      <p *ngIf="!formBack.productCompetences['en'] && !editable">
        English course isn't active. To activate the course click on form edit first
      </p>
      <div *ngIf="formBack.productCompetences['en']" class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
            type="text"
            class="form-control"
            name="enAccent"
            placeholder="enAccent"
            [(ngModel)]="formBack.productCompetences['en'].accent"
            [attr.disabled]="editable ? null : ''"
            [ngClass]="{ 'is-invalid': f.submitted && enAccent.invalid }"
            #enAccent="ngModel"
            required>
            <div *ngIf="f.submitted && enAccent.invalid" class="invalid-feedback">
              <div *ngIf="enAccent.errors.required">Accent is required</div>
            </div>
            <label class="field-required">Accent</label>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
            type="text"
            class="form-control"
            name="enVideo"
            placeholder="url"
            [(ngModel)]="formBack.productCompetences['en'].videoUrl"
            [attr.disabled]="editable ? null : ''"
            [ngClass]="{ 'is-invalid': f.submitted && enVideo.invalid }"
            #enVideo="ngModel"
            >
            <label>Video URL</label>
            <div *ngIf="f.submitted && enVideo.invalid" class="invalid-feedback">
                <div *ngIf="enVideo.errors.required">Video is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="form-floating">
            <textarea
              name="enDescription"
              placeholder="description"
              [(ngModel)]="formBack.productCompetences['en'].description"
              class="form-control"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && enDescription.invalid }"
              #enDescription="ngModel"
              required
            ></textarea>
            <label class="field-required">Description</label>
            <div *ngIf="f.submitted && enDescription.invalid" class="invalid-feedback">
                <div *ngIf="enDescription.errors.required">Description is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class="small mb-2">Competences</p>
          <div class="mb-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="en_prova"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['PROVA']"
              >
              <label class="form-check-label small">
                Prova
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="en_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['TEST']"
              >
              <label class="form-check-label small" >
                Test
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="en_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['A']"
              >
              <label class="form-check-label small">
                A ( Stage 1 )
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="en_b"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['B']"
              >
              <label class="form-check-label small" >
                B ( Stage 2 - 10 )
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="en_c"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en'].competences['C']"
              >
              <label class="form-check-label small" >
                C ( Stage 11 - 12 )
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showLang('sp')" class="tab-pane fade" id="nav-spanish" role="tabpanel" aria-labelledby="nav-spanish-tab">
      <button *ngIf="!formBack.productCompetences['sp'] && editable" class="btn btn-link text-decoration-none" (click)="activateLang('sp')">Spanish course isn't active. Click here to activate.</button>
      <p *ngIf="!formBack.productCompetences['sp'] && !editable">
          Spanish course isn't active. To activate the course click on form edit first
        </p>
      <div *ngIf="formBack.productCompetences['sp']" class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              name="spAccent"
              placeholder="spAccent"
              [(ngModel)]="formBack.productCompetences['sp'].accent"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && spAccent.invalid }"
              #spAccent="ngModel"
              required>
            <label class="field-required">Accent</label>
            <div *ngIf="f.submitted && spAccent.invalid" class="invalid-feedback">
              <div *ngIf="spAccent.errors.required">Accent is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              name="spVideo"
              placeholder="url"
              [(ngModel)]="formBack.productCompetences['sp'].videoUrl"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && spVideo.invalid }"
              #spVideo="ngModel"
            >
            <label>Video URL</label>
            <div *ngIf="f.submitted && spVideo.invalid" class="invalid-feedback">
                <div *ngIf="spVideo.errors.required">Video is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="form-floating">
            <textarea
              name="spDescription"
              placeholder="description"
              [(ngModel)]="formBack.productCompetences['sp'].description"
              class="form-control"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && spDescription.invalid }"
              #spDescription="ngModel"
              required
            ></textarea>
            <label class="field-required">Description</label>
            <div *ngIf="f.submitted && spDescription.invalid" class="invalid-feedback">
              <div *ngIf="spDescription.errors.required">Description is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class="small mb-2">Competences</p>
          <div class="mb-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="sp_prova"
              [(ngModel)]="formBack.productCompetences['sp'].competences['PROVA']"
              [attr.disabled]="compEditable ? null : ''"
              >
              <label class="form-check-label small">
                Prova
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="sp_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['sp'].competences['TEST']"
              >
              <label class="form-check-label small" >
                Test
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="sp_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['sp'].competences['A']"
              >
              <label class="form-check-label small" >
                A ( Stage 1 )
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="sp_b"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['sp'].competences['B']"
              >
              <label class="form-check-label small" >
                B ( Stage 2 - 6 )
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLang('en.ch')" class="tab-pane fade" id="nav-kids" role="tabpanel" aria-labelledby="nav-kids-tab">
      <button *ngIf="!formBack.productCompetences['en.ch'] && editable" class="btn btn-link text-decoration-none" (click)="activateLang('en.ch')">English Children course isn't active. Click here to activate.</button>
      <p *ngIf="!formBack.productCompetences['en.ch'] && !editable">
          English Children course isn't active. To activate the course click on form edit first
        </p>
      <div *ngIf="formBack.productCompetences['en.ch']" class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              name="enChAccent"
              placeholder="enChAccent"
              [(ngModel)]="formBack.productCompetences['en.ch'].accent"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && enChAccent.invalid }"
              #enChAccent="ngModel"
              required>
            <label class="field-required">Accent</label>
            <div *ngIf="f.submitted && enChAccent.invalid" class="invalid-feedback">
                <div *ngIf="enChAccent.errors.required">Accent is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              name="enChVideo"
              placeholder="enChVideo"
              [(ngModel)]="formBack.productCompetences['en.ch'].videoUrl"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && enChVideo.invalid }"
              #enChVideo="ngModel"
              >
            <label>Video URL</label>
            <div *ngIf="f.submitted && enChVideo.invalid" class="invalid-feedback">
                <div *ngIf="enChVideo.errors.required">Video is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="form-floating">
            <textarea
            name="enChDescription"
            placeholder="description"
            [(ngModel)]="formBack.productCompetences['en.ch'].description"
            class="form-control"
            [attr.disabled]="editable ? null : ''"
            [ngClass]="{ 'is-invalid': f.submitted && enChDescription.invalid }"
            #enChDescription="ngModel"
            required></textarea>
            <label class="field-required">Description</label>
            <div *ngIf="f.submitted && enChDescription.invalid" class="invalid-feedback">
              <div *ngIf="enChDescription.errors.required">Description is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class="small mb-2">Competences</p>
          <div class="mb-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_prova"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['PROVA']"
              [attr.disabled]="compEditable ? null : ''"
              >
              <label class="form-check-label small">
                Prova
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['TEST']"
              >
              <label class="form-check-label small" >
                Test
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['A']"
              >
              <label class="form-check-label small" >
                A ( Stage 1 )
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enCh_b"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.ch'].competences['B']"
              >
              <label class="form-check-label small" >
                B ( Stage 2 - 6 )
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="showLang('en.bs')" class="tab-pane fade" id="nav-business" role="tabpanel" aria-labelledby="nav-business-tab">
      <button *ngIf="!formBack.productCompetences['en.bs'] && editable" class="btn btn-link text-decoration-none" (click)="activateLang('en.bs')">English Business course isn't active. Click here to activate.</button>
      <p *ngIf="!formBack.productCompetences['en.bs'] && !editable">
          English Business course isn't active. To activate the course click on form edit first
        </p>
      <div *ngIf="formBack.productCompetences['en.bs']" class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              name="enBsAccent"
              placeholder="enBsAccent"
              [(ngModel)]="formBack.productCompetences['en.bs'].accent"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && enBsAccent.invalid }"
              #enBsAccent="ngModel"
              required>
              <label class="field-required">Accent</label>
              <div *ngIf="f.submitted && enBsAccent.invalid" class="invalid-feedback">
                  <div *ngIf="enBsAccent.errors.required">Accent is required</div>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              name="enBsVideo"
              placeholder="enBsVideo"
              [(ngModel)]="formBack.productCompetences['en.bs'].videoUrl"
              [attr.disabled]="editable ? null : ''"
              [ngClass]="{ 'is-invalid': f.submitted && enBsVideo.invalid }"
              #enBsVideo="ngModel"
            >
            <label>Video URL</label>
            <div *ngIf="f.submitted && enBsVideo.invalid" class="invalid-feedback">
                <div *ngIf="enBsVideo.errors.required">Video is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4">
          <div class="form-floating">
            <textarea
            name="enBsDescription"
            placeholder="description"
            [(ngModel)]="formBack.productCompetences['en.bs'].description"
            class="form-control"
            [attr.disabled]="editable ? null : ''"
            [ngClass]="{ 'is-invalid': f.submitted && enBsDescription.invalid }"
            #enBsDescription="ngModel"
            required></textarea>
            <label class="field-required">Description</label>
            <div *ngIf="f.submitted && enBsDescription.invalid" class="invalid-feedback">
              <div *ngIf="enBsDescription.errors.required">Description is required</div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class="small mb-2">Competences</p>
          <div class="mb-4">
            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enBs_prova"
              [(ngModel)]="formBack.productCompetences['en.bs'].competences['PROVA']"
              [attr.disabled]="compEditable ? null : ''"
              >
              <label class="form-check-label small">
                Prova
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enBs_test"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.bs'].competences['TEST']"
              >
              <label class="form-check-label small" >
                Test
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input"
              type="checkbox"
              name="enBs_a"
              [attr.disabled]="compEditable ? null : ''"
              [(ngModel)]="formBack.productCompetences['en.bs'].competences['A']"
              >
              <label class="form-check-label small" >
                A ( Stage 1 )
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="text-end" *ngIf="editable && !saving">
      <button class="btn btn-primary rounded-pill px-3">Save</button>
  </div>
</form>
