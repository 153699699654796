<div class="card" *ngIf="lessons">
  <div class="card-body">
    <p>
      Total: {{lessons.length}}
    </p>
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr class="table-row table-row-head">
            <th class="table-cell-min-width">Date</th>
            <th>Status</th>
            <th>Course</th>
            <th>Type</th>
            <th>Teacher</th>
            <th>Students</th>
            <th>Flags</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let l of lessons" class="table-row">
            <td class="table-cell-min-width">{{lessonDate(l) | date: 'dd MMM yyyy HH:mm' }}</td>
            <td>{{lessonStatus(l)}}</td>
            <td>{{lessonCourse(l)}}</td>
            <td>{{lessonType(l)}}</td>
            <td>{{teacher(l)}}</td>
            <td>{{students(l)}}</td>
            <td>{{flags(l)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
