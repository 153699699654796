import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApiLessonBundle, ApiLessonBundleUpdate} from "../../model/rest/rest-model";
import {ModalComponent} from "../modal/modal.component";
import {Dates} from "../../services/utils/calendar-utils";
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-student-bundle-extend',
  templateUrl: './student-bundle-extend.component.html',
  styleUrls: ['./student-bundle-extend.component.scss']
})
export class StudentBundleExtendComponent implements OnInit {

  @Input()
  studentId: number;

  @Input()
  schoolId: number;

  @ViewChild('modalComponent')
  modal: ModalComponent

  @Output()
  bundleUpdated = new EventEmitter<ApiLessonBundle>()

  constructor(
    private managerRest: ManagerRestService
  ) { }

  model: ApiLessonBundleUpdate;
  bundle: ApiLessonBundle;
  complete: boolean;
  error: any;
  inProgress = false;

  ngOnInit(): void {
    this.modal.show(true);
  }

  openFor(bundle: ApiLessonBundle) {
    this.model = new ApiLessonBundleUpdate();
    this.model.note = bundle.note;
    this.complete = false;
    this.model.expiryDate = Dates.parse(bundle.expiryDate as unknown as string);
    this.bundle = bundle;
    this.modal.show(true);
  }

  submitUpdate() {
    this.inProgress = true;
    const updatedItem = this.bundle;
    const updateModel = this.model;
    this.managerRest.updateStudentLessonBundle(
      this.schoolId,
      this.studentId,
      this.bundle.id,
      this.model
    ).pipe(
      finalize(() => {
        this.inProgress = false;
        this.complete = true;
        this.model = null;
        this.bundle = null;
      })
    ).subscribe(
      {
        error: err => this.error = err.userMessage ? err.userMessage : err.message,
        next: _ => {
          updatedItem.note = this.model.note;
          updatedItem.expiryDate = this.model.expiryDate;
          this.bundleUpdated.emit(updatedItem);
        }
      }
    )
  }
}
