import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "src/environments/environment";
import { AuthServiceProvider } from "./col2/auth.service";

@Injectable({
    providedIn: 'root'
  })
export class SentryService{
    constructor(authProvider: AuthServiceProvider){
      if(environment.logToSentry){
        authProvider.get().subscribe((authProvider) => {
          Sentry.configureScope(scope => {
            scope.addEventProcessor(event => {
              Sentry.setTag("userId", authProvider.getUserId())
              return new Promise(resolve =>
                resolve({
                  ...event
                })
              )
            })
          })
        })
      }
    }

    static async initSentry(){
      if(environment.logToSentry){
        Sentry.init({
          dsn: "https://a84f25e14c4e4591b3421c0a2d787282@o334018.ingest.sentry.io/5775156",
          environment: environment.env,
          integrations: [
            new Integrations.BrowserTracing({
              tracingOrigins: [environment.sentryTarget],
              routingInstrumentation: Sentry.routingInstrumentation,
            }),
          ],
          
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,
        });
      }
    }
}