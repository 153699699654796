import {Component, OnInit} from '@angular/core';
import {CspaRestService} from "../../services/rest/cspa-rest.service";
import {
  ExamSession,
  ExerciseSet,
} from "../../model/rest/cspa.model";
import {combineLatest, Observable, of} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {Student} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";

@Component({
  selector: 'app-manager-student-exam-results',
  templateUrl: './manager-student-exam-results.component.html',
  styleUrls: ['./manager-student-exam-results.component.scss']
})
export class ManagerStudentExamResultsComponent implements OnInit {

  exams: ExamSession[]
  private schoolId: number
  private student: Student;
  exerciseSets: Map<string, ExerciseSet> = null;
  exerciseSetExams: Map<ExerciseSet, ExamSession[]> = new Map<ExerciseSet, ExamSession[]>();

  constructor(private route: ActivatedRoute,
              private cspaService: CspaRestService,
              private casa: CasaRestApiV2
  ) { }

  ngOnInit(): void {
    combineLatest(
      [this.route.parent.parent.paramMap,
        this.route.parent.paramMap]
    ).pipe(
      map(([parentParams, params]) => [Number(parentParams.get('schoolId')), Number(params.get('studentId'))]),
      tap( ([schoolId,_]) => {
        this.schoolId = schoolId;
      }),
      switchMap(([schoolId, studentId]) =>
        this.casa.getStudent(schoolId, studentId)
      ),
      tap<Student>( student => this.student = student ),
      switchMap( _ => this.loadExerciseSets()),
      switchMap(_ => this.cspaService.findExamSessions(this.schoolId, this.student.person.id, ""))
    ).subscribe(exams =>{
        this.exams = exams.sort((a, b) => a.path.localeCompare(b.path))
      console.log(this.exams)
        this.setExerciseSetExams()
      })
  }

  loadExerciseSets(): Observable<any> {
    if (this.exerciseSets && this.exerciseSets.size > 0) return of(this.exerciseSets)
    return this.cspaService.listExerciseSets(this.schoolId).pipe(
      map( sets =>
        sets.reduce((res,current)=> res.set(current.path, current)
          ,new Map<string, ExerciseSet>())),
      tap( setsMap => this.exerciseSets = setsMap)
    )
  }

  setExerciseSetExams() {
    for (let set of this.exerciseSets.values()) {
      this.exerciseSetExams.set(
        set,
        this.exams.filter(e => e.path.split("_")[0] == set.path)
          .sort((a, b) => a.path.localeCompare(b.path))
      )
    }
  }

  getExamScore(exam: ExamSession) {
    return exam.parts.reduce((sum, p) => sum + (p.score * p.weight), 0)
  }
}
