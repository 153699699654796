export class ConfirmationRequestStatus {
  static Requested = 'Requested';
  static Confirmed = 'Confirmed';
  static Revoked = 'Revoked';
  static Expired = 'Expired';
  static Duplicated = 'Duplicated';
  static COMING: string[] = [
    ConfirmationRequestStatus.Requested,
    ConfirmationRequestStatus.Expired,
  ];
  static ALL: string[] = [
    ConfirmationRequestStatus.Requested,
    ConfirmationRequestStatus.Confirmed,
    ConfirmationRequestStatus.Revoked,
    ConfirmationRequestStatus.Expired,
    ConfirmationRequestStatus.Duplicated,
  ];
}

export class ConfirmationRequestType {
  static StudentInvite = 'StudentInvite';
  static TeacherInvite = 'TeacherInvite';
  static ManagerInvite = 'ManagerInvite';
  static PersonDetailsUpdate = 'PersonDetailsUpdate';
  static StudentInstantInvite = 'StudentInstantInvite';
  static ALL: string[] = [
    ConfirmationRequestType.StudentInvite,
    ConfirmationRequestType.TeacherInvite,
    ConfirmationRequestType.ManagerInvite,
    ConfirmationRequestType.PersonDetailsUpdate,
    ConfirmationRequestType.StudentInstantInvite,
  ];
}
export enum SchoolStatusEnum {
  NEW = 'New',
  PROSPECT = 'Prospect',
  NON_ACCREDITED = 'Non Accredited',
  PRE_ACCREDITED = 'Pre-Accredited with CSPA',
  ACCREDITED = 'Accredited',
  EX = 'Ex School',
  COLD_LEAD = 'Cold Lead',
  BLACKLISTED = 'Blacklisted',
  PRE_ACCREDITED_NO_CSPA = 'Pre-Accredited no CSPA',
  EXPIRED_CSPA = 'Expired with CSPA',
  EXPIRED_NO_CSPA = 'Expired no CSPA',
  ACCREDITED_NO_CSPA = 'Accredited no CSPA',
}

export class SchoolStatus {
  static NEW = 'NEW';
  static PROSPECT = 'PROSPECT';
  static NON_ACCREDITED = 'NON_ACCREDITED';
  static PRE_ACCREDITED = 'PRE_ACCREDITED';
  static ACCREDITED = 'ACCREDITED';
  static EX = 'EX';
  static COLD_LEAD = 'COLD_LEAD';
  static BLACKLISTED = 'BLACKLISTED';
  static PRE_ACCREDITED_NO_CSPA = 'PRE_ACCREDITED_NO_CSPA';
  static EXPIRED_CSPA = 'EXPIRED_CSPA';
  static EXPIRED_NO_CSPA = 'EXPIRED_NO_CSPA';
  static ACCREDITED_NO_CSPA = 'ACCREDITED_NO_CSPA';
  static ALL: string[] = [
    SchoolStatus.NEW,
    SchoolStatus.PROSPECT,
    SchoolStatus.PRE_ACCREDITED_NO_CSPA,
    SchoolStatus.PRE_ACCREDITED,
    SchoolStatus.ACCREDITED,
    SchoolStatus.EXPIRED_CSPA,
    SchoolStatus.EXPIRED_NO_CSPA,
    SchoolStatus.NON_ACCREDITED,
    SchoolStatus.EX,
    SchoolStatus.COLD_LEAD,
    SchoolStatus.BLACKLISTED,
    SchoolStatus.ACCREDITED_NO_CSPA,
  ];
}

export class Address {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  postal: string;
  city: string;
  county: string;
  country: string;
}

export class ContactType {
  static PHONE = 'PHONE';
  static EMAIL = 'EMAIL';
  static SKYPE = 'SKYPE';
  static WEBSITE = 'WEBSITE';
  static CUSTOM = 'CUSTOM';
}

export class ContactEntry {
  name: string;
  value: string;
  contactPerson: string;
  entryType: string;
  isDefault: boolean;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

export class PersonDetails {
  name: string;
  surname: string;
  address: Address;
  contacts: ContactEntry[];
  gender: Gender;
  nationality: string;
  birthDate: number;
  birthPlace: string;
  locale: string;
  photo: FileItem;
  biography: string;
}

export class StudentPersonDetails extends PersonDetails {
  studyReason: string;
}

export class FileItem {
  id: number;
  createDate: number;
  name: string;
  originalName: string;
  size: number;
  publicAccessUrl: string;
  secretHash: string;
}

export class SchoolConfirmationRequest {
  details: PersonDetails;
  requestedEmail: string;
  expiryDate: number;
  status: string;
  role: string;
  requestDate: number;
}

export class Person<T extends PersonDetails> {
  id: number;
  details: T;
  accountId: number;
  registrationEmail: string;
}

export class PersonInvitation {
  email: string;
  details: PersonDetails;
}

export class SchoolDetails {
  name: string;
  address: Address;
  contacts: ContactEntry[];
}

export class CorpDetails {
  corpEntityName: string;
  corpNumber: string;
  corpDetails: string;
  corpAddress: string;
  nameOfPartners: string;
  bankReference: string;
  capital: string;
}

export class SchoolStudentsEstimate {
  active: number;
  inactive: number;
  total: number;
}

export class SchoolStatusDetails {
  constructor(_expDate: Date, _status: SchoolStatus) {
    this.expDate = _expDate;
    this.status = _status;
  }
  expDate: Date;
  status: SchoolStatus;
}

export class SchoolBase {
  id: number;
  expDate: Date;
  status: SchoolStatus;
  details: SchoolDetails;
}

export class School extends SchoolBase {
  oldId: string;
  corpDetails: CorpDetails;
  labels: string;
  estimate: SchoolStudentsEstimate;
  created: Date;
}

export class TeacherStats {
  updates: number;
  new: number;
  activations: number;
  deletions: number;
  deactivations: number;
}

export class PersonBase {
  id: number
}
export class ManagerBase extends PersonBase {}

export class Manager {
  id: number;
  person: Person<PersonDetails>;
}

export class LearningUnitTeacherStatus {
  static ACTIVE = 'ACTIVE';
  static REMOVED = 'REMOVED';
  static BLOCKED = 'BLOCKED';
}

export class TeacherBase extends PersonBase {
  status: LearningUnitTeacherStatus;
}

export class Teacher extends TeacherBase {
  person: Person<PersonDetails>;
  profile: TeacherProfileDetails;
}

export class SchoolStudentStatus {
  static ACTIVE = 'ACTIVE';
  static REMOVED = 'REMOVED';
  static INACTIVE = 'INACTIVE';
}

export class StudentBase extends PersonBase {
  status: SchoolStudentStatus;
  expiryDate: Date;
}

export class Student extends StudentBase{
  person: Person<StudentPersonDetails>;
  created: Date;
  modified: Date;
}

export class SchoolRelated<S extends SchoolBase, R> {
  school: S;
  role: R;
}
export class PersonSearchResult {
  person: Person<PersonDetails>;
  managerOf: SchoolRelated<SchoolBase, ManagerBase>[];
  studentOf: SchoolRelated<SchoolBase, StudentBase>[];
  teacherOf: SchoolRelated<SchoolBase, TeacherBase>[];
}

export class PasswordChangeRequest {
  password: string;
}

export class VerifiedPasswordChangeRequest extends PasswordChangeRequest {
  managerPassword: string;
}

export class EmailChangeRequest {
  newEmail: string;
}

export class VerifiedEmailChangeRequest extends EmailChangeRequest {
  managerPassword: string;
}

export class ManualConfirmationRequest {
  passwords: VerifiedPasswordChangeRequest;
  request: SchoolConfirmationRequest;
}

export class CountryDefinition {
  code: string;
  id: number;
  name: string;
  translations: { [locale: string]: string };
}

export enum Course {
  en = 'en',
  enBs = 'enBs',
  es = 'es',
  enKid = 'enKids',
}

export enum Competence {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  TEST = 'TEST',
  PROVA = 'PROVA',
}

export class CourseTeacherProfileDetails {
  course: Course;
  competences: Competence[];
  description: string;
  accent: string;
  videoUrl: string;
}

export class TeacherProfileDetails {
  language: string;
  courseDetails: CourseTeacherProfileDetails[];
}

export class NoteContent {
  value: string;
  type: string;
}

export class Note {
  id: number;
  author: Person<PersonDetails>;
  createDate: Date;
  modifyDate: Date;
  deleteDate: Date;
  content: NoteContent;
}


export class StudentsFeePaymentConfirmationDetails {
  paymentDate: Date;
  paymentOrderId: string;
}
export class StudentsFeePaymentConfirmation extends StudentsFeePaymentConfirmationDetails {
  confirmationDate: Date;
}
export class StudentsFeeRequest {
  id: number;
  newStudents: number;
  renewedStudents: number;
  blockedStudents: number;
  unblockedStudents: number;
  deletedStudents: number;
  totalStudentsToPayFor: number;
  dateRangeStart: Date;
  dateRangeEnd: Date;
  dateRangeName: string;
  qId: string;
  confirmation: StudentsFeePaymentConfirmation;
}
export class SchoolStudentsStats {
  activations: number;
  newStudents: number;
  deactivations: number;
  deletions: number;
  prolongs: number;
}

/* NEWS */
export class NewsContent {
  title: string;
  subtitle: string;
  labels: string;
  copy: string;
  image: string;
}
export class NewsItem {
  creationDate: Date;
  publicationDate: Date;
  content: NewsContent;
  author: Person<PersonDetails>;
}
export class NewsListEntry {
  id: number;
  newsItem: NewsItem;
  readingDate: Date;
}
