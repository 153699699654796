<div class="card">
  <div class="card-header">
    <div class="col">
      <h5 class="mb-0">Lesson calendar</h5>
    </div>
    <div class="col-auto ms-auto text-end">
      <div class="btn-group my-auto">
        <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-end" (click)="moveScheduleWeekOffset(-1)">
          <app-icon name="angle-left" class="icon-primary icon-lg"></app-icon>
        </button>
        <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary px-3 text-muted" (click)="moveScheduleToNow()">{{getWeekStartDate | date: 'dd'}} - {{getWeekEndDate | date: 'dd MMM'}}</button>
        <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-start" (click)="moveScheduleWeekOffset(1)">
          <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <app-schedules-week-summary-calendar
      [schoolId]="schoolId"
      [getWeekStartDate]="getWeekStartDate"
      [conflictedSchedules]="conflictedSchedules"
      [toShow]="toShow"
    ></app-schedules-week-summary-calendar>
  </div>
</div>
