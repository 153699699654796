export const environment = {
  production: false,
  profileName : 'local',
  env: 'stage',
  isGroupVisible: true,

  // Callan online lessons server url
  apiEndpoint: 'https://api.lessons.stage.callanworks.co.uk/api',
  fileEndpoint: 'https://api.lessons.stage.callanworks.co.uk/files',

  // Casa server
  authEndpoint: 'https://casa-dev.callanonline.com',
  casaEndpoint: 'https://casa-dev.callanonline.com',

  serverBase: 'https://admin.callanworks.co.uk',

  // Video server
  // Stage Env
  videoApiBase: 'https://api.video.stage.callanworks.co.uk/api',
  videoWebBase: "https://video.callanworks.co.uk",

  // booking
  bookingEndpoint: 'https://api.booking.stage.callanworks.co.uk/api',

  // analytics
  analyticsUA: 'UA-69580833-1',
  adsTrackingCode: 'AW-977652603',
  adsTransactionLabel: 'XSmtCOa28JoBEPuWl9ID',

  // pusher
  pusher: {
    key: '2a010f7970d6884d1723',
    cluster: 'eu',
    instanceName: 'casa_staging'
  },
  creditsPusher: {
    key: 'e846d8148fb4c64ec3ef',
    cluster: 'eu',
    instanceName: 'casa_staging'
  },

  casaClientId: 'usadh8yh0h23h9hHA8h3vlz0sf8sdhvAFHf9yhf9joascnasdu9',
  callanonlineId: 1,
  // to remove
  // casaRoot: 'http://casa-dev.callanonline.com',
  // casaRoot: "http://localhost:8080/ROOT"
  cspaEndpoint: "https://cspa-dev.callanonline.com",
  callanAppUrl: null,
  sentryTarget: "https://admin.callanworks.co.uk",
  logToSentry: true,
  awsBase: 'https://s3.amazonaws.com',
};
