<div class="container-fluid px-0">
  <div class="card mt-2">
    <div class="card-body">
      <h5 class="pb-4">Add the credit</h5>
      <app-create-students-credits-bundle (creditsAdded)="purchases.refresh()" [schoolId]="schoolId" [studentId]="studentId"></app-create-students-credits-bundle>
      <app-student-purchase-history #purchases [schoolId]="schoolId" [studentId]="studentId" ></app-student-purchase-history>
      <app-student-transactions [studentId]="studentId" [schoolId]="schoolId"></app-student-transactions>
    </div>
  </div>
</div>
