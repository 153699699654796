import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})

export class PusherService {
  public static EVENT_LESSON_UPDATE = 'LessonUpdate';
  public static EVENT_LESSON_LIST_UPDATE = 'LessonListUpdate';

  private pusher: any;
  private channels: {[ channelName: string ]: any}  = {};
  private creditsPusher: any;
  private creditsChannels: {[ channelName: string ]: any}  = {};
  constructor() {
  }

  private initializeCreditsPusher() {
    if (this.creditsPusher) return;
    this.creditsPusher = new Pusher(environment.creditsPusher.key, {
      cluster: environment.creditsPusher.cluster,
      encrypted: true
    });
  }

  private initializePusher() {
    if (this.pusher) return;
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });
  }

  joinCredits<T>(channelName: string, event: string): Observable<T> {
    this.initializeCreditsPusher();
    if (!this.creditsChannels[channelName]) {
      this.creditsChannels[channelName] = this.creditsPusher.subscribe(channelName);
    }
    const channel = this.creditsChannels[channelName];
    const res = new Observable<T>(observer => {
      const binding = channel.bind(event, function(data) {
        observer.next(data);
      });
      return {
        unsubscribe: function() {
          channel.unbind(binding);
        }
      };
    });
    return res;
  }

  join<T>(channelName: string, event: string): Observable<T> {
    this.initializePusher();
    if (!this.channels[channelName]) {
      this.channels[channelName] = this.pusher.subscribe(channelName);
    }
    const channel = this.channels[channelName];
    const res = new Observable<T>(observer => {
      const binding = channel.bind(event, function(data) {
        observer.next(data);
      });
      return {
        unsubscribe: function() {
          channel.unbind(binding);
        }
      };
    });
    return res;
  }
}
