import {Injectable} from "@angular/core";
import {Observable, of, ReplaySubject} from "rxjs";
import { AuthServiceProvider} from "./col2/auth.service";
import {CasaRestApiV2} from "./rest/casa-rest.service";
import {map, switchMap} from "rxjs/operators";
import {School} from "../model/rest/casa-apiv2.model";

export enum AccessRequired {
  Video = "admin.video"
}


export class AccessList {
  constructor(public videoDisabled: boolean = true) {
  }

  public disabledList(): Array<string> {
    const result: Array<string> = [];
    if (this.videoDisabled) result.push("video");
    return result;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FunctionalAccessService {
  private accessStateSubject = new ReplaySubject<AccessList>(1)

  public getAccessState(): Observable<AccessList> {
    return this.accessStateSubject.asObservable();
  }

  isSuperUser(): Observable<boolean>{
    return this.authServiceProvider.get().pipe(
      map(api => api.hasSuperAdminRole())
    )
  }

  constructor(
    private authServiceProvider: AuthServiceProvider,
    private casaRest: CasaRestApiV2
  ) {
    this.initialize()
  }

  initialize() {
    this.authServiceProvider.get().subscribe(
      authApi => {
        // whole web service will be reinitialized after login
        // I don't have to trace the authorization then
        if (!authApi.isTokenValid()) return;
        this.isSuperUser().pipe(
          switchMap(isSuperUser => {
            if(isSuperUser) return of(null)
            else return this.casaRest.getSchoolDetails(authApi.getSchoolId())
          }),
          map<School, Set<string>>( school => this.mapSchoolToDisabledFunctionalities(school))
        ).subscribe(
          toDisable => this.setAsDisabled(toDisable)
        );
      }
    )
  }

  private mapSchoolToDisabledFunctionalities(school: School): Set<string> {
    const paramExpr = new RegExp("^\\s*ui_disable\\s*=\\s*(.*)");
    const splitExpr = new RegExp("\\s*;\\s*");

    if (!school || !school.labels) return new Set<string>();
    const functionalitiesLabel = school.labels.split(",").find(it =>
      it.match(paramExpr)
    )
    if (!functionalitiesLabel) return new Set<string>();

    const functionalitiesList = paramExpr.exec(functionalitiesLabel)[1].split(splitExpr);
    return new Set<string>(functionalitiesList.map(it => it.trim()));
  }

  private setAsDisabled(toDisable: Set<string>) {
    const result = new AccessList(
      toDisable.has(AccessRequired.Video)
    )
    this.accessStateSubject.next(result);
  }
}
