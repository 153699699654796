<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Dashboard'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb" *ngIf="isSchoolReady()">
      <ol class="breadcrumb" >
        <!-- <li class="breadcrumb-item active" aria-current="page">{{getSchoolName()}}</li> -->
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item" aria-current="page" *ngIf="hasParent()"><a class="text-capitalize" href="/" routerLink="/school/{{schoolId}}/{{getType()+'s'}}">{{getType()}}</a></li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="isInvitation()">New {{getType()}}</li>
        <li class="breadcrumb-item active" aria-current="page" *ngIf="!isInvitation()">Update {{getType()}}</li>
      </ol>
    </nav>

    <app-casa-personal-profile-form
      *ngIf="invitation"
      [busy]="isReadOnly()"
      [profile]="invitation.details"
      (savePerson)="doSave()"
      [role]="getFormRole()"
    >
      <div class="card-header border-bottom border-secondary pb-4" form-header>
        <h5 class="mb-0" *ngIf="isInvitation()">Add new <span class="h5">{{getType()}}</span></h5>
        <h5 class="mb-0" *ngIf="!isInvitation()">Update existing <span class="h5 text-capitalize">{{getType() + '\'s'}} personal details request.</span></h5>
      </div>
      <div form-body-top>
        <p class="lead" *ngIf="!isFinished()">Fill in the form below to
          <span *ngIf="isInvitation()"> create a {{getType()}} invitation</span>
          <span *ngIf="!isInvitation()"> make {{getType()}} update personal details</span>
        </p>
        <p *ngIf="!isFinished()" class="small">
          When you submit the form, the invitee will receive an email with a confirmation link.
          The link opens a web page where they can verify the information provided by you in this form and correct it if necessary.
          Their information will then be updated. You can check the invitation status on the <a routerLink="/school/{{schoolId}}/dashboard">dashboard page</a>.
        </p>
        <div class="alert alert-success" *ngIf="isFinished()">
          <p class="small">
            The invitation has been sent.
          </p>
          <p class="small">
            The invitee will receive an email with a confirmation link.
            The link opens a web page where they can verify the information provided by you in this form and correct it if necessary.
            Their information will then be updated. You can check the invitation status on the <a routerLink="/school/{{schoolId}}/dashboard">dashboard page</a>.
          </p>
          <p class="small">
            If necessary, you can confirm the invitation manually on the <a routerLink="/school/{{schoolId}}/dashboard">main dashboard</a> on behalf of the invitee.
          </p>
        </div>
        <div class="alert alert-danger" *ngIf="hasErrors()">
          Something went wrong. Please try again later or contact us at <a href="mailto: help@callan.co.uk">help@callan.co.uk</a>.
        </div>
      </div>
      <div class="form-floating mb-4" form-top>
        <input class="form-control"
              placeholder="Email"
              type="email"
              name="email"
              id="email"
              required="required"
              #email="ngModel"
              [readOnly]="isReadOnly()"
              [(ngModel)]="invitation.email"
              [ngClass]="{ 'is-invalid': email.invalid}"
        >
        <label for="email" class="field-required">Email</label>
        <div *ngIf="email.invalid" class="invalid-feedback">
          Field is required
        </div>
      </div>
      <div class="card mb-5" additional-card *ngIf="getFormRole()=='student'">
        <div class="card-header border-bottom border-secondary">
          <h5>Assign products</h5>
        </div>
        <div class="card-body">
          <app-account-products hideControls="true"
                                [productList]="productListType()"
                                [schoolId]="schoolId"
                                [productsAdapter]="adapter"
                                #studentProducts
          ></app-account-products>
        </div>
      </div>
    </app-casa-personal-profile-form>
    <div class="text-end" *ngIf="isFinished() && !hasErrors()">
      <button type="button" class="btn btn-secondary me-2" (click)="clearForm()">Add another</button>
      <button type="button" class="btn btn-primary" (click)="back()">Close</button>
    </div>
  </div>
</div>
