<div class="row border-top border-secondary">
  <div class="col">
    <!-- no bundle layout -->
    <div *ngIf="bundles && bundles.length==0" >
        <p class="pt-4 mb-0">
          Student has <span class="text-muted">0 credits.</span>
      </p>
    </div>

    <!-- multi bundle layout -->
    <div *ngIf="bundles && bundles.length>=1" >
      <h5 class="py-4">
        <span class="h5">Student has </span>
        <span class="text-muted h5">{{creditsTotal}} credits:</span>
      </h5>
      <table class="table table-borderless table-sm">
        <thead>
          <tr class="table-row table-row-head">
            <th>Plan</th>
            <th>Credits</th>
            <th>Expiry Date</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="table-row" *ngFor="let bundle of bundles">
            <td>{{bundle.name}}</td>
            <td>{{bundle.available}}</td>
            <td>{{bundle.expiryDate | date: 'dd MMM yyyy'}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>

