<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/sa/school/{{schoolId}}/student/{{studentId}}"><img src="/assets/img/casa-logo.png"  height="40" class="d-inline-block align-top" alt="" loading="lazy">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/sa/school/{{schoolId}}/student/{{studentId}}">Home <span class="sr-only">(current)</span></a>
      </li>
    </ul>
  </div>
  <a class="nav-link d-none d-lg-block" tabindex="" (click)="logout()">Logout</a>
</nav>

<div style="padding-top: 64px;">
  <router-outlet></router-outlet>
</div>
