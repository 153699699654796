import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpRequest} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ApiOrderItem, ApiProduct, SchoolStudentsFee, Stats, WarehouseEntry} from "../../model/rest/old-casa-api.model";
import {
  CountryDefinition,
  LearningUnitTeacherStatus,
  Manager,
  ManualConfirmationRequest,
  PersonDetails,
  PersonInvitation,
  School,
  SchoolConfirmationRequest,
  Student,
  StudentPersonDetails,
  Teacher,
  TeacherProfileDetails, TeacherStats, VerifiedEmailChangeRequest,
  VerifiedPasswordChangeRequest
} from "../../model/rest/casa-apiv2.model";
import {Page, Pageable} from "../../model/rest/base";
import {Observable} from "rxjs";
import {CacheService} from "../cache.service";
import {TimeUnits} from "../utils/calendar-utils";
import {RepoEntry} from "../../model/rest/documents";
import {SimpleOrder} from "../../model/transactional.v2";

@Injectable({
  providedIn: 'root'
})
export class CasaOldRestApi {

  constructor(
    private http: HttpClient
  ) {}

  public countStudentActions(schoolId: number): Observable<Stats> {
    return this.http.get<Stats>(this.url(`/stats/studentsActions/${schoolId}`))
  }

  public queryStudentsFee(schoolId: number) {
    return this.http.get<SchoolStudentsFee[]>(this.url(`/stats/studentsActions/${schoolId}/report`))
  }

  private url(method: string) {
    return `${environment.casaEndpoint}/api${method}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CasaRestApiV2 {
  constructor(
    private http: HttpClient,
    private cacheService: CacheService
  ) {
  }

  private url(method: string) {
    return `${environment.casaEndpoint}/api/v2${method}`;
  }

  private publicUrl(method: string) {
    return `${environment.casaEndpoint}/api/public/v2${method}`;
  }

  public getSchools():Observable<Page<School>> {
    const method = "/schools"
    return this.http.get<Page<School>>(this.url(method))
  }

  public getSchoolDetails(schoolId: number): Observable<School> {
    const method = `/schools/${schoolId}`;
    return this.cacheService.handleWitchCache(`rest/${method}`, this.http.get<School>(this.url(method)), TimeUnits.Hours(1).toMilis());
  }

  public getSchoolConfirmationRequests(
    schoolId: number,
    roles: string[],
    status: string[],
    expiresBefore: number,
    expiresAfter: number,
    pageable: Pageable) {
    let params = new HttpParams();
    if (roles) {
      params = roles.reduce((p, r) => p.append("role", r), params);
    }
    if (status) {
      params = status.reduce((p, s) => p.append("status", s), params);
    }
    if (expiresBefore) {
      params = params.append("expiresBefore", expiresBefore.toString());
    }
    if (expiresAfter) {
      params = params.append("expiresAfter", expiresAfter.toString());
    }

    params = Pageable.appedPageableParams(params, pageable)
    return this.http.get<Page<SchoolConfirmationRequest>>(this.url(`/schools/${schoolId}/invitations`), {params});
  }

  public confirmRequestManually(schoolId: number, managerPassword: string, userPassword: string, request: SchoolConfirmationRequest) {
    const requestData: ManualConfirmationRequest = {
      passwords: {
        password: userPassword,
        managerPassword
      },
      request
    }
    return this.http.post<any>(this.url(`/schools/${schoolId}/invitations/confirmed`), requestData)
  }

  public renewConfirmationRequest(schoolId: number, request: SchoolConfirmationRequest) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/invitations/renew`), request);
  }

  public revokeConfirmationRequest(schoolId: number, request: SchoolConfirmationRequest) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/invitations/revoked`), request);
  }

  public getManagers(schoolId: number) {
    return this.http.get<Manager[]>(this.url(`/schools/${schoolId}/managers`))
  }

  public inviteManager(schoolId: number, invitation: PersonInvitation) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/managers/invitations`), invitation);
  }

  public deleteManager(schoolId: number, personId: number) {
    return this.http.delete<any>(this.url(`/schools/${schoolId}/managers/${personId}`));
  }

  public updateMangerPersonDetails(schoolId: number, personId: number, details: PersonDetails) {
    return this.http.put<any>(this.url(`/schools/${schoolId}/managers/${personId}/person/details`), details);
  }

  public getStudents(schoolId: number, term: string, status: string[], expiryDate: number, pageable: Pageable) {
    let params = new HttpParams();
    params = Pageable.appedPageableParams(params, pageable)
    if (term) params = params.append("term", term);
    if (expiryDate) params = params.append("expiry", expiryDate.toString());
    if (status) params = status.reduce((p, s) => p.append("status", s), params);
    return this.http.get<Page<Student>>(this.url(`/schools/${schoolId}/students`), {params});
  }

  public getStudent(schoolId: number, studentId: number): Observable<Student> {
    return this.http.get<Student>(this.url(`/schools/${schoolId}/students/${studentId}`));
  }

  public inviteStudent(schoolId: number, invitation: PersonInvitation) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/students/invitations`), invitation);
  }

  public inviteStudentInstantly(schoolId: number, invitation: PersonInvitation) {
    return this.http.post<Student>(this.url(`/schools/${schoolId}/students`), invitation);
  }

  public deleteStudent(schoolId: number, studentId: number) {
    return this.http.delete<any>(this.url(`/schools/${schoolId}/students/${studentId}`));
  }

  public prolongStudent(schoolId: number, studentId: number) {
    return this.http.put<any>(this.url(`/schools/${schoolId}/students/${studentId}/expiry`), null);
  }

  public syncStudent(schoolId: number, studentId: number) {
    return this.http.patch(this.url(`/schools/${schoolId}/students/${studentId}/refresh`), {});
  }

  public clearStudentDeviceShares(schoolId: number, studentId: number) {
    return this.http.delete(this.url(`/schools/${schoolId}/students/${studentId}/cdrm/devices/shares`));
  }

  public changeStudentPassword(schoolId: number, studentId: number, request: VerifiedPasswordChangeRequest) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/students/${studentId}/person/password`), request);
  }

  public changeStudentEmail(schoolId: number, studentId: number, request: VerifiedEmailChangeRequest) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/students/${studentId}/account/email`), request);
  }

  public requestStudentPersonDetailsUpdate(schoolId: number, studentId: number, details: StudentPersonDetails, instant: boolean = false) {
    let params = new HttpParams();
    if (instant) {
      params = params.append("force", "1")
    }
    return this.http.put<any>(this.url(`/schools/${schoolId}/students/${studentId}/person/details`), details, {params});
  }

  public countSchoolTeachersStats(schoolId: number): Observable<TeacherStats> {
    return this.http.get<TeacherStats>(this.url(`/schools/${schoolId}/teachers/stats`));
  }

  public listTeachers(schoolId: number, term: string, pageable: Pageable) {
    let params = new HttpParams();
    params = Pageable.appedPageableParams(params, pageable);
    if (term) params = params.append("term", term);
    return this.http.get<Page<Teacher>>(this.url(`/schools/${schoolId}/teachers`), {params});
  }

  public inviteTeacher(schoolId: number, invitation: PersonInvitation) {
    return this.http.post<any>(this.url(`/schools/${schoolId}/teachers/invitations`), invitation);
  }

  public deleteTeacher(schoolId: number, personId: number) {
    return this.http.delete<any>(this.url(`/schools/${schoolId}/teachers/${personId}`));
  }

  public updateTeacherStatus(schoolId: number, teacherId: number, status: LearningUnitTeacherStatus): Observable<any> {
    return this.http.put
    (this.url(`/schools/${schoolId}/teachers/${teacherId}/status`), `"${status}"`, {
      headers: {"Content-Type" : "application/json"}
    });
  }

  public syncTeacher(schoolId: number, teacherId: number) {
    return this.http.patch(this.url(`/schools/${schoolId}/teachers/${teacherId}/refresh`), {});
  }

  public clearTeacherDeviceShares(schoolId: number, teacherId: number) {
    return this.http.delete(this.url(`/schools/${schoolId}/teachers/${teacherId}/cdrm/devices/shares`));
  }

  public changeTeacherPassword(schoolId: number, teacherId: number, request: VerifiedPasswordChangeRequest): Observable<any> {
    return this.http.post<any>(this.url(`/schools/${schoolId}/teachers/${teacherId}/person/password`), request);
  }

  public changeTeacherEmail(schoolId: number, teacherId: number, request: VerifiedEmailChangeRequest): Observable<any> {
    return this.http.post<any>(this.url(`/schools/${schoolId}/teachers/${teacherId}/account/email`), request);
  }

  public requestTeacherPersonDetailsUpdate(schoolId: number, personId: number, details: PersonDetails, instant: boolean = false) {
    let params = new HttpParams();
    if (instant) {
      params = params.append("force", "1")
    }
    return this.http.put<any>(this.url(`/schools/${schoolId}/teachers/${personId}/person/details`), details, {params});
  }
  
  public queryCredits(schoolId: number) {
    return this.http.get<WarehouseEntry[]>(this.url(`/schools/${schoolId}/credits`));
  }

  public createSchoolOrderCheckout(
    schoolId: number,
    successUrl: string,
    cancelUrl: string,
    order: SimpleOrder
  ): Observable<string> {
    const opt = { responseType: 'text' as 'text' };
    return this.http.post(
      this.url(`/schools/${schoolId}/checkouts?successUrl=${encodeURI(successUrl)}&cancelUrl=${encodeURI(cancelUrl)}`),
      order,
      { responseType: 'text' }
    )
  }

  public studentProducts(schoolId: number, personId: number): Observable<ApiOrderItem[]> {
    return this.http.get<ApiOrderItem[]>(this.url(`/schools/${schoolId}/students/${personId}/products`));
  }

  public teacherProducts(schoolId: number, teacherId: number): Observable<ApiOrderItem[]> {
    return this.http.get<ApiOrderItem[]>(this.url(`/schools/${schoolId}/teachers/${teacherId}/products`));
  }

  public syncStudentProducts(schoolId: number, personId: number, products: ApiProduct[]): Observable<ApiProduct[]> {
    return this.http.put<ApiProduct[]>(this.url(`/schools/${schoolId}/students/${personId}/products`), products);
  }

  public syncTeacherProducts(schoolId: number, teacherId: number, products: ApiProduct[]): Observable<ApiProduct[]> {
    return this.http.put<ApiProduct[]>(this.url(`/schools/${schoolId}/teachers/${teacherId}/products`), products);
  }


  public saveFile(fileToUpload: File) {
    const url = this.url('/files');
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const req = new HttpRequest('POST', url, formData, {reportProgress: true});
    return this.http.request(req);
  }

  public getCountries(): Observable<CountryDefinition[]>  {
    return this.http.get<CountryDefinition[]>(this.publicUrl("/countries"))
  }

  getTeacher(schoolId: number, teacherId: number): Observable<Teacher> {
    return this.http.get<Teacher>(this.url(`/schools/${schoolId}/teachers/${teacherId}`));
  }

  public updateTeacherCourseDetails(schoolId: number, teacherId: number, details: TeacherProfileDetails): Observable<any> {
    return this.http.put(this.url(`/schools/${schoolId}/teachers/${teacherId}/profile`), details);
  }

  public blockStudent(schoolId: number, studentId: number): Observable<any> {
    return this.http.put<any>(this.url(`/schools/${schoolId}/students/${studentId}/block`), null);
  }

  public listDocuments(schoolId: number): Observable<RepoEntry> {
    return this.http.get<RepoEntry>(this.url(`/schools/${schoolId}/documents`));
  }

  public getDocumentLink(schoolId: number, path: string): Observable<string> {
    return this.http.get(this.url(`/schools/${schoolId}/documents/${path}`), {responseType: "text"});
  }

  public refreshDocumentsRepository(schoolId: number): Observable<any> {
    const params = new HttpParams().append("action", "refresh");
    return this.http.post(this.url(`/schools/${schoolId}/documents`), null, {params});
  }

  anonymizePerson(personId: number): Observable<any> {
    return this.http.delete(this.url(`/people/${personId}/account`))
  }
  findTeacherSubscriptions(
    schoolId: number,
    teacherId: number
    ): Observable<ApiOrderItem[]> {
    return this.http.get<ApiOrderItem[]>(this.url(`/schools/${schoolId}/teachers/${teacherId}/subscriptions`));
  }

  syncTeacherSubscriptions(
    schoolId: number,
    teacherId: number,
    products: ApiProduct[]
  ): Observable<ApiProduct[]> {
    return this.http.put<ApiProduct[]>(this.url(`/schools/${schoolId}/teachers/${teacherId}/subscriptions`), products);
  }

}

