<form #newBundleForm="ngForm" (submit)="newBundleForm.valid && registerBundle()">

  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-floating mb-4">
        <select
          name="product"
          #product="ngModel"
          [(ngModel)]="newBundle.product"
          class="form-select"
          [ngClass]="{ 'is-invalid': newBundleForm.submitted && product.invalid,
          'select-not-empty': newBundle.product}"
          required>
          <option *ngFor="let prod of availableProducts" [ngValue]="prod">{{prod.name}}</option>
        </select>
        <label class="field-required">Product</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-floating mb-4">
        <input
        placeholder="0"
        type="number"
        class="form-control"
        name="lessonsNb"
        #lessonsNb="ngModel"
        [(ngModel)]="newBundle.available"
        required
        [ngClass]="{ 'is-invalid': newBundleForm.submitted && lessonsNb.invalid }"
        >
        <label class="field-required">Number of lessons</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-floating mb-4">
        <input
        type="date"
        class="form-control"
        name="expiryDate"
        #expiryDate="ngModel"
        [(ngModel)]="newBundle.expiryDate"
        required
        [ngClass]="{ 'is-invalid': newBundleForm.submitted && expiryDate.invalid }">
        <label class="field-required">Expiry date</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-floating mb-4">
        <input
        placeholder="name"
        type="text"
        class="form-control"
        name="name"
        #name="ngModel"
        [(ngModel)]="newBundle.name"
        required
        [ngClass]="{ 'is-invalid': newBundleForm.submitted && name.invalid }"
        minlength="2">
        <label class="field-required">Name</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-floating mb-4">
        <input
        placeholder="price"
        type="text"
        class="form-control"
        name="price"
        #price="ngModel"
        [(ngModel)]="newBundle.price"
        required
        [ngClass]="{ 'is-invalid': newBundleForm.submitted && price.invalid }"
        minlength="2">
        <label class="field-required">Price</label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="form-floating mb-4">
      <textarea
      placeholder="notes"
      type="text"
      class="form-control"
      name="note"
      #note="ngModel"
      [(ngModel)]="newBundle.note"
      [ngClass]="{ 'is-invalid': newBundleForm.submitted && note.invalid }"
      minlength="2"></textarea>
      <label>Notes</label>
    </div>
  </div>
  <div class="row mb-4 pb-4 border-bottom border-secondary">
    <div class="col-12 col-md-6 col-lg-4 ms-auto text-end">
      <button class="btn btn-primary btn-sm rounded-pill px-4 py-2">Add the credits bundle</button>
    </div>
  </div>
</form>
