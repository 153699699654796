<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Groups'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page"><a routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
          <li class="breadcrumb-item" aria-current="page" ><a routerLink="/school/{{schoolId}}/groups">Groups</a></li>
          <li class="breadcrumb-item active" aria-current="page">Group&nbsp;<strong *ngIf="template" class="text-muted">{{template.details.name}}</strong></li>
        </ol>
    </nav>
    <div class="card mb-5">
      <div class="card-header row border-bottom border-secondary pb-4" *ngIf="templateId && template">

        <div class="col-12 d-flex flex-column flex-lg-row ms-auto">
          <span class="h5 my-md-auto">Weekly planned lessons <span class="text-muted">( {{schedulesCount}} )</span>
          </span>
          <div class="d-flex flex-column flex-sm-row ms-auto mt-sm-3 mt-lg-0">
            <div *ngIf="hasScheduleQueryArgs()" class="btn-group my-3 my-sm-0 me-2 ms-auto">
              <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-end" (click)="moveScheduleWeekOffset(-1)">
                <app-icon name="angle-left" class="icon-primary icon-lg"></app-icon>
              </button>
              <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary px-3 text-muted" (click)="moveScheduleToNow()">{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</button>
              <button [disabled]="isScheduleLoading" class="btn btn-outline-secondary rounded-pill rounded-start" (click)="moveScheduleWeekOffset(1)">
                <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
              </button>
            </div>
            <button class="btn btn-primary px-4 py-2 rounded-pill" (click)="createNewSchedule()">Schedule lessons</button>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom border-secondary px-2 px-md-4">
        <app-schedules-list (dataSet)="onSchedulesDataSetUpdate($event)" (loading)="onSchedulesLoading($event)" [showTemplateName]="false" #schedulesListComponent [dataProvider]="getSchedulesDataProvider()"  (listEvent)="onSchedulesEvent($event)"></app-schedules-list>
      </div>
      <div class="card-header pt-5 d-block d-sm-flex">
        <button class="btn btn-outline-secondary rounded-pill px-4 py-2 me-auto mb-3 mb-sm-0" routerLink="/school/{{schoolId}}/groups">
          <app-icon name="angle-left" class="icon-primary icon-lg me-3"></app-icon>
          <span class="text-primary small">{{getGroupName()}}</span>
        </button>
        <div class="btn-group float-end" *ngIf="templateId">
          <button *ngIf="isDeleted()" class="btn btn-outline-secondary rounded-circle p-3 me-2" (click)="recover()">
            <app-icon name="undo" class="icon-primary"></app-icon>
          </button>
          <button class="btn btn-outline-secondary rounded-circle px-2 me-2" (click)="startConfirmModal.show(true)"
                  data-bs-toggle="tooltip"
                  title="Manually open the classroom for this lesson now"
          >
            <app-icon name="play-arrow" class="icon-primary icon-md"></app-icon>
          </button>
          <button *ngIf="!isDeleted()"
                  class="btn btn-outline-secondary rounded-circle px-2 me-2"
                  (click)="deleteConfirmModal.show(true)"
                  title="Delete lesson" data-bs-toggle="tooltip">
            <app-icon name="delete" class="icon-danger"></app-icon>
          </button>
        </div>
      </div>
      <div class="card-body border-bottom border-secondary">
          <app-room-template-edit [schoolId]="schoolId" [template]="template" (formEvent)="onEditEvent($event)"></app-room-template-edit>
          <div *ngIf="savedCorrectly" class="alert alert-success">
            Saved correctly
          </div>
          <div *ngIf="saveError" class="alert alert-danger">
            Saving error
          </div>
      </div>

      <div class="card-header pt-5 d-block d-sm-flex" *ngIf="templateId">
        <h5>Group's lesson progress history <span class="text-muted">({{historyLength}}):</span></h5>
      </div>
      <div class="card-body" *ngIf="templateId" [hidden]="historyLength === 0">
        <app-progress-history-list (itemsNumber)="historyLength = $event" [dataProvider]="historyDataProvider"></app-progress-history-list>
      </div>

      <div class="card-header row pb-4">
        <div class="col-12 d-flex flex-column flex-sm-row">
          <span class="h5 my-md-auto">This group's lessons <span class="text-muted">({{getRoomsCount()}}):</span>
          </span>
          <app-paginator
                *ngIf="getRoomsCount() > 0"
                class="ms-auto"
                [data]="roomsData"
                [pageable]="roomsPageable"
                (newPage)="changeRoomsPage($event)"
          ></app-paginator>
        </div>
      </div>
      <div class="card-body" *ngIf="templateId">
        <app-rooms-list [data]="roomsData" (details)="openRoomDetails($event)" ></app-rooms-list>
      </div>
    </div>
  </div>
</div>
<app-modal #startScheduleConfirmModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Manually open a classroom for this lesson now</h5>
            <button type="button" class="btn-close" (click)="startScheduleConfirmModal.hide()"  aria-label="Close">
            </button>
        </div>

        <div class="modal-body" *ngIf="scheduleToStart">
          Are you sure you want to open a classroom for the group <strong>{{scheduleToStart.template.details.name}}</strong> and send emails inviting the students to a new lesson?
        </div>

      <div class="modal-footer text-end">
            <button class="btn btn-secondary" (click)="startScheduleConfirmModal.hide()">No</button>
            <button class="btn btn-primary" (click)="onStartSchedule()">Yes</button>
        </div>
    </div>
</app-modal>

<app-modal #startConfirmModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Manually open a classroom for this lesson now</h5>
      <button type="button" class="btn-close" (click)="startConfirmModal.hide()"  aria-label="Close">
      </button>
    </div>

    <div class="modal-body" *ngIf="template">
      <p>
        Are you sure you want to open a classroom for the group <strong>{{template.details.name}}</strong> and send emails inviting the students to a new lesson?
      </p>
      <p>
        <strong class="text-danger">IMPORTANT:</strong> You are opening this Classroom outside the Scheduler. This lesson will not will not appear on the Planned lessons list.
      </p>
    </div>

    <div class="modal-footer text-end">
      <button class="btn btn-secondary" (click)="startConfirmModal.hide()">No</button>
      <button class="btn btn-primary" (click)="onStart()">Yes</button>
    </div>
  </div>
</app-modal>


<app-modal #deleteConfirmModal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title fw-bold">Delete</h5>
            <button type="button" class="btn-close" (click)="cancelDelete()"  aria-label="Close">
            </button>
        </div>
        <div class="modal-body" *ngIf="template">
            <div>
              <strong>Are you sure you want to delete this item?</strong>
            </div>
          <div class="form-check mt-4" *ngIf="scheduleToDelete">
            <input class="form-check-input" type="checkbox" [(ngModel)]="deleteFollowing" >
            <label class="form-check-label" >
              Delete all following schedules too
            </label>
          </div>
        </div>
        <div class="modal-footer text-end">
          <button class="btn btn-danger" (click)="onDelete()">Yes, delete</button>
          <button class="btn btn-primary" (click)="cancelDelete()">No</button>
        </div>
    </div>
</app-modal>

<app-create-schedule #createScheduleComponent [schoolId]="schoolId" [templateId]="templateId" (finish)="refreshSchedules()" [isGroupDefault]="true"></app-create-schedule>
