import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceProvider} from "../../services/col2/auth.service";

@Component({
  selector: 'app-student-area-layout',
  templateUrl: './student-area-layout.component.html',
  styleUrls: ['./student-area-layout.component.scss']
})
export class StudentAreaLayoutComponent implements OnInit {
  schoolId: number;
  studentId: number;

  constructor(
    activatedRoute: ActivatedRoute,
    private authProv: AuthServiceProvider) {
    activatedRoute.paramMap.subscribe(params => {
      this.schoolId = Number(params.get('schoolId'));
      this.studentId = Number(params.get('studentId'));
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.authProv.get().subscribe(api => api.logout());
  }
}
