<app-student-bundle-extend
  #bundleExtendComponent
  [schoolId]="_schoolId"
  [studentId]="_studentId"
  (bundleUpdated)="refresh()"
></app-student-bundle-extend>

<div class="table-responsive mb-4" *ngIf="lastPage && results">
  <table class="table table-sm">
    <thead>
      <tr class="table-row table-row-head">
        <th>Date</th>
        <th>Course</th>
        <th>Name</th>
        <th>Order id</th>
        <th>Price</th>
        <th>Credits</th>
        <th>Expiry Date</th>
        <th>Notes</th>
        <th style="width: 36px;"></th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr *ngFor="let bundle of results" class="table-row">
        <td>
          {{bundle.date | date: 'dd MMM yyyy HH:mm'}}
        </td>
        <td>
          {{bundle.product.name}}
        </td>
        <td>
          {{bundle.name}}
        </td>
        <td>
          {{bundle.orderId}}
        </td>
        <td>
          {{bundle.price}}
        </td>
        <td>
          {{bundle.available}} / {{bundle.registeredLessons}}
        </td>
        <td>
          {{bundle.expiryDate | date: 'dd/MM/yyyy'}}
        </td>
        <td>
          {{bundle.note}}
        </td>
        <td class="text-end">
          <a class="btn btn-outline-secondary-2 rounded-circle p-2" id="dropdownMenuReference" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
            <app-icon name="more-vertical" class="icon-primary"></app-icon>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuReference">
            <a class="dropdown-item cursor-pointer" (click)="changeExpiryDate(bundle)">Change Expiry Date</a>
          </div>
        </td>
      </tr>
      <tr *ngIf="hasNext">
        <td colspan="9" class="text-center rounded-3">
          <a (click)="next()" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<p *ngIf="lastPage && results.length==0" >
  Didn't find any purchases
</p>
