<div class="row">
  <div class="col-4">
    <p class="xsmall text-muted">Image</p>
  </div>
  <div class="col-4">
    <p class="xsmall text-muted">Student's answer</p>
  </div>
  <div class="col-4">
    <p class="xsmall text-muted">Correct answer</p>
  </div>
</div>
<div *ngFor="let q of questionAnswerMap" class="row mb-2">
  <div class="col-4">
    <img class="w-100 picture-max-width" [src]="getImgSource(q.img)" [alt]="q.correctAnswer"/>
  </div>
  <div class="col-4 d-flex">
    <p class="small my-auto" [ngClass]="{ 'text-danger': isAnswerIncorrect(q)}">{{ q.studentAnswer }}</p>
  </div>
  <div class="col-4 d-flex">
    <p class="small my-auto">{{ q.correctAnswer }}</p>
  </div>
</div>
