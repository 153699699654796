<div *ngIf="lessonDetails">
  <!-- lesson details card -->
  <div class="card mb-5">
    <div class="card-header border-bottom border-secondary pb-4">
      <span class="me-auto h5 mb-0">{{lessonDetails.course.name}}</span>
      <span class="text-muted small my-auto">{{getLessonDate() | date: 'EEE, dd MMM yyyy HH:mm'}}&nbsp;</span>
      <span class="text-muted small my-auto" *ngIf="getStartDate()">(started: {{getStartDate() | date: 'HH:mm'}})</span>
    </div>
    <div class="card-body">
      <div class="row g-5 py-3 border-bottom border-secondary">
        <div class="col-lg-4 order-lg-last text-end">
          <div class="rounded-3 p-2 lh-lg">
            <div class="rounded-circle img-thumbnail float-start p-0 border-0" style="width: 2rem;" *ngIf="getPersonPhoto(lessonDetails.teacher.person)">
              <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getPersonPhoto(lessonDetails.teacher.person) +')'"></div>
            </div>
            <div *ngIf="!getPersonPhoto(lessonDetails.teacher.person)" class="initials-circle-box-sm float-start me-3">
              <span class="m-auto xsmall text-white">{{ getInitials(lessonDetails.teacher.person) }}</span>
            </div>
            {{getPersonName(lessonDetails.teacher.person)}}
          </div>
          <div class="rounded-3 p-2 lh-lg">
              <div  class="rounded-circle img-thumbnail float-start" style="width: 2rem;" *ngIf="getPersonPhoto(lessonDetails.students[0].person)">
                <div class="profile-photo rounded-circle" [style.background-image]="'url(' + getPersonPhoto(lessonDetails.students[0].person) +')'"></div>
              </div>
              <div *ngIf="!getPersonPhoto(lessonDetails.students[0].person)" class="initials-circle-box-sm float-start me-3">
                <span class="m-auto xsmall text-white">{{ getInitials(lessonDetails.students[0].person) }}</span>
              </div>
              {{getPersonName(lessonDetails.students[0].person)}}
            </div>
        </div>
        <div class="col-lg-8 d-flex flex-column gap-3">
          <div class="form-floating">
            <select
            name="lessonType"
            [(ngModel)]="lessonDetails.lessonType"
            (change)="updateLessonType()"
            [ngClass]="{ 'select-not-empty': lessonDetails.lessonType }"
            class="form-select">
              <option value="Standard">Standard</option>
              <option value="Revision">Revision</option>
              <option value="QuickStageRevision">Quick Stage Revision</option>
              <option value="Introduction">Introduction</option>
              <option value="Exam">Exam</option>
              <option value="Prova">Prova</option>
            </select>
            <label>Lesson type</label>
          </div>

          <div class="form-floating">
            <input class="form-control pe-none"
            placeholder="placeholder"
            [value]="lessonDetails.lessonStatus">
            <label>Status</label>
          </div>
          <div class="form-floating" *ngIf="hasComment(lessonDetails)">
            <input class="form-control pe-none"
            placeholder="placeholder"
            [value]="getComment(lessonDetails)">
            <label>Lesson comment</label>
          </div>
          <div class="form-floating" *ngIf="hasPrivateComment(lessonDetails)">
            <input class="form-control pe-none"
            placeholder="placeholder"
            [value]="getPrivateComment(lessonDetails)">
            <label>Private comment</label>
          </div>
          <div class="form-floating" *ngIf="hasStudentMessage()">
            <input class="form-control pe-none"
            placeholder="placeholder"
            [value]="lessonDetails.studentMessage">
            <label>Message to student</label>
          </div>
          <div class="row" *ngIf="hasFlags()">
            <h6 class="text-muted py-3">Flags</h6>
            <table class="table">
              <thead>
                <tr class="table-row table-row-head">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr *ngFor="let flag of lessonDetails.flags" class="table-row">
                  <td>
                    {{flag.date | date: 'dd/MM/yy HH:mm'}}
                  </td>
                  <td>
                    {{getFlagTypeName(flag.flagType)}}
                  </td>
                  <td>
                    {{flag.description}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="lessonDetails.cancellationDetails" class="border-top border-secondary py-3">
        <h6 class="text-muted py-4">Cancellation details</h6>
        <div class="row">
          <div class="col-12 col-lg-4 mb-4">
            <div class="form-floating">
              <input class="form-control pe-none"
              type="text"
              [value]="lessonDetails.cancellationDetails.cancelationDate | date: 'EEE, dd MMM yyyy HH:mm '">
              <label>Date</label>
            </div>
          </div>
          <div class="col-12 col-lg-8 mb-4">
            <div class="form-floating">
              <input class="form-control pe-none"
              type="text"
              [value]="lessonDetails.cancellationDetails.cancellationReason">
              <label>Reason</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="studentProgress">
        <h6 class="text-muted py-4">Current student progress</h6>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="table-row table-row-head">
                <th></th>
                <th>Start page</th>
                <th>New work page</th>
                <th>Reading</th>
                <th>Last headword</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr class="table-row">
                <th></th>
                <td>{{studentProgress.start}}</td>
                <td>{{studentProgress.nwp}}</td>
                <td>{{studentProgress.reading}}</td>
                <td>{{studentProgress.hw}}</td>
              </tr>
              <tr class="table-row" *ngIf="estimatedProgress">
                <th>Estimated:</th>
                <td>{{estimatedProgress.start}}</td>
                <td>{{estimatedProgress.nwp}}</td>
                <td>{{estimatedProgress.reading}}</td>
                <td>{{estimatedProgress.hw}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2 me-2" *ngIf="mayBook()" (click)="book()">Book</button>
      <button class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2" *ngIf="mayConfirm()" (click)="confirm()">Confirm</button>
    </div>
  </div>
  <!-- attach the lesson flag -->
  <div class="card mb-5">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Add the flag</h5>
    </div>
    <div class="card-body">
      <form #flagForm="ngForm" class="form" (ngSubmit)="flagForm.form.valid && saveFlag()">
        <div class="row">
          <div class="col-12">
            <div class="form-floating mb-4">
              <select class="form-select"
                name="flagType"
                [(ngModel)]="_newFlagType"
                [ngClass]="{ 'select-not-empty': _newFlagType }"
                required
                >
                <option *ngFor="let flagType of flagTypes" value="{{flagType}}">{{getFlagTypeName(flagType)}}</option>
              </select>
              <label>Flag type</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating mb-4">
              <textarea
                name="flagDescription"
                placeholder="flagDescription"
                [(ngModel)]="_newFlagDescription"
                rows="1" class="form-control">
              </textarea>
              <label>description</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2">Add Flag</button>
      </form>
    </div>
  </div>
  <!-- finished lesson progress -->
  <div class="card mb-5" *ngIf="lessonDetails && lessonDetails.progressCommited">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Lesson Progress</h5>
    </div>
    <div class="card-body">
      <form #progFrm="ngForm" (ngSubmit)="progFrm.form.valid && updateProgress()">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input
              class="form-control field-required"
              placeholder="0"
              type="number"
              name="comStart"
              [(ngModel)]="lessonDetails.progressCommited.start"
              #comStart="ngModel"
              [ngClass]="{ 'is-invalid': progFrm.submitted && comStart.invalid }"
              required
              >
              <label>Start page</label>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input
              class="form-control field-required"
              type="number"
              name="comdNwp"
              placeholder="comdNwp"
              #comNwp="ngModel"
              [(ngModel)]="lessonDetails.progressCommited.nwp"
              [ngClass]="{ 'is-invalid': progFrm.submitted && comNwp.invalid }"
              required
              >
              <label>New work page</label>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input
              class="form-control"
              type="number"
              name="comReading"
              placeholder="comReading"
              #comReading="ngModel"
              [(ngModel)]="lessonDetails.progressCommited.reading"
              [ngClass]="{ 'is-invalid': progFrm.submitted && comReading.invalid }"

              >
              <label>Reading</label>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input
              class="form-control field-required"
              type="text"
              name="comHw"
              placeholder="comHw"
              [(ngModel)]="lessonDetails.progressCommited.hw"
              #comHw="ngModel"
              [ngClass]="{ 'is-invalid': progFrm.submitted && comHw.invalid }"
              required
              >
              <label>Last headword</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-sm rounded-pill float-end  px-3 py-2">Update</button>
      </form>
    </div>
  </div>
  <!-- cancellation section -->
  <div class="card mb-5" *ngIf="mayCancel()">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Cancellation</h5>
    </div>
    <div class="card-body">
      <form #cncFrm="ngForm" (ngSubmit)="cncFrm.form.valid && cancel()">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input class="form-control field-required"
              name="reason"
              placeholder="reason"
              [(ngModel)]="_cancelReason"
              required>
              <label>Reason</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-danger btn-sm rounded-pill float-end px-3 py-2">Cancel</button>
      </form>
    </div>
  </div>
  <!-- squander section -->
  <div class="card mb-5" *ngIf="maySquander()">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Squander Lesson</h5>
    </div>
    <div class="card-body">
      <form #sqFrm="ngForm" (ngSubmit)="sqFrm.form.valid && squander()">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input class="form-control field-required"
              name="reason"
              placeholder="reason"
              [(ngModel)]="_squanderReason"
              required>
              <label>Reason</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-danger btn-sm rounded-pill float-end px-3 py-2">Squader</button>
      </form>
    </div>
  </div>
  <!-- lesson start section -->
  <div class="card mb-5" *ngIf="mayStart()">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Start the lesson</h5>
    </div>
    <div class="card-body">
      <form #stFrm="ngForm" (ngSubmit)="stFrm.form.valid && start()">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <input class="form-control"
              name="startDate"
              type="date"
              placeholder="dd-MM-yyyy HH:mm"
              [(ngModel)]="_startDate">
              <label>Start Date: (leave empty if has to be default)</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2">Start</button>
      </form>
    </div>
  </div>
  <!-- lesson finish section -->
  <div class="card mb-5" *ngIf="mayFinish()">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Finish the lesson</h5>
    </div>
    <div class="card-body">
      <form #finFrm="ngForm" (ngSubmit)="finFrm.form.valid && finish()">
        <div class="row">
          <div class="col-12 col-lg-6 mb-4">
            <div class="form-floating">
              <input class="form-control"
              name="finishDate"
              type="date"
              [(ngModel)]="_finishDate"
              placeholder="dd-MM-yyyy HH:mm">
              <label>Finish Date</label>
            </div>
          </div>
            <div class="col-12 col-lg-6 mb-4">
              <div class="form-floating">
                <input
                class="form-control field-required"
                type="number"
                name="comStart"
                placeholder="comStart"
                [(ngModel)]="_finishProgress.start"
                #comStart="ngModel"
                [ngClass]="{ 'is-invalid': finFrm.submitted && comStart.invalid }"
                required
                >
                <label>Start page</label>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <div class="form-floating">
              <input
              class="form-control field-required"
              type="number"
              name="comdNwp"
              placeholder="comdNwp"
              #comNwp="ngModel"
              [(ngModel)]="_finishProgress.nwp"
              [ngClass]="{ 'is-invalid': finFrm.submitted && comNwp.invalid }"
              required
              >
              <label>New work page</label>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <div class="form-floating">
              <input
              class="form-control"
              type="number"
              name="comReading"
              placeholder="comReading"
              #comReading="ngModel"
              [(ngModel)]="_finishProgress.reading"
              [ngClass]="{ 'is-invalid': finFrm.submitted && comReading.invalid }"
              >
              <label>Reading</label>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <div class="form-floating">
              <input
              class="form-control field-required"
              type="text"
              name="comHw"
              placeholder="comHw"
              [(ngModel)]="_finishProgress.hw"
              #comHw="ngModel"
              [ngClass]="{ 'is-invalid': finFrm.submitted && comHw.invalid }"
              required
              >
              <label>Last headword</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2">Finish</button>
      </form>
    </div>
  </div>
  <!-- lesson timing -->
  <div class="card mb-5" *ngIf="mayUpdateLessonTime()">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Lesson execution time</h5>
    </div>
    <div class="card-body">
      <form #timeFrm="ngForm" class="form" (ngSubmit)="timeFrm.form.valid && updateLessonTime()">
        <div class="row">
          <div class="col-12 col-lg-6 mb-4">
            <div class="form-floating">
              <input
              name="startTime"
              type="date"
              placeholder="dd-MM-yyyy HH:mm"
              class="form-control"
              [(ngModel)]="_startDate">
              <label>Started at</label>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <div class="form-floating">
              <input
              name="duration"
              placeholder="duration"
              class="form-control"
              type="number"
              [(ngModel)]="_lessonDuration">
              <label>Duration (minutes)</label>
            </div>
          </div>
        </div>
        <button type="submit" class="tn btn-primary btn-sm rounded-pill float-end px-3 py-2">Update</button>
      </form>
    </div>
  </div>
  <!-- lesson message -->
  <div class="card mb-5">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Lesson messages</h5>
    </div>
    <div class="card-body">
      <form class="form" #lMsgForm="ngForm" (ngSubmit)="lMsgForm.form.valid && saveComment()">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <textarea class="form-control field-required"
              name="privateComment"
              placeholder="privateComment"
              [(ngModel)]="_privateComment"
              required
              ></textarea>
              <label>Lesson private comment</label>
            </div>
          </div>
        </div>
        <button class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2" type="submit">Save</button>
      </form>
    </div>
  </div>
  <!-- student message -->
  <div class="card mb-5">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Student messages</h5>
    </div>
    <div class="card-body">
      <form class="form" #sMsgForm="ngForm" (ngSubmit)="sMsgForm.form.valid && saveStudentMessage()">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="form-floating">
              <textarea class="form-control field-required"
              name="privateComment"
              placeholder="privateComment"
              [(ngModel)]="_messageToStudent"
              required
              ></textarea>
              <label>Message to student</label>
            </div>
          </div>
        </div>
        <button class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2" type="submit">Save</button>
      </form>
    </div>
  </div>
  <!-- student current context -->
  <div class="card mb-5">
    <div class="card-header border-bottom border-secondary pb-4">
      <h5 class="my-auto">Student context</h5>
    </div>
    <div class="card-body">
      <form #sctxForm="ngForm" (ngSubmit)="sctxForm.form.valid && saveStudentContext()">
        <div class="row">
          <div *ngIf="studentContext" class="col-md-6 mb-4">
            <div class="form-floating">
              <select
                class="form-select field-required"
                name="commingLessonType"
                [(ngModel)]="studentContext.nextLessonType"
                [ngClass]="{ 'select-not-empty': studentContext.nextLessonType }"
                required
                >
                <option value="Standard">Standard</option>
                <option value="Revision">Revision</option>
                <option value="QuickStageRevision">Quick Stage Revision</option>
                <option value="Introduction">Introduction</option>
                <option value="Exam">Exam</option>
                <option value="Exam">Prova</option>
              </select>
              <label>Coming Lesson Type</label>
            </div>
          </div>
          <div *ngIf="studentContext" class="col-md-6 mb-4">
            <div class="form-floating">
              <select
                class="form-select field-required"
                name="studentsStage"
                [(ngModel)]="studentContext.currentCourse"
                [ngClass]="{ 'select-not-empty': studentContext.currentCourse }"
                required
                >
                <option *ngFor="let course of productCourses" [ngValue]="course">{{course.name}}</option>
              </select>
              <label>Student's Stage</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-sm rounded-pill float-end px-3 py-2">Update</button>
      </form>
    </div>
  </div>
  <div class="card mb-5">
    <div class="card-header border-bottom border-secondary pb-4">
        <h5 class="my-auto">Lesson history</h5>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr class="table-row table-row-head">
              <th>Type</th>
              <th>Date</th>
              <th>Start</th>
              <th>Nwp</th>
              <th>Read</th>
              <th>Headword</th>
              <th>Tutor</th>
              <th>Comment</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr [ngClass]="getLessonColorClass(lesson)" *ngFor="let lesson of history" class="table-row">
              <td >{{lesson.lessonType}}</td>
              <td>{{getHistoryDate(lesson) |  date: 'dd/MM/yy HH:mm'}}</td>
              <td>{{lesson.progressCommited?.start }}</td>
              <td>{{lesson.progressCommited?.nwp }}</td>
              <td>{{lesson.progressCommited?.reading }}</td>
              <td>{{lesson.progressCommited?.hw }}</td>
              <td>{{getPersonName(lesson.teacher.person)}}</td>
              <td>
                <span *ngIf="hasComment(lesson)">lesson comment: {{getComment(lesson)}}<br></span>
                <span *ngIf="hasPrivateComment(lesson)">lesson private comment: {{getPrivateComment(lesson)}}<br></span>
              </td>
              <td>
                <div class="lesson-table-tooltip">
                  <i *ngIf="lesson.flags.length > 0" class="fas fa-flag"></i>
                  <div class="lesson-table-tooltip-content p-2 rounded shadow">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let flag of lesson.flags">
                          <strong>{{getFlagTypeName(flag.flagType)}}</strong><span *ngIf="flag.description">:<br>
                          with description: {{flag.description}}
                          </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="lesson-table-tooltip">
                  <i *ngIf="lesson.studentMessage" class="fas fa-comments"></i>
                  <div class="lesson-table-tooltip-content p-2 rounded shadow">
                    <span *ngIf="lesson.studentMessage">message to the student: {{lesson.studentMessage}}<br></span>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="hasHistoryNext">
              <td colspan="9" class="text-center"> <a (click)="historyNext()" tabindex=""><i class="fa fa-angle-double-down justify-content-center"></i></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
