import {Component, Input, OnInit} from '@angular/core';
import {
  Dictation,
  DictationAnswer,
  ExerciseSessionQuestion
} from "../../../model/rest/cspa.model";
import {BlockComparator} from "../../../utils/text-compare";

@Component({
  selector: 'app-dict-result',
  templateUrl: './dict-result.component.html',
  styleUrls: ['./dict-result.component.scss']
})
export class DictResultComponent implements OnInit {
  @Input() questions: ExerciseSessionQuestion<DictationAnswer, Dictation> []

  constructor() { }

  ngOnInit(): void {
  }

  getCorrectAnswer(q: ExerciseSessionQuestion<DictationAnswer, Dictation>) {
    return q.question.definition.answer.answer
  }

  getStudentAnswer(q: ExerciseSessionQuestion<DictationAnswer, Dictation>) {
    if(!q.answer) return [null]
    const correctAnswerStream = BlockComparator.tokenize(q.question.definition.answer.answer);
    const userAnswer = BlockComparator.tokenize(q.answer?.answer);
    const result = BlockComparator.compare(correctAnswerStream, userAnswer);
    const blocks = BlockComparator.divideToBlocks(result, userAnswer);
    return BlockComparator.compressBlocks(blocks);
  }
}
