import { RoomDef, ParticipantDef} from './server';
import { SearchingContextService } from '../services/searching-context.service';
import { Observable } from 'rxjs';
import {Page, Pageable} from "./rest/base";

export class ParticipantData {
    roomDef: RoomDef;
    videoRoom: any;
    participantDef: ParticipantDef;
    participant: any;
    local = false;
  }

export interface GenericSimpleDataProvider<T> {
    doLoad(): Observable<T>;
  }

export interface PageableDataProvider<T> extends GenericSimpleDataProvider<Page<T>> {
  nextPage();

  /**
   * wrapper for doLoad
   */
  load(): Observable<Page<T>>;
}

export abstract class GenericDataProvider<D, P extends Pageable> implements PageableDataProvider<D> {
  constructor(public searchingContextPath: string,
              public pageable: P,
              public searchContextService: SearchingContextService) {}
  nextPage() {
    this.pageable = this.pageable.next() as P;
  }

  abstract doLoad(): Observable<Page<D>>;

  load(): Observable<Page<D>> {
    this.searchContextService.updateContext<Pageable>(this.searchingContextPath, this.pageable.cloneForInitial());
    return this.doLoad();
  }
}
