<div class="table-responsive table-height">
  <p class="text-muted text-center pt-3" *ngIf="dataIsEmpty()">
    no results
  </p>

  <div class="table-responsive table-height" id="calendar" *ngIf="!dataIsEmpty()">
  <table class="table table-sm">
    <thead class="position-sticky top-0 text-center z-index-10" id="table-header">
    <tr class="sticky-border-bottom bg-head">
      <th class="bg-head position-sticky sticky-border-bottom-end start-0 top-0"></th>
      <th *ngFor="let dayDate of getWeekDates()">
          <span class="xsmall">
            {{dayDate | date: "EEE"}}
          </span>
        <span class="small text-muted fw-normal">{{dayDate | date: "d"}}</span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let x of [].constructor(24); let hour = index"
        class="border-bottom border-secondary">
      <td class="p-3 position-sticky start-0 sticky-border-bottom-end bg-head">
        <span>{{hour}}:00</span>
      </td>
      <td *ngFor="let dayDate of getWeekDates(); let dayIndex = index"
          class="border-start border-secondary align-top">
        <ng-container *ngFor="let schedule of getCalendarDisplayViewDay(dayDate)">
          <div class="bg-calendar class rounded p-3 d-block h-100" *ngIf="isScheduleInDateTime(schedule, hour, dayIndex)">
            <a [ngClass]="getBadgeColorClassByStatus(schedule)"
               class="badge rounded px-2 py-1 text-white w-fit-content mb-2"
               routerLink="/school/{{schoolId}}/schedules/{{schedule.schedule.id}}"
               data-bs-toggle="tooltip"
               [title]="getTooltipContent(schedule)">
                <span class="text-white">
                  {{schedule.schedule.details.startDate | date: 'HH:mm'}}
                </span>
              <span class="text-white" *ngIf="getEndDate(schedule)">
                  - {{getEndDate(schedule) | date: "HH:mm"}}
                </span>
            </a>
            <br>
            <p class="text-muted class mb-0">
              {{getPlace(schedule)}}
              <app-icon name="school" *ngIf="hasAnyOffline(schedule)" class="icon-secondary icon-xs"></app-icon>
            </p>
            <a class="group" routerLink="/school/{{schoolId}}/groups/{{schedule.template.id}}">
              {{getGroupName(schedule.template)}}
            </a>
          </div>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>
</div>
