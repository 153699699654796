<div class="card h-100" *ngIf="isReady()">
  <div class="card-header">
    <app-icon name="person" class="icon-primary icon-bg bg-gray"></app-icon>
    <div>
      <h6 class="card-title">Students</h6>
      <p class="card-subtitle small text-muted">You have {{getTotal()}} students, of which {{getActive()}} are active.</p>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="getNew() || getRenewed() || getBlocked() || getUnblocked() || getDeleted()">
      <p class="xsmall">This quarter you have:</p>
      <ul class="list-unstyled separated-list">
        <li class="small" *ngIf="getNew()">{{getNew()}} new student(s)</li>
        <li class="small" *ngIf="getRenewed()">{{getRenewed()}} renewed student(s)</li>
        <li class="small" *ngIf="getBlocked()">{{getBlocked()}} blocked student(s)</li>
        <li class="small" *ngIf="getUnblocked()">{{getUnblocked()}} unblocked student(s)</li>
        <li class="small" *ngIf="getDeleted()">{{getDeleted()}} deleted student(s)</li>
      </ul>
    </div>
  </div>
  <div class="card-footer text-end">
    <a href="#" class="btn btn-secondary btn-sm rounded-pill px-3 px-md-4 py-2 me-2" routerLink="/school/{{mSchool.id}}/students">Students’ details</a>
    <a href="#" class="btn btn-primary btn-sm rounded-pill px-3 px-md-4 py-2" routerLink="/school/{{mSchool.id}}/student/new">Add student</a>
  </div>
</div>

