import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile,
  ApiTeacherProfile,
  EntityRelatedRow
} from 'src/app/model/rest/rest-model';

@Injectable({
  providedIn: 'root'
})
export class ReportsRestService {

  constructor( private http: HttpClient) {}

  prepareSchoolUrl( schoolId: number) {
    return environment.apiEndpoint + '/reports/school/' + schoolId;
  }

  private static prepareReportBaseQueryParams(dateFrom: Date, dateTo: Date, productCode: string) {
    let params = new HttpParams();
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toISOString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toISOString());
    }
    if (productCode) {
      params = params.append('productCode', productCode);
    }
    return params;
  }

  listSchoolTeacherLessonsTypesReport( schoolId: number, dateFrom: Date, dateTo: Date, productCode: string)
  : Observable<EntityRelatedRow<ApiTeacherProfile>[]> {
    const url = this.prepareSchoolUrl(schoolId) + '/teacher-lesson-types';
    const params = ReportsRestService.prepareReportBaseQueryParams(dateFrom, dateTo, productCode);

    return this.http.get<EntityRelatedRow<ApiTeacherProfile>[]>(url, {params: params});
  }

  listLessonDetails(schoolId: number, dateFrom: Date, dateTo: Date, productCode: string,
    lessonType: string[], lessonStatus: string[], flags: string[])
    : Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
      const url = this.prepareSchoolUrl(schoolId) + '/lessons';

      let params = ReportsRestService.prepareReportBaseQueryParams(dateFrom, dateTo, productCode);
      for (const t of lessonType) {
        params = params.append('lessonType', t);
      }
      for (const s of lessonStatus) {
        params = params.append('lessonStatus', s);
      }
      if (flags) {
        for (const f of flags) {
          params = params.append('withFlags', f);
        }
      }
      return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(url, {params: params});
    }
}
