<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Groups'"></top-bar>
  <div class="container py-4 px-md-5" >
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" aria-current="page"><a href="/" routerLink="/school/{{schoolId}}/dashboard">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Groups</li>
      </ol>
    </nav>

    <div class="row mb-4">
      <div class="col-auto mb-3 mb-sm-0">
        <h4 class="mb-0">Groups <span class="text-primary">( {{getGroupsNumber()}} )</span>
        </h4>
      </div>
      <div class="col-auto ms-auto">
        <a class="btn btn-primary px-4 py-2 rounded-pill" routerLink="/school/{{schoolId}}/v2_groups/new">Add new group</a>
      </div>
    </div>
    <div  class="card">
      <form class="card-header row flex-column flex-md-row gy-3" *ngIf="currentState" (ngSubmit)="restartSearch()">
        <div class="col-12 col-md-5">
          <div class="position-relative">
            <input name="groups-search" type="text" class="form-control p-3 mb-3 mb-md-0" [(ngModel)]="currentState.term" placeholder="Search">
            <button type="submit" class="btn position-absolute end-0 top-0 bottom-0">
              <app-icon name="search" class="icon-primary"></app-icon>
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 ms-auto" *ngIf="currentState">
          <select name="groups-sorting" [(ngModel)]="currentState.sortingRule" (ngModelChange)="restartSearch()" class="form-select p-3 mb-3 mb-md-0"
                  [ngClass]="{ 'select-not-empty': currentState.sortingRule }">
            <option [ngValue]="sorting" *ngFor="let sorting of sortingRules">{{sorting.name}}</option>
          </select>
        </div>
        <div class="col-12 col-lg-auto d-flex">
          <app-paginator
            class="my-auto ms-auto"
            [pageable]="currentState?.page"
            [data]="groups"
            (newPage)="listSchools($event)"
          ></app-paginator>
        </div>
      </form>
      <div class="card-body">
        <div class="table-responsive" *ngIf="groups">
          <table class="table">
            <tbody class="table-body cursor-pointer">
              <tr *ngFor="let group of groups.content" class="table-row" routerLink="./group/{{group.id}}">
                <td>{{group.details.name}}
                  <span class="badge bg-warning xsmall ms-2">{{group.status}}</span>
                </td>
                <td class="text-end">
                  <button class="btn btn-outline-secondary-2 rounded-circle" type="button">
                    <app-icon name="angle-right" class="icon-primary icon-lg"></app-icon>
                  </button>
                </td>
              </tr>
              <tr *ngIf="groups.numberOfElements == 0">
                <td colspan="3" class="text-center text-muted">
                  No results found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
