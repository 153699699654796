
export class Range< D> {
    data: D;
    from: number;
    to: number;

    constructor(from: number, to: number, data?: D ) {
        this.from = from;
        this.to = to;
        this.data = data;
    }

    clone() {
        return new Range( this.from, this.to, this.data);
    }

    intersect(other: Range<D>): Range<D> {
        const from = Math.max(this.from, other.from);
        const to = Math.min(this.to, other.to);

        return new Range<D>(from, to);
    }

    isEmpty(): boolean {
        return this.from >= this.to;
    }

}

export class RangeSet<D> {
    ranges: Range<D>[] = [];
    groupsNumber: number;
    assigments: number[] = null;

    public getAsigmentByData(data: D) {
        if (this.assigments === null) { return 0; }
        for (let i = 0 ; i < this.ranges.length ; i ++) {
            if (this.ranges[i].data === data ) {
                return this.assigments[i];
            }
        }
        return 0;

    }

    public addRange(from: number, to: number, data: D) {
        this.ranges.push(new Range<D>(from, to, data));
    }

    private sortRanges() {
       this.ranges = this.ranges.sort( (l, r) => l.from - r.from);
    }

    private findColisioningGroups() {
        const result: Array<number[]> = [];

        for (let i = 0 ; i < this.ranges.length ; i ++ ) {
            const currentGroup = [i];
            let intersection = this.ranges[i].clone();
            for ( let j = i + 1 ; j < this.ranges.length ; j++ ) {
                intersection = intersection.intersect(this.ranges[j]);
                if (intersection.isEmpty()) { break; }
                currentGroup.push(j);
            }
            if (currentGroup.length > 1) {
                result.push(currentGroup);
            }
        }

        return result;
    }

    private maximumNumberOfColisions(colisionGroups: Array<number[]>) {
        return Math.max(...colisionGroups.map ( g => g.length ));
    }

    public reassingGroups() {
        this.assigments = null;
        this.sortRanges();
        const colisioningGroups = this.findColisioningGroups();
        this.groupsNumber = this.maximumNumberOfColisions(colisioningGroups);
        if (this.groupsNumber <= 0 ) {
            this.groupsNumber = 1;
        }
        if (this.groupsNumber === 1 ) {
            return;
        }
        const assigments: number[] = [];
        let assigmentsNumber = 0;
        for (let i = 0 ; i < colisioningGroups.length ; i ++ ) {
            const availability = Array.from({length: this.groupsNumber}, (v, k) => k );
            const colisioningGroup = colisioningGroups[i];
            for (let j = 0 ; j < colisioningGroup.length ; j++ ) {
                const colisioningElement = colisioningGroup[j];
                const groupAssigned = assigments[colisioningElement];
                if (groupAssigned !== undefined ) {
                    availability.splice(availability.indexOf(groupAssigned), 1);
                }
            }
            for (let j = 0 ; j < colisioningGroup.length ; j++ ) {
                const colisioningElement = colisioningGroup[j];
                let groupAssigned = assigments[colisioningElement];
                if (groupAssigned !== undefined) { continue; }
                groupAssigned = availability[0];
                assigments[colisioningElement] = groupAssigned;
                availability.splice(0, 1 );
                assigmentsNumber++;
            }
        }

        this.assigments = assigments;
    }
}

