<div #calendar class="week-calendar">
  <div class="cal-header">
    <div class="row py-2">
      <div class="col" style="line-height: 1.3;">
        <span *ngIf="_currentDate" >{{ _currentDate | date: 'MMMM' }}</span>&nbsp;
        <span *ngIf="_currentDate">{{_currentDate | date: 'yyyy'}}</span>
        <span class="text-muted d-none d-sm-inline xsmall"><br>Calendar shows your local time</span>
      </div>
      <div class="col-auto text-end">
        <div class="btn-group">
          <div class="btn btn-outline-secondary text-success rounded-start" (click)="moveDays(-7)">
            <app-icon name="angle-left" class="icon-secondary arrow-icon"></app-icon>
          </div>
          <div class="btn btn-outline-secondary btn-sm text-black py-2" (click)="today()">Today</div>
          <div class="btn btn-outline-secondary text-danger rounded-end" (click)="moveDays(7)">
            <app-icon name="angle-right" class="icon-blue arrow-icon"></app-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end d-block d-sm-none text-center">
      <small class="text-muted">Calendar shows your local time</small>
    </div>
  </div>
  <div class="wcal">
    <div class="wcal-hours-col wc-head-width">
      <div class="wcal-empty wc-head-height border-end border-bottom border-secondary">
      </div>
      <div #hoursScroll class="wcal-hours-scroll-wrapper wc-height">
        <div class="wcal-hours-panel border-end border-secondary">
          <div
          *ngFor="let hour of getHours()"
          class="wcal-hour wc-col-height position-relative border-bottom border-secondary"
          >
          <span class="xsmall me-1 me-sm-2">{{ hour }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wcal-grid-col">
      <div #daysScroll class="overflow-hidden w-100">
        <div
          class="wcal-days-panel wc-head-height wc-width d-flex border-secondary"
        >
          <div
            *ngFor="let weekDate of getWeekDates()"
            class="wcal-day wc-col-width border-bottom border-inline border-secondary"
          >
            <div class="d-flex flex-column align-items-center">
              <span class="xsmall text-muted">
                {{ weekDate | date : "EEE" }}</span>
              <span class="small">{{ weekDate | date : "dd" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div #gridScroll class="wcal-grid-scroll-wrapper wc-height">
        <div class="wc-width d-flex">
          <div
            *ngFor="let x of [].constructor(7); let i = index"
            class="wcal-day-col wc-col-width border-inline"
            (click)="dayClick(i, $event)"
            [ngClass]="{ active: getActiveDay() == i }"
          >
            <div
              *ngFor="let i of [].constructor(24*2)"
              class="wcal-day-hour wc-col-height border-bottom border-secondary"
            ></div>
            <div
              *ngFor="let event of _dayEvents[i]"
              class="event position-absolute rounded"
              [ngClass]="event.classes"
              [ngStyle]="{
                top: event.start + '%',
                height: 'calc(' + event.height + '% - 2px)',
                left: event.left + '%',
                width: event.width + '%'
              }"
              (click)="eventClick(i, event, $event)"
            >
              <span *ngIf="event.title" class="xsmall ms-1 event title">{{
                event.title()
              }}</span>
              <span *ngIf="event.subtitle" class="xsmall ms-1">{{
                event.subtitle
              }}</span>
            </div>
            <div
              *ngIf="getActiveDay() == i"
              class="currentTime"
              [ngStyle]="{ top: getCurrentTimeAsProgress() + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading"  class="loading-board">
        <div class="loader">
    </div>
  </div>

</div>
</div>
