import {Adapter} from "./person-autocomplete.component";
import {Student, Teacher} from "../../model/rest/casa-apiv2.model";
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";
import {Observable} from "rxjs";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {Pageable} from "../../model/rest/base";
import {map} from "rxjs/operators";

/**
 * Adapter which provides autocomplete entries basing on the provided term
 * Base adapter for student and teacher as they have very similar structure and
 * most of the code may be shared
  */
abstract class PersonAutocompleteAdapterBase<T extends Student | Teacher> implements Adapter<T> {
  abstract dataProvider(string): Observable<T[]>;

  mapper(item: T) {
    return PersonNameExtractor.getCasaPersonName(item.person);
  }

  detailsMapper(item: T) {
    if (!item || !item.person.registrationEmail) return null;
    return item.person.registrationEmail;
  }
}


/**
 * Student's specific autocomplete adapter
 */
export class StudentAutocompleteAdapter extends PersonAutocompleteAdapterBase<Student> {

  constructor(
    private schoolId: number,
    private casa: CasaRestApiV2
  ) {
    super();
  }

  dataProvider(term: string): Observable<Student[]> {
    return this.casa.getStudents(this.schoolId, term, null, null, Pageable.of(0, 5, null)).pipe(
      map( page => page.content)
    );
  }
}

/**
 * Teacher's specific autocomplete adapter
 */
export class TeacherAutocompleteAdapter extends PersonAutocompleteAdapterBase<Teacher> {

  constructor(
    private schoolId: number,
    private casa: CasaRestApiV2
  ) {
    super();
  }

  dataProvider(term: string): Observable<Teacher[]> {
    return this.casa.listTeachers(this.schoolId, term, Pageable.of(0, 5, null)).pipe(
      map( page => page.content)
    );
  }
}
